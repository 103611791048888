import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["checked", "disabled"];
const _hoisted_2 = {
    key: 0,
    class: "ml-1"
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeCheckbox',
    props: {
        label: {},
        checked: { type: Boolean },
        disabled: { type: Boolean }
    },
    emits: ['update:checked'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const onInput = (event) => emit('update:checked', event.target.checked);
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["flex select-none flex-row place-items-center text-gray-800 dark:text-gray-400 cursor-pointer", { 'cursor-not-allowed': _ctx.disabled }])
            }, [
                _createElementVNode("input", {
                    onInput: onInput,
                    type: "checkbox",
                    checked: _ctx.checked,
                    disabled: _ctx.disabled
                }, null, 40, _hoisted_1),
                (_ctx.label)
                    ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
