import BaseService from './base.service';
class CertificadosUsuariosService extends BaseService {
    constructor() {
        super({ resource: 'certificadoUsuario' });
    }
    createCertificateUser = (idCertificado, idUsuario, tipoCertificado, idCursoFormacao, certificate) => {
        const formData = new FormData();
        formData.append('file', certificate);
        return this.plantarApi.post(`${this.resource}/${idCertificado}/usuario/${idUsuario}/tipo/${tipoCertificado}/${idCursoFormacao}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };
}
export default new CertificadosUsuariosService();
