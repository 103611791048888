import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "absolute top-0 left-0 h-screen w-screen overflow-hidden bg-black/90 transition-opacity duration-500 z-[9999]"
};
const _hoisted_2 = { class: "absolute top-[10%] left-[18%] box-border flex h-full max-h-[80%] w-8/12 flex-col rounded-md bg-neutral-100 p-4 text-black dark:bg-[#1D1D1D] dark:text-[#FDFDFD]" };
const _hoisted_3 = {
    key: 0,
    class: "text-right uppercase flex justify-end gap-2"
};
const _hoisted_4 = {
    key: 1,
    class: "pt-2 text-right uppercase"
};
import { ref, computed, watch, onUnmounted, reactive } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import { useRouter } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import UiAlert from '@/components/ui/alert.vue';
import UiButton from '@/components/ui/button.vue';
import { useTermoDeUsoStore, useAuthStore, useDepartamentosStore, useUnidadesDeNegocioStore, useCargosStore, useUsuariosStore, useComunicacoesStore } from '@/stores';
export default /*@__PURE__*/ _defineComponent({
    __name: 'modalAceiteTermoDeUso',
    props: {
        preview: { type: Boolean }
    },
    emits: ['onClose'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const props = __props;
        const router = useRouter();
        const emit = __emit;
        const unidadesDeNegocioStore = useUnidadesDeNegocioStore();
        const departamentosStore = useDepartamentosStore();
        const comunicacoesStore = useComunicacoesStore();
        const termoDeUsoStore = useTermoDeUsoStore();
        const usuariosStore = useUsuariosStore();
        const cargosStore = useCargosStore();
        const authStore = useAuthStore();
        const show = ref(false);
        const termoDeUso = ref({
            conteudo: ''
        });
        const contentRef = ref();
        const canAccept = ref(false);
        const quillRef = ref();
        const userJobTitle = ref();
        const userUnit = ref();
        const userDepartment = ref();
        const localPromise = reactive({
            resolve: null,
            reject: null
        });
        const qlEditor = computed(() => document.querySelector('.ql-editor'));
        const user = computed(() => ({
            id: authStore.userLogged.id,
            nome: authStore.userLogged.nomeCompleto,
            unidade: userUnit.value?.nome || '',
            departamento: userDepartment.value?.nome || '',
            cargo: userJobTitle.value?.nome || ''
        }));
        watch(() => contentRef.value, () => {
            qlEditor.value?.addEventListener('scroll', handleScroll);
            if (qlEditor.value)
                canAccept.value = qlEditor.value?.offsetHeight === qlEditor.value?.scrollHeight ? true : false;
        });
        const handleScroll = () => {
            const div = qlEditor.value;
            if (canAccept.value)
                return;
            canAccept.value = div.offsetHeight + div.scrollTop >= div.scrollHeight - 20 ? true : false;
        };
        const back = () => {
            emit('onClose');
            show.value = false;
        };
        const logout = () => authStore.logout();
        const saveDecision = async (accept) => {
            window.removeEventListener('beforeunload', logout);
            if (accept && !props.preview) {
                await usuariosStore.acceptTermOfUse(user.value.id);
                authStore.userLogged.dataTermoDeUsoAceito = new Date().toLocaleDateString();
                comunicacoesStore.getUserCommunication();
            }
            else {
                authStore.logout();
                router.push('/login');
            }
            show.value = false;
            return localPromise.resolve(accept);
        };
        const getUserJobTitle = async () => {
            userJobTitle.value = await cargosStore.getById(authStore.userLogged.cargoId);
        };
        const getUserUnit = async () => {
            userUnit.value = await unidadesDeNegocioStore.getById(authStore.userLogged.unidadeDeNegocioId);
        };
        const getUserDepartment = async () => {
            userDepartment.value = await departamentosStore.getById(authStore.userLogged.departamentoId);
        };
        const open = async () => {
            try {
                if (!authStore.canAcceptTermOfUse && !props.preview) {
                    return (show.value = false);
                }
                !props.preview && window.addEventListener('beforeunload', logout);
                const termOfUseId = authStore.userLogged.termoDeUsoId;
                termoDeUso.value = await termoDeUsoStore.getById(termOfUseId);
                termoDeUso.value?.conteudo.includes('#CARGO#') && (await getUserJobTitle());
                termoDeUso.value?.conteudo.includes('#DEPARTAMENTO#') && (await getUserDepartment());
                termoDeUso.value?.conteudo.includes('#UNIDADEDENEGOCIO#') && (await getUserUnit());
                if (termoDeUso.value && termoDeUso.value?.conteudo) {
                    const date = new Date().toLocaleDateString();
                    const time = new Date().toLocaleTimeString();
                    termoDeUso.value.conteudo = termoDeUso.value.conteudo
                        .replaceAll('#USUARIO#', user.value.nome)
                        .replaceAll('#UNIDADEDENEGOCIO#', user.value.unidade)
                        .replaceAll('#DEPARTAMENTO#', user.value.departamento)
                        .replaceAll('#CARGO#', user.value.cargo)
                        .replaceAll('#DATA E HORA#', `${date} ${time}`)
                        .replaceAll('#DATA#', `${date}`)
                        .replaceAll('#HORA#', `${time}`);
                }
                show.value = true;
                return new Promise((resolve, reject) => {
                    localPromise.resolve = resolve;
                    localPromise.reject = reject;
                });
            }
            catch (error) {
                //
            }
        };
        onUnmounted(() => {
            document.removeEventListener('scroll', () => { });
            window.removeEventListener('beforeunload', logout);
        });
        __expose({
            open
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(VueFinalModal), {
                modelValue: show.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((show).value = $event)),
                classes: "overflow-y-auto bg-black/50",
                "content-class": "flex justify-center items-center w-full h-full box-border overflow-hidden",
                "esc-to-close": false,
                "click-to-close": false
            }, {
                default: _withCtx(() => [
                    _createVNode(_Transition, {
                        mode: "out-in",
                        "enter-active-class": "transition duration-300 transform",
                        "enter-from-class": "opacity-0 -translate-y-8",
                        "leave-active-class": "duration-300 transform",
                        "leave-to-class": "opacity-0 -translate-y-8",
                        appear: ""
                    }, {
                        default: _withCtx(() => [
                            (termoDeUso.value?.conteudo && show.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                    _createElementVNode("div", _hoisted_2, [
                                        _createVNode(UiAlert, {
                                            type: "warning",
                                            closable: false,
                                            icon: !canAccept.value ? 'lock' : 'lock-open',
                                            class: "mb-4"
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t('alerts.scrollTermsOfUse')), 1)
                                            ]),
                                            _: 1
                                        }, 8, ["icon"]),
                                        _createElementVNode("div", {
                                            ref_key: "contentRef",
                                            ref: contentRef,
                                            class: "h-full w-full overflow-hidden",
                                            onScroll: handleScroll
                                        }, [
                                            _createVNode(_unref(QuillEditor), {
                                                content: termoDeUso.value.conteudo,
                                                "onUpdate:content": _cache[0] || (_cache[0] = ($event) => ((termoDeUso.value.conteudo) = $event)),
                                                ref_key: "quillRef",
                                                ref: quillRef,
                                                "content-type": "html",
                                                "read-only": "",
                                                toolbar: "",
                                                theme: "",
                                                class: "h-full w-full"
                                            }, null, 8, ["content"])
                                        ], 544),
                                        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "my-4 border-b-0.5 -mx-4 border-0 border-solid border-b border-b-neutral-500/10" }, null, -1)),
                                        (!_ctx.preview)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                                _createVNode(UiButton, {
                                                    type: "primary",
                                                    outline: "",
                                                    class: "p-3",
                                                    onClick: _cache[1] || (_cache[1] = ($event) => (saveDecision(false)))
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.$t('labels.reject')), 1)
                                                    ]),
                                                    _: 1
                                                }),
                                                _createVNode(UiButton, {
                                                    type: "primary",
                                                    class: "p-3",
                                                    loading: _unref(usuariosStore).loading,
                                                    disabled: !canAccept.value,
                                                    onClick: _cache[2] || (_cache[2] = ($event) => (saveDecision(true)))
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.$t('button.accept')), 1)
                                                    ]),
                                                    _: 1
                                                }, 8, ["loading", "disabled"])
                                            ]))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                                _createVNode(UiButton, {
                                                    type: "primary",
                                                    class: "p-2",
                                                    onClick: back
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.$t('button.back')), 1)
                                                    ]),
                                                    _: 1
                                                })
                                            ]))
                                    ])
                                ]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["modelValue"]));
        };
    }
});
