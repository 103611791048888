import baseApi from './base.api';
// Filtros comuns utilizados no dashboard
const genFiltersParams = (filters) => {
    filters = filters || {};
    const resFilter = {
        DepartamentoIds: filters.departments?.length > 0 ? filters.departments : null,
        CargoIds: filters.roles?.length > 0 ? filters.roles : null,
        UnidadeDeNegocioIds: filters.units?.length > 0 ? filters.units : null,
        ConteudoIds: filters.contents?.length > 0 ? filters.contents : null,
        UsuariosIds: filters.users?.length > 0 ? filters.users : null,
        DataInicio: filters.dateRange?.[0] ? (new Date(filters.dateRange?.[0])).toISOString().split('T')[0] : '',
        DataFim: filters.dateRange?.[1] ? (new Date(filters.dateRange?.[1])).toISOString().split('T')[0] : ''
    };
    if (!resFilter.DataInicio)
        delete resFilter.DataInicio;
    if (!resFilter.DataFim)
        delete resFilter.DataFim;
    return resFilter;
};
const api = {
    ...baseApi,
    async getManagerData(filters) {
        const { success, data } = await api.setResource('DashboardGestor').post('', genFiltersParams(filters));
        return {
            success,
            data: {
                subscriptions: {
                    total: data?.inscricoes?.total || 0,
                    complete: data?.inscricoes?.concluido || 0,
                    completePercent: data?.inscricoes?.percentualConcluido || 0,
                    onGoing: data?.inscricoes?.emAndamento || 0,
                    onGoingPercent: data?.inscricoes?.percentualEmAndamento || 0,
                    notStarted: data?.inscricoes?.naoIniciado || 0,
                    notStartedPercent: data?.inscricoes?.percentualNaoIniciado || 0
                },
                contentSubscriptions: {
                    data: data?.inscricoesPorConteudo?.conteudos.map(item => ({
                        type: item.tipo,
                        complete: item.concluido || 0,
                        completePercent: item.percentualConcluido || 0,
                        onGoing: item.emAndamento || 0,
                        onGoingPercent: item.percentualEmAndamento || 0,
                        notStarted: item.naoIniciado || 0,
                        notStartedPercent: item.percentualNaoIniciado || 0
                    }) || []),
                    total: data?.inscricoesPorConteudo?.total || 0
                },
                hours: {
                    total: data?.horas?.total || 0,
                    complete: data?.horas?.concluido || 0,
                    completePercent: data?.horas?.percentualConcluido || 0,
                    onGoing: data?.horas?.emAndamento || 0,
                    onGoingPercent: data?.horas?.percentualEmAndamento || 0,
                },
                users: {
                    total: data?.usuarios?.total || 0,
                    active: data?.usuarios?.ativos || 0,
                    activePercent: data?.usuarios?.percentualAtivos || 0,
                    inactive: data?.usuarios?.inativos || 0,
                    inactivePercent: data?.usuarios?.percentualInativos || 0
                },
                details: {
                    subscriptions: data?.detalhesInscricoes?.map(item => ({
                        userId: item.usuarioId,
                        userStatus: item.ativo ? 'Ativo' : 'Inativo',
                        userName: item.nomeDoAluno,
                        userEmail: item.email,
                        userDocument: item.usuarioCpf,
                        contentName: item.nomeDoConteudo,
                        contentType: item.tipo,
                        contentCategory: item.categoria,
                        contentPercentage: item.percentualConsumoConteudo,
                        subscribedAt: item.dataInscricao,
                        completedAt: item.dataConclusao,
                        expired: item.expirado,
                        status: item.status,
                        certificateId: item.certificadoId,
                        courseOrFormationId: item.cursoFormacaoId,
                        origin: item.origem
                    }) || []),
                    hours: data?.detalhesHoras?.map(item => ({
                        userStatus: item.ativo ? 'Ativo' : 'Inativo',
                        userName: item.nomeDoAluno,
                        userEmail: item.email,
                        hoursTrained: item.horasTreinadas,
                        inscriptions: item.inscricoes,
                        contentType: item.tipo
                    }) || []),
                    consumedContent: data?.detalhesConteudosConsumidos?.map(item => ({
                        userStatus: item.ativo ? 'Ativo' : 'Inativo',
                        userName: item.nomeDoAluno,
                        userEmail: item.email,
                        contentName: item.nomeDoConteudo,
                        contentType: item.tipo,
                        startedAt: item.dataInicio,
                        completedAt: item.dataConclusao,
                        status: item.status,
                        contentCategory: item.categoria,
                        duration: item.duracao,
                        percent: item.percentualConsumoConteudo
                    }) || []),
                    access: data?.acessos?.detalhes?.map(el => ({
                        userStatus: el.ativo ? 'Ativo' : 'Inativo',
                        userName: el.aluno,
                        qtAccess: el.qtdAcessos,
                        lastAccess: el.ultimoAcesso
                    }))
                },
                consumedContent: {
                    total: data?.conteudosConsumidos?.total || 0,
                    complete: data?.conteudosConsumidos?.concluido || 0,
                    completePercent: data?.conteudosConsumidos?.percentualConcluido || 0,
                    onGoing: data?.conteudosConsumidos?.emAndamento || 0,
                    onGoingPercent: data?.conteudosConsumidos?.percentualEmAndamento || 0,
                },
                access: {
                    accessPerYear: data?.acessos?.acessosPorAno?.map(year => ({
                        year: year.ano,
                        total: year.total,
                        accessPerMonth: year.acessosPorMes.map(month => ({
                            month: month.mes,
                            total: month.total
                        }))
                    })),
                    total: data?.acessos?.total || 0
                }
            }
        };
    },
    async getUserData(filters) {
        const { success, data } = await api.setResource('DashboardAluno').post('', genFiltersParams(filters));
        return {
            success,
            data: {
                contentSubscriptions: {
                    total: data.inscricoesPorConteudo?.total || 0,
                    data: data.inscricoesPorConteudo?.conteudos.map(item => ({
                        type: item.tipo,
                        complete: item.concluido || 0,
                        completePercent: item.percentualConcluido || 0,
                        onGoing: item.emAndamento || 0,
                        onGoingPercent: item.percentualEmAndamento || 0,
                        notStarted: item.naoIniciado || 0,
                        notStartedPercent: item.percentualNaoIniciado || 0
                    })) || []
                },
                hours: {
                    total: data.horas?.total || 0,
                    course: data.horas?.curso || 0,
                    coursePercentage: data.horas?.percentualCurso || 0,
                    formation: data.horas?.formacao || 0,
                    formationPercentage: data.horas?.percentualFormacao || 0
                },
                onGoing: data.emAndamento?.map(item => ({
                    id: item.id,
                    type: item.tipo?.toLowerCase() === 'formação' ? 'Trilha' : item.tipo,
                    title: item.titulo,
                    cover: item.linkDaImagemDeCapa,
                    progress: item.percentualConsumoConteudo,
                    src: item.tipo?.toLowerCase() === 'scorm' ? item.scormScriptLink : item.urlDoRecurso
                }) || []),
                details: {
                    subscriptions: data.detalhesInscricoes?.map(item => ({
                        active: item.ativo,
                        name: item.nomeDoConteudo,
                        category: item.categoria,
                        type: item.tipo,
                        progress: item.percentualConsumoConteudo,
                        subscribedAt: item.dataInscricao,
                        completedAt: item.dataConclusao,
                        courseOrFormationId: item.cursoFormacaoId,
                        certificateId: item.certificadoId,
                        expired: item.expirado,
                        status: item.status,
                        origin: item.origem
                    }) || []),
                    hours: data.detalhesHoras?.map(item => ({
                        name: item.nomeDoConteudo,
                        trained: item.horasTreinadas,
                        progress: item.percentualConsumoConteudo,
                        type: item.tipo
                    }) || []),
                    consumedContent: data?.detalhesConteudosConsumidos?.map(item => ({
                        userStatus: item.ativo ? 'Ativo' : 'Inativo',
                        contentName: item.nomeDoConteudo,
                        contentType: item.tipo,
                        startedAt: item.dataInicio,
                        completedAt: item.dataConclusao,
                        status: item.status,
                        contentCategory: item.categoria
                    }) || []),
                    access: data?.acessos?.detalhes?.map(el => ({
                        dateAccess: el.dataHoraDoAcesso,
                    }))
                },
                consumedContent: {
                    total: data?.conteudosConsumidos?.total || 0,
                    complete: data?.conteudosConsumidos?.concluido || 0,
                    completePercent: data?.conteudosConsumidos?.percentualConcluido || 0,
                    onGoing: data?.conteudosConsumidos?.emAndamento || 0,
                    onGoingPercent: data?.conteudosConsumidos?.percentualEmAndamento || 0,
                },
                access: {
                    accessPerYear: data?.acessos?.acessosPorAno?.map(year => ({
                        year: year.ano,
                        total: year.total,
                        accessPerMonth: year.acessosPorMes.map(month => ({
                            month: month.mes,
                            total: month.total
                        }))
                    })),
                    total: data?.acessos?.total || 0
                }
            }
        };
    }
};
export default api;
