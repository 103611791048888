import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["disabled"];
import { useEyeDropper } from '@vueuse/core';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeEyeDropper',
    props: {
        modelValue: {}
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const { isSupported, open } = useEyeDropper({
            initialValue: props.modelValue
        });
        const onClick = () => {
            open().then((val) => {
                emit('update:modelValue', val.sRGBHex);
            }).catch(() => {
                //
            });
        };
        return (_ctx, _cache) => {
            const _component_pe_icon = _resolveComponent("pe-icon");
            return (_openBlock(), _createElementBlock("button", {
                disabled: !_unref(isSupported),
                onClick: _cache[0] || (_cache[0] = ($event) => (onClick())),
                class: "w-6 h-6 flex items-center justify-center p-0 rounded-full border border-black/20"
            }, [
                _createVNode(_component_pe_icon, { icon: "colorize" })
            ], 8, _hoisted_1));
        };
    }
});
