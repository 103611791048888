import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "pt-20 pb-10 px-12 relative box-border flex gap-y-10 w-full max-w-[380px] flex-col items-center gap-4 rounded-[32px] bg-neutral-50 text-neutral-700 dark:bg-neutral-800 dark:text-neutral-200 text-center shadow-[0_4px_4px_8px_rgba(0,0,0,.1)]" };
const _hoisted_2 = ["src"];
const _hoisted_3 = { class: "text-4xl font-semibold" };
const _hoisted_4 = { class: "w-full flex gap-4 justify-between" };
const _hoisted_5 = ["src"];
const _hoisted_6 = { class: "grow" };
const _hoisted_7 = { class: "text-xl text-neutral-400" };
const _hoisted_8 = { class: "text-2xl text-[--c-primary-400] font-semibold" };
const _hoisted_9 = { class: "w-full rounded-md bg-amber-100 dark:bg-amber-800 p-3 mb-1 text-sm" };
const _hoisted_10 = { class: "flex gap-4" };
import { onBeforeMount, ref } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import UiButton from '@/components/ui/button.vue';
import Sparks from '@/components/ui/sparks/index.vue';
import CountTo from '@/views/gestao/dashboard/components/counter.vue';
import ImgGamePointsStack from '@/assets/game/icon-points-stack.svg';
import ImgGameSparks from '@/assets/game/game-sparkles.svg';
export default /*@__PURE__*/ _defineComponent({
    __name: 'completation',
    props: {
        title: {},
        subtitle: {},
        points: {}
    },
    emits: ["confirm"],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const props = __props;
        const values = ref({
            title: '',
            subtitle: '',
            points: 0
        });
        const disabled = ref(false);
        const close = () => {
            disabled.value = true;
            values.value.points = 0;
            setTimeout(() => {
                emit('confirm');
            }, 1000);
        };
        onBeforeMount(() => {
            values.value = {
                title: props.title || '',
                subtitle: props.subtitle || '',
                points: props.points || 0
            };
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(VueFinalModal), {
                class: "flex justify-center items-center",
                "content-transition": {
                    mode: 'out-in',
                    enterActiveClass: 'transition ease-out duration-150 transform',
                    enterFromClass: 'opacity-0 scale-50 -translate-y-1/2',
                    leaveActiveClass: 'ease-in duration-200',
                    leaveToClass: 'opacity-0 scale-50 -translate-y-1/2'
                }
            }, {
                default: _withCtx(() => [
                    _createVNode(Sparks, null, {
                        default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                                _createElementVNode("img", {
                                    src: _unref(ImgGameSparks),
                                    class: "w-20 h-20 absolute right-0 top-0"
                                }, null, 8, _hoisted_2),
                                _createElementVNode("div", _hoisted_3, _toDisplayString(values.value.title), 1),
                                _createElementVNode("div", _hoisted_4, [
                                    _createElementVNode("img", {
                                        src: _unref(ImgGamePointsStack),
                                        alt: "points",
                                        class: "h-16 w-16 object-contain"
                                    }, null, 8, _hoisted_5),
                                    _createElementVNode("div", _hoisted_6, [
                                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('gamification.won')), 1),
                                        _createElementVNode("div", _hoisted_8, [
                                            _cache[1] || (_cache[1] = _createTextVNode("+ ")),
                                            _createVNode(CountTo, {
                                                value: values.value.points
                                            }, null, 8, ["value"]),
                                            _cache[2] || (_cache[2] = _createTextVNode(" pts"))
                                        ])
                                    ])
                                ]),
                                _createElementVNode("div", _hoisted_9, _toDisplayString(values.value.subtitle), 1),
                                _createElementVNode("div", _hoisted_10, [
                                    _createVNode(UiButton, {
                                        type: "primary",
                                        class: "p-4",
                                        loading: disabled.value,
                                        onClick: _cache[0] || (_cache[0] = ($event) => (close()))
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t('gamification.getPoints')), 1)
                                        ]),
                                        _: 1
                                    }, 8, ["loading"])
                                ])
                            ])
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }));
        };
    }
});
