import BaseService from './base.service';
import baseApi from './base.api';
import { toast } from '@/common/util';
export const courseService = {
    ...baseApi,
    resource: 'curso',
    async getSubscriptions(opt, options) {
        if (!opt.id)
            throw new Error('Id do curso não informado!');
        if (typeof opt.status === 'undefined')
            throw new Error('Tipo não informado!');
        const paramsDictionary = {
            active: 'ativo',
            name: 'nomeCompleto',
            search: 'search',
            positionIds: 'CargoIds',
            departmentsIds: 'departamentoIds',
            unitIds: 'unidadeDeNegocioIds',
            origin: 'origem',
            page: 'page',
            size: 'size'
        };
        const originDictionary = {
            manual: 'Manual',
            manager: 'Gestor',
            'auto-position': 'AutoCargo',
            'auto-unit': 'AutoUnidade',
            import: 'Importacao'
        };
        const orderDictionary = {
            name: 'Usuario',
            department: 'Departamento',
            position: 'Cargo',
            unit: 'Unidade',
            origin: 'Origem'
        };
        const extraData = {};
        Object.keys(opt).forEach((key) => {
            if (typeof paramsDictionary[key] !== 'undefined' && typeof opt[key] !== 'undefined' && opt[key] !== null)
                extraData[paramsDictionary[key]] = opt[key];
            if (key === 'origin' && extraData[paramsDictionary[key]]) {
                extraData[paramsDictionary[key]] = originDictionary[extraData[paramsDictionary[key]]];
            }
        });
        // Ordenação
        if (opt.orderBy?.length) {
            extraData['Ordenacao'] = opt.orderBy.map((item) => `${orderDictionary[item.prop]}${item.order === 'DESC' ? 'Desc' : 'Asc'}`);
        }
        // if (opt.order && orderDictionary[opt.order]) {
        //   extraData['Ordenacao'] = orderDictionary[opt.order] + (opt.direction === 'desc' ? 'Desc' : 'Asc')
        // }
        return courseService
            .get(`Usuarios/${opt.status}/${opt.id}`, extraData, options)
            .then((res) => ({
            status: res.success,
            page: res.pageNumber,
            size: res.pageSize,
            total: res.totalItems,
            pages: res.totalPages,
            data: res.data?.map((item) => ({
                id: item.id || null,
                userId: item.usuarioId,
                name: item.nome,
                active: item.active,
                progress: item.percentualConsumoConteudo || 0,
                unit: item.unidade,
                department: item.departamento,
                position: item.cargo,
                origin: item.origem
            }))
        }));
    },
    /**
     * Inscreve o usuário em um curso
     * @param courseId
     */
    async subscribe(courseId) {
        return await courseService.post([courseId, 'subscribe'].join('/'));
    },
    /**
     * Inscreve o usuário em um curso
     * @param courseId
     */
    async unsubscribe(courseId) {
        return await courseService.post([courseId, 'unsubscribe'].join('/'));
    },
    async getCourses(opt, options) {
        const paramsDictionary = {
            id: 'id',
            active: 'ativo',
            title: 'titulo',
            search: 'search',
            categoryId: 'categoriaId',
            courseIds: 'cursoIds',
            code: 'codigoCurso',
            competenceIds: 'competenciasIds',
        };
        const paramsToSend = {};
        if (opt) {
            Object.keys(opt).forEach((key) => {
                if (typeof paramsDictionary[key] !== 'undefined' && typeof opt[key] !== 'undefined' && opt[key] !== null)
                    paramsToSend[paramsDictionary[key]] = opt[key];
            });
        }
        return courseService.get('', paramsToSend, options)
            .then((res) => ({
            status: res.success,
            data: res.data?.map((item) => ({
                id: item.id || null,
                titulo: item.titulo,
                active: item.active,
                origem: item.origem,
                synopsis: item.sinopse,
                description: item.descricao,
                cargaHoraria: item.cargaHoraria,
                categoriaId: item.categoriaId,
                certificadoId: item.certificadoId,
                categoriaNome: item.categoriaNome,
                obrigatorio: item.obrigatorio,
                sequenciaObrigatoria: item.sequenciaObrigatoria,
                limiteDeDiasParaConclusao: item.limiteDeDiasParaConclusao,
                aproveitamentoRequerido: item.aproveitamentoRequerido,
                temMaterialComplementar: item.temMaterialComplementar,
                competenciasIds: item.competenciasIds,
                secoesIds: item.secoesIds,
                itens: item.itens,
                itensDoCurso: item.itensDoCurso,
                linkDaImagemDeCapa: item.linkDaImagemDeCapa,
                contadores: item.contadores,
                createdAt: item.createdAt,
                inscrito: item.inscrito,
                ativo: item.ativo,
                finalizado: item.finalizado,
                porcentagemDeConclusao: item.porcentagemDeConclusao,
                expirado: item.expirado,
                codigoCurso: item.codigoCurso,
                inscricoes: item.inscricoes,
                dataExpirada: item.dataExpirada,
                desafiosIds: item.desafiosIds,
                possuiAnotacao: item.possuiAnotacao,
                cursoOrigemId: item.cursoOrigemId,
            })),
        }));
    },
    async bookmark(courseId) {
        return await courseService.post([courseId, 'bookmark'].join('/'));
    },
    async unbookmark(courseId) {
        return await courseService.post([courseId, 'unbookmark'].join('/'));
    },
    async setReactionCourse(params) {
        return await courseService.post(`SetReaction`, params);
    }
};
class CursosService extends BaseService {
    constructor() {
        super({ resource: 'curso' });
    }
    /**
     * Inscreve o usuário em um curso
     * @param courseId
     */
    async fileUpload(id, file) {
        const formData = new FormData();
        formData.append('arquivo', file);
        formData.append('cursoId', id);
        return await this.plantarApi.post(`${this.resource}/${id}/fileUpload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    async getBonds(cursoId) {
        return this.plantarApi.get(`${this.resource}/vinculos/${cursoId}`);
    }
    async sendSubscriptions(data, inscrever) {
        return this.plantarApi.post(`${this.resource}/inscreverusuarios/${inscrever}`, data);
    }
    async getDetails(courseId) {
        try {
            return this.plantarApi.get(`${this.resource}/Detalhes/${courseId}`);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] });
        }
    }
}
const cursosService = new CursosService();
export default cursosService;
