import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "relative" };
const _hoisted_2 = { class: "p-2 text-xs leading-3 max-w-48 text-center" };
const _hoisted_3 = { class: "font-semibold mb-1" };
import { ref, inject } from 'vue';
import UiButton from '@/components/ui/button.vue';
import UiDropdown from '@/components/ui/dropdown.vue';
import UiIcon from '@/components/ui/icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'versionCheck',
    setup(__props) {
        const dropdown = ref(null);
        const systemUpdate = inject('app.systemUpdate', ref({
            hasUpdate: false,
            hash: ''
        }));
        const reload = () => {
            if (systemUpdate.value.hash) {
                localStorage.setItem('version', systemUpdate.value.hash);
            }
            window.location.reload();
        };
        return (_ctx, _cache) => {
            const _directive_tooltip = _resolveDirective("tooltip");
            return (_unref(systemUpdate).hasUpdate)
                ? (_openBlock(), _createBlock(UiDropdown, {
                    key: 0,
                    ref_key: "dropdown",
                    ref: dropdown
                }, {
                    default: _withCtx(() => [
                        _withDirectives((_openBlock(), _createBlock(UiButton, {
                            type: "ghost",
                            class: "h-10 w-10",
                            title: _ctx.$t('versionManager.newVersion')
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_1, [
                                    _createVNode(UiIcon, {
                                        icon: "devices-exclamation",
                                        size: 24
                                    }),
                                    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute -top-2 -right-2 w-2 h-2 bg-red-500 rounded-full animate-pulse" }, null, -1))
                                ])
                            ]),
                            _: 1
                        }, 8, ["title"])), [
                            [_directive_tooltip]
                        ])
                    ]),
                    content: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('versionManager.newAvailable')), 1),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('versionManager.newInstructions')), 1),
                            _createVNode(UiButton, {
                                type: "primary",
                                class: "px-2 py-1 mt-2",
                                onClick: reload
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('versionManager.update')), 1)
                                ]),
                                _: 1
                            })
                        ])
                    ]),
                    _: 1
                }, 512))
                : _createCommentVNode("", true);
        };
    }
});
