import { toast } from '@/common/util';
import BaseService from './base.service';
class InscricoesService extends BaseService {
    constructor() {
        super({ resource: 'inscricao' });
    }
    async validateFile(file) {
        const formData = new FormData();
        formData.append('file', file);
        return await this.plantarApi.post(`${this.resource}/validatebatch/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    async importSubscriptions(fileId) {
        try {
            return await this.plantarApi.post(`${this.resource}/importbatch/${fileId}`);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar importar dados!' });
        }
    }
}
const inscricoesService = new InscricoesService();
export default inscricoesService;
