import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, normalizeClass as _normalizeClass, Teleport as _Teleport, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "box-border self-end z-10 m-6 max-[380px]:max-h-[250px] max-h-[224px] max-w-[364px] h-full w-full rounded-md bg-[#FDFDFD] p-6 text-[#666666] dark:bg-[#1D1D1D] dark:text-[#D2D2D2]"
};
const _hoisted_2 = { class: "mb-4 font-semibold text-[#212121]" };
const _hoisted_3 = { class: "mb-4 text-sm leading-4" };
const _hoisted_4 = {
    key: 0,
    class: "absolute top-0 left-0 z-10 box-border h-full w-full p-4"
};
const _hoisted_5 = { class: "box-border flex h-full w-full flex-col rounded-md bg-neutral-900 p-4" };
const _hoisted_6 = { class: "py-4" };
const _hoisted_7 = ["src"];
import { ref, computed } from 'vue';
import { cookies } from '@/common/cookies';
import UiButton from '@/components/ui/button.vue';
import UiIcon from '@/components/ui/icon.vue';
import { useSystemStore } from '@/stores';
export default /*@__PURE__*/ _defineComponent({
    __name: 'cookies',
    setup(__props) {
        const systemStore = useSystemStore();
        const hasAccepted = ref(cookies.getCookie('privacy-policy') || false);
        const showPrivacyPolicy = ref(false);
        const privacyPolicyLink = computed(() => systemStore.clientSettings.settings.general.privacyPolicyUrl || 'https://www.plantareducacao.com.br/politica-de-privacidade/');
        const acceptCookies = () => {
            cookies.addCookie('privacy-policy', true);
            hasAccepted.value = true;
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
                _createElementVNode("div", {
                    class: _normalizeClass(["absolute top-0 left-0 h-screen w-screen overflow-hidden bg-black/50 transition-opacity duration-500 flex", { 'pointer-events-none !bg-black/0': hasAccepted.value }])
                }, [
                    _createVNode(_Transition, {
                        mode: "out-in",
                        "enter-active-class": "transition duration-1000 transform",
                        "enter-from-class": "opacity-0 translate-y-full",
                        "leave-active-class": "duration-1000 transform",
                        "leave-to-class": "opacity-0 translate-y-full",
                        appear: ""
                    }, {
                        default: _withCtx(() => [
                            (!hasAccepted.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('mainContent.cookies.title')), 1),
                                    _createElementVNode("div", _hoisted_3, [
                                        _createTextVNode(_toDisplayString(_ctx.$t('mainContent.cookies.content')) + " ", 1),
                                        _createElementVNode("a", {
                                            href: "#",
                                            onClick: _cache[0] || (_cache[0] = ($event) => (showPrivacyPolicy.value = true)),
                                            class: "font-semibold"
                                        }, _toDisplayString(_ctx.$t('labels.privacyPolicy')), 1),
                                        _createTextVNode(". " + _toDisplayString(_ctx.$t('mainContent.cookies.continue')), 1)
                                    ]),
                                    _createElementVNode("div", null, [
                                        _createVNode(UiButton, {
                                            type: "primary",
                                            class: "w-full p-4",
                                            onClick: _cache[1] || (_cache[1] = ($event) => (acceptCookies()))
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t('button.accept')), 1)
                                            ]),
                                            _: 1
                                        })
                                    ])
                                ]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    }),
                    _createVNode(_Transition, {
                        mode: "out-in",
                        "enter-active-class": "transition duration-300 transform",
                        "enter-from-class": "opacity-0 -translate-y-8",
                        "leave-active-class": "duration-300 transform",
                        "leave-to-class": "opacity-0 -translate-y-8",
                        appear: ""
                    }, {
                        default: _withCtx(() => [
                            (showPrivacyPolicy.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    _createElementVNode("div", _hoisted_5, [
                                        _createElementVNode("div", _hoisted_6, [
                                            _createElementVNode("a", {
                                                href: "#",
                                                class: "border-0 border-b-2 border-solid border-transparent py-2 pr-1 !text-neutral-50 no-underline transition-all hover:border-neutral-50",
                                                onClick: _cache[2] || (_cache[2] = ($event) => (showPrivacyPolicy.value = false))
                                            }, [
                                                _createVNode(UiIcon, { icon: "arrow-left" }),
                                                _createTextVNode(_toDisplayString(_ctx.$t('button.back')), 1)
                                            ])
                                        ]),
                                        _createElementVNode("iframe", {
                                            src: privacyPolicyLink.value,
                                            frameborder: "0",
                                            class: "h-full w-full"
                                        }, null, 8, _hoisted_7)
                                    ])
                                ]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    })
                ], 2)
            ]));
        };
    }
});
