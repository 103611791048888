import { toast } from '@/common/util';
import { plantarApi } from '@/config/appAPIs';
import i18n from '@/plugins/i18n';
const { t } = i18n.global;
export class BaseService {
    resource;
    plantarApi;
    isSilent;
    constructor({ resource }) {
        this.resource = resource;
        this.plantarApi = plantarApi;
    }
    /**
     * Silencia as notificações automaticas
     * @param status
     */
    silent(status) {
        this.isSilent = typeof status !== 'undefined' ? status : true;
    }
    setApiVersion(version) {
        const { plantar } = window.configuration || { plantar: {} };
        this.plantarApi.defaults.baseURL = [plantar.host, `api/v${version || 1}`].join('/');
    }
    /**
     * Retorna o caminho para um recurso
     * @param {string} resourceId
     * @returns
     */
    getPath(resourceId) {
        return resourceId ? [this.resource, resourceId].join('/') : this.resource;
    }
    /**
     * Efetua uma requisição do tipo POST para a API
     * @param {object} resourceData
     * @param {string} type opcional ex: 'multipart'
     * @returns
     */
    async create(resourceData, type) {
        let postData = resourceData;
        let headers = {};
        if (type === 'multipart') {
            postData = new FormData();
            headers['Content-Type'] = 'multipart/form-data';
            const keys = Object.keys(resourceData);
            for (let i = 0; i < keys.length; i++)
                postData.append(keys[i], resourceData[keys[i]]);
        }
        try {
            const { data, success } = await this.plantarApi.post(this.getPath(), postData, { headers });
            if (success && !this.isSilent)
                toast.success({ message: t('toasts.createSuccess') });
            return { data, success, notifications: [] };
        }
        catch (error) {
            const { notifications, success } = error.response?.data || {};
            return {
                data: null,
                success,
                notifications
            };
        }
    }
    /**
     * Efetua uma requisição GET para a API
     * @param {string} resourceId Se informado executa uma função "SelectOne" se omitido "SelectAll"
     * @returns
     */
    get(resourceId, params) {
        return this.plantarApi.get(this.getPath(resourceId), {
            params
        });
    }
    /**
     * Efetua uma requisição PUT para API
     * @param {string} resourceId Obrigatório, index do elemento a ser alterado
     * @param {object} resourceData Dados a serem gravados
     * @param {string} type opcional ex: 'multipart'
     * @returns
     */
    async update(resourceId, resourceData, type) {
        let postData = resourceData;
        let headers = {};
        if (type === 'multipart') {
            postData = new FormData();
            headers['Content-Type'] = 'multipart/form-data';
            const keys = Object.keys(resourceData);
            for (let i = 0; i < keys.length; i++)
                postData.append(keys[i], resourceData[keys[i]]);
        }
        try {
            const { data, success } = await this.plantarApi.put(this.getPath(resourceId), postData, { headers });
            if (success && !this.isSilent)
                toast.success({ message: t('toasts.updateSuccess') });
            return { data, success, notifications: [] };
        }
        catch (error) {
            const { notifications, success } = error.response?.data || {};
            return {
                data: null,
                success,
                notifications
            };
        }
    }
    /**
     * Efetua uma requisição DELETE para API
     * @param { string } resourceId Obrigatório, index do elemento a ser removido
     * @returns
     */
    async remove(resourceId) {
        try {
            const { success } = await this.plantarApi.delete(this.getPath(resourceId));
            if (success && !this.isSilent) {
                if (this.resource === 'formacao') {
                    toast.success({
                        message: 'Trilha removida com sucesso'
                    });
                }
                else {
                    toast.success({
                        message: 'Registro removido com sucesso'
                    });
                }
            }
            return { success, notifications: [] };
        }
        catch (error) {
            const { notifications, success } = error.response?.data || {};
            return {
                success,
                notifications
            };
        }
    }
    /**
     * Envia uma requisição GET para API, para receber um arquivo csv
     * @returns
     */
    exportCsv() {
        return this.plantarApi.get(this.getPath('csv'));
    }
}
export default BaseService;
