// eslint-disable-next-line no-unused-vars
import { computePosition, flip, shift, offset } from '@floating-ui/vue';
import { nextTick } from 'vue';
import { useEventListener } from '@vueuse/core';
let c = 0;
const appear = (el, opt) => {
    if (!el.getAttribute('ui-title') && !el.getAttribute('title'))
        return;
    opt = opt || {};
    let uId = el.dataset?.uittId;
    if (!uId) {
        let ttId = `tt-${c++}`;
        el.dataset.uittId = ttId;
    }
    let ttContainer = document.getElementById(el.dataset.uittId);
    if (!ttContainer) {
        ttContainer = document.createElement('div');
        ttContainer.id = el.dataset.uittId;
        ttContainer.classList.add(...'text-sm max-w-[320px] absolute bg-neutral-950/90 text-white p-2 rounded-md transition-opacity z-[100000]'.split(' '));
        ttContainer.style.opacity = '0';
        ttContainer.style.display = 'none';
        ttContainer.setAttribute('role', 'tooltip');
        document.body.appendChild(ttContainer);
        ttContainer.addEventListener('transitionend', () => {
            ttContainer.remove();
        });
    }
    const update = () => {
        ttContainer.innerHTML = el.getAttribute('ui-title') || el.getAttribute('title');
        computePosition(el, ttContainer, {
            placement: opt.placement || 'top',
            middleware: [offset(4), flip(), shift({ padding: 4 }), /* arrow({ element: arrowEl }) */]
            // eslint-disable-next-line no-unused-vars
        }).then(({ x, y, placement, middlewareData }) => {
            Object.assign(ttContainer.style, {
                left: `${x}px`,
                top: `${y}px`
            });
            // Accessing the data
            // const { x: arrowX, y: arrowY } = middlewareData.arrow
            // const staticSide = {
            //   top: 'bottom',
            //   right: 'left',
            //   bottom: 'top',
            //   left: 'right'
            // }[placement.split('-')[0]]
            // Object.assign((arrowEl as any).style, {
            //   left: arrowX !== null ? `${arrowX}px` : '',
            //   top: arrowY !== null ? `${arrowY}px` : '',
            //   right: '',
            //   bottom: '',
            //   [staticSide]: '-4px'
            // })
        });
    };
    nextTick(() => {
        ttContainer.style.display = 'block';
        ttContainer.style.opacity = '1';
        update();
    });
};
const disappear = (el) => {
    if (el.dataset.uittId === undefined)
        return;
    const ttContainer = document.getElementById(el.dataset.uittId);
    if (!ttContainer)
        return;
    ttContainer.style.opacity = '0';
};
const onMouseEnter = (el, binding) => {
    appear(el, binding);
};
const onMouseLeave = (el) => {
    disappear(el);
};
export default {
    mounted(el, binding) {
        if (binding.value === false)
            return;
        useEventListener(el, 'mouseenter', () => {
            onMouseEnter(el, binding);
            if (el.title)
                el.setAttribute('ui-title', el.title);
            el.title = '';
        });
        useEventListener(el, 'mouseleave', () => {
            onMouseLeave(el);
        });
    },
    unmounted(el) {
        // if (el.dataset.uittId === undefined) return
        // el.removeEventListener('mouseenter', onMouseEnter)
        // el.removeEventListener('mouseleave', onMouseLeave)
        document.getElementById(el.dataset.uittId)?.remove();
    },
    updated() {
    }
};
