import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createBlock as _createBlock, withCtx as _withCtx, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, Transition as _Transition } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "absolute top-0 left-0 z-50 h-full max-h-full w-screen overflow-hidden bg-black/60 dark:bg-white/30 box-border p-6 flex items-center justify-center"
};
const _hoisted_2 = { class: "box-border w-full flex flex-col gap-4 items-end" };
const _hoisted_3 = { class: "box-border w-full flex justify-center items-center gap-2" };
const _hoisted_4 = ["src", "onClick"];
const _hoisted_5 = { class: "box-border w-full flex flex-col justify-between items-center gap-3 overflow-hidden" };
const _hoisted_6 = { class: "flex flex-col gap-3 px-4 overflow-auto" };
const _hoisted_7 = { class: "relative over" };
const _hoisted_8 = ["src"];
const _hoisted_9 = { class: "block md:hidden absolute z-1 -bottom-2 -right-2 drop-shadow-2xl transition-opacity" };
const _hoisted_10 = { class: "box-border text-[#212121] dark:text-[#FDFDFD] text-sm md:text-base w-full leading-[17px] font-semibold p-0 m-0" };
const _hoisted_11 = {
    key: 0,
    class: "w-full flex items-center justify-center gap-4"
};
const _hoisted_12 = {
    key: 0,
    class: "flex gap-1 transition-all delay-150"
};
const _hoisted_13 = { class: "w-full text-black box-border text-sm flex flex-wrap justify-center md:justify-between items-center gap-4" };
const _hoisted_14 = {
    key: 0,
    for: "showAgain",
    class: "cursor-pointer truncate"
};
import { ref, computed, nextTick, watch } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import { useScroll } from '@vueuse/core';
import webStorage from '@/common/webStorage';
import { useComunicacoesStore, useSystemStore } from '@/stores';
import { debounce, safeCopy, validateLink } from '@/common/util';
import UiIcon from '@/components/ui/icon.vue';
import UiButton from '@/components/ui/button.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'modalViewCommunication',
    props: {
        preview: { type: Boolean },
        showAllCommunication: { type: Boolean }
    },
    emits: ['onClose'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const emit = __emit;
        const props = __props;
        const comunicacoesStore = useComunicacoesStore();
        const systemStore = useSystemStore();
        const showLargeImg = ref(false);
        const show = ref(false);
        const communications = ref([]);
        const indexCommunication = ref(0);
        const indexPost = ref(0);
        const communicationsRef = ref();
        const backButtonRef = ref(null);
        const { arrivedState } = useScroll(communicationsRef);
        const showButtons = computed(() => communications.value.length > 1 || currentCommunication.value.posts.length > 1);
        const darkMode = computed(() => systemStore.options.dark === 0);
        const canDisable = computed(() => !props.preview && isLastPost.value && !currentCommunication.value.forceDisplay);
        const currentCommunication = computed(() => communications.value[indexCommunication.value]);
        const currentPost = computed(() => currentCommunication.value.posts[indexPost.value]);
        const isLastPost = computed(() => currentCommunication.value.posts.length === (indexPost.value + 1));
        const isFirstPost = computed(() => indexPost.value === 0);
        const isLastCommunication = computed(() => communications.value.length === (indexCommunication.value + 1));
        const isFirstCommunication = computed(() => indexCommunication.value === 0);
        const qtdPosts = computed(() => currentCommunication.value?.posts?.length);
        const hasNextCommunication = computed(() => !!communications.value[indexCommunication.value + 1]);
        const hasPreviousCommunication = computed(() => !!communications.value[indexCommunication.value - 1]);
        const hasControls = computed(() => communicationsRef.value?.scrollWidth > communicationsRef.value?.clientWidth);
        const noCoverImage = require('@/assets/placeholder/no_cover.jpg');
        watch(() => indexPost.value, () => {
            embededVideo();
            debounce(() => insertOrRemoveDarkModeEditor(), 50)();
        });
        const getImage = (communication) => communication.posts.find(post => !!post.linkImage)?.linkImage || noCoverImage;
        const insertOrRemoveDarkModeEditor = () => {
            if (darkMode.value)
                document.querySelector(`#post-${indexPost.value}`)?.classList.add('mavon-editor-dark-mode');
            else
                document.querySelector(`#post-${indexPost.value}`)?.classList.remove('mavon-editor-dark-mode');
        };
        const embededVideo = () => {
            nextTick(() => {
                document.querySelectorAll(`.v-show-content p`).forEach(el => {
                    const isYoutube = validateLink.youtube(el?.textContent);
                    const isVimeo = validateLink.vimeo(el?.textContent);
                    if (isYoutube || isVimeo) {
                        let videoId = '';
                        let srcLink = '';
                        if (isYoutube) {
                            videoId = el.textContent.includes('v=') ? el.textContent.split('v=')[1] : el.textContent.split('/').at(-1);
                            videoId = videoId.includes('&list') ? videoId.split('&list')[0] : videoId;
                            srcLink = `https://www.youtube.com/embed/${videoId}`;
                        }
                        if (isVimeo) {
                            videoId = el.textContent.split('/').at(-1);
                            srcLink = `https://player.vimeo.com/video/${videoId}`;
                        }
                        const newEl = `<iframe style="width: 100%;"  height="460" src="${srcLink}" async frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
                        const newElm = document.createElement('div');
                        newElm.insertAdjacentHTML('beforeend', newEl);
                        el.replaceWith(newElm);
                    }
                });
            });
        };
        const stepBack = () => {
            communicationsRef.value.scrollLeft -= 288 * 1;
        };
        const stepNext = () => {
            const child0 = communicationsRef.value.children[0]?.getBoundingClientRect().x;
            const child1 = communicationsRef.value.children[1]?.getBoundingClientRect().x;
            const padding = 20;
            const extraSpace = arrivedState.left ? backButtonRef.value.getBoundingClientRect().width + padding : 0;
            const scrollAmount = child1 - child0 - extraSpace;
            communicationsRef.value.scrollLeft += scrollAmount > 0 ? scrollAmount : 20;
        };
        const previousPost = () => {
            nextTick(() => {
                isFirstPost.value && hasPreviousCommunication.value
                    ? changeCommunication((indexCommunication.value - 1))
                    : indexPost.value -= 1;
            });
        };
        const nextPost = () => {
            nextTick(() => {
                isLastPost.value && hasNextCommunication.value
                    ? changeCommunication((indexCommunication.value + 1))
                    : indexPost.value += 1;
            });
        };
        const reset = () => {
            indexCommunication.value = 0;
            indexPost.value = 0;
        };
        const changeCommunication = (index) => {
            nextTick(() => {
                indexPost.value = 0;
                indexCommunication.value = index;
            });
        };
        const open = async (id, communication) => {
            reset();
            if (props.showAllCommunication) {
                const data = await comunicacoesStore.getUserCommunication(props.showAllCommunication);
                communications.value = safeCopy(data);
            }
            else if (!id && !props.preview) {
                communications.value = safeCopy(comunicacoesStore.userCommunications);
            }
            if (!id && communication) {
                communications.value = [communication];
            }
            if (id && props.preview) {
                const data = await comunicacoesStore.getById(id);
                communications.value = [data];
            }
            if (!communications.value.length)
                return;
            show.value = true;
            embededVideo();
            debounce(() => insertOrRemoveDarkModeEditor(), 200)();
        };
        const close = () => {
            // Verifica e salva alterações de "Não exibir novamente"
            !props.preview && comunicacoesStore.toggledontDisplay([...communications.value]);
            webStorage.removeItem('showCommunication');
            show.value = false;
            emit('onClose');
            reset();
        };
        __expose({
            open,
            close
        });
        return (_ctx, _cache) => {
            const _component_VueEasyLightbox = _resolveComponent("VueEasyLightbox");
            const _component_mavon_editor = _resolveComponent("mavon-editor");
            return (_openBlock(), _createBlock(_unref(VueFinalModal), {
                modelValue: show.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((show).value = $event)),
                classes: "overflow-y-auto",
                "content-class": "flex justify-center items-center w-full h-full box-border overflow-hidden"
            }, {
                default: _withCtx(() => [
                    _createVNode(_Transition, {
                        mode: "out-in",
                        "enter-active-class": "transition duration-300 transform",
                        "enter-from-class": "opacity-0 -translate-y-8",
                        "leave-active-class": "duration-300 transform",
                        "leave-to-class": "opacity-0 -translate-y-8",
                        appear: ""
                    }, {
                        default: _withCtx(() => [
                            (show.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                    (_openBlock(), _createElementBlock("div", {
                                        key: indexPost.value,
                                        class: "card-communication",
                                        id: "card-communication"
                                    }, [
                                        _createElementVNode("div", _hoisted_2, [
                                            _createElementVNode("div", _hoisted_3, [
                                                (hasControls.value)
                                                    ? (_openBlock(), _createElementBlock("div", {
                                                        key: 0,
                                                        ref_key: "backButtonRef",
                                                        ref: backButtonRef,
                                                        class: _normalizeClass(["button-arrow bg-[#E5D0FF] dark:bg-[#C091FC]", { 'opacity-0 pointer-events-none': _unref(arrivedState).left }]),
                                                        onClick: stepBack
                                                    }, [
                                                        _createVNode(UiIcon, {
                                                            icon: "chevron-left",
                                                            "stroke-width": "1.5",
                                                            class: "w-full h-full"
                                                        })
                                                    ], 2))
                                                    : _createCommentVNode("", true),
                                                (communications.value?.length > 1)
                                                    ? (_openBlock(), _createElementBlock("div", {
                                                        key: 1,
                                                        ref_key: "communicationsRef",
                                                        ref: communicationsRef,
                                                        class: "w-full flex gap-2 overflow-hidden justify-start touch-manipulation scroll-smooth p-2"
                                                    }, [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(communications.value, (communication, i) => {
                                                            return (_openBlock(), _createElementBlock("img", {
                                                                key: i,
                                                                src: getImage(communication),
                                                                class: _normalizeClass(["h-[50px] w-[50px] rounded-full object-cover cursor-pointer", { 'border-2 border-white ring-2 ring-[--c-primary-400] ring-offset-2': indexCommunication.value === i }]),
                                                                alt: "Imagem da comunicação",
                                                                onClick: ($event) => (changeCommunication(i))
                                                            }, null, 10, _hoisted_4));
                                                        }), 128))
                                                    ], 512))
                                                    : _createCommentVNode("", true),
                                                (hasControls.value)
                                                    ? (_openBlock(), _createElementBlock("div", {
                                                        key: 2,
                                                        class: _normalizeClass(["button-arrow bg-[#E5D0FF] dark:bg-[#C091FC]", { 'opacity-0 pointer-events-none': _unref(arrivedState).right }]),
                                                        onClick: stepNext
                                                    }, [
                                                        _createVNode(UiIcon, {
                                                            icon: "chevron-right",
                                                            "stroke-width": "1.5",
                                                            class: "w-full h-full"
                                                        })
                                                    ], 2))
                                                    : _createCommentVNode("", true)
                                            ])
                                        ]),
                                        _createElementVNode("div", _hoisted_5, [
                                            _createElementVNode("div", _hoisted_6, [
                                                _createElementVNode("div", _hoisted_7, [
                                                    (currentPost.value?.linkImage)
                                                        ? (_openBlock(), _createElementBlock("img", {
                                                            key: 0,
                                                            class: "w-full h-auto rounded-md object-contain grow cursor-pointer",
                                                            src: currentPost.value?.linkImage,
                                                            alt: "Imagem de destaque",
                                                            onClick: _cache[0] || (_cache[0] = ($event) => (showLargeImg.value = true))
                                                        }, null, 8, _hoisted_8))
                                                        : _createCommentVNode("", true),
                                                    _createElementVNode("div", _hoisted_9, [
                                                        _createVNode(UiIcon, {
                                                            icon: "hand-click",
                                                            class: "animate-bounce text-neutral-300 text-lg"
                                                        })
                                                    ]),
                                                    _createVNode(_component_VueEasyLightbox, {
                                                        visible: showLargeImg.value,
                                                        imgs: currentPost.value?.linkImage,
                                                        index: 0,
                                                        onHide: _cache[1] || (_cache[1] = ($event) => (showLargeImg.value = false))
                                                    }, null, 8, ["visible", "imgs"])
                                                ]),
                                                _createElementVNode("div", _hoisted_10, _toDisplayString(currentPost.value.title), 1),
                                                (currentPost.value.content)
                                                    ? (_openBlock(), _createBlock(_component_mavon_editor, {
                                                        key: 0,
                                                        modelValue: currentPost.value.content,
                                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((currentPost.value.content) = $event)),
                                                        id: `post-${indexPost.value}`,
                                                        class: "mavoneditor",
                                                        scrollStyle: true,
                                                        boxShadow: false,
                                                        editable: false,
                                                        toolbarsFlag: false,
                                                        subfield: false,
                                                        defaultOpen: "preview",
                                                        language: "pt-BR"
                                                    }, null, 8, ["modelValue", "id"]))
                                                    : _createCommentVNode("", true)
                                            ]),
                                            (showButtons.value)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                                    _createVNode(UiButton, {
                                                        type: "primary",
                                                        outline: "",
                                                        class: "p-2",
                                                        disabled: (isFirstCommunication.value && isFirstPost.value),
                                                        onClick: previousPost
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(UiIcon, {
                                                                icon: "arrow-left",
                                                                "stroke-width": "1.5",
                                                                class: "text-[#212121] dark:text-[--c-primary-500]"
                                                            })
                                                        ]),
                                                        _: 1
                                                    }, 8, ["disabled"]),
                                                    (qtdPosts.value > 1)
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(qtdPosts.value, (_, i) => {
                                                                return (_openBlock(), _createElementBlock("div", {
                                                                    key: i,
                                                                    class: _normalizeClass(["h-[8px] rounded-md", {
                                                                            'bg-[--c-primary-600] w-4': indexPost.value === i,
                                                                            'bg-[#D9D9D9] w-2': indexPost.value !== i
                                                                        }])
                                                                }, null, 2));
                                                            }), 128))
                                                        ]))
                                                        : _createCommentVNode("", true),
                                                    _createVNode(UiButton, {
                                                        type: "primary",
                                                        outline: "",
                                                        class: "p-2",
                                                        disabled: (isLastCommunication.value && isLastPost.value),
                                                        onClick: nextPost
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(UiIcon, {
                                                                icon: "arrow-right",
                                                                "stroke-width": "1.5",
                                                                class: "text-[#212121] dark:text-[--c-primary-500]"
                                                            })
                                                        ]),
                                                        _: 1
                                                    }, 8, ["disabled"])
                                                ]))
                                                : _createCommentVNode("", true),
                                            _createElementVNode("div", _hoisted_13, [
                                                (canDisable.value)
                                                    ? (_openBlock(), _createElementBlock("label", _hoisted_14, [
                                                        _withDirectives(_createElementVNode("input", {
                                                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((currentCommunication.value.dontDisplay) = $event)),
                                                            type: "checkbox",
                                                            name: "showAgain",
                                                            id: "showAgain"
                                                        }, null, 512), [
                                                            [_vModelCheckbox, currentCommunication.value.dontDisplay]
                                                        ]),
                                                        _createTextVNode(" " + _toDisplayString(_ctx.$t('input.notShowAgain')), 1)
                                                    ]))
                                                    : _createCommentVNode("", true),
                                                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "grow-0 md:grow" }, null, -1)),
                                                _createVNode(UiButton, {
                                                    type: "primary",
                                                    outline: "",
                                                    class: "text-sm px-4 py-2 outline-[0px] uppercase",
                                                    onClick: close
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.$t('button.close')), 1)
                                                    ]),
                                                    _: 1
                                                })
                                            ])
                                        ])
                                    ]))
                                ]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["modelValue"]));
        };
    }
});
