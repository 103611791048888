import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import unidadesDeNegocioService from '@/services/unidadesDeNegocio.service';
import { unidadesDeNegocioServices } from '@/services/unidadesDeNegocio.service';
import { dialog, findElementTree } from '@/common/util';
export const useUnidadesDeNegocioStore = defineStore('unidadesDeNegocio', () => {
    const { t } = useI18n();
    /**
     * States
     */
    const list = ref([]);
    const basicList = ref([]);
    const status = ref(0); // -1 erro de exceção; 0 - inicial; 1 - carregando; 2 - sucesso; 3 - erro não fatal
    const loading = computed(() => status.value === 1);
    const linearList = computed(() => convertListUnitsToArr(list.value, []));
    /**
     * Getters
     */
    const lazyBasicList = computed(() => {
        if (status.value === 0) {
            getBasicList();
            return [{ value: '', label: 'Aguarde...' }];
        }
        return basicList.value;
    });
    /**
     * Actions
     */
    const create = async (dataObj) => {
        status.value = 1;
        try {
            const { data, success } = await unidadesDeNegocioService.create(dataObj);
            if (success)
                getAll();
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const update = async (dataObj, id) => {
        status.value = 1;
        try {
            const { data, success } = await unidadesDeNegocioService.update(id, dataObj);
            if (success) {
                const value = findElementTree(list.value, id);
                value && Object.assign(value, data);
            }
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const getAll = async () => {
        status.value = 1;
        try {
            const { data } = await unidadesDeNegocioService.get();
            list.value = data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const remove = async (unidade, force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.unit').toLowerCase(), name: unidade.nome }),
                complete: () => remove(unidade, true)
            });
        try {
            status.value = 1;
            const { success } = await unidadesDeNegocioService.remove(unidade.id);
            if (success)
                deleteElementInTree(unidade.id, list.value);
            status.value = 2;
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    const getById = async (id) => {
        status.value = 1;
        try {
            const { data } = await unidadesDeNegocioService.get(id);
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const exportUnidadeNegocio = async () => {
        status.value = 1;
        try {
            const csvFile = await unidadesDeNegocioService.exportCsv();
            return csvFile;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const deleteElementInTree = (id, list) => {
        let idx = list.findIndex((el) => el.id === id);
        idx >= 0
            ? list.splice(idx, 1)
            : list.forEach((row) => row.children.length > 0 && deleteElementInTree(id, row.children));
    };
    const convertListUnitsToArr = (tree, result) => {
        if (!tree)
            return [];
        tree.forEach((element) => {
            if (element)
                result.push(element);
            if (element.children?.length > 0)
                convertListUnitsToArr(element.children, result);
        });
        return result;
    };
    const getBasicList = (params) => {
        status.value = 1;
        return unidadesDeNegocioServices.getListBasic({ ...params })
            .then(({ data }) => {
            basicList.value = (data || []).map(c => ({ value: c.id, label: c.nome }));
            status.value = 2;
            return basicList.value;
        })
            .catch(() => { status.value = 3; });
    };
    return {
        list,
        status,
        loading,
        linearList,
        basicList,
        //Getters
        lazyBasicList,
        // Actions
        create,
        update,
        getAll,
        getById,
        remove,
        getBasicList,
        exportUnidadeNegocio
    };
});
export default useUnidadesDeNegocioStore;
