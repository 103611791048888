import { dialog } from '@/common/util';
import etapasFormacao from '@/services/etapasDaFormacao.service';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
export const useFormacoesItensStore = defineStore('formacoesItens', () => {
    /**
     * States
     */
    const list = ref([]);
    const status = ref(0); // -1 erro de exceção; 0 - inicial; 1 - carregando; 2 - sucesso; 3 - erro não fatal
    /**
     * Getters
     */
    const loading = computed(() => status.value === 1);
    /**
     * Actions
     */
    const create = async (objData) => {
        status.value = 1;
        try {
            const { data, success, notifications } = await etapasFormacao.create(objData);
            if (success)
                getAll();
            return data || notifications;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const update = async (objData, id) => {
        status.value = 1;
        try {
            const { data, success } = await etapasFormacao.update(id, objData);
            if (success)
                getAll();
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const getAll = async () => {
        status.value = 1;
        try {
            const { data } = await etapasFormacao.get();
            list.value = data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const remove = async (item, force) => {
        status.value = 1;
        if (!force)
            return dialog.open({
                title: 'Atenção!',
                message: `<p>Você está prestes a remover um item da trilha!</p>
              <p>Esta ação não poderá ser desfeita!</p>`,
                complete: () => remove(item, true)
            });
        try {
            const { success } = await etapasFormacao.remove(item.id);
            if (success)
                await getAll();
            return success;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const getById = async (id) => {
        status.value = 1;
        try {
            const { data } = await etapasFormacao.get(id);
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const exportCsv = async () => {
        status.value = 1;
        try {
            return await etapasFormacao.exportCsv();
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    return {
        list,
        status,
        // Getters
        loading,
        // Actions
        create,
        update,
        getAll,
        getById,
        remove,
        exportCsv
    };
});
export default useFormacoesItensStore;
