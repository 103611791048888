import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, computed, onMounted } from 'vue';
import { isMatch, getDaysInMonth } from 'date-fns';
import { useSystemStore } from '@/stores';
import UiInput from './input.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'datepicker',
    props: /*@__PURE__*/ _mergeModels({
        placeholder: {},
        disabled: { type: Boolean },
        error: {},
        mask: {},
        togglePassword: { type: Boolean },
        size: {},
        enableTimePicker: { type: Boolean },
        format: {},
        maxDate: {}
    }, {
        "modelValue": {},
        "modelModifiers": {},
    }),
    emits: ["update:modelValue"],
    setup(__props) {
        const props = __props;
        const formatLocale = ref('pt-BR');
        // Gera automaticamente a máscara e o formato do input (se não for passado pelo usuário)
        const auto = computed(() => {
            const tmp = (new Date(Date.UTC(1999, 11, 31, 0, 0, 0)));
            const base = tmp.toLocaleDateString(undefined, { timeZone: 'UTC' });
            return {
                mask: props.mask || base.replaceAll(/[0-9]/g, '#'),
                format: props.format || base.replace('1999', 'yyyy').replace('12', 'MM').replace('31', 'dd')
            };
        });
        const modelValue = _useModel(__props, "modelValue");
        const datepickerRef = ref();
        const updateData = (val) => {
            val = val.trim();
            if (isMatch(val, auto.value.format)) {
                const dayPos = auto.value.format.indexOf('dd');
                const monthPos = auto.value.format.indexOf('MM');
                const yearPos = auto.value.format.indexOf('yyyy');
                let day = (dayPos !== -1) ? Number(val.substr(dayPos, 2)) : 0;
                let month = (monthPos !== -1) ? Number(val.substr(monthPos, 2)) : 0;
                let year = (yearPos !== -1) ? Number(val.substr(yearPos, 4)) : 0;
                if (`${year}`.length < 4)
                    return;
                // Normaliza o mês
                if (month > 12)
                    month = 12;
                else if (Number(month < 1))
                    month = 1;
                // Normaliza o dia
                const lastDMonth = getDaysInMonth(new Date(year, month - 1));
                if (day > lastDMonth)
                    day = lastDMonth;
                else if (day < 1)
                    day = 1;
                modelValue.value = new Date(year, month - 1, day);
            }
        };
        const onInput = ({ target }) => {
            if (!target.value)
                return;
            updateData(target.value);
        };
        onMounted(() => {
            if (navigator.language.substring(0, 2) === 'en')
                formatLocale.value = 'en-US';
            else if (navigator.language.substring(0, 2) === 'es')
                formatLocale.value = 'es-ES';
        });
        return (_ctx, _cache) => {
            const _component_date_picker = _resolveComponent("date-picker");
            return (_openBlock(), _createBlock(_component_date_picker, {
                ref_key: "datepickerRef",
                ref: datepickerRef,
                modelValue: modelValue.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((modelValue).value = $event)),
                "auto-apply": "",
                disabled: _ctx.disabled,
                dark: (!_unref(useSystemStore)().isDark),
                "enable-time-picker": _ctx.enableTimePicker,
                format: auto.value.format,
                locale: formatLocale.value,
                "text-input": { format: auto.value.format },
                "max-date": _ctx.maxDate,
                teleport: true,
                config: {
                    allowPreventDefault: true,
                    tabOutClosesMenu: true,
                    closeOnAutoApply: true,
                    allowStopPropagation: true
                },
                class: "border-0 bg-transparent box-border outline-none"
            }, {
                "dp-input": _withCtx(({ value }) => [
                    _createVNode(UiInput, {
                        value: value,
                        mask: auto.value.mask,
                        size: _ctx.size,
                        placeholder: _ctx.placeholder || auto.value.format?.toUpperCase(),
                        disabled: _ctx.disabled,
                        class: "w-full",
                        onInput: onInput
                    }, null, 8, ["value", "mask", "size", "placeholder", "disabled"])
                ]),
                _: 1
            }, 8, ["modelValue", "disabled", "dark", "enable-time-picker", "format", "locale", "text-input", "max-date"]));
        };
    }
});
