import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import notificacaoAutomatizadaService from '@/services/notificacaoAutomatizada.service';
import { dialog, toast } from '@/common/util';
export const useAutomatedNotificationsStore = defineStore('automatedNotifications', () => {
    const { t } = useI18n();
    /**
     * States
     */
    const list = ref([]);
    const status = reactive({
        getAll: 'initial',
        create: 'initial',
        update: 'initial',
        delete: 'initial',
        getById: 'initial',
        sendEmail: 'initial',
        execute: 'initial',
    });
    const loading = computed(() => Object.values(status).some(el => el === 'loading'));
    const getFormatSubmit = (notification) => {
        return {
            id: notification.id,
            nome: notification.name,
            situacao: notification.condition,
            evento: notification.eventType,
            intervaloEnvio: notification.sendingInterval,
            diasParaEnvio: notification.daysSubmit,
            diasSemAcesso: notification.daysNoAccess,
            copiaParaSuperior: notification.copyManager,
            remetenteAdicional: notification.additionalRecipient,
            assuntoDoEmail: notification.subject,
            templateDoEmail: notification.template,
            segmentacoes: notification.segmentationsIds.map(el => ({ segmentacaoId: el })),
            active: notification.active
        };
    };
    const getFormatResponse = (notification) => {
        return {
            id: notification.id,
            name: notification.nome,
            condition: notification.situacao,
            eventType: notification.evento,
            sendingInterval: notification.intervaloEnvio,
            daysSubmit: notification.diasParaEnvio,
            daysNoAccess: notification.diasSemAcesso,
            copyManager: notification.copiaParaSuperior,
            additionalRecipient: notification.remetenteAdicional,
            subject: notification.assuntoDoEmail,
            template: notification.templateDoEmail,
            segmentationsIds: notification.segmentacoes.map(el => el.segmentacaoId),
            active: notification.active
        };
    };
    const create = async (obj) => {
        status.create = 'loading';
        try {
            const { success } = await notificacaoAutomatizadaService.post(null, getFormatSubmit(obj));
            if (success)
                getAll();
            status.create = 'success';
            return success;
        }
        catch {
            status.create = 'error';
        }
    };
    const update = async (id, obj) => {
        status.update = 'loading';
        try {
            const { success } = await notificacaoAutomatizadaService.put(id, getFormatSubmit(obj));
            if (success) {
                getAll();
            }
            status.update = 'success';
            return success;
        }
        catch {
            status.update = 'error';
        }
    };
    const getAll = async () => {
        status.getAll = 'loading';
        try {
            const { data } = await notificacaoAutomatizadaService.get();
            list.value = data.map(el => getFormatResponse(el));
        }
        catch {
            status.getAll = 'success';
        }
        finally {
            status.getAll = 'error';
        }
    };
    const remove = async (obj, force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.automatedNotification').toLowerCase(), name: obj.name }),
                complete: () => remove(obj, true)
            });
        try {
            status.delete = 'loading';
            const { success } = await notificacaoAutomatizadaService.delete(obj.id);
            if (success) {
                getAll();
            }
            status.delete = 'success';
            return success;
        }
        catch {
            status.delete = 'error';
        }
    };
    const getById = async (id) => {
        status.getById = 'loading';
        try {
            const { data } = await notificacaoAutomatizadaService.get(id);
            status.getById = 'success';
            return getFormatResponse(data);
        }
        catch {
            status.getById = 'error';
        }
    };
    const sendTestEmail = async (props) => {
        status.sendEmail = 'loading';
        try {
            const { data, success } = await notificacaoAutomatizadaService.sendTestEmail(props);
            console.log({ data, success });
            if (success) {
                toast.success({ message: t('feedback.emailSent') });
                status.sendEmail = 'success';
            }
        }
        catch {
            status.sendEmail = 'error';
        }
    };
    const executePerTenant = async (obj, force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.executeManualNotification.message'),
                complete: () => executePerTenant(obj, true)
            });
        try {
            status.execute = 'loading';
            const { success } = await notificacaoAutomatizadaService.executePerTenant(obj.id);
            if (success)
                getAll();
            status.execute = 'success';
            return success;
        }
        catch {
            status.execute = 'error';
        }
    };
    return {
        list,
        status,
        loading,
        // Actions
        create,
        update,
        getAll,
        getById,
        remove,
        sendTestEmail,
        executePerTenant
    };
});
