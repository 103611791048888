import { encodeToBase64, decodeFromBase64 } from './crypto';
const webStorage = {
    getItem(key) {
        const encodedKey = typeof key !== 'undefined' ? encodeToBase64(key) : key;
        const encodedContent = localStorage.getItem(encodedKey) || sessionStorage.getItem(encodedKey);
        try {
            return encodedContent ? JSON.parse(decodeFromBase64(encodedContent)) : null;
        }
        catch (error) {
            return null;
        }
    },
    setItem(key, content, local) {
        const encodedKey = encodeToBase64(key);
        const encodedContent = encodeToBase64(JSON.stringify(content));
        local ? localStorage.setItem(encodedKey, encodedContent) : sessionStorage.setItem(encodedKey, encodedContent);
    },
    removeItem(key) {
        sessionStorage.removeItem(encodeToBase64(key));
        localStorage.removeItem(encodeToBase64(key));
    }
};
export default webStorage;
