import WebStorage from '@/common/webStorage';
class TokenService {
    storageKeyword;
    constructor() {
        this.storageKeyword = ['aliare', 'token'].join(':');
    }
    getToken() {
        return WebStorage.getItem(this.storageKeyword);
    }
    setTokens(token) {
        WebStorage.setItem(this.storageKeyword, token);
    }
    removeToken() {
        WebStorage.removeItem(this.storageKeyword);
    }
    getStoredRefreshToken() {
        const token = this.getToken();
        return token?.refresh_token;
    }
    getStoredAccessToken() {
        const token = this.getToken();
        return token?.access_token;
    }
    updateStoredAccessToken(accessToken) {
        const token = { ...this.getToken(), accessToken };
        this.setTokens(token);
        return token;
    }
}
const tokenService = new TokenService();
export default tokenService;
