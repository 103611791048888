import { useModel as _useModel, defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, unref as _unref, Fragment as _Fragment, renderList as _renderList, createBlock as _createBlock, Transition as _Transition, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "text-neutral-800 dark:text-neutral-300 box-border fixed px-4 py-6 flex flex-wrap items-center justify-center inset-0 z-50 h-full w-full bg-black/60 backdrop-blur-[1px] dark:bg-white/30"
};
const _hoisted_2 = { class: "box-border grow flex flex-col mx-auto rounded-md bg-neutral-50 px-16 py-10 shadow dark:bg-[#2e2e2e] max-w-[760px] max-h-full" };
const _hoisted_3 = { class: "flex flex-wrap items-center justify-between gap-x-2 mb-4" };
const _hoisted_4 = { class: "font-semibold text-xl" };
const _hoisted_5 = { class: "-mx-4 mb-6 grow flex items-center bg-neutral-200/50 dark:bg-neutral-800/70 rounded border dark:border-neutral-400" };
const _hoisted_6 = ["placeholder"];
const _hoisted_7 = {
    key: 0,
    class: "shrink-0 mr-2"
};
const _hoisted_8 = {
    key: 0,
    class: "italic text-center py-1 mb-8"
};
const _hoisted_9 = {
    key: 1,
    class: "font-medium text-lg text-center py-4 flex flex-col items-center"
};
const _hoisted_10 = { class: "mb-4" };
const _hoisted_11 = { class: "font-normal text-base max-w-[512px] mx-auto" };
const _hoisted_12 = { class: "mt-4 mb-10" };
const _hoisted_13 = {
    key: 2,
    class: "text-red-500 dark:text-red-400 text-center py-4"
};
const _hoisted_14 = { class: "hidden md:flex flex-wrap items-center justify-center text-neutral-500 mt-4 gap-4" };
const _hoisted_15 = { class: "kbk" };
const _hoisted_16 = { class: "kbk" };
import { ref, watch } from 'vue';
import { useMagicKeys, whenever, watchDebounced } from '@vueuse/core';
import { midiaService } from '@/services/midias.service';
import { useAuthStore } from '@/stores';
import UiButton from '@/components/ui/button.vue';
import UiIcon from '@/components/ui/icon.vue';
import UiCustomSvg from '@/components/ui/customSvg.vue';
import ImgSearch from '@/assets/art/search.vue';
import ImgNoResults from '@/assets/art/noResults.vue';
import ModalSuggestion from '@/views/aprendizado/home/components/modalSuggestion.vue';
import SearchItem from './topbarSearchItem.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'topbarSearch',
    props: {
        "modelValue": { type: Boolean },
        "modelModifiers": {},
    },
    emits: ["update:modelValue"],
    setup(__props) {
        const { ctrl_k, escape, arrowdown, arrowup } = useMagicKeys({
            passive: false,
            onEventFired(e) {
                if (e.ctrlKey && e.key === 'k' && e.type === 'keydown')
                    e.preventDefault();
                if (model.value && (e.key === 'ArrowDown' || e.key === 'ArrowUp'))
                    e.preventDefault();
            }
        });
        const model = _useModel(__props, "modelValue");
        const modalSuggestion = ref();
        const searchItems = ref();
        const searchInput = ref();
        const searchTerm = ref('');
        const list = ref({
            files: [],
            courses: [],
            trails: []
        });
        watch(() => model.value, (value) => {
            if (value) {
                setTimeout(() => {
                    searchInput.value?.focus();
                }, 100);
            }
        });
        const close = () => {
            if ((model.value))
                model.value = false;
        };
        whenever(ctrl_k, () => model.value = !model.value);
        whenever(escape, () => close());
        const handleArrowKey = (direction) => {
            if (!model.value)
                return;
            const items = searchItems.value?.querySelectorAll('*[tabindex="0"]');
            if (items) {
                const index = Array.from(items).findIndex((el) => el === document.activeElement);
                if (direction === 'down' && index < items.length - 1) {
                    items[index + 1].focus();
                }
                else if (direction === 'up' && index > 0) {
                    items[index - 1].focus();
                }
            }
        };
        whenever(arrowdown, () => handleArrowKey('down'));
        whenever(arrowup, () => handleArrowKey('up'));
        const searchSts = ref('initial');
        watchDebounced(searchTerm, () => {
            if (searchTerm.value.length > 0) {
                searchSts.value = 'loading';
                midiaService.search(searchTerm.value)
                    .then(({ data }) => {
                    list.value = data;
                    searchSts.value = 'success';
                })
                    .catch(() => {
                    list.value = { files: [], courses: [], trails: [] };
                    searchSts.value = 'error';
                });
            }
            else {
                list.value = { files: [], courses: [], trails: [] };
                searchSts.value = 'initial';
            }
        }, { debounce: 500 });
        return (_ctx, _cache) => {
            const _directive_motion_slide_bottom = _resolveDirective("motion-slide-bottom");
            const _directive_auto_animate = _resolveDirective("auto-animate");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("div", {
                    class: "hidden md:block p-2 rounded-md bg-neutral-200/80 dark:bg-neutral-800/70 border border-transparent hover:border-[--c-primary-500] cursor-pointer transition-colors",
                    onClick: _cache[0] || (_cache[0] = () => model.value = true)
                }, [
                    _createVNode(UiIcon, {
                        icon: "search",
                        size: "16px"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('placeholder.search')) + " ", 1),
                    _cache[6] || (_cache[6] = _createElementVNode("span", { class: "ml-4 text-xs text-neutral-500 dark:text-neutral-400 p-1 border border-neutral-400 rounded" }, "Ctrl + K", -1))
                ]),
                _createElementVNode("div", {
                    class: "md:hidden",
                    onClick: _cache[1] || (_cache[1] = () => model.value = true)
                }, [
                    _createVNode(UiButton, {
                        type: "ghost",
                        class: "h-10 w-10"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(UiIcon, {
                                icon: "search",
                                size: 24
                            })
                        ]),
                        _: 1
                    })
                ]),
                (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
                    _createVNode(_Transition, {
                        appear: "",
                        mode: "out-in",
                        "enter-active-class": "transition ease-in-out duration-[.5s] transform",
                        "enter-from-class": "opacity-0",
                        "leave-active-class": "transition ease-in-out duration-[.5s] transform",
                        "leave-to-class": "opacity-0"
                    }, {
                        default: _withCtx(() => [
                            (model.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                    _createElementVNode("div", _hoisted_2, [
                                        _createElementVNode("div", _hoisted_3, [
                                            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('placeholder.contentSearch')), 1),
                                            _createVNode(UiButton, {
                                                type: "ghost",
                                                icon: "x",
                                                class: "px-4 py-2 underline underline-offset-2",
                                                text: _ctx.$t('feedback.close'),
                                                onClick: _cache[2] || (_cache[2] = () => model.value = false)
                                            }, null, 8, ["text"])
                                        ]),
                                        _createElementVNode("label", _hoisted_5, [
                                            _createVNode(UiIcon, {
                                                icon: "search",
                                                size: 24,
                                                class: "shrink-0 text-[#666] ml-2"
                                            }),
                                            _withDirectives(_createElementVNode("input", {
                                                ref_key: "searchInput",
                                                ref: searchInput,
                                                type: "text",
                                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((searchTerm).value = $event)),
                                                class: "w-full rounded-md border-0 bg-transparent p-2 text-base text-[#212121] dark:text-neutral-200 placeholder-neutral-500 focus:outline-none dark:placeholder-neutral-400",
                                                placeholder: _ctx.$t('placeholder.search')
                                            }, null, 8, _hoisted_6), [
                                                [_vModelText, searchTerm.value]
                                            ]),
                                            (searchSts.value === 'loading')
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                                    _createVNode(UiIcon, {
                                                        icon: "loader",
                                                        class: "animate-spin"
                                                    })
                                                ]))
                                                : _createCommentVNode("", true),
                                            (searchTerm.value.length)
                                                ? (_openBlock(), _createElementBlock("div", {
                                                    key: 1,
                                                    class: "shrink-0 text-[#666] mr-2 cursor-pointer hover:text-red-600 transition-colors",
                                                    onClick: _cache[4] || (_cache[4] = () => searchTerm.value = '')
                                                }, [
                                                    _createVNode(UiIcon, {
                                                        icon: "x",
                                                        size: 24
                                                    })
                                                ]))
                                                : _createCommentVNode("", true)
                                        ]),
                                        (searchSts.value === 'initial' || !searchTerm.value)
                                            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [
                                                _createVNode(UiCustomSvg, { class: "mb-6 mt-8" }, {
                                                    default: _withCtx(() => [
                                                        _createVNode(ImgSearch, { class: "w-auto h-[150px]" })
                                                    ]),
                                                    _: 1
                                                }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.$t('feedback.searchInitFeedback')), 1)
                                            ])), [
                                                [_directive_motion_slide_bottom]
                                            ])
                                            : (searchSts.value === 'success' && !list.value.files.length && !list.value.courses.length && !list.value.trails.length)
                                                ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
                                                    _createVNode(UiCustomSvg, null, {
                                                        default: _withCtx(() => [
                                                            _createVNode(ImgNoResults, { class: "w-60 h-60" })
                                                        ]),
                                                        _: 1
                                                    }),
                                                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('feedback.noResultsSearch')), 1),
                                                    (!_unref(useAuthStore)().clientMode?.tenant)
                                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('placeholder.noResults')), 1),
                                                            _createElementVNode("div", _hoisted_12, [
                                                                _createVNode(UiButton, {
                                                                    type: "primary",
                                                                    class: "py-3 px-4",
                                                                    icon: "send-2",
                                                                    onClick: _cache[5] || (_cache[5] = () => modalSuggestion.value.open())
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.$t('button.sendSuggestion')), 1)
                                                                    ]),
                                                                    _: 1
                                                                })
                                                            ])
                                                        ], 64))
                                                        : _createCommentVNode("", true)
                                                ])), [
                                                    [_directive_motion_slide_bottom]
                                                ])
                                                : (searchSts.value === 'error')
                                                    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_13, [
                                                        _createTextVNode(_toDisplayString(_ctx.$t('feedback.failedLoadInfo')), 1)
                                                    ])), [
                                                        [_directive_motion_slide_bottom]
                                                    ])
                                                    : _createCommentVNode("", true),
                                        _withDirectives((_openBlock(), _createElementBlock("div", {
                                            ref_key: "searchItems",
                                            ref: searchItems,
                                            class: "space-y-4 grow max-h-full overflow-hidden overflow-y-auto"
                                        }, [
                                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['files', 'courses', 'trails'], (type) => {
                                                return (_openBlock(), _createElementBlock(_Fragment, { key: type }, [
                                                    (list.value[type].length)
                                                        ? (_openBlock(), _createBlock(SearchItem, {
                                                            key: 0,
                                                            itemList: list.value[type],
                                                            title: _ctx.$t(`labels.${{ files: 'media', courses: 'course', trails: 'trail' }[type]}`, 2),
                                                            onClose: close
                                                        }, null, 8, ["itemList", "title"]))
                                                        : _createCommentVNode("", true)
                                                ], 64));
                                            }), 64))
                                        ])), [
                                            [_directive_auto_animate]
                                        ]),
                                        _createElementVNode("div", _hoisted_14, [
                                            _createElementVNode("div", null, [
                                                _createElementVNode("span", _hoisted_15, [
                                                    _createVNode(UiIcon, {
                                                        icon: "arrow-up",
                                                        size: "16px"
                                                    })
                                                ]),
                                                _createElementVNode("span", _hoisted_16, [
                                                    _createVNode(UiIcon, {
                                                        icon: "arrow-down",
                                                        size: "16px"
                                                    })
                                                ]),
                                                _createTextVNode(" " + _toDisplayString(_ctx.$t('feedback.navigate')), 1)
                                            ]),
                                            _createElementVNode("div", null, [
                                                _cache[7] || (_cache[7] = _createElementVNode("span", { class: "kbk" }, "Enter", -1)),
                                                _createTextVNode(" " + _toDisplayString(_ctx.$t('feedback.select')), 1)
                                            ]),
                                            _createElementVNode("div", null, [
                                                _cache[8] || (_cache[8] = _createElementVNode("span", { class: "kbk" }, "Esc", -1)),
                                                _createTextVNode(" " + _toDisplayString(_ctx.$t('feedback.close')), 1)
                                            ])
                                        ])
                                    ])
                                ]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    })
                ])),
                _createVNode(ModalSuggestion, {
                    ref_key: "modalSuggestion",
                    ref: modalSuggestion,
                    searchTerm: searchTerm.value
                }, null, 8, ["searchTerm"])
            ], 64));
        };
    }
});
