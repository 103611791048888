import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { defineStore } from 'pinia';
import { dialog, toast } from '@/common/util';
import competenciasService from '@/services/competencias.service';
export const useCompetenciasStore = defineStore('competencias', () => {
    const { t } = useI18n();
    /**
     * States
     */
    const list = ref([]);
    const basicList = ref({
        files: [],
        courses: [],
        trails: [],
        assessments: []
    });
    const status = reactive({
        list: 'initial',
        user: 'initial',
        create: 'initial',
        update: 'initial',
        remove: 'initial',
        get: 'initial',
        basicList: {
            all: 'initial',
            files: 'initial',
            courses: 'initial',
            trails: 'initial',
            assessments: 'initial'
        }
    });
    /**
     * Getters
     */
    const loading = computed(() => Object.keys(status).some(key => status[key] === 'loading'));
    const loadingBasicList = computed(() => Object.values(status.basicList).some(value => value === 'loading'));
    const lazyBasicList = computed(() => {
        return (type) => {
            if (!type)
                type = 'all';
            if (status.basicList[type] === 'initial') {
                getBasicList({ origem: 'Proprio', type: type });
                return [{ value: '', label: t('feedback.wait') }];
            }
            return basicList.value[type];
        };
    });
    /**
     * Actions
     */
    const create = async (objData) => {
        status.create = 'loading';
        try {
            const { data, success } = await competenciasService.post(null, objData);
            if (success) {
                toast.success({ message: t('toasts.createSuccess') });
                getAll();
                emptyList();
            }
            status.create = 'success';
            return data;
        }
        catch {
            status.create = 'error';
        }
    };
    const update = async (objData, id) => {
        status.update = 'loading';
        try {
            const { data, success } = await competenciasService.put(id, objData);
            if (success) {
                const value = list.value.find(el => el.id === data.id);
                value && Object.assign(value, data);
                toast.success({ message: t('toasts.updateSuccess') });
                emptyList();
            }
            status.update = 'success';
            return data;
        }
        catch {
            status.update = 'error';
        }
    };
    const getAll = async () => {
        status.list = 'loading';
        try {
            const { data } = await competenciasService.get();
            list.value = data;
            status.list = 'success';
        }
        catch {
            status.list = 'error';
        }
    };
    const remove = async (competencia, force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.skill').toLowerCase(), name: competencia.nome }),
                complete: () => remove(competencia, true)
            });
        status.remove = 'loading';
        try {
            const { success } = await competenciasService.delete(competencia.id);
            if (success) {
                toast.success({ message: t('toasts.removeSuccess') });
                list.value.splice(list.value.findIndex(el => el.id === competencia.id), 1);
                emptyList();
            }
            status.remove = 'success';
        }
        catch {
            status.remove = 'error';
        }
    };
    const getById = async (id) => {
        status.get = 'loading';
        try {
            const { data } = await competenciasService.get(id);
            status.get = 'success';
            return data;
        }
        catch {
            status.get = 'error';
        }
    };
    const emptyList = () => {
        Object.keys(status.basicList).forEach((key) => status.basicList[key] = 'initial');
        Object.keys(basicList.value).forEach((key) => basicList.value[key].splice(0));
    };
    const getBasicList = (params) => {
        if (!params)
            params = {};
        if (!params?.type)
            params.type = 'all';
        status.basicList[params.type] = 'loading';
        return competenciasService.getListBasic({ ...params })
            .then(({ data }) => {
            basicList.value[params.type] = (data || []).map(c => ({ value: c.id, label: c.nome }));
            status.basicList[params.type] = 'success';
            return basicList.value;
        })
            .catch(() => { status.basicList[params.type] = 'error'; });
    };
    const getAllBySection = async (sectionId) => {
        try {
            const { data } = await competenciasService.getAllBySection(sectionId);
            return data;
        }
        catch {
            //
        }
    };
    return {
        list,
        status,
        // getters,
        loading,
        lazyBasicList,
        loadingBasicList,
        // Actions
        create,
        update,
        getAll,
        getById,
        remove,
        getAllBySection,
        getBasicList,
        getListBasic: getBasicList
    };
});
export default useCompetenciasStore;
