import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "flex flex-col p-1 text-sm text-neutral-600 dark:text-neutral-300" };
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
    key: 0,
    class: "absolute right-2 flex items-center justify-center p-1 top-1/2 -translate-y-1/2 bg-emerald-200 rounded-full"
};
import { ref } from 'vue';
import { useSystemStore } from '@/stores';
import UiIcon from '@/components/ui/icon.vue';
import UiModal from '@/components/ui/modal.vue';
import pt from '@/components/ui/icons/flagBrazil.vue';
import en from '@/components/ui/icons/flagUnitedKingdom.vue';
import es from '@/components/ui/icons/flagSpain.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'langSelector',
    props: {
        text: {},
        asModal: { type: Boolean }
    },
    setup(__props, { expose: __expose }) {
        const systemStore = useSystemStore();
        const originalLang = ref(systemStore.clientSettings.lang);
        const modal = ref();
        const getFlag = (lang) => {
            switch (lang) {
                case 'pt':
                    return pt;
                case 'en':
                    return en;
                case 'es':
                    return es;
                default:
                    return pt;
            }
        };
        const open = () => {
            originalLang.value = systemStore.clientSettings.lang;
            modal.value.open();
        };
        const cancel = () => {
            systemStore.setLanguage(originalLang.value);
        };
        __expose({
            open,
            cancel
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(UiModal, {
                ref_key: "modal",
                ref: modal,
                width: "320px",
                title: _ctx.$t('language.set'),
                "before-cancel": cancel
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(systemStore).availableLangs, (lang) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: lang.value,
                                class: "ls-item border border-solid border-neutral-300 mb-1",
                                tabindex: "0",
                                onClick: ($event) => (_unref(systemStore).setLanguage(lang.value))
                            }, [
                                (lang.value === _unref(systemStore).clientSettings.lang)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                        _createVNode(UiIcon, {
                                            icon: "check",
                                            class: "text-emerald-600"
                                        })
                                    ]))
                                    : _createCommentVNode("", true),
                                (_openBlock(), _createBlock(_resolveDynamicComponent(getFlag(lang.value)), { class: "w-6 h-6 mr-2" })),
                                _createTextVNode(" " + _toDisplayString(lang.label), 1)
                            ], 8, _hoisted_2));
                        }), 128))
                    ])
                ]),
                _: 1
            }, 8, ["title"]));
        };
    }
});
