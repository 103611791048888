<template>
  <div class="flex flex-wrap">
    <slot />
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>