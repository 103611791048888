import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = ["src"];
const _hoisted_2 = {
    key: 1,
    class: "absolute inset-0 w-full h-full box-border p-[5px]"
};
const _hoisted_3 = { class: "absolute top-0 left-0 w-full aspect-square" };
const _hoisted_4 = ["src"];
import { computed } from 'vue';
import { getInitials } from '@/common/util';
import ImgRankAvatarGold from '@/assets/game/rank-avatar-gold.svg';
import ImgRankAvatarSilver from '@/assets/game/rank-avatar-silver.svg';
import ImgRankAvatarBronze from '@/assets/game/rank-avatar-bronze.svg';
import ImgRankAvatarDefault from '@/assets/game/rank-avatar-default.svg';
export default /*@__PURE__*/ _defineComponent({
    __name: 'avatar',
    props: {
        rank: {},
        compact: { type: Boolean },
        img: {},
        alt: {}
    },
    setup(__props) {
        const props = __props;
        const ImgRankAvatar = computed(() => {
            if (props.rank === 1)
                return ImgRankAvatarGold;
            if (props.rank === 2)
                return ImgRankAvatarSilver;
            if (props.rank === 3)
                return ImgRankAvatarBronze;
            return ImgRankAvatarDefault;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["game-avatar", { compact: _ctx.compact }])
            }, [
                _renderSlot(_ctx.$slots, "default", {}, () => [
                    (_ctx.img)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: _ctx.img,
                            alt: ""
                        }, null, 8, _hoisted_1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createElementVNode("div", {
                                class: _normalizeClass(["w-full h-full bg-[--c-primary-300] text-neutral-700 dark:text-neutral-200 dark:bg-[--c-primary-600] box-border rounded-full flex items-center justify-center font-bold", { 'text-2xl': !_ctx.compact }])
                            }, _toDisplayString(_unref(getInitials)(_ctx.alt)), 3)
                        ]))
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("img", {
                        class: "w-full h-full object-contain",
                        src: ImgRankAvatar.value,
                        alt: ""
                    }, null, 8, _hoisted_4),
                    _createElementVNode("div", {
                        class: _normalizeClass(["absolute top-[70%] h-[25%] text-center left-0 right-0 font-semibold text-black flex items-center justify-center pt-[0.09rem]", { 'text-xs': _ctx.compact, 'pt-[0.4rem]': !_ctx.compact }])
                    }, _toDisplayString(_ctx.rank ? `${_ctx.rank}º` : '-'), 3)
                ])
            ], 2));
        };
    }
});
