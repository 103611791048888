import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "w-[80px] box-border flex h-[46px] items-center justify-center rounded-l-lg pl-2 pr-1 hover:cursor-pointer hover:bg-slate-200 dark:hover:bg-neutral-700" };
const _hoisted_2 = ["src"];
const _hoisted_3 = { class: "whitespace-nowrap text-sm" };
const _hoisted_4 = { class: "inline-flex w-48 flex-col" };
const _hoisted_5 = ["onClick"];
const _hoisted_6 = ["src"];
const _hoisted_7 = { class: "m-0 p-0" };
const _hoisted_8 = { class: "m-0 p-0 text-slate-400" };
const _hoisted_9 = ["data-maska", "placeholder", "disabled"];
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeInputPhoneNumber',
    props: {
        modelValue: { type: [String, Boolean, Number, null] },
        disabledInput: { type: Boolean },
        disabledDropDown: { type: Boolean },
        placeholder: {},
        simpleUi: { type: Boolean }
    },
    emits: ['update:modelValue'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const { t } = useI18n();
        const countries = [
            {
                code: 'BR',
                name: 'Brasil',
                prefix: '+55',
                flag: 'https://cdn.kcak11.com/CountryFlags/countries/br.svg',
                mask: ['(##) ####-####', '(##) #####-####'],
                placeholder: `(DD) + ${t('labels.number')}`
            },
            {
                code: 'PY',
                name: 'Paraguai',
                prefix: '+595',
                flag: 'https://cdn.kcak11.com/CountryFlags/countries/py.svg',
                mask: ['(##) ###-###', '(###) ###-###', '(##) #####-####', '(###) #####-####'],
                placeholder: `(DD) + ${t('labels.number')}`
            }
        ];
        const country = ref({ ...countries[0] });
        const setByCountry = (countryCode) => {
            const result = countries.find((el) => el.code === countryCode);
            if (result?.code)
                country.value = result;
        };
        const setByPrefix = (prefix) => {
            country.value = countries.find((el) => el.prefix === prefix);
        };
        const value = computed({
            get: () => {
                const arrPre = countries.map((el) => el.prefix).sort((a, b) => b.length - a.length);
                if (props.modelValue && typeof props.modelValue === 'string') {
                    const prefix = arrPre.find((pref) => pref === `${props.modelValue}`.substring(0, pref.length));
                    if (prefix) {
                        setByPrefix(prefix);
                        return `${props.modelValue}`.split(prefix).pop();
                    }
                }
                return props.modelValue;
            },
            set: (val) => {
                const clean = `${val}`.replace(/\D/g, '');
                return emit('update:modelValue', `${country.value.prefix}${clean}`);
            }
        });
        __expose({
            setByCountry,
            setByPrefix
        });
        return (_ctx, _cache) => {
            const _component_pe_icon = _resolveComponent("pe-icon");
            const _component_pe_dropdown = _resolveComponent("pe-dropdown");
            const _directive_maska = _resolveDirective("maska");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["pe-input inline-flex items-center", {
                        'pe-input-simple-ui': _ctx.simpleUi,
                        'pe-input': !_ctx.simpleUi
                    }])
            }, [
                _createVNode(_component_pe_dropdown, { disabled: _ctx.disabledDropDown }, {
                    default: _withCtx(({ active }) => [
                        _createElementVNode("div", _hoisted_1, [
                            _createElementVNode("img", {
                                src: country.value.flag,
                                alt: "Imagem do país selecionado",
                                width: "18",
                                height: "18"
                            }, null, 8, _hoisted_2),
                            _createVNode(_component_pe_icon, {
                                icon: "arrow_drop_down",
                                size: 18,
                                class: _normalizeClass(["transition-transform duration-300", { '-rotate-180': active }])
                            }, null, 8, ["class"]),
                            _createElementVNode("span", _hoisted_3, _toDisplayString(country.value.prefix), 1)
                        ])
                    ]),
                    content: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(countries, (item, i) => {
                                return _createElementVNode("div", {
                                    key: i,
                                    class: "item",
                                    onClick: ($event) => (country.value = item)
                                }, [
                                    _createElementVNode("img", {
                                        src: item.flag,
                                        alt: "Imagem do país",
                                        width: "18",
                                        height: "18"
                                    }, null, 8, _hoisted_6),
                                    _createElementVNode("span", _hoisted_7, _toDisplayString(item.name), 1),
                                    _createElementVNode("span", _hoisted_8, _toDisplayString(item.prefix), 1)
                                ], 8, _hoisted_5);
                            }), 64))
                        ])
                    ]),
                    _: 1
                }, 8, ["disabled"]),
                _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((value).value = $event)),
                    type: "text",
                    "data-maska": JSON.stringify(country.value.mask || ''),
                    placeholder: _ctx.placeholder ?? country.value.placeholder,
                    disabled: _ctx.disabledInput,
                    class: "pe-input-content w-full grow text-inherit"
                }, null, 8, _hoisted_9), [
                    [_vModelText, value.value],
                    [_directive_maska]
                ])
            ], 2));
        };
    }
});
