import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { dialog } from '@/common/util';
import avaliacaoService from '@/services/avaliacao.service';
export const useAvaliacaoStore = defineStore('avaliacoes', () => {
    const { t } = useI18n();
    const list = ref([]);
    const status = reactive({
        getAll: 'initial',
        create: 'initial',
        update: 'initial',
        delete: 'initial',
        getById: 'initial',
        bonds: 'initial',
        prepare: 'initial',
    });
    const loading = computed(() => Object.values(status).some(el => el === 'loading'));
    const methodStatus = computed(() => (method, sts) => {
        if (Array.isArray(method))
            return method.some((el) => status[el] === sts);
        return status[method] === sts;
    });
    const clear = () => {
        list.value.splice(0, list.value.length);
    };
    const getAll = async () => {
        status.getAll = 'loading';
        clear();
        try {
            const { data, success } = await avaliacaoService.get();
            if (success) {
                // Normaliza os atributos da avaliação
                list.value = data.map((exam) => fmAvaliacaoResponse(exam));
            }
            status.getAll = 'success';
        }
        catch (error) {
            status.getAll = 'error';
        }
    };
    const getById = async (id) => {
        status.getById = 'loading';
        try {
            const { data, success } = await avaliacaoService.get(id);
            if (success) {
                status.getById = 'success';
                return {
                    id: data.id,
                    title: data.titulo,
                    observation: data.observacao,
                    type: data.tipoDeAvaliacao,
                    duration: data.duracaoDaAvaliacao,
                    skillsIds: data.competenciasIds,
                    score: data.notaDaAvaliacao,
                    canRetry: data.permitirNovasTentativas,
                    maxRetries: data.maximoTentativas,
                    maxQuestions: data.maximoQuestoes,
                    shuffleQuestions: data.embaralharQuestoes,
                    shuffleOptions: data.embaralharAlternativas,
                    displayAnswers: data.exibirCorretaseErradas,
                    showTimer: data.exibirTimer,
                    questions: data.questoes.map((questao) => ({
                        id: questao.id,
                        type: questao.tipoDeQuestao,
                        statement: questao.enunciado,
                        scale: questao.escala,
                        showFeedback: questao.exibirFeedBack,
                        feedback: {
                            right: questao.feedBackAcerto,
                            wrong: questao.feedBackErro,
                        },
                        order: questao.ordem,
                        imageLink: questao.anexo,
                        options: questao.alternativas.sort((a, b) => a.ordem < b.ordem ? 1 : -1).map((option) => ({
                            id: option.id,
                            text: option.descricao || '',
                            isTrue: option.correta,
                            questionId: option.questaoId
                        }))
                    })),
                    active: data.active,
                    categoryId: data.categoriaId,
                    categoryName: data.categoriaNome
                };
            }
        }
        catch (error) {
            status.getById = 'error';
        }
    };
    const create = async (data) => {
        status.create = 'loading';
        try {
            const { success } = await avaliacaoService.create({
                titulo: data.title,
                observacao: data.observation,
                tipoDeAvaliacao: data.type,
                duracaoDaAvaliacao: `${data.duration || '00:00'}:00`,
                competenciasIds: data.skillsIds,
                permitirNovasTentativas: data.canRetry,
                maximoTentativas: data.maxRetries,
                maximoQuestoes: data.maxQuestions,
                notaDaAvaliacao: data.score,
                embaralharQuestoes: data.shuffleQuestions,
                embaralharAlternativas: data.shuffleOptions,
                exibirCorretaseErradas: data.displayAnswers,
                exibirTimer: data.showTimer,
                questoes: data.questions.map((question) => ({
                    tipoDeQuestao: question.type,
                    enunciado: question.statement,
                    escala: question.scale,
                    ordem: question.order,
                    exibirFeedBack: question.showFeedback,
                    feedBackAcerto: question.feedback.right,
                    feedBackErro: question.feedback.wrong,
                    anexo: question.imageLink,
                    imagem: (question?.image ? ({
                        data: question?.image?.split(',').at(-1),
                        mime: question?.image?.split(':').at(-1).split(';').at(0)
                    }) : null),
                    alternativas: question.options.map((option) => ({
                        descricao: option.text || '',
                        correta: option.isTrue
                    }))
                })),
                active: true,
                categoriaId: data.categoryId
            });
            if (success) {
                status.create = 'success';
                getAll();
            }
            return success;
        }
        catch {
            status.create = 'error';
        }
    };
    const update = async (postData, id) => {
        status.update = 'loading';
        const { success } = await avaliacaoService.update(id, {
            id: id,
            titulo: postData.title,
            observacao: postData.observation,
            tipoDeAvaliacao: postData.type,
            duracaoDaAvaliacao: postData.duration.split(':').length > 2 ? postData.duration : `${postData.duration}:00`,
            competenciasIds: postData.skillsIds,
            permitirNovasTentativas: postData.canRetry,
            maximoTentativas: postData.maxRetries,
            maximoQuestoes: postData.maxQuestions,
            notaDaAvaliacao: postData.score,
            embaralharQuestoes: postData.shuffleQuestions,
            embaralharAlternativas: postData.shuffleOptions,
            exibirCorretaseErradas: postData.displayAnswers,
            exibirTimer: postData.showTimer,
            questoes: postData.questions.map((question) => (question.id ?? delete question.id) && ({
                id: question.id,
                tipoDeQuestao: question.type,
                enunciado: question.statement,
                escala: question.scale,
                ordem: question.order,
                exibirFeedBack: question.showFeedback,
                feedBackAcerto: question.feedback.right,
                feedBackErro: question.feedback.wrong,
                anexo: question.imageLink,
                imagem: (question?.image ? ({
                    data: question?.image?.split(',').at(-1),
                    mime: question?.image?.split(':').at(-1).split(';').at(0)
                }) : null),
                alternativas: question.options.map((option) => ({
                    id: option.id,
                    descricao: option.text || '',
                    correta: option.isTrue,
                    questaoId: option.questionId
                }))
            })),
            active: postData.active,
            categoriaId: postData.categoryId
        });
        if (success) {
            status.update = 'success';
            getAll();
        }
        else {
            status.update = 'error';
        }
        return success;
    };
    const remove = async (avaliacao, force) => {
        if (!force) {
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.test').toLowerCase(), name: avaliacao.title }),
                complete: () => remove(avaliacao, true)
            });
        }
        try {
            status.delete = 'loading';
            const { success } = await avaliacaoService.remove(avaliacao.id);
            if (success)
                list.value.splice(list.value.findIndex(el => el.id === avaliacao.id), 1);
            status.delete = 'success';
        }
        catch (error) {
            status.delete = 'error';
        }
    };
    const bonds = async (avaliacaoId) => {
        status.bonds = 'loading';
        try {
            const { data } = await avaliacaoService.getBonds(avaliacaoId);
            status.bonds = 'success';
            return data;
        }
        catch {
            status.bonds = 'error';
        }
    };
    const prepare = async (opt) => {
        status.prepare = 'loading';
        try {
            const result = avaliacaoService.getInfo(opt);
            status.prepare = 'success';
            return result;
        }
        catch (error) {
            status.prepare = 'error';
        }
    };
    const fmAvaliacaoResponse = (data) => {
        return {
            id: data.id,
            title: data.titulo,
            observation: data.observacao,
            type: data.tipoDeAvaliacao,
            duration: data.duracaoDaAvaliacao,
            skillsIds: data.avaliacoesCompetencias,
            score: 0,
            canRetry: data.permitirNovasTentativas,
            maxRetries: data.maximoTentativas,
            maxQuestions: data.maximoQuestoes,
            shuffleQuestions: data.embaralharQuestoes,
            shuffleOptions: data.embaralharAlternativas,
            displayAnswers: data.exibirCorretaseErradas,
            showTimer: data.exibirTimer,
            questions: data.questoes,
            active: data.active,
            origin: data.origem,
            categoryId: data.categoriaId,
            categoryName: data.categoriaNome
        };
    };
    return {
        list,
        loading,
        status,
        methodStatus,
        //
        getAll,
        getById,
        create,
        remove,
        update,
        bonds,
        prepare
    };
});
