import axios from 'axios';
// A R0FNQklBUlJB (||) abaixo foi feito para não dar pau no JEST. (Pq não da pra desestruturar configuration de window... pq é undefined...)
const { plantar, aliare, notificationHub, vistraBi } = window.configuration || { plantar: {}, aliare: {}, notificationHub: {} };
export const plantarApi = axios.create({
    baseURL: [plantar.host, plantar.pathV1].join('/'),
    headers: { 'Content-Type': 'application/json' }
});
export const plantarApiV2 = axios.create({
    baseURL: [plantar.host, plantar.pathV2].join('/'),
    headers: { 'Content-Type': 'application/json' }
});
export const aliareApi = {
    auth: axios.create({
        baseURL: [[aliare.host, aliare.path].join('/'), 'aliare-auth'].join('/'),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }),
    tenant: axios.create({
        baseURL: [[aliare.host, aliare.path].join('/'), 'aliare-tenant'].join('/'),
        headers: { 'Content-Type': 'application/json' }
    }),
    users: axios.create({
        baseURL: [[aliare.host, aliare.path].join('/'), ''].join('/'),
        headers: { 'Content-Type': 'application/json' }
    }),
    portal: axios.create({
        baseURL: [[aliare.host, aliare.path].join('/'), 'aliare-portal'].join(''),
        headers: { 'Content-Type': 'application/json' }
    }),
};
aliareApi.users.interceptors.response.use((response) => {
    return response?.data;
}, (error) => {
    return Promise.reject(error.response);
});
export const notificationHubApi = axios.create({
    baseURL: [notificationHub.host, notificationHub.path].join('/'),
    headers: { 'Content-Type': 'application/json' }
});
export const vistraBiApi = axios.create({
    baseURL: [vistraBi.host, vistraBi.path].join('/'),
    headers: { 'Content-Type': 'application/json', Accept: '*/*' }
});
export default aliareApi;
