import baseApi from './base.api';
import { plantarApiV2 } from '@/config/appAPIs';
const api = {
    ...baseApi,
    resource: 'Desafio',
    findById(id) {
        return api.get(id);
    },
    findAll(opts) {
        // Remove empty params
        const params = {};
        if (opts.id)
            params['id'] = opts.id;
        if (opts.name)
            params['nome'] = opts.name;
        if (opts.from)
            params['dataInicio'] = opts.from;
        if (opts.to)
            params['dataFim'] = opts.to;
        return api.get(null, params);
    },
    getTrophies(opts) {
        const params = {
            page: opts.page || 0,
            size: opts.size || 6
        };
        if (opts.userId)
            params['usuarioId'] = opts.userId;
        return api.get('Conquistas', params)
            .then(res => ({
            page: res.pageNumber,
            size: res.pageSize,
            total: res.totalItems,
            data: res.data?.map(item => ({
                date: new Date(item.data),
                content: item.nomeConteudo || '',
                environment: item.ambiente,
                rule: item.regra,
                score: item.pontuacao
            }))
        }))
            .catch(err => {
            console.log(err);
            return Promise.reject(err);
        });
    },
    getRank(opts) {
        const params = {
            page: opts.page || 1,
            size: opts.size || 5
        };
        return api.get('RankingGeral', params)
            .then((res) => ({
            page: res.pageNumber,
            size: res.pageSize,
            total: res.totalItems,
            data: res.data?.map(item => ({
                name: item.usuarioNome,
                rank: item.posicao,
                score: item.pontos,
                role: item.usuarioCargo,
                img: item.usuarioFotoUrl || ''
            }))
        }))
            .catch(err => {
            console.log(err);
            return Promise.reject(err);
        });
    },
    getMyRank() {
        return api.get('MeuRanking')
            .then(res => ({
            success: res.success,
            data: {
                score: res.data?.pontos || 0,
                rank: res.data?.posicao || 0,
                trophies: res.data?.qtdeConquistas || 0,
                img: res.data?.usuarioFotoUrl || ''
            }
        }));
    },
    remove(id, motivo) {
        const formData = new FormData();
        formData.append('motivo', motivo);
        return api.delete(id, formData);
    },
    getMyRankV2(opts) {
        const params = {
            UsuarioId: opts.usuarioId,
            UnidadeDeNegocioId: opts.unidadeDeNegocioId
        };
        return plantarApiV2.get('Desafio/MeuRanking', { params })
            .then(res => ({
            success: res.success,
            data: {
                rankingGeral: {
                    score: res.data?.rankingGeral?.pontos || 0,
                    rank: res.data?.rankingGeral?.posicao || 0,
                    trophies: res.data?.rankingGeral?.qtdeConquistas || 0,
                    img: res.data?.rankingGeral?.usuarioFotoUrl || ''
                },
                rankingByUnidadeDeNegocio: {
                    score: res.data?.rankingByUnidadeDeNegocio?.pontos || 0,
                    rank: res.data?.rankingByUnidadeDeNegocio?.posicao || 0,
                    trophies: res.data?.rankingByUnidadeDeNegocio?.qtdeConquistas || 0,
                    img: res.data?.rankingByUnidadeDeNegocio?.usuarioFotoUrl || ''
                },
            }
        }));
    },
    getRankV2(opts) {
        const params = {
            page: opts.page || 1,
            size: opts.size || 5,
            unidadeDeNegocioId: opts.unidadeDeNegocioId
        };
        return plantarApiV2.get('Desafio/RankingGeral', { params })
            .then(response => {
            const rankingGeral = response.data?.rankingGeral?.data?.map(item => ({
                name: item.usuarioNome,
                rank: item.posicao,
                score: item.pontos,
                role: item.usuarioCargo,
                img: item.usuarioFotoUrl || ''
            })) || null;
            const rankingByUnidadeDeNegocio = response.data?.rankingByUnidadeDeNegocio?.data?.map(unit => ({
                name: unit.usuarioNome,
                rank: unit.posicao,
                score: unit.pontos,
                role: unit.usuarioCargo,
                img: unit.usuarioFotoUrl || ''
            })) || null;
            return {
                rankingGeral: {
                    data: rankingGeral || null,
                    page: response.data?.rankingGeral?.pageNumber,
                    size: response.data?.rankingGeral?.pageSize,
                    total: response.data?.rankingGeral?.totalItems,
                },
                rankingByUnidadeDeNegocio: {
                    data: rankingByUnidadeDeNegocio || null,
                    page: response.data?.rankingByUnidadeDeNegocio?.pageNumber,
                    size: response.data?.rankingByUnidadeDeNegocio?.pageSize,
                    total: response.data?.rankingByUnidadeDeNegocio?.totalItems,
                }
            };
        })
            .catch(err => {
            console.log(err);
            return Promise.reject(err);
        });
    }
};
export default api;
