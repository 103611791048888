import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot } from "vue";
import { computed } from 'vue';
import * as icons from '@tabler/icons-vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'icon',
    props: {
        size: {},
        icon: {},
        strokeWidth: {}
    },
    setup(__props) {
        _useCssVars(_ctx => ({
            "6063d288": (iconSize.value),
            "6dec895a": (iconStrokeWidth.value)
        }));
        const props = __props;
        /**
         * Normaliza a largura dos traços do icone
         */
        const iconStrokeWidth = computed(() => props.strokeWidth ?? 1.5);
        /**
         * Normaliza o tamanho da fonte (inherit caso omisso)
         */
        const iconSize = computed(() => {
            if (!props.size)
                return '1.5em';
            const size = `${props.size}`.toLowerCase().replace(/\s*/, '').match(/(\d+)(px|em|rem|%)?/);
            return `${size[1] || 14}${size[2] || 'px'}`;
        });
        const transformCamelCase = (value) => {
            return 'Icon' + value.split('.').at(-1).split('-').map((v) => `${(v[0].toUpperCase() + v.substring(1))}`).join('');
        };
        const Icon = computed(() => icons[transformCamelCase(props.icon)]);
        return (_ctx, _cache) => {
            return (_ctx.icon)
                ? (_openBlock(), _createBlock(_unref(Icon), {
                    key: 0,
                    class: "ui-icon"
                }))
                : _renderSlot(_ctx.$slots, "default", {
                    key: 1,
                    class: "ui-icon"
                });
        };
    }
});
