import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = ["disabled"];
const _hoisted_2 = { key: 0 };
import { computed } from 'vue';
import UiIcon from '@/components/ui/icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeButton',
    props: {
        type: {},
        round: { type: Boolean },
        disabled: { type: Boolean },
        loading: { type: Boolean },
        loadingText: {},
        size: {}
    },
    setup(__props) {
        const props = __props;
        const getBtnClasses = () => {
            const classes = [];
            if (['primary', 'info', 'success', 'warning', 'danger', 'white', 'secondary'].includes(props.type))
                classes[props.type] = true;
            if (['small', 'large'].includes(props.size))
                classes[`--pe-button-${props.size}`] = true;
            return classes;
        };
        const isDisabled = computed(() => props.disabled || props.loading);
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("button", _mergeProps({
                class: ["pe-button box-border inline-flex items-center gap-1", {
                        ...getBtnClasses(),
                        'rounded-full': props.round,
                        'rounded-lg': !props.round
                    }],
                disabled: isDisabled.value,
                type: "button"
            }, _ctx.$attrs), [
                (_ctx.loading)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                        _createVNode(UiIcon, {
                            icon: "loader-2",
                            class: "animate-spin"
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.loadingText || _ctx.$t('feedback.await')), 1)
                    ]))
                    : _renderSlot(_ctx.$slots, "default", { key: 1 })
            ], 16, _hoisted_1));
        };
    }
});
