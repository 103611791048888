import { generateUUID } from '@/common/util';
import { defineStore } from 'pinia';
import { ref } from 'vue';
// @TODO: Remover isso daqui
const defaultToastOptions = {
    type: 'info',
    title: 'Info',
    message: 'Empty message.',
    autoClose: true,
    duration: 5
};
export const useNotificationsStore = defineStore('notifications', () => {
    const toasts = ref([]);
    const dialogs = ref([]);
    const newDialogs = ref([]);
    const notifications = ref([]);
    const addToast = (toast) => {
        const id = generateUUID();
        toasts.value.push({
            id,
            ...Object.assign({ ...defaultToastOptions }, toast)
        });
        return id;
    };
    const dismissToast = (id) => {
        const index = toasts.value.findIndex((toast) => toast.id === id);
        if (index > -1)
            toasts.value.splice(index, 1);
    };
    const addDialog = (dialog) => {
        dialogs.value.push({
            id: generateUUID(),
            ...dialog
        });
    };
    const setNotification = (notification) => {
        const { title, message, status } = notification;
        const index = notifications.value.findIndex((notification) => notification.id === notification.id);
        let id = notification.id || generateUUID();
        const data = {
            id,
            title,
            message,
            status,
            haveRead: false
        };
        if (index > -1)
            notifications.value.splice(index, 1, data);
        else
            notifications.value.unshift(data);
        return id;
    };
    const markAsRead = (id) => {
        const index = notifications.value.findIndex((notification) => notification.id === id);
        if (index > -1)
            notifications.value[index].haveRead = true;
    };
    const dismissDialog = (id) => {
        const index = dialogs.value.findIndex((dialog) => dialog.id === id);
        if (index > -1)
            dialogs.value.splice(index, 1);
    };
    const addNewDialog = (newDialog) => {
        newDialogs.value.push({
            id: generateUUID(),
            ...newDialog
        });
    };
    const dismissNewDialog = (id) => {
        const index = newDialogs.value.findIndex((dialog) => dialog.id === id);
        if (index > -1)
            newDialogs.value.splice(index, 1);
    };
    return {
        toasts,
        dialogs,
        notifications,
        newDialogs,
        // Actions
        setNotification,
        markAsRead,
        addToast,
        dismissDialog,
        addDialog,
        dismissToast,
        addNewDialog,
        dismissNewDialog
    };
});
