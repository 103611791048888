import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = { class: "flex flex-wrap gap-2" };
const _hoisted_2 = ["title"];
const _hoisted_3 = ["value", "disabled"];
import { computed } from 'vue';
import UiIcon from '@/components/ui/icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'radioButton',
    props: {
        modelValue: {},
        options: {},
        disabled: { type: Boolean },
        hideRadio: { type: Boolean }
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const selected = computed({
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value)
        });
        return (_ctx, _cache) => {
            const _directive_tooltip = _resolveDirective("tooltip");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (opt, index) => {
                    return _withDirectives((_openBlock(), _createElementBlock("label", {
                        key: index,
                        title: opt.tooltip,
                        class: _normalizeClass(['radio-button', { 'selected': selected.value === opt.value, 'cursor-not-allowed pointer-events-none disabled': _ctx.disabled }])
                    }, [
                        _withDirectives(_createElementVNode("input", {
                            type: "radio",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((selected).value = $event)),
                            value: opt.value,
                            disabled: _ctx.disabled,
                            class: "hidden"
                        }, null, 8, _hoisted_3), [
                            [_vModelRadio, selected.value]
                        ]),
                        (!_ctx.hideRadio)
                            ? (_openBlock(), _createBlock(UiIcon, {
                                key: 0,
                                icon: selected.value === opt.value ? 'circle-check-filled' : 'circle'
                            }, null, 8, ["icon"]))
                            : _createCommentVNode("", true),
                        _createElementVNode("span", null, _toDisplayString(opt.label), 1)
                    ], 10, _hoisted_2)), [
                        [_directive_tooltip]
                    ]);
                }), 128))
            ]));
        };
    }
});
