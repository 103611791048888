import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "min-w-0 p-2 bg-gray-500 dark:bg-neutral-800 button-filter" };
const _hoisted_2 = ["onClick"];
import { computed } from 'vue';
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSystemStore } from '@/stores';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeDateFilter',
    props: {
        modelValue: {},
        defaultTime: {}
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const systemStore = useSystemStore();
        const emit = __emit;
        // eslint-disable-next-line no-unused-vars
        const { t, locale } = useI18n();
        const dateFiltered = ref([]);
        const selected = ref(-1);
        const open = ref(false);
        const dateFilterComponent = ref();
        const getLocale = computed(() => ({
            'en': 'en-US',
            'pt': 'pt-BR',
            'es': 'es-ES'
        }[locale.value]));
        const setDate = (index) => {
            selected.value = index;
            const now = new Date();
            if (index === 3) { // Month
                const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
                const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                dateFiltered.value = [firstDay, lastDay];
            }
            if (index === 2) { // week
                const firstDay = new Date(now.setDate(now.getDate() - now.getDay()));
                const lastDay = new Date(now.setDate(now.getDate() - now.getDay() + 6));
                dateFiltered.value = [firstDay, lastDay];
            }
            if (index === 1) // yesterday
                dateFiltered.value = [getYesterday(), getYesterday(true)];
            if (index === 0) // today
                dateFiltered.value = [now, now];
            emit('update:modelValue', dateFiltered.value);
        };
        const getYesterday = (end) => {
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            end ? yesterday.setUTCHours(23, 59, 59) : yesterday.setUTCHours(0, 0, 0);
            return yesterday.getTime();
        };
        const getDateTime = (date) => {
            const first = new Date(date[0].setUTCHours(0, 0, 0, 0)).getTime();
            const last = new Date(date[1].setUTCHours(23, 59, 59, 59)).getTime();
            return [first, last];
        };
        const onChangeDate = (date) => {
            dateFiltered.value = getDateTime(date);
            selected.value = -1;
            emit('update:modelValue', dateFiltered.value);
        };
        const clear = () => {
            selected.value = -1;
            dateFiltered.value = [];
            emit('update:modelValue', []);
        };
        onMounted(() => {
            if (props.defaultTime && ['today', 'yesterday', 'week', 'month'].includes(props.defaultTime))
                setDate(['today', 'yesterday', 'week', 'month'].indexOf(props.defaultTime));
        });
        return (_ctx, _cache) => {
            const _component_pe_icon = _resolveComponent("pe-icon");
            const _component_date_picker = _resolveComponent("date-picker");
            return (_openBlock(), _createElementBlock("div", {
                ref_key: "dateFilterComponent",
                ref: dateFilterComponent,
                class: "h-full flex flex-wrap items-center gap-2 relative"
            }, [
                (dateFiltered.value.length > 0)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "min-w-[60px] bg-red-500 dark:bg-[#FF4980] dark:hover:bg-[#FF6694] px-4 py-2 button-filter",
                        onClick: clear
                    }, [
                        _createVNode(_component_pe_icon, { icon: "delete" }),
                        _createTextVNode(" " + _toDisplayString(_unref(t)('button.clear')), 1)
                    ]))
                    : _createCommentVNode("", true),
                _createVNode(_component_date_picker, {
                    modelValue: dateFiltered.value,
                    "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event) => ((dateFiltered).value = $event)),
                        onChangeDate
                    ],
                    "auto-apply": "",
                    range: "",
                    locale: getLocale.value,
                    format: "dd/MM/yyyy",
                    enableTimePicker: false,
                    dark: (!_unref(systemStore).isDark),
                    onOpen: _cache[1] || (_cache[1] = ($event) => (open.value = true)),
                    onClosed: _cache[2] || (_cache[2] = ($event) => (open.value = false)),
                    class: "w-14"
                }, {
                    trigger: _withCtx(() => [
                        _createElementVNode("button", _hoisted_1, [
                            _createVNode(_component_pe_icon, {
                                icon: "date_range",
                                size: 18
                            }),
                            _createVNode(_component_pe_icon, {
                                icon: open.value ? 'arrow_drop_up' : 'arrow_drop_down',
                                size: 18
                            }, null, 8, ["icon"])
                        ])
                    ]),
                    _: 1
                }, 8, ["modelValue", "locale", "dark"]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([
                    [0, _unref(t)('button.today')],
                    [1, _unref(t)('button.yesterday')],
                    [2, _unref(t)('button.thisWeek')],
                    [3, _unref(t)('button.thisMonth')]
                ], (item, index) => {
                    return (_openBlock(), _createElementBlock("button", {
                        key: index,
                        class: _normalizeClass(["min-w-[60px] px-4 py-2 button-filter", {
                                'pointer-events-none bg-[#5D5FEF] dark:bg-[#5D5FEF]/70': selected.value === index,
                                'bg-gray-500 dark:bg-neutral-800': selected.value !== index,
                            }]),
                        onClick: ($event) => (setDate(index))
                    }, _toDisplayString(item[1]), 11, _hoisted_2));
                }), 128))
            ], 512));
        };
    }
});
