import { defineComponent as _defineComponent } from 'vue';
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "grow" };
const _hoisted_2 = { class: "mt-4" };
import { ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeCollapse',
    props: {
        title: {}
    },
    setup(__props) {
        const open = ref(false);
        const toggle = () => (open.value = !open.value);
        return (_ctx, _cache) => {
            const _component_pe_icon = _resolveComponent("pe-icon");
            return (_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", {
                    class: "flex cursor-pointer items-center gap-2 rounded-lg bg-white dark:bg-neutral-800 p-4 text-lg font-semibold leading-6 shadow-[0_0_2px_rgba(0,0,0,.1)]",
                    onClick: _cache[0] || (_cache[0] = ($event) => (toggle()))
                }, [
                    _createVNode(_component_pe_icon, { weight: 500 }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createTextVNode("layers")
                        ])),
                        _: 1
                    }),
                    _createElementVNode("span", _hoisted_1, [
                        _renderSlot(_ctx.$slots, "title", {}, () => [
                            _createTextVNode(_toDisplayString(_ctx.title), 1)
                        ])
                    ]),
                    _createVNode(_component_pe_icon, {
                        weight: 500,
                        class: _normalizeClass(["transition-transform", { '-rotate-180': open.value }])
                    }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode("expand_more")
                        ])),
                        _: 1
                    }, 8, ["class"])
                ]),
                _createVNode(_Transition, {
                    "enter-active-class": "transition ease-out duration-300 transform",
                    "enter-from-class": "opacity-0 -translate-y-4",
                    "enter-to-class": "opacity-100 translate-y-0",
                    "leave-active-class": "transition ease-out duration-300 transform",
                    "leave-from-class": "opacity-100 translate-y-0",
                    "leave-to-class": "opacity-0 -translate-y-4"
                }, {
                    default: _withCtx(() => [
                        _withDirectives(_createElementVNode("div", _hoisted_2, [
                            _renderSlot(_ctx.$slots, "default", { open: open.value })
                        ], 512), [
                            [_vShow, open.value]
                        ])
                    ]),
                    _: 3
                })
            ]));
        };
    }
});
