import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "relative" };
const _hoisted_2 = ["src"];
import { onMounted } from 'vue';
import { useSessionStorage } from '@vueuse/core';
import { useGamificacaoStore } from '@/stores';
import UiIcon from '@/components/ui/icon.vue';
import CountTo from '@/views/gestao/dashboard/components/counter.vue';
import ImgGamePoints from '@/assets/game/icon-points.svg';
export default /*@__PURE__*/ _defineComponent({
    __name: 'topbarItem',
    setup(__props) {
        const gameBar = useSessionStorage('game-bar', false);
        const gamificationStore = useGamificacaoStore();
        const toggleGameBar = () => {
            gamificationStore.getRank({ page: 1, size: 5 });
            gamificationStore.getTrophies({ page: 1, size: 6 });
            gameBar.value = !gameBar.value;
        };
        onMounted(() => {
            gamificationStore.getMyRank();
        });
        return (_ctx, _cache) => {
            return (_unref(gamificationStore).userRank.status !== 'initial' && _unref(gamificationStore).userRank.data.score > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "flex items-center gap-2 cursor-pointer hover:bg-white/20 px-4 py-2 rounded-md font-semibold",
                    onClick: _cache[0] || (_cache[0] = ($event) => (toggleGameBar()))
                }, [
                    _createElementVNode("span", _hoisted_1, [
                        _createElementVNode("img", {
                            src: _unref(ImgGamePoints),
                            alt: "points",
                            width: "24",
                            height: "24"
                        }, null, 8, _hoisted_2),
                        (_unref(gamificationStore).userRank.status === 'loading')
                            ? (_openBlock(), _createBlock(UiIcon, {
                                key: 0,
                                icon: "loader",
                                class: "animate-spin absolute top-0 left-0 opacity-50"
                            }))
                            : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("span", {
                        class: _normalizeClass(["inline-block", { 'animate-pulse': _unref(gamificationStore).userRank.status === 'loading' }])
                    }, [
                        _createVNode(CountTo, {
                            value: _unref(gamificationStore).userRank.data.score || 0
                        }, null, 8, ["value"]),
                        _cache[1] || (_cache[1] = _createTextVNode(" pts "))
                    ], 2)
                ]))
                : _createCommentVNode("", true);
        };
    }
});
