import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "flex justify-between text-right flex-wrap sm:space-x-2 space-y-2 sm:space-y-0"
};
const _hoisted_2 = { class: "space-x-2" };
import { ref } from 'vue';
import { useAuthStore, useUsuariosStore } from '@/stores';
import UiModal from '@/components/ui/modal.vue';
import ProfileContent from './content.vue';
import UiButton from '@/components/ui/button.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    setup(__props, { expose: __expose }) {
        const auth = useAuthStore();
        const modal = ref(null);
        const loader = ref(null);
        const profileForm = ref(null);
        const isOpen = ref(false);
        const beforeOpen = async () => {
            isOpen.value = false;
            try {
                if (!auth.userData.id) {
                    loader.value?.open();
                    await auth.getUserInfo(auth.persistentInfo.id);
                    loader.value?.cancel();
                }
            }
            catch (error) {
                loader.value?.cancel();
                return false;
            }
            isOpen.value = true;
            return true;
        };
        const close = () => { modal.value?.cancel(); };
        const confirm = async () => {
            if (!await profileForm.value?.submit())
                return;
            close();
        };
        const resetPassword = async () => {
            await profileForm.value?.resetPassword();
        };
        __expose({
            open: () => modal.value?.open()
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(UiModal, {
                    ref_key: "modal",
                    ref: modal,
                    title: _ctx.$t('modals.profile'),
                    width: "986px",
                    disabled: _unref(useUsuariosStore)().status.update === 'loading',
                    loading: _unref(useUsuariosStore)().status.getById === 'loading',
                    "before-open": beforeOpen
                }, {
                    footer: _withCtx(() => [
                        (_unref(useUsuariosStore)().status.getById !== 'loading')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                _createVNode(UiButton, {
                                    type: "danger",
                                    class: "w-36 h-12 text-sm uppercase",
                                    onClick: _cache[0] || (_cache[0] = ($event) => (resetPassword()))
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('button.resetPassword')), 1)
                                    ]),
                                    _: 1
                                }),
                                _createElementVNode("div", _hoisted_2, [
                                    _createVNode(UiButton, {
                                        type: "danger",
                                        outline: "",
                                        class: "px-6 py-3 text-sm uppercase",
                                        onClick: _cache[1] || (_cache[1] = ($event) => (close()))
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t('button.cancel')), 1)
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(UiButton, {
                                        type: "primary",
                                        class: "px-6 py-3 text-sm uppercase",
                                        disabled: _unref(useUsuariosStore)().status.update === 'loading',
                                        onClick: _cache[2] || (_cache[2] = ($event) => (confirm()))
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t('button.save')), 1)
                                        ]),
                                        _: 1
                                    }, 8, ["disabled"])
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    default: _withCtx(() => [
                        (isOpen.value)
                            ? (_openBlock(), _createBlock(ProfileContent, {
                                key: 0,
                                ref_key: "profileForm",
                                ref: profileForm,
                                class: "px-4"
                            }, null, 512))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["title", "disabled", "loading"]),
                _createVNode(UiModal, {
                    ref_key: "loader",
                    ref: loader,
                    title: _ctx.$t('feedback.loading'),
                    width: "986px",
                    "disable-esc": "",
                    "hide-close": "",
                    "hide-footer": ""
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('feedback.pleaseWait')), 1)
                    ]),
                    _: 1
                }, 8, ["title"])
            ], 64));
        };
    }
});
