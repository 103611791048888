import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "dropdown-toggle box-border flex h-[46px] w-max items-center justify-center rounded-l-lg pl-2 pr-1 hover:cursor-pointer hover:bg-transparent" };
const _hoisted_2 = ["src"];
const _hoisted_3 = { class: "whitespace-nowrap text-sm" };
const _hoisted_4 = { class: "dropdown-content inline-flex w-48 flex-col" };
const _hoisted_5 = ["onClick"];
const _hoisted_6 = ["src"];
const _hoisted_7 = { class: "" };
const _hoisted_8 = { class: "text-slate-400" };
import { ref, computed } from 'vue';
import UiDropdown from '@/components/ui/dropdown.vue';
import UiIcon from '@/components/ui/icon.vue';
import UiInput from '@/components/ui/input.vue';
import { useI18n } from 'vue-i18n';
export default /*@__PURE__*/ _defineComponent({
    __name: 'inputNumberPhone',
    props: {
        modelValue: {},
        disabled: { type: Boolean },
        placeholder: {}
    },
    emits: ['update:modelValue'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const { t } = useI18n();
        const props = __props;
        const emit = __emit;
        const countries = [
            {
                code: 'BR',
                name: 'Brazil',
                prefix: '+55',
                flag: 'https://cdn.kcak11.com/CountryFlags/countries/br.svg',
                mask: ['(##) ####-####', '(##) #####-####'],
                placeholder: `(DD) + ${t('labels.number')}`
            },
            {
                code: 'PY',
                name: 'Paraguay',
                prefix: '+595',
                flag: 'https://cdn.kcak11.com/CountryFlags/countries/py.svg',
                mask: ['(##) ###-###', '(###) ###-###', '(##) #####-####', '(###) #####-####'],
                placeholder: `(DD) + ${t('labels.number')}`
            }
        ];
        const country = ref({ ...countries[0] });
        const setByCountry = (countryCode) => {
            const result = countries.find((el) => el.code === countryCode);
            if (result?.code)
                country.value = result;
        };
        const setByPrefix = (prefix) => {
            const index = countries.findIndex((el) => el.prefix === prefix);
            if (index !== -1)
                country.value = countries[index];
        };
        const value = computed({
            get: () => {
                const sortedPrefixes = countries.map((el) => el.prefix).sort((a, b) => b.length - a.length);
                if (props.modelValue && typeof props.modelValue === 'string') {
                    const prefix = sortedPrefixes.find((pref) => pref === `${props.modelValue}`.substring(0, pref.length));
                    if (prefix) {
                        setByPrefix(prefix);
                        return `${props.modelValue}`.split(prefix).pop();
                    }
                }
                return props.modelValue;
            },
            set: (val) => {
                if (val === '')
                    return emit('update:modelValue', null);
                const clean = `${val}`.replace(/\D/g, '');
                return emit('update:modelValue', `${country.value.prefix}${clean}`);
            }
        });
        __expose({
            setByCountry,
            setByPrefix
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["ui-phone-input inline-flex items-center", { 'ui-phone-input--disabled': _ctx.disabled }])
            }, [
                _createVNode(UiDropdown, {
                    disabled: _ctx.disabled,
                    class: _normalizeClass(["w-20", { 'pointer-events-none': _ctx.disabled }])
                }, {
                    default: _withCtx(({ open }) => [
                        _createElementVNode("div", _hoisted_1, [
                            _createElementVNode("img", {
                                src: country.value.flag,
                                alt: "Selected country flag",
                                width: "18",
                                height: "18"
                            }, null, 8, _hoisted_2),
                            _createVNode(UiIcon, {
                                icon: "caret-down",
                                size: "18",
                                class: _normalizeClass(["transition-transform duration-300", { '-rotate-180': open }])
                            }, null, 8, ["class"]),
                            _createElementVNode("span", _hoisted_3, _toDisplayString(country.value.prefix), 1)
                        ])
                    ]),
                    content: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(countries, (item, i) => {
                                return _createElementVNode("div", {
                                    key: i,
                                    class: "dropdown-item",
                                    onClick: ($event) => (country.value = item)
                                }, [
                                    _createElementVNode("img", {
                                        src: item.flag,
                                        alt: "Country flag",
                                        width: "18",
                                        height: "18"
                                    }, null, 8, _hoisted_6),
                                    _createElementVNode("span", _hoisted_7, _toDisplayString(item.name), 1),
                                    _createElementVNode("span", _hoisted_8, _toDisplayString(item.prefix), 1)
                                ], 8, _hoisted_5);
                            }), 64))
                        ])
                    ]),
                    _: 1
                }, 8, ["disabled", "class"]),
                _createVNode(UiInput, {
                    modelValue: value.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((value).value = $event)),
                    type: "text",
                    mask: JSON.stringify(country.value.mask || ''),
                    placeholder: _ctx.placeholder ?? country.value.placeholder,
                    disabled: _ctx.disabled,
                    class: "ui-phone-input-content w-full hover:bg-transparent focus:border-transparent focus:bg-transparent border-0 disabled:bg-transparent"
                }, null, 8, ["modelValue", "mask", "placeholder", "disabled"])
            ], 2));
        };
    }
});
