import { toast } from '@/common/util';
import baseApi from './base.api';
const api = {
    ...baseApi,
    resource: 'Competencia',
    async getListBasic(params) {
        const filter = { ExibirEm: null };
        if (params?.type) {
            if (params.type === 'files')
                filter.ExibirEm = 'Midia';
            if (params.type === 'courses')
                filter.ExibirEm = 'Curso';
            if (params.type === 'trails')
                filter.ExibirEm = 'Formacao';
            if (params.type === 'assessments')
                filter.ExibirEm = 'Avaliacao';
            delete params.type;
        }
        try {
            return await api.get(`Basic`, { ...params, ...filter });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao buscar lista de competências!' });
        }
    },
    async getAllBySection(sectionId) {
        try {
            return await api.get(`GetAllBySection/${sectionId}`);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao buscar competências da seção!' });
        }
    }
};
export default api;
