import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["min", "max", "disabled", "value"];
import { ref, computed, onMounted } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'slider',
    props: {
        modelValue: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            default: 'primary',
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        _useCssVars(_ctx => ({
            "52f038fe": (percent.value)
        }));
        const props = __props;
        const emit = __emit;
        const slider = ref(null);
        const percent = ref('50%');
        const baseClasses = computed(() => {
            const classes = { 'cursor-pointer': !props.disabled, };
            const validTypes = ['primary', 'danger', 'success', 'purple'];
            if (validTypes.includes(props.type))
                classes[props.type] = true;
            return classes;
        });
        const updatePercent = () => {
            if (slider.value)
                percent.value = `${(100 * Number(slider.value.value)) / (props.max - props.min)}%`;
        };
        const onUpdate = (event) => {
            const value = parseInt(event.target.value, 10);
            updatePercent();
            emit('update:modelValue', value);
        };
        onMounted(() => { updatePercent(); });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("input", {
                ref_key: "slider",
                ref: slider,
                type: "range",
                class: _normalizeClass(["pe-slider", baseClasses.value]),
                min: __props.min,
                max: __props.max,
                disabled: __props.disabled,
                value: __props.modelValue,
                onInput: onUpdate
            }, null, 42, _hoisted_1));
        };
    }
});
