<template>
  <RouterView v-slot="{ Component }">
    <transition
      mode="out-in"
      appear
      enter-active-class="transition ease-out duration-150 transform"
      enter-from-class="opacity-0 translate-y-4"
      leave-active-class="ease-in duration-200"
      leave-to-class="opacity-0 -translate-y-4"
    >
      <component :is="Component" />
    </transition>
  </RouterView>
</template>