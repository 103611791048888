import { computed, reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { dialog, toast } from '@/common/util';
import formacaoService from '@/services/formacao.service';
import { formationService } from '@/services/formacao.service';
export const useFormacoesStore = defineStore('formacoes', () => {
    const { t } = useI18n();
    /**
     * States
     */
    const list = ref([]);
    const status = reactive({
        create: 'initial',
        update: 'initial',
        delete: 'initial',
        getById: 'initial',
        getAll: 'initial',
        associations: 'initial',
        subscriptions: 'initial',
        bonds: 'initial',
        favorites: 'initial',
        reaction: 'initial'
    });
    /**
     * Getters
     */
    const loading = computed(() => Object.values(status).some(el => el === 'loading'));
    /**
     * Actions
     */
    const create = async (objData, file) => {
        status.create = 'loading';
        try {
            const { data, success } = await formacaoService.create(objData);
            if (success) {
                const result = data;
                if (file)
                    await formacaoService.fileUpload(result.id, file);
                getAll();
                status.create = 'success';
            }
            return data;
        }
        catch {
            status.create = 'error';
        }
    };
    const update = async (objData, id, file) => {
        status.update = 'loading';
        try {
            const { data, success } = await formacaoService.update(id, objData);
            if (success) {
                if (file)
                    await formacaoService.fileUpload(id, file);
                getAll();
                status.update = 'success';
            }
            return data;
        }
        catch {
            status.update = 'error';
        }
    };
    const getAll = async () => {
        status.getAll = 'loading';
        try {
            const { data } = await formacaoService.get();
            status.getAll = 'success';
            list.value = data;
        }
        catch {
            status.getAll = 'error';
        }
    };
    const remove = async (formacao, force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.trail').toLowerCase(), name: formacao.titulo }),
                complete: () => remove(formacao, true)
            });
        try {
            status.delete = 'loading';
            const { success } = await formacaoService.remove(formacao.id);
            if (success)
                list.value.splice(list.value.findIndex(el => el.id === formacao.id), 1);
            status.delete = 'success';
            return success;
        }
        catch {
            status.delete = 'error';
        }
    };
    const getById = async (id) => {
        status.getById = 'loading';
        try {
            const { data } = await formacaoService.get(id);
            status.getById = 'success';
            return data;
        }
        catch {
            status.getById = 'error';
        }
    };
    const exportFormations = async () => {
        try {
            return await formacaoService.exportCsv();
        }
        catch {
            //
        }
    };
    const bonds = async (formacaoId) => {
        status.bonds = 'loading';
        try {
            const { data } = await formacaoService.getBonds(formacaoId);
            status.bonds = 'success';
            return data;
        }
        catch {
            status.bonds = 'error';
        }
    };
    const sendSubscriptions = async (data, inscrever) => {
        status.subscriptions = 'loading';
        try {
            const { success } = await formacaoService.sendSubscriptions(data, inscrever);
            if (success)
                toast.success({
                    message: inscrever
                        ? t('toasts.inscriptions.subscribe', [data.usuariosIds.length])
                        : t('toasts.inscriptions.unsubscribe', [data.usuariosIds.length])
                });
            status.subscriptions = 'success';
        }
        catch {
            status.subscriptions = 'error';
        }
    };
    const getSubscriptions = async (formacaoId) => {
        status.subscriptions = 'loading';
        try {
            const { data } = await formacaoService.getSubscriptions(formacaoId);
            status.subscriptions = 'success';
            return data;
        }
        catch {
            status.subscriptions = 'error';
        }
    };
    const setSubscription = async (id, unsubscribe) => {
        status.subscriptions = 'loading';
        try {
            const { success } = (!unsubscribe ? await formacaoService.subscribe(id) : await formacaoService.unsubscribe(id));
            status.subscriptions = 'success';
            return success;
        }
        catch {
            status.subscriptions = 'error';
        }
    };
    const setFavorite = async (id, favorite) => {
        status.favorites = 'loading';
        try {
            const { success } = (favorite ? await formationService.bookmark(id) : await formationService.unbookmark(id));
            status.favorites = 'success';
            return success;
        }
        catch {
            status.favorites = 'error';
        }
    };
    const setReactionFormation = async (objData) => {
        status.reaction = 'loading';
        try {
            const data = await formationService.setReactionFormation(objData);
            status.reaction = 'success';
            return data;
        }
        catch {
            status.reaction = 'error';
        }
    };
    return {
        list,
        status,
        // Getters
        loading,
        // Actions
        create,
        update,
        getAll,
        getById,
        remove,
        exportFormations,
        bonds,
        sendSubscriptions,
        getSubscriptions,
        setSubscription,
        setFavorite,
        setReactionFormation
    };
});
export default useFormacoesStore;
