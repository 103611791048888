import { defineStore } from 'pinia';
import { ref } from 'vue';
export const useFavoritosStore = defineStore('favoritos', () => {
    // States
    const list = ref(JSON.parse(localStorage.getItem('favorites')) || []);
    const isFavorite = (id) => list.value.some((item) => item.id === id);
    const toggleFavorite = (obj) => {
        const favKey = list.value.findIndex((fav) => fav.id === obj.id);
        if (favKey === -1)
            list.value.push(obj);
        else
            list.value.splice(favKey, 1);
        localStorage.setItem('favorites', JSON.stringify(list.value));
    };
    return {
        list,
        isFavorite,
        toggleFavorite
    };
});
