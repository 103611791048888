import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "container-center mb-5 flex flex-col overflow-x-auto"
};
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useSessionStorage } from '@vueuse/core';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeTabs',
    props: {
        itens: {}
    },
    setup(__props) {
        const settings = useSessionStorage('sys-settings', {
            newLayout: true,
            demoGamification: false
        });
        const props = __props;
        const router = useRouter();
        // Referência aos elementos do templates
        const navigation = ref(null);
        const active = ref(null);
        /**
         * Evento selecionar elemento
         * @param event
         */
        const select = (index) => {
            props.itens[index].redirect
                ? window.open(props.itens[index].redirect, '_blank')
                : active.value = index;
        };
        /**
         * Execução ao montar elemento
         */
        onMounted(() => {
            const idx = props.itens.findIndex((c) => c.to === router.currentRoute.value.path);
            // "Clica" automaticamente no elemento do menu correspondente á rota atual
            if (idx > -1) {
                navigation.value?.querySelectorAll('.navlink')[idx].click();
            }
        });
        return (_ctx, _cache) => {
            const _component_pe_icon = _resolveComponent("pe-icon");
            const _component_router_link = _resolveComponent("router-link");
            return (!_unref(settings).newLayout)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("nav", {
                        ref_key: "navigation",
                        ref: navigation,
                        class: "mb-2 flex"
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itens, (item, key) => {
                            return (_openBlock(), _createBlock(_component_router_link, {
                                key: key,
                                to: item.to || '',
                                class: _normalizeClass(["navlink inline-flex items-center gap-2 px-4 py-2 text-center text-sm text-black hover:text-neutral-900 dark:hover:text-neutral-500 no-underline dark:text-neutral-300", { active: active.value === key }]),
                                onClick: ($event) => (select(key))
                            }, {
                                default: _withCtx(() => [
                                    (item.icon)
                                        ? (_openBlock(), _createBlock(_component_pe_icon, {
                                            key: 0,
                                            type: item.icon.type,
                                            fill: item.icon.fill,
                                            weight: item.icon.weight,
                                            grade: item.icon.grade,
                                            "optical-size": item.icon.opticalSize,
                                            size: 24,
                                            icon: item.icon.name
                                        }, null, 8, ["type", "fill", "weight", "grade", "optical-size", "icon"]))
                                        : _createCommentVNode("", true),
                                    _createTextVNode(" " + _toDisplayString(item.nome), 1)
                                ]),
                                _: 2
                            }, 1032, ["to", "class", "onClick"]));
                        }), 128))
                    ], 512)
                ]))
                : _createCommentVNode("", true);
        };
    }
});
