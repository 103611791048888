import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "box-border flex flex-col h-full text-neutral-700 dark:text-neutral-400 px-4" };
const _hoisted_2 = {
    key: 0,
    class: "mb-4"
};
const _hoisted_3 = { class: "p-2 flex justify-between items-center text-sm font-semibold" };
const _hoisted_4 = {
    key: 0,
    class: "p-3"
};
const _hoisted_5 = {
    key: 1,
    class: "text-center mb-2"
};
const _hoisted_6 = {
    key: 2,
    class: "mb-2"
};
const _hoisted_7 = { class: "flex flex-col gap-2 mb-4" };
const _hoisted_8 = { class: "flex justify-between items-center text-sm font-semibold" };
const _hoisted_9 = { class: "mb-1 font-semibold text-sm" };
const _hoisted_10 = { class: "mb-2 text-neutral-700 dark:text-neutral-400" };
const _hoisted_11 = { class: "text-sm" };
const _hoisted_12 = { class: "flex justify-between text-sm" };
const _hoisted_13 = { class: "-ml-1" };
const _hoisted_14 = { class: "text-neutral-600" };
const _hoisted_15 = {
    key: 3,
    class: "grow px-4"
};
const _hoisted_16 = { class: "flex flex-col items-center gap-6 mt-12" };
const _hoisted_17 = ["src"];
const _hoisted_18 = { class: "flex items-center" };
const _hoisted_19 = { class: "my-4 flex items-center justify-between mt-auto pb-2 shrink-0" };
const _hoisted_20 = { class: "w-full flex flex-col gap-2" };
import { computed, onBeforeMount, ref, watch, nextTick } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue';
import { useMidiaStore, useUserNotificationsStore } from '@/stores';
import { status, init, subscribed, subscribe, /* unsubscribe */ } from '@/common/firebase';
import UiDrawer from '@/components/ui/drawer.vue';
import UiButton from '@/components/ui/button.vue';
import UiAlert from '@/components/ui/alert.vue';
import UiIcon from '@/components/ui/icon.vue';
import UiUploadProgress from './uploadProgress.vue';
import 'overlayscrollbars/overlayscrollbars.css';
import ImgAllClear from '@/assets/allclear.svg';
export default /*@__PURE__*/ _defineComponent({
    __name: 'notificationsBar',
    setup(__props, { expose: __expose }) {
        const midiaStore = useMidiaStore();
        const userNotifications = useUserNotificationsStore();
        const isOpen = ref(false);
        const isSupported = computed(() => typeof Notification !== 'undefined');
        const userPermission = ref(Notification.permission);
        const uoNotifications = useLocalStorage('uo.notifications', {
            onlyUnread: false
        });
        // Group notifications by date
        const notificationList = computed(() => {
            let list = [];
            let baseList = userNotifications.state.list;
            if (uoNotifications.value.onlyUnread) {
                baseList = baseList.filter((item) => item.status === 'SENT');
            }
            baseList.forEach((item) => {
                const date = new Date(item.deliveryTime);
                const time = date?.getTime() || 0;
                const dateIndex = list.findIndex((group) => group.date.toDateString() === date.toDateString());
                if (dateIndex === -1) {
                    list.push({ date, time, notifications: [item] });
                }
                else {
                    list[dateIndex].notifications.push(item);
                }
            });
            return list;
        });
        const uploads = computed(() => midiaStore.uploadList.slice(0, 4));
        const open = () => {
            isOpen.value = true;
        };
        const doSubscribe = () => {
            nextTick(() => {
                if (userPermission.value === 'granted' && status.value === 2 && !subscribed.value) {
                    subscribe();
                }
            });
        };
        watch(() => status.value, (v) => {
            if (v === 2)
                doSubscribe();
        }, { immediate: true });
        const askConsent = async () => {
            if (isSupported.value && userPermission.value === 'default') {
                const consent = await Notification.requestPermission();
                userPermission.value = consent;
                doSubscribe();
            }
        };
        onBeforeMount(() => {
            if (status.value === 0)
                init();
            if (userNotifications.status === 'initial')
                userNotifications.update({ page: 1, size: 16 });
        });
        __expose({
            open
        });
        return (_ctx, _cache) => {
            const _component_RouterLink = _resolveComponent("RouterLink");
            const _component_pe_switch = _resolveComponent("pe-switch");
            return (_openBlock(), _createBlock(UiDrawer, {
                modelValue: isOpen.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((isOpen).value = $event)),
                title: _ctx.$t('notifications.activityHistory'),
                width: "400px"
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(_unref(OverlayScrollbarsComponent), {
                            defer: "",
                            class: "overflow-auto mb-4 pr-4 -mr-4"
                        }, {
                            default: _withCtx(() => [
                                (_unref(midiaStore).uploadList.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                        _createElementVNode("div", _hoisted_3, [
                                            _cache[5] || (_cache[5] = _createElementVNode("span", null, "Uploads", -1)),
                                            _createElementVNode("span", null, _toDisplayString(uploads.value.length) + " de " + _toDisplayString(_unref(midiaStore).uploadList.length), 1)
                                        ]),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(uploads.value, (item) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                                key: item.id,
                                                class: "nofication-item"
                                            }, [
                                                _createVNode(UiUploadProgress, { item: item }, null, 8, ["item"])
                                            ]));
                                        }), 128)),
                                        (_unref(midiaStore).uploadList.length > 4)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                                _createVNode(_component_RouterLink, {
                                                    to: "/notifications",
                                                    onClick: _cache[0] || (_cache[0] = ($event) => (isOpen.value = false))
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.$t('notifications.uploadHistory')), 1)
                                                    ]),
                                                    _: 1
                                                })
                                            ]))
                                            : _createCommentVNode("", true)
                                    ]))
                                    : _createCommentVNode("", true),
                                (!isSupported.value || userPermission.value === 'denied')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                        _createVNode(UiAlert, {
                                            type: "warning",
                                            title: _ctx.$t('notifications.unsupportedTitle'),
                                            text: _ctx.$t('notifications.unsupportedBody')
                                        }, null, 8, ["title", "text"])
                                    ]))
                                    : (userPermission.value === 'default')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                            _createVNode(UiAlert, {
                                                type: "info",
                                                title: _ctx.$t('notifications.unsupportedTitle'),
                                                class: "text-center"
                                            }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.$t('notifications.alertEnable')) + " ", 1),
                                                    _createVNode(UiButton, {
                                                        type: "info",
                                                        class: "mt-2 p-2",
                                                        onClick: askConsent
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.$t('notifications.enable')), 1)
                                                        ]),
                                                        _: 1
                                                    })
                                                ]),
                                                _: 1
                                            }, 8, ["title"])
                                        ]))
                                        : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_7, [
                                    _createElementVNode("div", _hoisted_8, [
                                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('labels.notification', 2)), 1),
                                        _createElementVNode("span", null, [
                                            _createTextVNode(_toDisplayString(_unref(uoNotifications).onlyUnread ? _ctx.$t('notifications.unread') : _ctx.$t('labels.all', 2)) + " ", 1),
                                            _createVNode(_component_pe_switch, {
                                                modelValue: _unref(uoNotifications).onlyUnread,
                                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_unref(uoNotifications).onlyUnread) = $event)),
                                                class: "scale-75"
                                            }, null, 8, ["modelValue"])
                                        ])
                                    ]),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notificationList.value, (group, index) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                            key: `${index}-${group.time}`
                                        }, [
                                            _createElementVNode("div", _hoisted_9, _toDisplayString(group.date.toLocaleDateString()), 1),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.notifications, (notification) => {
                                                return (_openBlock(), _createElementBlock("div", {
                                                    key: notification.id,
                                                    class: _normalizeClass(['nofication-item font-light', { 'unread': notification.status === 'SENT' }])
                                                }, [
                                                    _createElementVNode("div", _hoisted_10, [
                                                        _createElementVNode("div", {
                                                            class: _normalizeClass(["mb-2", { 'font-semibold !text-neutral-800 dark:!text-neutral-300': notification.status === 'SENT' }])
                                                        }, _toDisplayString(notification.title), 3),
                                                        _createElementVNode("div", _hoisted_11, _toDisplayString(notification.content || '(Conteúdo em branco)'), 1)
                                                    ]),
                                                    _createElementVNode("div", _hoisted_12, [
                                                        _createElementVNode("div", _hoisted_13, [
                                                            _createVNode(UiButton, {
                                                                type: "ghost",
                                                                class: "!text-red-500",
                                                                onClick: ($event) => (_unref(userNotifications).remove(notification.id))
                                                            }, {
                                                                default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(_ctx.$t('button.delete')), 1)
                                                                ]),
                                                                _: 2
                                                            }, 1032, ["onClick"]),
                                                            (notification.status === 'SENT')
                                                                ? (_openBlock(), _createBlock(UiButton, {
                                                                    key: 0,
                                                                    type: "link",
                                                                    class: "mr-1",
                                                                    onClick: ($event) => (_unref(userNotifications).markAsRead(notification.id))
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.$t('button.markAsRead')), 1)
                                                                    ]),
                                                                    _: 2
                                                                }, 1032, ["onClick"]))
                                                                : _createCommentVNode("", true)
                                                        ]),
                                                        _createElementVNode("div", _hoisted_14, _toDisplayString(notification.deliveryTime ? new Date(notification.deliveryTime).toLocaleTimeString() : '-'), 1)
                                                    ])
                                                ], 2));
                                            }), 128))
                                        ]));
                                    }), 128))
                                ]),
                                (!notificationList.value?.length && !uploads.value.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                        _createElementVNode("div", _hoisted_16, [
                                            _createElementVNode("img", {
                                                src: _unref(ImgAllClear),
                                                alt: "",
                                                class: "h-full w-full max-w-[200px]"
                                            }, null, 8, _hoisted_17),
                                            _createElementVNode("div", null, [
                                                _createVNode(UiAlert, {
                                                    type: "warning",
                                                    title: _ctx.$t('alerts.youIncredible'),
                                                    text: _ctx.$t('alerts.noNotifications')
                                                }, {
                                                    icon: _withCtx(() => [
                                                        _createElementVNode("div", _hoisted_18, [
                                                            _createVNode(UiIcon, {
                                                                icon: "confetti",
                                                                size: "48"
                                                            })
                                                        ])
                                                    ]),
                                                    _: 1
                                                }, 8, ["title", "text"])
                                            ])
                                        ])
                                    ]))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 1
                        }),
                        _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("div", _hoisted_20, [
                                _createVNode(_component_RouterLink, {
                                    to: "/notifications",
                                    onClick: _cache[2] || (_cache[2] = ($event) => (isOpen.value = false))
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('notifications.history')), 1)
                                    ]),
                                    _: 1
                                })
                            ]),
                            _createVNode(UiButton, {
                                type: "primary",
                                class: "p-4",
                                loading: _unref(userNotifications).status === 'loading',
                                disabled: _unref(userNotifications).status === 'loading',
                                onClick: _cache[3] || (_cache[3] = ($event) => (_unref(userNotifications).update()))
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('button.update')), 1)
                                ]),
                                _: 1
                            }, 8, ["loading", "disabled"])
                        ])
                    ])
                ]),
                _: 1
            }, 8, ["modelValue", "title"]));
        };
    }
});
