import { defineStore } from 'pinia';
import { reactive, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { dialog } from '@/common/util';
import notesService from '@/services/notes.service';
import useAuthStore from './authStore';
export const useNotesStore = defineStore('notes', () => {
    const { t } = useI18n();
    const list = ref([]);
    const status = reactive({
        getAll: 'initial',
        create: 'initial',
        update: 'initial',
        delete: 'initial'
    });
    const loading = computed(() => Object.values(status).some(el => el === 'loading'));
    const userId = computed(() => useAuthStore().persistentInfo.id);
    const getAll = async (params) => {
        status.getAll = 'loading';
        try {
            const { data } = await notesService.get(null, { ...params });
            list.value = data.map((el) => formatResponse(el));
            status.getAll = 'success';
        }
        catch {
            status.getAll = 'error';
        }
    };
    const create = async (obj) => {
        status.create = 'loading';
        try {
            const { data, success } = await notesService.post(null, formatSubmit(obj));
            if (success && data) {
                const index = list.value.findIndex(el => el.id === data.id);
                list.value.splice(index, 1, formatResponse(data));
            }
            status.create = 'success';
            return success;
        }
        catch {
            status.create = 'error';
        }
    };
    const update = async (obj) => {
        status.update = 'loading';
        try {
            const { data, success } = await notesService.put(obj.id, formatSubmit(obj));
            if (success && data) {
                const index = list.value.findIndex(el => el.id === data.id);
                list.value.splice(index, 1, formatResponse(data));
            }
            status.update = 'success';
            return success;
        }
        catch {
            status.update = 'error';
        }
    };
    const remove = async (note, force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.note', 3).toLowerCase(), name: note.title }),
                complete: () => remove(note, true)
            });
        try {
            status.delete = 'loading';
            const { success } = await notesService.delete(note.id);
            if (success) {
                const index = list.value.findIndex(el => el.id === note.id);
                index >= 0 && list.value.splice(index, 1);
            }
            status.delete = 'success';
            return success;
        }
        catch {
            status.delete = 'error';
        }
    };
    const formatResponse = (data) => {
        return {
            id: data.id,
            title: data.tituloAnotacao,
            description: data.anotacao,
            imageUrl: data.urlCapturaTela,
            image: data.capturaTela,
            screenshotTime: data.momentoCapturaTela,
            courseId: data.cursoId,
            formationId: data.formacaoId,
            mediaId: data.midiaId,
            userId: data.usuarioId
        };
    };
    const formatSubmit = (data) => {
        const formData = new FormData();
        data.id && formData.append('id', data.id);
        formData.append('tituloAnotacao', data.title);
        formData.append('anotacao', data.description);
        data.imageUrl && formData.append('urlCapturaTela', data.imageUrl);
        data.image && formData.append('capturaTela', data.image);
        data.screenshotTime && formData.append('momentoCapturaTela', data.screenshotTime);
        data.courseId && formData.append('cursoId', data.courseId);
        data.formationId && formData.append('formacaoId', data.formationId);
        data.mediaId && formData.append('midiaId', data.mediaId);
        formData.append('usuarioId', userId.value);
        return formData;
    };
    return {
        // State
        list,
        // Getters
        loading,
        // Actions
        create,
        update,
        remove,
        getAll
    };
});
