import { defineComponent as _defineComponent } from 'vue';
import { vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, vModelText as _vModelText, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, vModelRadio as _vModelRadio, vModelSelect as _vModelSelect } from "vue";
const _hoisted_1 = { class: "pe-table mb-4 w-full rounded-lg bg-neutral-50 dark:bg-neutral-800" };
const _hoisted_2 = { class: "bg-neutral-50 dark:bg-neutral-800" };
const _hoisted_3 = {
    key: 0,
    class: "pe-table--header",
    width: "1"
};
const _hoisted_4 = { class: "flex items-center" };
const _hoisted_5 = ["title", "onClick"];
const _hoisted_6 = { class: "flex items-center gap-2 px-4 py-2" };
const _hoisted_7 = { key: 0 };
const _hoisted_8 = ["value", "onUpdate:modelValue"];
const _hoisted_9 = {
    key: 1,
    class: "max-h-72 overflow-y-auto font-normal"
};
const _hoisted_10 = ["value", "onUpdate:modelValue"];
const _hoisted_11 = {
    key: 2,
    class: "py-2 text-center font-normal"
};
const _hoisted_12 = { class: "flex justify-end bg-neutral-500/10 p-4" };
const _hoisted_13 = {
    key: 1,
    class: "pe-table--header text-center",
    width: "1"
};
const _hoisted_14 = { key: 0 };
const _hoisted_15 = { key: 0 };
const _hoisted_16 = ["value"];
const _hoisted_17 = ["value", "checked"];
const _hoisted_18 = ["width"];
const _hoisted_19 = { key: 1 };
const _hoisted_20 = { key: 2 };
const _hoisted_21 = { key: 3 };
const _hoisted_22 = { key: 4 };
const _hoisted_23 = { key: 5 };
const _hoisted_24 = { key: 6 };
const _hoisted_25 = { class: "inline-flex items-center gap-2 rounded-full bg-slate-100 px-2 text-sm font-medium leading-6 dark:bg-white/10 dark:text-neutral-300" };
const _hoisted_26 = {
    key: 1,
    class: "overflow-hidden text-ellipsis"
};
const _hoisted_27 = {
    key: 2,
    class: "overflow-hidden text-ellipsis px-4"
};
const _hoisted_28 = { key: 7 };
const _hoisted_29 = {
    key: 8,
    class: "flex flex-wrap gap-2"
};
const _hoisted_30 = {
    key: 10,
    class: "font-bold text-blue-700"
};
const _hoisted_31 = {
    key: 1,
    class: "flex items-center justify-start gap-2"
};
const _hoisted_32 = ["title", "disabled", "onClick"];
const _hoisted_33 = ["title", "onClick"];
const _hoisted_34 = {
    key: 1,
    class: "body__skeleton"
};
const _hoisted_35 = {
    key: 0,
    width: "1"
};
const _hoisted_36 = ["width"];
const _hoisted_37 = { class: "flex items-center justify-end gap-2" };
const _hoisted_38 = {
    key: 0,
    class: "my-6 flex items-center"
};
const _hoisted_39 = {
    key: 0,
    class: "ml-5"
};
const _hoisted_40 = ["disabled"];
const _hoisted_41 = ["disabled"];
const _hoisted_42 = ["disabled"];
const _hoisted_43 = ["disabled"];
import { ref, computed, reactive, watch, nextTick, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { strSearch, clamp, formatter } from '@/common/util';
import circleProgress from '@/components/aprendizado/circleProgress.vue';
import UiIcon from '../components/ui/icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeTable',
    props: {
        data: {},
        actions: {},
        columns: {},
        searchTerm: {},
        selectable: { type: Boolean },
        selectionType: {},
        rowIndex: {},
        defaultSort: {},
        defaultSortOrder: {},
        defaultFilters: {},
        defaultFiltersOptions: {},
        loading: { type: Boolean },
        pagedInfo: {}
    },
    emits: [
        'selectionChange',
        'onRemove',
        'onEdit',
        'onView',
        'onDownload',
        'onViewAssociations',
        'onAssociationsSections',
        'onAssociationsPermissionGroup',
        'onRedirect',
        'onSubscriptions',
        'onGenerateQrcode',
        'onGenerateCertificate',
        'onViewMore',
        'onAliareChangePage',
        'onAutoSubscriptions',
        'onAnalyzeRequest',
        'onEditEmail',
        'onAssociationContracts',
        'onAssociationsContent'
    ],
    setup(__props, { expose: __expose, emit: __emit }) {
        const { t } = useI18n();
        const props = __props;
        // @TODO: Isso aqui pode ser melhorado
        const defaultActions = ref([
            {
                id: 'editEmail',
                title: t('button.editUserEmail'),
                icon: 'alternate_email',
                eventName: 'onEditEmail'
            },
            {
                id: 'view',
                title: t('button.view'),
                icon: 'eye',
                newIcon: true,
                eventName: 'onView'
            },
            {
                id: 'department',
                title: t('labels.department'),
                icon: 'menu',
                eventName: 'onDepartment'
            },
            {
                id: 'role',
                title: t('labels.jobTitle'),
                icon: 'badge',
                eventName: 'onRole'
            },
            {
                id: 'user',
                title: t('labels.user'),
                icon: 'account_circle',
                eventName: 'onUser'
            },
            {
                id: 'segmentation',
                title: t('labels.segmentation'),
                icon: 'groups',
                eventName: 'onSegmentation'
            },
            {
                id: 'download',
                title: t('button.download'),
                icon: 'download',
                newIcon: true,
                eventName: 'onDownload'
            },
            {
                id: 'associations',
                title: t('button.associations'),
                icon: 'list-check',
                newIcon: true,
                eventName: 'onViewAssociations'
            },
            {
                id: 'sections',
                title: t('labels.associate', { title: t('labels.section') }),
                icon: 'widgets',
                eventName: 'onAssociationsSections'
            },
            {
                id: 'permissions',
                title: t('labels.associate', { title: t('labels.permission') }),
                icon: 'lock_person',
                eventName: 'onAssociationsPermissionGroup'
            },
            {
                id: 'redirect',
                title: t('button.goClassRoom'),
                icon: 'play_arrow',
                eventName: 'onRedirect'
            },
            {
                id: 'subscriptions',
                title: t('button.studentInscriptions'),
                icon: 'checklist',
                newIcon: true,
                eventName: 'onSubscriptions'
            },
            {
                id: 'qrcode',
                title: t('button.createQrCode'),
                icon: 'qrcode',
                newIcon: true,
                eventName: 'onGenerateQrcode'
            },
            {
                id: 'certificate',
                title: t('button.generateCertificate'),
                icon: 'verified',
                eventName: 'onGenerateCertificate'
            },
            {
                id: 'viewMore',
                title: t('button.seeMore'),
                icon: 'info',
                eventName: 'onViewMore'
            },
            {
                id: 'autoSubscriptions',
                title: t('button.automateJourneys'),
                icon: 'checklist',
                newIcon: true,
                eventName: 'onAutoSubscriptions'
            },
            {
                id: 'associationContracts',
                title: t('labels.associate', { title: t('labels.contract') }),
                icon: 'post_add',
                eventName: 'onAssociationContracts'
            },
            {
                id: 'associationContent',
                title: t('labels.associate', { title: t('labels.data') }),
                icon: 'post_add',
                eventName: 'onAssociationsContent'
            },
            {
                id: 'edit',
                title: t('button.edit'),
                icon: 'edit',
                newIcon: true,
                eventName: 'onEdit'
            },
            {
                id: 'remove',
                title: t('button.delete'),
                icon: 'trash',
                newIcon: true,
                eventName: 'onRemove'
            },
            {
                id: 'analyzeRequest',
                title: t('button.reviewRequest'),
                icon: 'assignment',
                eventName: 'onAnalyzeRequest'
            }
        ]);
        const emit = __emit;
        const checkSelection = ref([]);
        const radioSelection = ref(null);
        const sort = reactive({
            prop: '',
            asc: true
        });
        const filterSearch = ref('');
        watch(() => props.searchTerm, () => setPage(1));
        const itemsSelected = computed(() => radioSelection.value ? [radioSelection.value] : checkSelection.value);
        const visibleActions = computed(() => defaultActions.value.filter((el) => props.actions?.includes(el.id)));
        // Paginação
        const pagination = reactive({
            current: 1,
            limit: 10,
            totalRows: computed(() => {
                if (props.pagedInfo != undefined) {
                    return props.pagedInfo.totalItems || 0;
                }
                return rows.value?.length || 0;
            }),
            totalPages: computed(() => {
                if (props.pagedInfo != undefined) {
                    return calculateTotalPages(props.pagedInfo.totalItems);
                }
                return calculateTotalPages(rows.value?.length);
            })
        });
        const visibleFilters = reactive([]);
        const selectedFilters = reactive({});
        /**
         * Seta a pagina atual
         * @param page
         */
        const setPage = (page) => {
            // A páginação só pode ser setada após a atualização das propriedades computadas (nextTick) pois é dependente do calculo do total máximo de paginas
            nextTick(() => {
                pagination.current = clamp(page, 1, pagination.totalPages);
            });
        };
        /**
         * Calcula o total de páginas
         * @param listLength
         */
        const calculateTotalPages = (listLength) => {
            return Math.ceil((listLength || 0) / pagination.limit);
        };
        const onChangePageLimit = () => {
            if (props.pagedInfo != undefined) {
                emit('onAliareChangePage', { page: pagination.current, pageSize: pagination.limit });
            }
            setPage(1);
        };
        const lastPage = () => {
            if (props.pagedInfo != undefined) {
                emit('onAliareChangePage', { page: pagination.totalPages, pageSize: pagination.limit });
            }
            setPage(pagination.totalPages);
        };
        const nextPage = () => {
            if (props.pagedInfo != undefined) {
                emit('onAliareChangePage', { page: props.pagedInfo.page + 1, pageSize: pagination.limit });
            }
            setPage(pagination.current += 1);
        };
        const previousPage = () => {
            if (props.pagedInfo != undefined) {
                emit('onAliareChangePage', { page: pagination.current - 1, pageSize: pagination.limit });
            }
            setPage(pagination.current -= 1);
        };
        const firtstPage = () => {
            if (props.pagedInfo != undefined) {
                emit('onAliareChangePage', { page: 1, pageSize: pagination.limit });
            }
            setPage(1);
        };
        const getPosition = (colIndex) => {
            const firstIndexes = [0, 1].includes(colIndex);
            const lastIndexes = [(visibleColumns.value?.length || 0) - 1].includes(colIndex);
            return firstIndexes ? 'bottom-start' : lastIndexes ? 'bottom-end' : 'bottom';
        };
        const visibleColumns = computed(() => {
            return (props.columns?.filter((col) => !col.hidden) || []);
        });
        const columnsValuePair = computed(() => props.columns.reduce((acc, el) => (acc[el.prop] = el) && acc, {}));
        const currentSelection = ref([]);
        // Linhas a serem exibidas
        const rows = computed(() => {
            let result = [];
            let dataRows = props.data;
            const sortProp = sort.prop ? sort.prop : props.defaultSort ? props.defaultSort : '';
            const asc = sort.prop ? sort.asc : props.defaultSortOrder === 'desc' ? false : true;
            if (dataRows.length && sortProp !== '') {
                dataRows = dataRows.toSorted((a, b) => (a[sortProp] > b[sortProp] ? 1 : b[sortProp] > a[sortProp] ? -1 : 0) * (asc ? 1 : -1));
            }
            // Executa os filtros internos
            Object.keys(selectedFilters).map((filtro) => {
                if (selectedFilters[filtro]?.length) {
                    dataRows = dataRows.filter((row) => selectedFilters[filtro].includes(getFilterFormatter(row, columnsValuePair.value[filtro])));
                }
            });
            if (props.searchTerm) {
                for (let i = 0; i < dataRows.length; i++) {
                    const row = dataRows[i];
                    const matchCol = props.columns.some((column) => {
                        const colValue = row[column.prop];
                        if (typeof colValue !== 'string')
                            return false;
                        return strSearch(colValue, props.searchTerm);
                    });
                    if (matchCol)
                        result.push(row);
                }
            }
            else {
                result = dataRows;
            }
            if (itemsSelected.value.length) {
                const selected = result.filter((el) => currentSelection.value.includes(el[props.rowIndex]));
                const unselected = result.filter((el) => !currentSelection.value.includes(el[props.rowIndex]));
                result = [...selected, ...unselected];
            }
            return result;
        });
        const exibitRows = computed(() => {
            if (props.pagedInfo != undefined)
                return rows.value;
            return rows.value.slice((pagination.current - 1) * pagination.limit, pagination.current * pagination.limit);
        });
        /**
         * Altera a ordenação da tabela através de uma coluna
         * @param prop
         * @param order Caso não informada segue o padrão (ASC => DESC => Nenhum)
         */
        const changeSorting = (prop, order) => {
            if (!props.columns.find((el) => el.prop === prop).sortable)
                return;
            // Mesma propriedade ativa
            if (sort.prop === prop) {
                if (typeof order === 'undefined') {
                    if (sort.asc === false) {
                        sort.prop = '';
                    }
                    else {
                        sort.asc = !sort.asc;
                    }
                }
            }
            else {
                sort.prop = prop;
                sort.asc = true;
            }
        };
        const getFormatter = {
            'type-people': formatter.typePeople,
            'format-cpfcnpj': formatter.cpfCnpj,
            'format-date': formatter.date,
            'format-status': (value) => value ? t('labels.active') : t('labels.inactive'),
            'active-indicator': (value) => !!value,
            'format-boolean': (value) => value ? t('labels.yes') : t('labels.no'),
            'status-percentage': (value) => value === 0 ? t('labels.notStarted') : `${value}% ${t('labels.completed')}`,
            'type-classificacao-cliente': formatter.typeClassificacaoCliente,
            'type-produto-aliare': formatter.typeProdutoAliare
        };
        const getFilterFormatter = (el, col) => {
            if (!col)
                return;
            return typeof col.transform === 'string'
                ? getFormatter[col.transform]?.(el[col.prop])
                : typeof col.transform === 'function'
                    ? col.transform(el)
                    : el[col.prop];
        };
        const getFilter = (key) => selectedFilters[key] || [];
        /**
         * Gera a lista de filtros relacionada a coluna selecionada
         * @param prop
         */
        const toggleFilter = (col) => {
            // Gera a lista unica de dados de filtragem
            visibleFilters.splice(0, visibleFilters.length);
            filterSearch.value = '';
            props.data
                .map((el) => getFilterFormatter(el, col))
                ?.filter((value, index, self) => self.indexOf(value) === index)
                ?.map((el) => visibleFilters.push(el));
            if (typeof selectedFilters[col.prop] === 'undefined')
                selectedFilters[col.prop] = [];
        };
        const filterListItens = computed(() => (extraData) => [...new Set([visibleFilters, extraData || []].flat().filter(Boolean))]
            .filter((el) => el && strSearch(String(el), filterSearch.value))
            .sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1));
        /**
         * Reseta o filtro indicado
         * @param prop
         */
        const resetFilters = (prop) => {
            selectedFilters[prop].splice(0, selectedFilters[prop]?.length || 0);
            filterSearch.value = '';
        };
        // Listener de mudança de quantidade linhas por página
        watch(() => pagination.limit, () => onChangePageLimit());
        watch(() => ({ total: pagination.totalPages, current: pagination.current, filtersLength: Object.keys(selectedFilters).reduce((aggregate, key) => {
                const size = selectedFilters[key].filter(el => el?.length)?.length || 0;
                return aggregate + size;
            }, 0) }), (newVal, oldVal) => {
            if (newVal.current > newVal.total || newVal.current === 0 || oldVal.filtersLength !== newVal.filtersLength)
                setPage(1);
        }, {
            deep: true
        });
        /**
         * Trata o caso de selecionar/desselecionar todos os items
         */
        const allChecked = computed({
            get: () => checkSelection.value?.length === rows.value?.length,
            set: (val) => {
                setSelection(val ? rows.value.map((el) => el[props.rowIndex]) : []);
                return val;
            }
        });
        watch(checkSelection, (newVal, oldVal) => {
            if (newVal !== oldVal)
                emit('selectionChange', checkSelection.value);
        });
        watch(radioSelection, (newVal, oldVal) => {
            if (newVal !== oldVal)
                emit('selectionChange', radioSelection.value);
        });
        const getSelection = () => (props.selectionType !== 'single' ? checkSelection.value : radioSelection.value);
        const setSelection = (value) => {
            if (props.selectionType !== 'single') {
                checkSelection.value = value;
                currentSelection.value = value;
            }
            else {
                radioSelection.value = value;
                currentSelection.value = [value];
            }
        };
        const onClick = (row) => {
            if (!props.selectable)
                return;
            row.target.closest('tr').querySelector('input')?.click();
        };
        onMounted(() => {
            if (props.defaultFilters)
                Object.assign(selectedFilters, props.defaultFilters);
        });
        __expose({
            getSelection,
            setSelection,
            changeSorting,
            setPage,
            nextPage,
            previousPage,
            exibitRows: () => exibitRows.value,
            allRows: () => rows.value,
            selectedFilters: () => selectedFilters
        });
        return (_ctx, _cache) => {
            const _component_pe_tooltip = _resolveComponent("pe-tooltip");
            const _component_pe_icon = _resolveComponent("pe-icon");
            const _component_pe_button = _resolveComponent("pe-button");
            const _component_pe_dropdown = _resolveComponent("pe-dropdown");
            const _component_pe_skeleton = _resolveComponent("pe-skeleton");
            return (_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", null, [
                    _createElementVNode("table", _hoisted_1, [
                        _createElementVNode("thead", _hoisted_2, [
                            _createElementVNode("tr", null, [
                                (_ctx.selectable)
                                    ? (_openBlock(), _createElementBlock("th", _hoisted_3, [
                                        (_ctx.selectionType !== 'single')
                                            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                                key: 0,
                                                type: "checkbox",
                                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((allChecked).value = $event))
                                            }, null, 512)), [
                                                [_vModelCheckbox, allChecked.value]
                                            ])
                                            : _createCommentVNode("", true)
                                    ]))
                                    : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleColumns.value, (col, colKey) => {
                                    return (_openBlock(), _createElementBlock("th", {
                                        key: colKey,
                                        class: "pe-table--header"
                                    }, [
                                        _createElementVNode("div", _hoisted_4, [
                                            _createTextVNode(_toDisplayString(!col.hideLabel ? col.label : '') + " ", 1),
                                            (col.showTooltip)
                                                ? (_openBlock(), _createBlock(_component_pe_tooltip, {
                                                    key: 0,
                                                    text: col?.tooltip,
                                                    icon: "",
                                                    class: "ml-2"
                                                }, null, 8, ["text"]))
                                                : _createCommentVNode("", true),
                                            (col.sortable)
                                                ? (_openBlock(), _createElementBlock("span", {
                                                    key: 1,
                                                    class: "relative ml-2 h-6 cursor-pointer overflow-hidden opacity-50",
                                                    title: _ctx.$t('tooltips.toggleOrder'),
                                                    onClick: ($event) => (changeSorting(col.prop))
                                                }, [
                                                    _createVNode(_Transition, {
                                                        mode: "out-in",
                                                        "enter-active-class": "transition ease-out duration-150 transform",
                                                        "enter-from-class": "-translate-y-6",
                                                        "enter-to-class": "translate-y-0",
                                                        "leave-active-class": "ease-in duration-150",
                                                        "leave-from-class": "translate-y-0",
                                                        "leave-to-class": "translate-y-6"
                                                    }, {
                                                        default: _withCtx(() => [
                                                            (col.prop === sort.prop && sort.asc)
                                                                ? (_openBlock(), _createBlock(_component_pe_icon, {
                                                                    key: 0,
                                                                    icon: "keyboard_arrow_down",
                                                                    size: 24
                                                                }))
                                                                : (col.prop === sort.prop)
                                                                    ? (_openBlock(), _createBlock(_component_pe_icon, {
                                                                        key: 1,
                                                                        icon: "keyboard_arrow_up",
                                                                        size: 24
                                                                    }))
                                                                    : (_openBlock(), _createBlock(_component_pe_icon, {
                                                                        key: 2,
                                                                        icon: "unfold_more",
                                                                        size: 24
                                                                    }))
                                                        ]),
                                                        _: 2
                                                    }, 1024)
                                                ], 8, _hoisted_5))
                                                : _createCommentVNode("", true),
                                            (col.filterable)
                                                ? (_openBlock(), _createBlock(_component_pe_dropdown, {
                                                    key: 2,
                                                    class: "ml-1 text-left",
                                                    position: getPosition(colKey)
                                                }, {
                                                    content: _withCtx(() => [
                                                        _renderSlot(_ctx.$slots, `filter-${col.prop}`, {}, () => [
                                                            _createElementVNode("div", null, [
                                                                _createElementVNode("div", _hoisted_6, [
                                                                    _withDirectives(_createElementVNode("input", {
                                                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((filterSearch).value = $event)),
                                                                        type: "text",
                                                                        class: "w-full min-w-[200px] rounded-md border border-solid border-black px-2 text-lg dark:bg-neutral-800 dark:text-neutral-100 dark:border-neutral-500",
                                                                        placeholder: "Filtrar"
                                                                    }, null, 512), [
                                                                        [_vModelText, filterSearch.value]
                                                                    ]),
                                                                    _createVNode(_component_pe_icon, { size: 24 }, {
                                                                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                                            _createTextVNode("search")
                                                                        ])),
                                                                        _: 1
                                                                    })
                                                                ]),
                                                                (col.transform === 'active-indicator')
                                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([
                                                                            [true, 'sts-ativo', _unref(t)('labels.active')],
                                                                            [false, 'sts-inativo', _unref(t)('labels.inactive')]
                                                                        ], (sts, stsIdx) => {
                                                                            return (_openBlock(), _createElementBlock("label", {
                                                                                key: stsIdx,
                                                                                class: "flex items-center gap-2 py-2 px-4"
                                                                            }, [
                                                                                _withDirectives(_createElementVNode("input", {
                                                                                    type: "checkbox",
                                                                                    value: sts[0],
                                                                                    "onUpdate:modelValue": ($event) => ((selectedFilters[col.prop]) = $event)
                                                                                }, null, 8, _hoisted_8), [
                                                                                    [_vModelCheckbox, selectedFilters[col.prop]]
                                                                                ]),
                                                                                _createElementVNode("div", {
                                                                                    class: _normalizeClass(["rounded-full py-[5px] px-4 text-center text-sm font-medium", {
                                                                                            'bg-[#C8F9E5] text-[#0E563A] dark:text-[#17AC6C] dark:bg-[#1C3329]': sts[0],
                                                                                            'bg-[#FDEBF1] text-[#FF4980] dark:bg-[#3F242C]': !sts[0]
                                                                                        }])
                                                                                }, _toDisplayString(sts[2]), 3)
                                                                            ]));
                                                                        }), 128))
                                                                    ]))
                                                                    : _createCommentVNode("", true),
                                                                (filterListItens.value(_ctx.defaultFiltersOptions?.[col.prop])?.length > 0 && col.transform !== 'active-indicator')
                                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterListItens.value(_ctx.defaultFiltersOptions?.[col.prop]), (filter, fKey) => {
                                                                            return (_openBlock(), _createElementBlock("label", {
                                                                                key: fKey,
                                                                                class: "block py-3 px-4 text-black transition-all hover:bg-neutral-400/20"
                                                                            }, [
                                                                                _withDirectives(_createElementVNode("input", {
                                                                                    type: "checkbox",
                                                                                    value: filter,
                                                                                    "onUpdate:modelValue": ($event) => ((selectedFilters[col.prop]) = $event)
                                                                                }, null, 8, _hoisted_10), [
                                                                                    [_vModelCheckbox, selectedFilters[col.prop]]
                                                                                ]),
                                                                                _createTextVNode(" " + _toDisplayString(filter), 1)
                                                                            ]));
                                                                        }), 128))
                                                                    ]))
                                                                    : _createCommentVNode("", true),
                                                                (!filterListItens.value.length && col.transform !== 'active-indicator')
                                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, "Nenhum resultado encontrado"))
                                                                    : _createCommentVNode("", true),
                                                                _createElementVNode("div", _hoisted_12, [
                                                                    _createVNode(_component_pe_button, {
                                                                        type: "primary",
                                                                        onClick: ($event) => (resetFilters(col.prop))
                                                                    }, {
                                                                        default: _withCtx(() => [
                                                                            _createTextVNode(_toDisplayString(_unref(t)('button.clear')), 1)
                                                                        ]),
                                                                        _: 2
                                                                    }, 1032, ["onClick"])
                                                                ])
                                                            ])
                                                        ])
                                                    ]),
                                                    default: _withCtx(() => [
                                                        _createVNode(_component_pe_icon, {
                                                            icon: "filter_alt",
                                                            weight: "300",
                                                            size: 24,
                                                            fill: getFilter(col.prop).length > 0,
                                                            class: _normalizeClass(["cursor-pointer", {
                                                                    'opacity-50': !getFilter(col.prop).length,
                                                                    'text-emerald-300': getFilter(col.prop).length
                                                                }]),
                                                            onClick: ($event) => (toggleFilter(col))
                                                        }, null, 8, ["fill", "class", "onClick"])
                                                    ]),
                                                    _: 2
                                                }, 1032, ["position"]))
                                                : _createCommentVNode("", true)
                                        ])
                                    ]));
                                }), 128)),
                                (_ctx.actions && _ctx.actions.length > 0)
                                    ? (_openBlock(), _createElementBlock("th", _hoisted_13, _toDisplayString(_unref(t)('labels.actions')), 1))
                                    : _createCommentVNode("", true)
                            ])
                        ]),
                        (!_ctx.loading)
                            ? (_openBlock(), _createElementBlock("tbody", _hoisted_14, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(exibitRows.value, (row, key) => {
                                    return (_openBlock(), _createElementBlock("tr", {
                                        key: key,
                                        class: _normalizeClass(["pt-table--row", { 'cursor-pointer': _ctx.selectable }])
                                    }, [
                                        (_ctx.selectable)
                                            ? (_openBlock(), _createElementBlock("td", _hoisted_15, [
                                                (_ctx.selectionType !== 'single')
                                                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                                        key: 0,
                                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((checkSelection).value = $event)),
                                                        value: row[_ctx.rowIndex],
                                                        type: "checkbox"
                                                    }, null, 8, _hoisted_16)), [
                                                        [_vModelCheckbox, checkSelection.value]
                                                    ])
                                                    : _withDirectives((_openBlock(), _createElementBlock("input", {
                                                        key: 1,
                                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((radioSelection).value = $event)),
                                                        value: row[_ctx.rowIndex],
                                                        type: "radio",
                                                        checked: radioSelection.value === row[_ctx.rowIndex]
                                                    }, null, 8, _hoisted_17)), [
                                                        [_vModelRadio, radioSelection.value]
                                                    ])
                                            ]))
                                            : _createCommentVNode("", true),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleColumns.value, (col, keyCol) => {
                                            return (_openBlock(), _createElementBlock("td", {
                                                key: keyCol,
                                                width: col.width || '',
                                                onClick: onClick
                                            }, [
                                                _renderSlot(_ctx.$slots, col.prop, { row: row }, () => [
                                                    (col.transform === 'active-indicator')
                                                        ? (_openBlock(), _createElementBlock("div", {
                                                            key: 0,
                                                            class: _normalizeClass(["text-center rounded-full py-1 text-sm font-medium", {
                                                                    'bg-[#C8F9E5] text-[#0E563A] dark:text-[#17AC6C] dark:bg-[#1C3329]': row[col.prop],
                                                                    'bg-[#FDEBF1] text-[#FF4980] dark:bg-[#3F242C]': !row[col.prop]
                                                                }])
                                                        }, _toDisplayString(row[col.prop] ? _unref(t)('labels.active') : _unref(t)('labels.inactive')), 3))
                                                        : (col.transform === 'format-boolean')
                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(row[col.prop] ? _unref(t)('labels.yes') : _unref(t)('labels.no')), 1))
                                                            : (col.transform === 'format-date')
                                                                ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_unref(formatter).date(row[col.prop])), 1))
                                                                : (col.transform === 'format-cpfcnpj')
                                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_unref(formatter).cpfCnpj(row[col.prop])), 1))
                                                                    : (col.transform === 'type-people')
                                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_unref(formatter).typePeople(row[col.prop])), 1))
                                                                        : (col.transform === 'type-classificacao-cliente')
                                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_unref(formatter).typeClassificacaoCliente(row[col.prop])), 1))
                                                                            : (col.transform === 'status-percentage')
                                                                                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                                                                    _createElementVNode("div", _hoisted_25, [
                                                                                        (row[col.prop] > 0)
                                                                                            ? (_openBlock(), _createBlock(circleProgress, {
                                                                                                key: 0,
                                                                                                radius: 12,
                                                                                                stroke: 3,
                                                                                                progress: row[col.prop],
                                                                                                segments: 10
                                                                                            }, null, 8, ["progress"]))
                                                                                            : _createCommentVNode("", true),
                                                                                        (row[col.prop] > 0)
                                                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(row[col.prop]) + "% " + _toDisplayString(_unref(t)('labels.complete')), 1))
                                                                                            : (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_unref(t)('labels.notStarted')), 1))
                                                                                    ])
                                                                                ]))
                                                                                : (col.transform === 'status')
                                                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                                                                        _createElementVNode("div", {
                                                                                            class: _normalizeClass(["inline-flex items-center rounded-full bg-slate-100 px-4 py-2 text-sm font-normal leading-6", {
                                                                                                    'bg-[#99E2B4] text-black': row[col.prop] === 'Concluído',
                                                                                                    'bg-[--c-primary-400] text-white': row[col.prop] === 'Em andamento'
                                                                                                }])
                                                                                        }, _toDisplayString(row[col.prop]), 3)
                                                                                    ]))
                                                                                    : (col.transform === 'to-tag')
                                                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row[col.prop], (item, index) => {
                                                                                                return (_openBlock(), _createElementBlock("div", {
                                                                                                    key: index,
                                                                                                    class: "inline-block bg-emerald-400 text-xs rounded px-2 py-1 text-white"
                                                                                                }, _toDisplayString(item), 1));
                                                                                            }), 128))
                                                                                        ]))
                                                                                        : (typeof col.transform === 'function')
                                                                                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 9 }, [
                                                                                                _createTextVNode(_toDisplayString(col.transform(row, key)), 1)
                                                                                            ], 64))
                                                                                            : (col.transform === 'bold')
                                                                                                ? (_openBlock(), _createElementBlock("div", _hoisted_30, _toDisplayString(row[col.prop]), 1))
                                                                                                : (_openBlock(), _createElementBlock(_Fragment, { key: 11 }, [
                                                                                                    _createTextVNode(_toDisplayString(row[col.prop]), 1)
                                                                                                ], 64))
                                                ])
                                            ], 8, _hoisted_18));
                                        }), 128)),
                                        (row.actions?.length || _ctx.actions?.length)
                                            ? (_openBlock(), _createElementBlock("td", _hoisted_31, [
                                                _renderSlot(_ctx.$slots, "actions", { row: row }),
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleActions.value, (action) => {
                                                    return (_openBlock(), _createElementBlock("button", {
                                                        key: action.id,
                                                        title: row.hintForActions && row.hintForActions[action.id] ? row.hintForActions[action.id] : action.title,
                                                        disabled: (typeof row.actions?.[action.id] !== 'undefined' && !row.actions[action.id]) || (row.actionsDisabled && row.actionsDisabled?.includes(action.id)),
                                                        class: "action-item",
                                                        onClick: ($event) => (_ctx.$emit(action.eventName, row))
                                                    }, [
                                                        (!action?.newIcon)
                                                            ? (_openBlock(), _createBlock(_component_pe_icon, {
                                                                key: 0,
                                                                size: 22,
                                                                weight: 300,
                                                                class: _normalizeClass(row?.colorAction?.id === action?.id && (row?.colorAction?.color || ''))
                                                            }, {
                                                                default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(action.icon), 1)
                                                                ]),
                                                                _: 2
                                                            }, 1032, ["class"]))
                                                            : (_openBlock(), _createBlock(UiIcon, {
                                                                key: 1,
                                                                icon: action.icon,
                                                                class: _normalizeClass(["shrink-0", row?.colorAction?.id === action?.id && (row?.colorAction?.color || '')]),
                                                                size: "24px",
                                                                "stroke-width": "1"
                                                            }, null, 8, ["icon", "class"]))
                                                    ], 8, _hoisted_32));
                                                }), 128)),
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(defaultActions.value.filter(el => row.actions?.includes(el.id)), (action) => {
                                                    return (_openBlock(), _createElementBlock("button", {
                                                        key: action.id,
                                                        title: action.title,
                                                        class: "action-item",
                                                        onClick: ($event) => (_ctx.$emit(action.eventName, row))
                                                    }, [
                                                        _createVNode(_component_pe_icon, {
                                                            size: 22,
                                                            weight: 300
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(action.icon), 1)
                                                            ]),
                                                            _: 2
                                                        }, 1024)
                                                    ], 8, _hoisted_33));
                                                }), 128))
                                            ]))
                                            : _createCommentVNode("", true)
                                    ], 2));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("tbody", _hoisted_34, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pagination.limit, (_, index) => {
                                    return (_openBlock(), _createElementBlock("tr", {
                                        key: index,
                                        class: "pt-table--row"
                                    }, [
                                        (_ctx.selectable)
                                            ? (_openBlock(), _createElementBlock("td", _hoisted_35))
                                            : _createCommentVNode("", true),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleColumns.value, (col, keyCol) => {
                                            return (_openBlock(), _createElementBlock("td", {
                                                key: keyCol,
                                                width: col.width || ''
                                            }, [
                                                (['status', 'active', 'ativo'].includes(col.prop))
                                                    ? (_openBlock(), _createBlock(_component_pe_skeleton, {
                                                        key: 0,
                                                        animated: "",
                                                        width: "24px",
                                                        height: "24px"
                                                    }))
                                                    : (_openBlock(), _createBlock(_component_pe_skeleton, {
                                                        key: 1,
                                                        animated: "",
                                                        width: col.width ? `${col.width}px` : ''
                                                    }, null, 8, ["width"]))
                                            ], 8, _hoisted_36));
                                        }), 128)),
                                        _createElementVNode("td", _hoisted_37, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleActions.value, (action) => {
                                                return (_openBlock(), _createBlock(_component_pe_skeleton, {
                                                    key: action.id,
                                                    animated: "",
                                                    width: "24px",
                                                    height: "24px"
                                                }));
                                            }), 128))
                                        ])
                                    ]));
                                }), 128))
                            ]))
                    ])
                ]),
                (exibitRows.value?.length && !_ctx.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                        _createElementVNode("span", null, _toDisplayString(_unref(t)('labels.listPerPage')), 1),
                        _withDirectives(_createElementVNode("select", {
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((pagination.limit) = $event)),
                            class: "mx-6 border-0 dark:bg-neutral-800 dark:text-neutral-200"
                        }, _cache[10] || (_cache[10] = [
                            _createElementVNode("option", { value: 10 }, "10", -1),
                            _createElementVNode("option", { value: 20 }, "20", -1),
                            _createElementVNode("option", { value: 50 }, "50", -1)
                        ]), 512), [
                            [_vModelSelect, pagination.limit]
                        ]),
                        _createElementVNode("div", null, _toDisplayString(_unref(t)('labels.pagination', {
                            min: (pagination.current - 1) * pagination.limit + 1,
                            max: (pagination.current - 1) * pagination.limit + (exibitRows.value?.length || 0),
                            total: pagination.totalRows
                        })), 1),
                        (itemsSelected.value.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_39, _toDisplayString(_unref(t)('labels.selected')) + " - " + _toDisplayString(itemsSelected.value.length), 1))
                            : _createCommentVNode("", true),
                        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "flex-grow" }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_unref(t)('labels.currentPage', { current: pagination.current, total: pagination.totalPages })), 1),
                        _createElementVNode("button", {
                            class: "group mx-1 cursor-pointer border-0 bg-transparent disabled:opacity-50 dark:text-neutral-200",
                            disabled: pagination.current === 1,
                            onClick: _cache[5] || (_cache[5] = ($event) => (firtstPage()))
                        }, [
                            _createVNode(UiIcon, {
                                size: "23",
                                icon: 'chevrons-left',
                                class: _normalizeClass(["transition-transform", { 'group-hover:-translate-x-1': pagination.current > 1 }])
                            }, null, 8, ["class"])
                        ], 8, _hoisted_40),
                        _createElementVNode("button", {
                            class: "group mx-1 cursor-pointer border-0 bg-transparent disabled:opacity-50 dark:text-neutral-200",
                            disabled: pagination.current === 1,
                            onClick: _cache[6] || (_cache[6] = ($event) => (previousPage()))
                        }, [
                            _createVNode(UiIcon, {
                                size: "20",
                                icon: 'chevron-left',
                                class: _normalizeClass(["transition-transform", { 'group-hover:-translate-x-1': pagination.current > 1 }])
                            }, null, 8, ["class"])
                        ], 8, _hoisted_41),
                        _createElementVNode("button", {
                            class: "group mx-1 cursor-pointer border-0 bg-transparent disabled:opacity-50 dark:text-neutral-200",
                            disabled: pagination.current >= pagination.totalPages,
                            onClick: _cache[7] || (_cache[7] = ($event) => (nextPage()))
                        }, [
                            _createVNode(UiIcon, {
                                size: "20",
                                icon: 'chevron-right',
                                class: _normalizeClass(["transition-transform", { 'group-hover:translate-x-1': pagination.current < pagination.totalPages }])
                            }, null, 8, ["class"])
                        ], 8, _hoisted_42),
                        _createElementVNode("button", {
                            class: "group mx-1 cursor-pointer border-0 bg-transparent disabled:opacity-50 dark:text-neutral-200",
                            disabled: pagination.current >= pagination.totalPages,
                            onClick: _cache[8] || (_cache[8] = ($event) => (lastPage()))
                        }, [
                            _createVNode(UiIcon, {
                                size: "23",
                                icon: 'chevrons-right',
                                class: _normalizeClass(["transition-transform", { 'group-hover:translate-x-1': pagination.current < pagination.totalPages }])
                            }, null, 8, ["class"])
                        ], 8, _hoisted_43),
                        _createTextVNode(" " + _toDisplayString(pagination.current) + " - " + _toDisplayString(pagination.totalPages), 1)
                    ]))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
