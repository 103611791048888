import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, Transition as _Transition, createVNode as _createVNode } from "vue";
const _hoisted_1 = ["innerHTML"];
import { nextTick, ref } from 'vue';
import { useFloating, shift, offset, autoUpdate, flip } from '@floating-ui/vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeTooltip',
    props: {
        text: {},
        icon: { type: Boolean },
        delay: {},
        width: {},
        position: {}
    },
    setup(__props) {
        const props = __props;
        const hover = ref(false);
        let tm = null;
        const floatingEl = ref();
        const el = ref();
        const { floatingStyles } = useFloating(el, floatingEl, {
            placement: props.position || 'top',
            middleware: [offset(10), flip(), shift()],
            whileElementsMounted: autoUpdate,
        });
        const mouseOver = () => {
            if (tm)
                return;
            tm = setTimeout(() => {
                hover.value = true;
            }, props.delay || 400);
        };
        const mouseLeave = () => {
            hover.value = false;
            clearTimeout(tm);
            nextTick(() => (tm = null));
        };
        return (_ctx, _cache) => {
            const _component_pe_icon = _resolveComponent("pe-icon");
            return (_openBlock(), _createElementBlock("div", {
                ref_key: "el",
                ref: el,
                class: "pe-tooltip group relative",
                onMouseenter: _cache[0] || (_cache[0] = ($event) => (mouseOver())),
                onMouseleave: _cache[1] || (_cache[1] = ($event) => (mouseLeave()))
            }, [
                (!_ctx.icon)
                    ? _renderSlot(_ctx.$slots, "default", { key: 0 })
                    : (_openBlock(), _createBlock(_component_pe_icon, { key: 1 }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode("info")
                        ])),
                        _: 1
                    })),
                _createVNode(_Transition, {
                    "enter-active-class": "ease-out duration-150",
                    "enter-from-class": "opacity-0 -translate-y-4",
                    "leave-active-class": "ease-in duration-150",
                    "leave-to-class": "opacity-0 -translate-y-4"
                }, {
                    default: _withCtx(() => [
                        (_ctx.text && hover.value)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                ref_key: "floatingEl",
                                ref: floatingEl,
                                class: "pe-tooltip--box top-0 group-hover:opacity-100",
                                style: _normalizeStyle({ ..._unref(floatingStyles), width: props?.width }),
                                innerHTML: _ctx.text
                            }, null, 12, _hoisted_1))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                })
            ], 544));
        };
    }
});
