import baseApi from './base.api';
import { toast } from '@/common/util';
const api = {
    ...baseApi,
    resource: 'Secao',
    getEnviroment(str, reverse) {
        if (!str)
            return '';
        const envs = [
            ['learning', 'collection', 'favorites'],
            ['aprendizado', 'colecao', 'favoritos']
        ];
        const index = envs[reverse ? 1 : 0].indexOf(str.toLocaleLowerCase());
        return (index > -1) ? envs[reverse ? 0 : 1][index] : str;
    },
    prepareParams(param) {
        const fPar = {
            tipoAmbiente: this.getEnviroment(param.environment),
            tiposConteudos: param.contents,
            categoriasIds: param.categories,
            competenciasIds: param.skills,
            secoesIds: param.sections,
            obrigatorio: param.required,
            search: param.search,
            status: param.status,
            origem: param.origin,
            page: param.page,
            size: param.size
        };
        Object.keys(fPar).forEach(key => {
            if (typeof fPar[key] !== 'boolean' && !fPar[key])
                delete fPar[key];
        });
        return fPar;
    },
    async getAtivos(params) {
        try {
            return api.get('ativas', { ...params });
        }
        catch (error) {
            // const { notifications }: { notifications: string[] } = error.response?.data || {}
            // toast.error({message: notifications[0] || 'Não foi possível recuperar as seções'})
        }
    },
    async fileUpload(id, file) {
        try {
            const formData = new FormData();
            formData.append('arquivo', file);
            formData.append('secaoId', id);
            return await api.post(`${id}/fileUpload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar realizar upload do arquivo!' });
        }
    },
    async sendOrderSections(data) {
        return await api.post(`Ordenar`, data);
    },
    async getListBasic(params) {
        const filter = { ExibirEm: null };
        if (params?.type) {
            if (params.type === 'files')
                filter.ExibirEm = 'Midia';
            if (params.type === 'courses')
                filter.ExibirEm = 'Curso';
            if (params.type === 'trails')
                filter.ExibirEm = 'Formacao';
            if (params.type === 'assessments')
                filter.ExibirEm = 'Avaliacao';
            delete params.type;
        }
        try {
            return await api.get(`Basic`, { ...params, ...filter });
        }
        catch (error) {
            // const { notifications }: { notifications: string[] } = error.response?.data || {}
            // toast.error({message: notifications[0] || 'Erro ao buscar lista de seções!'})
        }
    },
    async findActive(params, options) {
        return api
            .get('ActivesPaged', this.prepareParams(params), options)
            .then(res => ({
            success: true,
            page: res?.pageNumber,
            size: res?.pageSize,
            items: res?.totalItems,
            totalPages: res?.totalPages,
            data: res.data?.filter(sec => sec.active).map(sec => {
                const tp = {
                    comunicacao: 'communication',
                    conteudo: 'content'
                }[sec.tipo?.toLocaleLowerCase()] || null;
                const result = {
                    id: sec.id,
                    name: sec.nome,
                    observation: sec.observacao,
                    type: tp,
                    environment: this.getEnviroment(sec.tipoAmbiente, true),
                };
                if (tp === 'communication') {
                    result.link = sec.link;
                    result.image = sec.urlDaImagem;
                    result.newTabRedirectLink = sec.linkDeRedirecionamentoNewTab;
                }
                return result;
            })
        }))
            .catch(() => {
            return {
                success: false,
                page: 0,
                size: 0,
                items: 0,
                totalPages: 0,
                data: []
            };
        });
    },
    findSectionItems(idSection, params, options) {
        const dataParams = this.prepareParams(params);
        if (dataParams?.tipoAmbiente)
            delete dataParams.tipoAmbiente;
        Object.keys(dataParams).forEach(key => {
            if (typeof dataParams[key] !== 'boolean' && !dataParams[key])
                delete dataParams[key];
        });
        // Temos 3 urls distintas... que tem o mesmo tipo de retorno...
        let eUrl = `${idSection}/ActivesItemsPaged`;
        if (idSection === 'continue')
            eUrl = 'KeepLearningPaged';
        else if (idSection === 'suggested')
            eUrl = 'SuggestedForYouPaged';
        return api
            .get(eUrl, dataParams, options)
            .then(res => {
            return {
                success: true,
                page: res?.pageNumber,
                size: res?.pageSize,
                items: res?.totalItems,
                nPages: res?.totalPages,
                data: res.data?.map(item => item)
            };
        })
            .catch(() => {
            return {
                success: false,
                page: 0,
                size: 0,
                items: 0,
                nPages: 0,
                data: []
            };
        });
    },
    async getActivesItemsPaged(idSection, filters) {
        let eUrl = `${idSection}/ActivesItemsPaged`;
        if (idSection === 'continue')
            eUrl = 'KeepLearningPaged';
        else if (idSection === 'suggested')
            eUrl = 'SuggestedForYouPaged';
        try {
            return await api.get(eUrl, this.prepareParams(filters));
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao buscar items da seção!' });
        }
    },
    async getCountActivesItems(params, options) {
        try {
            return await api.get(`CountActivesItems`, { ...params }, options);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar carregar quantidade de itens!' });
        }
    },
    async associationContracts(sectionId, contracstIds) {
        try {
            return await api.put(`${sectionId}/UpdateContratosSecoes`, contracstIds);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar vincular contratos a seção!' });
        }
    },
    async getSections(opt, options) {
        const paramsDictionary = {
            search: 'search',
            //
            id: 'id',
            name: 'nome',
            active: 'ativo',
            required: 'obrigatorio',
            status: 'status',
            origin: 'origem',
            //
            types: 'tipo',
            typeContents: 'tiposConteudos',
            typeEnvironment: 'tipoAmbiente',
            //
            courseIds: 'cursoId',
            sectionsIds: 'secoesIds',
            competenceIds: 'competenciasIds',
            categoryIds: 'categoriasIds',
            //
            page: 'page',
            size: 'size'
        };
        const typeContentsDictionary = {
            trail: "formacao",
            course: "curso",
            video: "video",
            podcast: "podcast",
            image: "imagem",
            document: "documento",
            pdf: "pdf",
            scorm: "cursointerativo",
            link: "link"
        };
        const paramsToSend = {};
        if (opt) {
            Object.keys(opt).forEach((key) => {
                if (typeof paramsDictionary[key] !== 'undefined' && typeof opt[key] !== 'undefined' && opt[key] !== null)
                    paramsToSend[paramsDictionary[key]] = opt[key];
                if (key === 'typeContents' && typeof paramsToSend[paramsDictionary[key]] === 'object') {
                    paramsToSend[paramsDictionary[key]] = Object.values(opt[key]).map((item) => typeContentsDictionary[item] || item);
                }
            });
        }
        return api.get('', paramsToSend, options)
            .then((res) => {
            let data = res.data?.map((item) => ({
                id: item.id || null,
                name: item.nome,
                observation: item.observacao,
                type: item.tipo,
                typeEnvironment: item.tipoAmbiente,
                redirectionLink: item.linkDeRedirecionamento,
                imageLink: item.linkDaImagem,
                initialPublicationDate: item.dtPublicacaoInicial,
                origin: item.origem,
                finalPublicationDate: item.dtPublicacaoFinal,
                createdAt: item.createdAt,
                order: item.ordem,
                roleIds: item.cargosIds,
                segmentationIds: item.segmentacoesIds,
                businessUnitIds: item.unidadesDeNegociosIds,
                departmentIds: item.departamentosIds,
                sectionItems: item.itensDaSecao,
                ativo: item.ativo,
                active: item.active,
                public: item.publico,
                contractIds: item.contratosIds,
                secaoOrigemId: item.secaoOrigemId,
            })) || [];
            if (opt.public !== undefined && opt.public !== null)
                data = data.filter((item) => item.public === opt.public);
            return {
                status: res.success,
                page: res.pageNumber,
                size: res.pageSize,
                total: res.totalItems,
                pages: res.totalPages,
                data: data
            };
        });
    },
    async getFavorites() {
        return api.get(`Favoritos`)
            .then((res) => {
            return {
                success: res.success,
                page: res.pageNumber,
                size: res.pageSize,
                totalItems: res.totalItems,
                pages: res.totalPages,
                data: res.data?.map((item) => ({
                    id: item.id || null,
                    tipo: item.tipo,
                    tipoMidia: item.tipoMidia,
                    titulo: item.titulo,
                    sinopse: item.sinopse,
                    descricao: item.descricao,
                    likes: item.likes,
                    liked: item.liked,
                    favorito: item.favorito,
                    inscrito: item.inscrito,
                    porcentagemDeConclusao: item.porcentagemDeConclusao,
                    urlDoRecurso: item.urlDoRecurso,
                    urlDaImagem: item.urlDaImagem,
                    competenciasIds: item.competenciasIds,
                    expirado: item.expirado,
                    scormScriptLink: item.scormScriptLink,
                    obrigatorio: item.obrigatorio,
                    categoriaId: item.categoriaId,
                    origem: item.origem,
                    dataExpirada: item.dataExpirada,
                    secaoNome: item.secaoNome,
                }))
            };
        }).catch(() => {
            return {
                success: false,
                page: 0,
                size: 0,
                totalItems: 0,
                pages: 0,
                data: []
            };
        });
    },
};
export default api;
