import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "grow flex flex-col md:flex-row w-full" };
const _hoisted_2 = { class: "flex items-center gap-3 text-base font-semibold text-black" };
const _hoisted_3 = ["src"];
import { computed } from 'vue';
import CountTo from '@/views/gestao/dashboard/components/counter.vue';
import UiGameAvatar from '@/components/ui/gamification/avatar.vue';
import ImgGamePoints from '@/assets/game/icon-points.svg';
export default /*@__PURE__*/ _defineComponent({
    __name: 'rank',
    props: {
        rank: {},
        name: {},
        role: {},
        img: {},
        score: {},
        compact: { type: Boolean }
    },
    setup(__props) {
        const props = __props;
        // eslint-disable-next-line no-unused-vars
        const rankClass = computed(() => {
            if (props.rank === 1)
                return 'text-yellow-700 from-yellow-200 via-yellow-50 to-yellow-300 border-yellow-600';
            if (props.rank === 2)
                return 'text-gray-700 from-gray-300 via-gray-100 to-gray-400 border-gray-600';
            if (props.rank === 3)
                return 'text-[#9f5c21] from-[#fdc980] via-[#fff0dc] to-[#e49862] border-[#c16d41]';
            return 'text-gray-700 from-neutral-50 via-white to-neutral-200 border-neutral-400';
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["user-rank", { 'user-rank-small': _ctx.compact }])
            }, [
                _createVNode(UiGameAvatar, {
                    rank: _ctx.rank,
                    img: _ctx.img,
                    compact: _ctx.compact,
                    alt: _ctx.name
                }, null, 8, ["rank", "img", "compact", "alt"]),
                _createElementVNode("div", {
                    class: _normalizeClass(["border-2 border-solid w-full rounded-2xl bg-gradient-to-br from-50% to-[52%]", [!_ctx.compact ? 'p-4' : 'p-2', rankClass.value]])
                }, [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", {
                            class: _normalizeClass(["grow flex flex-col justify-center", !_ctx.compact ? 'gap-1' : ''])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(!_ctx.compact ? 'text-lg leading-4 font-semibold' : 'text-sm')
                            }, _toDisplayString(_ctx.name), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass(!_ctx.compact ? 'text-sm' : 'text-xs')
                            }, _toDisplayString(_ctx.role), 3)
                        ], 2),
                        _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("span", null, [
                                _createVNode(CountTo, {
                                    value: _ctx.score || 0
                                }, null, 8, ["value"]),
                                _cache[0] || (_cache[0] = _createTextVNode()),
                                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-sm font-normal" }, "pts", -1))
                            ]),
                            _createElementVNode("img", {
                                src: _unref(ImgGamePoints),
                                width: "24",
                                height: "24",
                                alt: "points"
                            }, null, 8, _hoisted_3)
                        ])
                    ])
                ], 2)
            ], 2));
        };
    }
});
