import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "hidden sm:block relative w-0 lg:w-1/2 overflow-hidden grow-0" };
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
const _hoisted_5 = { class: "p-4" };
const _hoisted_6 = ["src"];
const _hoisted_7 = { class: "shrink-0 w-full lg:w-1/2 relative box-border flex h-full flex-col items-center justify-between overflow-y-auto bg-[#FDFDFD] text-[#212121] dark:bg-base-100/95 dark:text-[#FDFDFD] lg:bg-[#FDFDFD]/100 dark:lg:bg-base-100" };
const _hoisted_8 = { class: "absolute flex flex-row-reverse gap-2 right-4 top-4" };
const _hoisted_9 = { class: "box-border w-[396px] max-w-full grow px-6 mt-8 lg:mt-10" };
const _hoisted_10 = {
    key: 0,
    class: "flex flex-wrap items-center gap-1 text-sm justify-center"
};
import { computed, ref } from 'vue';
import useSystemStore from '@/stores/systemStore';
import { arrRand } from '@/common/util';
import UiButton from '@/components/ui/button.vue';
import UiIcon from '@/components/ui/icon.vue';
import UiLangSelector from '@/components/ui/langSelector.vue';
// import LogoAliare from './components/logoAliare.vue'
import CookiesDialog from './components/cookies.vue';
import LoginCarousel from './components/carousel.vue';
// Exclusivos da Aliare
import bg1 from '@/assets/bg-login-1.png';
import bg2 from '@/assets/bg-login-2.png';
import bg3 from '@/assets/bg-login-3.png';
import bg3a from '@/assets/bg-login-3-alt.png';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    setup(__props) {
        _useCssVars(_ctx => ({
            "45461775": (bgImage.value)
        }));
        const systemStore = useSystemStore();
        const flip = ref(false);
        const isLeft = computed(() => flip.value || systemStore.clientSettings.settings.login.align === 'left');
        const contactSupport = () => {
            document.getElementsByClassName('md-chat-widget-btn-wrapper')[0]?.click();
        };
        const bgImage = computed(() => {
            if (systemStore.getConfig.login.backgrounds.length > 0)
                return arrRand(systemStore.getConfig.login.backgrounds)[0];
            return false;
        });
        const carousels = computed(() => systemStore.getConfig.login.carousels);
        return (_ctx, _cache) => {
            const _component_router_view = _resolveComponent("router-view");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["box-border w-screen h-screen flex", { 'flex-row-reverse': isLeft.value }])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    (!carousels.value?.length)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (bgImage.value)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    src: bgImage.value,
                                    alt: "",
                                    class: "h-full w-full object-cover object-left"
                                }, null, 8, _hoisted_2))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createElementVNode("img", {
                                        src: _unref(bg1),
                                        alt: "",
                                        class: _normalizeClass(["h-full w-full object-cover", isLeft.value ? 'object-left' : 'object-right'])
                                    }, null, 10, _hoisted_3),
                                    _createElementVNode("img", {
                                        src: _unref(bg2),
                                        alt: "",
                                        class: _normalizeClass(["absolute bottom-0 object-cover max-w-full max-h-screen", !isLeft.value ? 'scale-x-100 -left-10' : '-scale-x-100 -right-10'])
                                    }, null, 10, _hoisted_4),
                                    _createElementVNode("div", {
                                        class: _normalizeClass(["absolute right-0 top-0 h-full w-full flex items-start xl:items-center", !isLeft.value ? 'justify-end' : 'justify-start'])
                                    }, [
                                        _createElementVNode("div", _hoisted_5, [
                                            _createElementVNode("img", {
                                                src: !isLeft.value ? _unref(bg3) : _unref(bg3a),
                                                alt: "",
                                                class: "w-auto max-w-full max-h-[90vh]"
                                            }, null, 8, _hoisted_6)
                                        ])
                                    ], 2)
                                ], 64))
                        ], 64))
                        : (_openBlock(), _createBlock(LoginCarousel, {
                            key: 1,
                            carousels: carousels.value
                        }, null, 8, ["carousels"]))
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                        _createVNode(UiButton, {
                            type: "ghost",
                            class: "p-2 !rounded-full",
                            round: "",
                            onClick: _cache[0] || (_cache[0] = ($event) => (_unref(systemStore).toggleTheme()))
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_Transition, {
                                    mode: "out-in",
                                    "enter-active-class": "transition ease-out duration-150 transform",
                                    "enter-from-class": "opacity-50 rotate-90",
                                    "leave-active-class": "ease-in duration-200",
                                    "leave-to-class": "opacity-50 -rotate-90"
                                }, {
                                    default: _withCtx(() => [
                                        (_unref(systemStore).isDark)
                                            ? (_openBlock(), _createBlock(UiIcon, {
                                                key: 0,
                                                icon: "moon",
                                                size: 28
                                            }))
                                            : (_openBlock(), _createBlock(UiIcon, {
                                                key: 1,
                                                icon: "sun-high",
                                                size: 28
                                            }))
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        }),
                        _createElementVNode("div", null, [
                            _createVNode(UiButton, {
                                type: "ghost",
                                class: "w-11 h-11",
                                round: ""
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(UiLangSelector, { size: "lg" })
                                ]),
                                _: 1
                            })
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_router_view, null, {
                            default: _withCtx(({ Component }) => [
                                _createVNode(_Transition, {
                                    appear: "",
                                    mode: "out-in",
                                    "enter-active-class": "transition ease-out duration-150 transform",
                                    "enter-from-class": "opacity-0 translate-x-8",
                                    "leave-active-class": "ease-in duration-200",
                                    "leave-to-class": "opacity-0 -translate-x-8"
                                }, {
                                    default: _withCtx(() => [
                                        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                                    ]),
                                    _: 2
                                }, 1024)
                            ]),
                            _: 1
                        }),
                        (!_unref(systemStore).getConfig.hideSupport)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createTextVNode(_toDisplayString(_ctx.$t('login.helpNeeded')) + " ", 1),
                                _createElementVNode("a", {
                                    href: "#",
                                    class: "font-semibold",
                                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event) => (contactSupport()), ["prevent"]))
                                }, _toDisplayString(_ctx.$t('login.talkToUs')), 1)
                            ]))
                            : _createCommentVNode("", true)
                    ])
                ]),
                _createVNode(CookiesDialog)
            ], 2));
        };
    }
});
