import BaseService from './base.service';
class EmpresasService extends BaseService {
    constructor() {
        super({ resource: 'empresa' });
    }
    async getPeopleByIdentification(identification) {
        return await this.plantarApi.get(`${this.resource}/documentoidentificacao/${identification}`);
    }
}
const empresasService = new EmpresasService();
export default empresasService;
