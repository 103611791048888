import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "inline-flex w-10 shrink-0 items-center justify-center self-stretch bg-slate-500/80 text-center text-base font-normal text-white"
};
const _hoisted_2 = { class: "--bfb-content grow truncate" };
const _hoisted_3 = {
    key: 2,
    class: "--bfb-icon"
};
const _hoisted_4 = {
    key: 3,
    class: "inline-flex w-10 shrink-0 items-center justify-center self-stretch bg-slate-500/80 text-center text-base font-normal text-white"
};
import { dialog } from '@/common/util';
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeButtonFeedback',
    props: {
        modelValue: {},
        position: {},
        multiple: { type: Boolean },
        placeholder: {},
        data: {},
        propName: {},
        disabled: { type: Boolean },
        clearable: { type: Boolean },
        size: {}
    },
    emits: ['onRemoveSelected', 'update:modelValue'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const props = __props;
        const isLeft = computed(() => !props.position || props.position !== 'right');
        const label = computed(() => {
            if (props.data && props.modelValue) {
                const valueFound = props.data?.find((el) => el.id === props.modelValue);
                return props?.propName ? valueFound?.[props.propName] : valueFound?.nome;
            }
            return '';
        });
        const removeSelected = () => {
            if (props.disabled)
                return;
            dialog.open({
                title: 'Atenção!',
                message: `Deseja realmente remover o registro selecionado?`,
                complete: () => emit('update:modelValue', '')
            });
        };
        return (_ctx, _cache) => {
            const _component_pe_icon = _resolveComponent("pe-icon");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["--btn-feedback", { 'pointer-events-none': _ctx.disabled, '--bfb-sm': _ctx.size === 'small' }])
            }, [
                (_ctx.multiple && isLeft.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.modelValue?.length), 1))
                    : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "default", {}, () => [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("span", {
                            class: _normalizeClass({ 'italic text-neutral-500 dark:text-neutral-200': !label.value && _ctx.placeholder })
                        }, _toDisplayString(label.value || _ctx.placeholder || '‎'), 3)
                    ])
                ]),
                (_ctx.clearable && _ctx.modelValue)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "--bfb-icon",
                        onClick: _withModifiers(removeSelected, ["stop"])
                    }, [
                        _createVNode(_component_pe_icon, {
                            size: _ctx.size === 'small' ? 18 : 24,
                            class: "hover:text-red-500"
                        }, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                                _createTextVNode("delete")
                            ])),
                            _: 1
                        }, 8, ["size"])
                    ]))
                    : _createCommentVNode("", true),
                (!_ctx.disabled)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_pe_icon, {
                            size: _ctx.size === 'small' ? 18 : 24
                        }, {
                            default: _withCtx(() => _cache[1] || (_cache[1] = [
                                _createTextVNode("search")
                            ])),
                            _: 1
                        }, 8, ["size"])
                    ]))
                    : _createCommentVNode("", true),
                (_ctx.multiple && !isLeft.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.modelValue?.length), 1))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
