import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import { computed, provide, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
export default /*@__PURE__*/ _defineComponent({
    __name: 'menu',
    props: {
        modelValue: { type: Boolean },
        closeOnClickOutside: { type: Boolean, default: true },
        closeOnSelect: { type: Boolean, default: true }
    },
    emits: ['update:modelValue'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const props = __props;
        const menu = ref(null);
        onClickOutside(menu, () => {
            if (props.closeOnClickOutside)
                close();
        });
        const localStatus = ref(false);
        const isOpen = computed(() => props.modelValue ?? localStatus.value);
        const emit = __emit;
        const setOpen = (value) => {
            if (typeof props.modelValue === 'undefined')
                localStatus.value = value;
            else
                emit('update:modelValue', value);
        };
        const open = () => setOpen(true);
        const close = () => setOpen(false);
        const toggle = () => setOpen(!isOpen.value);
        provide('UiMenu', {
            closeOnSelect: props.closeOnSelect,
            close
        });
        __expose({ open, close, toggle });
        return (_ctx, _cache) => {
            return (isOpen.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    ref_key: "menu",
                    ref: menu,
                    class: "box-border flex w-56 flex-col gap-2 rounded-md bg-[#FDFDFD] p-2 text-base shadow dark:bg-[#2D2D2D]"
                }, [
                    _renderSlot(_ctx.$slots, "default")
                ], 512))
                : _createCommentVNode("", true);
        };
    }
});
