import { computed, reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import { useSessionStorage } from '@vueuse/core';
import { convertTreeRouterToArr } from '@/common/util';
import gruposDePermissaoService from '@/services/gruposDePermissao.service';
import { permissionGroupService } from '@/services/gruposDePermissao.service';
import webStorage from '@/common/webStorage';
import useUsuariosStore from './usuariosStore';
export const useGrupoDePermissoes = defineStore('grupoDePermissoes', () => {
    /**
     * States
     */
    const list = ref([]);
    const newList = useSessionStorage('permGroup', {
        status: 0,
        groups: [],
        users: [],
        lastUpdate: 0
    });
    const permissions = ref(webStorage.getItem('permissions') ?? []);
    const nStatus = reactive({
        getUserPermissions: 'initial',
        create: 'initial',
        update: 'initial',
        getAll: 'initial',
        getById: 'initial',
        delete: 'initial',
        setPermission: 'initial',
        disabledPermission: 'initial',
        deletePermission: 'initial',
    });
    const loading = computed(() => Object.values(nStatus).some(el => el === 'loading'));
    /**
     * Getters
     */
    const listFormatter = computed(() => list.value
        .map((grupo) => ({
        id: grupo.id,
        active: grupo.productProfile.active,
        nome: grupo.productProfile.description
    }))
        .filter((el) => el.active));
    const listPermissionsName = computed(() => permissions.value.flatMap(el => el.access.map(el => el.description.toLowerCase())));
    const usuarioStore = useUsuariosStore();
    /**
     * Actions
     */
    const create = async (dataObj) => {
        nStatus.create = 'loading';
        try {
            const { success } = await gruposDePermissaoService.create(dataObj);
            if (success) {
                getAll();
                clearOptimizedList();
            }
            nStatus.create = 'success';
            return success;
        }
        catch {
            nStatus.create = 'error';
        }
    };
    const update = async (dataObj, id) => {
        nStatus.update = 'loading';
        try {
            const { data, success } = await gruposDePermissaoService.update(id, dataObj);
            if (success && data) {
                getAll();
                clearOptimizedList();
            }
            nStatus.update = 'success';
            return success;
        }
        catch {
            nStatus.update = 'error';
        }
    };
    const getAll = async () => {
        nStatus.getAll = 'loading';
        try {
            const { data, success } = await gruposDePermissaoService.get();
            nStatus.getAll = 'success';
            // @TODO: tirar o map após ajuste no endpoit da aliare
            list.value = success
                ? data.data.map((el) => ({ ...el, productProfile: { ...el.productProfile, active: true } }))
                : [];
        }
        catch {
            nStatus.getAll = 'error';
        }
    };
    // Verifica se já existe um requisicao de getOptimizedAll em andamento
    let getOptmisedListPromise = null;
    /**
     * Retorna a lista de grupos de permissão de maneira otimizada (Com cache)
     * @param force ignora o cache e força uma nova requisição
     * @returns
     */
    const getOptimizedAll = (force) => {
        if (newList.value.status === 1 && getOptmisedListPromise)
            return;
        // Cancela a requisição se a última atualização foi a menos de 5 minutos
        if (!force && getOptmisedListPromise && newList.value.lastUpdate && Date.now() - new Date(newList.value.lastUpdate).getTime() < 300000)
            return;
        newList.value.status = 1;
        newList.value.groups.splice(0, newList.value.groups.length);
        newList.value.users.splice(0, newList.value.users.length);
        getOptmisedListPromise = gruposDePermissaoService.get()
            .then((response) => {
            const { data, success } = response;
            if (success) {
                const size = data.data?.length;
                const ids = [];
                for (let i = 0; i < size; i++) {
                    var gpInfo = data.data[i];
                    if (!ids.includes(gpInfo.id)) {
                        ids.push(gpInfo.id);
                        newList.value.groups.push({
                            id: gpInfo.id,
                            name: gpInfo?.productProfile?.description,
                            active: gpInfo?.productProfile?.active
                        });
                    }
                    const upSize = gpInfo.usersProfiles.length;
                    for (let j = 0; j < upSize; j++) {
                        const upInfo = gpInfo.usersProfiles[j];
                        if (!upInfo.active || upInfo.blocked || !upInfo.userId)
                            continue;
                        const nlUgroupIdx = newList.value.users.findIndex((el) => el.permissionId === upInfo.permissionId);
                        if (nlUgroupIdx >= 0) {
                            newList.value.users[nlUgroupIdx].users.push(upInfo.userId);
                        }
                        else { // Agrupa a cada grupo de permissão os usuários que possuem a permissão
                            newList.value.users.push({
                                permissionId: upInfo.permissionId,
                                users: [upInfo.userId]
                            });
                        }
                    }
                }
            }
            newList.value.lastUpdate = Date.now();
            newList.value.status = 2;
        })
            .catch(() => {
            newList.value.status = -1;
        })
            .finally(() => {
            Promise.resolve(getOptmisedListPromise);
        });
    };
    const clearOptimizedList = () => {
        Object.assign(newList.value, {
            status: 0,
            groups: [],
            users: [],
            lastUpdate: 0
        });
    };
    const getGroupByUser = (userId) => {
        const result = [];
        newList.value?.users.forEach((el) => {
            if (el.users.includes(userId)) {
                const group = newList.value.groups.find((el2) => el2.id === el.permissionId);
                if (group)
                    result.push(group);
            }
        });
        return result;
    };
    const remove = async (id) => {
        nStatus.delete = 'loading';
        try {
            const { success } = await gruposDePermissaoService.remove(id);
            if (success) {
                list.value.splice(list.value.findIndex((el) => el.id === id), 1);
                clearOptimizedList();
            }
            nStatus.delete = 'success';
            return success;
        }
        catch {
            nStatus.delete = 'error';
        }
    };
    const getById = async (id) => {
        nStatus.getById = 'loading';
        try {
            const { data } = await gruposDePermissaoService.get(id);
            nStatus.getById = 'success';
            return data;
        }
        catch {
            nStatus.getById = 'error';
        }
    };
    const getUserPermissions = async (userId) => {
        nStatus.getUserPermissions = 'loading';
        try {
            const { data } = await gruposDePermissaoService.getPermissionFromUser(userId);
            permissions.value = data;
            webStorage.setItem('permissions', permissions.value, true);
            nStatus.getUserPermissions = 'success';
            return data;
        }
        catch (error) {
            nStatus.getUserPermissions = 'error';
        }
    };
    const canUserAccess = (permission, parent) => {
        if (!permission)
            return true;
        try {
            if (parent)
                return listPermissionsName.value.includes(permission.toLowerCase()) && listPermissionsName.value.includes(parent.toLowerCase());
            return listPermissionsName.value.includes(permission.toLowerCase());
        }
        catch {
            //
        }
    };
    const getFirstRouteAccess = (router) => {
        const treeFuncs = router.options.routes.find((rt) => rt.name === 'main')?.children || [];
        const elements = convertTreeRouterToArr(treeFuncs, [], null);
        return elements.find((el) => listPermissionsName.value.includes(el.name));
    };
    const isPermissionsEmpty = () => permissions.value.length === 0;
    const setPermissions = () => (permissions.value = webStorage.getItem('permissions') ?? []);
    const setUsersPermission = async (idGrupoPermissao, usersId) => {
        nStatus.setPermission = 'loading';
        try {
            const success = await gruposDePermissaoService.sendBondPermissionUsers(idGrupoPermissao, usersId);
            if (success) {
                usuarioStore.pagedList.list
                    .filter((el) => usersId.includes(el.id))
                    .forEach((user) => {
                    Object.assign(user, { ...user, grupoPermissao: idGrupoPermissao, isMissingData: false });
                });
            }
            nStatus.setPermission = 'success';
        }
        catch (error) {
            nStatus.setPermission = 'error';
        }
    };
    const disablePermissionUsers = async (usersAndPermissionsId) => {
        nStatus.disabledPermission = 'loading';
        try {
            const result = await gruposDePermissaoService.sendDisablePermissionUsers(usersAndPermissionsId);
            nStatus.disabledPermission = 'success';
            return result;
        }
        catch {
            nStatus.disabledPermission = 'error';
        }
    };
    const deleteBulking = async (usersAndPermissionsId) => {
        nStatus.deletePermission = 'loading';
        try {
            const { data } = await permissionGroupService.deleteBulking(usersAndPermissionsId);
            nStatus.deletePermission = 'success';
            return data;
        }
        catch {
            nStatus.deletePermission = 'error';
        }
    };
    return {
        nStatus,
        list,
        optimizedList: newList,
        permissions,
        loading,
        // Getters
        listFormatter,
        listPermissionsName,
        // Actions
        create,
        update,
        getAll,
        getOptimizedAll,
        getById,
        getGroupByUser,
        remove,
        clearOptimizedList,
        getUserPermissions,
        canUserAccess,
        getFirstRouteAccess,
        isPermissionsEmpty,
        setPermissions,
        setUsersPermission,
        disablePermissionUsers,
        deleteBulking
    };
});
export default useGrupoDePermissoes;
