import { toast } from '@/common/util';
import BaseService from '@/services/base.service';
import baseApi from './base.api';
import { plantarApiV2 } from '@/config/appAPIs';
export const permissionGroupService = {
    ...baseApi,
    resource: 'grupoDePermissao',
    async deleteBulking(data) {
        return await plantarApiV2.delete(`${this.resource}/DeleteBulking`, { data });
    }
};
class GruposDePermissaoService extends BaseService {
    constructor() {
        super({ resource: 'grupoDePermissao' });
    }
    async getPermissionFromUser(userId) {
        if (!userId)
            throw new Error('Id do usuário não informado!');
        return this.plantarApi.get(`${this.resource}/getpermissionfromuser/${userId}`);
    }
    async sendBondPermissionUsers(permissionId, usersIds) {
        const oldBaseURL = this.plantarApi.defaults.baseURL;
        this.setApiVersion(2);
        const promise = this.plantarApi.post(`${this.resource}/AddBondPermissionUser`, {
            permissionId,
            usersIds
        });
        // Retona a api para versão anterior
        this.setApiVersion(Number(oldBaseURL.slice(-1)));
        return promise.then(({ data }) => {
            const success = data.successItems?.length || 0;
            const fail = data.failedItems?.length || 0;
            if (success > 0 && fail === 0) {
                toast.success({ message: success > 1 ? 'Permissão vinculada aos usuários com sucesso!' : 'Permissão vinculada ao usuário com sucesso!' });
                return { success: true, refresh: false };
            }
            if (fail >= 0 && success === 0) {
                toast.error({ message: fail > 1 ? 'Erro ao tentar vincular permissão aos usuários!' : 'Erro ao tentar vincular permissão ao usuário!' });
                return { success: false, refresh: false };
            }
            toast.open({
                type: 'warning',
                title: 'Atenção!',
                message: `Requisição finalizada, mas infelizmente alguns usuários não puderam ter a permissão vinculada!`
            });
            return { success: true, refresh: true };
        });
    }
    async sendDisablePermissionUsers(data) {
        const promises = data.map((el) => this.plantarApi.put(`${this.resource}/user/${el.userId}/permission/${el.permissionId}/disable`));
        return Promise.allSettled(promises)
            .then((response) => {
            if (response.every(el => el.status === 'fulfilled')) {
                // toast.success({ message: 'Permissões desvinculadas com sucesso!' })
                return { success: true, refresh: false };
            }
            if (response.every(el => el.status === 'rejected')) {
                toast.error({ message: 'Erro ao tentar desvincular permissão!' });
                return { success: false, refresh: false };
            }
            toast.open({
                type: 'warning',
                title: 'Atenção!',
                message: `Requisição finalizada, mas infelizmente alguns usuários não puderam ter a permissão desvinculada!`
            });
            return { success: true, refresh: true };
        });
    }
}
const gruposDePermissaoService = new GruposDePermissaoService();
export default gruposDePermissaoService;
