import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, Fragment as _Fragment, createTextVNode as _createTextVNode, Teleport as _Teleport } from "vue";
const _hoisted_1 = { class: "box-border flex items-center gap-3 md:gap-4 border-0 border-b border-solid border-neutral-200 bg-neutral-50 px-4 lg:px-9 dark:border-neutral-50/10 dark:bg-neutral-700" };
const _hoisted_2 = { class: "inline-block md:hidden" };
const _hoisted_3 = { class: "flex items-center gap-3" };
const _hoisted_4 = { class: "relative" };
const _hoisted_5 = ["title"];
const _hoisted_6 = ["title"];
const _hoisted_7 = ["src"];
const _hoisted_8 = {
    key: 1,
    class: "text-center w-full h-full flex items-center justify-center text-lg text-[--c-primary-900] bg-[--c-primary-400] dark:text-[--c-primary-300] dark:bg-[--c-primary-800]"
};
const _hoisted_9 = {
    key: 1,
    class: "hover:scale-105 transition-all -mt-1"
};
const _hoisted_10 = { class: "hidden md:flex h-10 flex-col justify-between" };
const _hoisted_11 = { class: "flex w-full cursor-pointer items-center justify-between whitespace-nowrap border-0 bg-transparent p-0 font-semibold leading-[17px] text-inherit" };
const _hoisted_12 = { class: "text-sm leading-[15px] text-neutral-500 dark:text-neutral-300" };
const _hoisted_13 = {
    key: 0,
    class: "tooltip tooltip-left",
    "data-tip": "Desabilitado no modo cliente"
};
const _hoisted_14 = {
    key: 1,
    class: "flex items-center justify-between"
};
const _hoisted_15 = {
    key: 0,
    class: "relative"
};
const _hoisted_16 = { class: "flex items-center justify-between" };
const _hoisted_17 = {
    key: 0,
    class: "tooltip tooltip-left",
    "data-tip": "Desabilitado no modo cliente"
};
const _hoisted_18 = { key: 0 };
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useLocalStorage, useStorage } from '@vueuse/core';
import autoAnimate from '@formkit/auto-animate';
import { useAuthStore, useSystemStore, useUserNotificationsStore, useGamificacaoStore, storeToRefs } from '@/stores';
import { getInitials } from '@/common/util';
import webStorage from '@/common/webStorage';
import UiIcon from '@/components/ui/icon.vue';
import UiGameAvatar from '@/components/ui/gamification/avatar.vue';
import UiButton from '@/components/ui/button.vue';
import UiLangSelector from '@/components/ui/langSelector.vue';
import UiMenu from '@/components/ui/menu.vue';
import UiMenuItem from '@/components/ui/menuItem.vue';
import UiSeparator from '@/components/ui/separator.vue';
// import UiPopover from '@/components/ui/popover.vue'
import GamificationTopbarItem from '@/components/gamification/topbarItem.vue';
import ModalProfile from '@/components/modais/profile/index.vue';
import TopbarSearch from './topbarSearch.vue';
import NotificationsBar from './notificationsBar.vue';
import VersionCheck from './versionCheck.vue';
import ModalAceiteTermoDeUso from '@/components/modais/usuario/modalAceiteTermoDeUso.vue';
import ViewCommunication from '@/components/modais/comunicacao/modalViewCommunication.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'topbar',
    setup(__props) {
        const persistentInfo = useLocalStorage('userInfo', {}, { mergeDefaults: true });
        const sidebar = useStorage('sideBar', {
            minimize: false,
            offScreen: false
        });
        const route = useRoute();
        const userMenu = ref();
        const systemStore = useSystemStore();
        const authStore = useAuthStore();
        const userNotifications = useUserNotificationsStore();
        const gamificationStore = useGamificacaoStore();
        const { userRank } = storeToRefs(gamificationStore);
        const notifyBar = ref();
        const hasAppBtn = ref(false);
        const openSearch = ref(false);
        const showUserMenu = ref(false);
        const refProfile = ref();
        const modalTermOfUse = ref();
        const communicationsRef = ref();
        const userBirthday = computed(() => {
            if (!authStore.persistentInfo.birthdate)
                return false;
            const bd = new Date(authStore.persistentInfo.birthdate);
            const today = new Date();
            return bd.getDate() === today.getDate() && bd.getMonth() === today.getMonth();
        });
        const toggleHelp = () => {
            document.getElementsByClassName('md-chat-widget-btn-wrapper')[0]?.click();
        };
        const openSolutions = () => {
            window?.AliareSolutions.open({ target: '#app-toolbar' });
        };
        onBeforeMount(() => {
            // Configura o ambiente do Aliare Solutions
            const solUri = (window?.configuration?.aliare?.solutions_url || '').trim();
            if (!['', '__Auth.SolutionsUrl__'].includes(solUri)) {
                hasAppBtn.value = true;
                const ASol = window?.AliareSolutions;
                ASol?.setEnviroment('uat')
                    .setBearer(authStore.accessToken)
                    .setTenant(authStore.getActiveTentant);
            }
        });
        onMounted(async () => {
            autoAnimate(userMenu.value);
            gamificationStore.getMyRank();
            if (!authStore.userLogged.email)
                await authStore.getUserInfo(authStore.persistentInfo.id);
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(UiButton, {
                        class: "h-10 w-10 bg-transparent hover:bg-neutral-500/20 !text-neutral-600 dark:!text-neutral-300 active:ring-0",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(() => _unref(sidebar).offScreen = !_unref(sidebar).offScreen, ["stop", "prevent"]))
                    }, {
                        default: _withCtx(() => [
                            _createVNode(UiIcon, {
                                icon: "menu-2",
                                size: 24
                            })
                        ]),
                        _: 1
                    })
                ]),
                _createVNode(TopbarSearch, {
                    modelValue: openSearch.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((openSearch).value = $event))
                }, null, 8, ["modelValue"]),
                _cache[18] || (_cache[18] = _createElementVNode("div", { id: "topbarExtra" }, null, -1)),
                _cache[19] || (_cache[19] = _createElementVNode("div", { class: "grow" }, null, -1)),
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", _hoisted_4, [
                        _createVNode(_Transition, {
                            mode: "out-in",
                            "enter-active-class": "transition ease-out duration-150 transform",
                            "enter-from-class": "opacity-0 -translate-y-4",
                            "leave-active-class": "ease-in duration-200",
                            "leave-to-class": "opacity-0 -translate-y-4"
                        }, {
                            default: _withCtx(() => [
                                (!_unref(route).meta?.focusMode)
                                    ? (_openBlock(), _createBlock(UiButton, {
                                        key: 0,
                                        type: "ghost",
                                        class: "h-10 w-10",
                                        title: _ctx.$t('labels.notification', 2),
                                        onClick: _cache[2] || (_cache[2] = () => notifyBar.value?.open())
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiIcon, {
                                                icon: "bell",
                                                size: 24
                                            }),
                                            (_unref(userNotifications).unreadCount > 0)
                                                ? (_openBlock(), _createElementBlock("div", {
                                                    key: 0,
                                                    title: `${_unref(userNotifications).unreadCount} ${_ctx.$t('tooltip.unreadNotifications')}`,
                                                    class: "absolute -top-1 -right-1 text-xs font-semibold px-1 min-w-[12px] min-h-[12px] text-white rounded-full bg-red-500"
                                                }, _toDisplayString(_unref(userNotifications).unreadCount), 9, _hoisted_5))
                                                : _createCommentVNode("", true)
                                        ]),
                                        _: 1
                                    }, 8, ["title"]))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 1
                        })
                    ]),
                    _createElementVNode("div", null, [
                        (!_unref(route).meta.classRoom)
                            ? (_openBlock(), _createBlock(GamificationTopbarItem, { key: 0 }))
                            : _createCommentVNode("", true),
                        _cache[12] || (_cache[12] = _createElementVNode("div", { id: "tp-game-button" }, null, -1))
                    ]),
                    (!_unref(systemStore).getConfig.hideSupport)
                        ? (_openBlock(), _createBlock(UiButton, {
                            key: 0,
                            type: "ghost",
                            class: "h-10 w-10",
                            title: _ctx.$t('placeholder.help'),
                            onClick: _cache[3] || (_cache[3] = () => toggleHelp())
                        }, {
                            default: _withCtx(() => [
                                _createVNode(UiIcon, {
                                    icon: "help-circle",
                                    size: 24
                                })
                            ]),
                            _: 1
                        }, 8, ["title"]))
                        : _createCommentVNode("", true),
                    _createVNode(VersionCheck)
                ]),
                _createElementVNode("div", {
                    ref_key: "userMenu",
                    ref: userMenu,
                    class: "relative flex gap-3 px-2"
                }, [
                    _createElementVNode("div", {
                        onClick: _cache[4] || (_cache[4] = ($event) => (showUserMenu.value = !showUserMenu.value)),
                        class: "relative flex gap-3 cursor-pointer"
                    }, [
                        (!_unref(userRank).data.rank)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "hover:scale-105 transition-all h-10 w-10 shrink-0 overflow-hidden rounded-full bg-neutral-200 dark:bg-neutral-600",
                                title: _unref(authStore).persistentInfo.name
                            }, [
                                (_unref(authStore).persistentInfo.avatar)
                                    ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        src: _unref(authStore).persistentInfo.avatar,
                                        class: "h-full w-full object-cover",
                                        alt: "avatar"
                                    }, null, 8, _hoisted_7))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_unref(getInitials)(_unref(authStore).persistentInfo.name) || ''), 1))
                            ], 8, _hoisted_6))
                            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createVNode(UiGameAvatar, {
                                    rank: _unref(userRank).data.rank,
                                    alt: _unref(authStore).persistentInfo?.name,
                                    role: _unref(authStore).persistentInfo?.role,
                                    img: _unref(authStore).persistentInfo?.avatar,
                                    compact: ""
                                }, null, 8, ["rank", "alt", "role", "img"])
                            ])),
                        _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("button", _hoisted_11, [
                                _createElementVNode("span", null, _toDisplayString(_unref(authStore).persistentInfo.name || ''), 1),
                                _createVNode(UiIcon, {
                                    icon: "chevron-down",
                                    size: 15,
                                    "stroke-width": "2"
                                })
                            ]),
                            _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(authStore).persistentInfo.role), 1)
                        ]),
                        (userBirthday.value)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                _cache[13] || (_cache[13] = _createElementVNode("div", { class: "animate-ping absolute bottom-0 left-0 bg-red-400 w-2 h-2 rounded-full" }, null, -1)),
                                _cache[14] || (_cache[14] = _createElementVNode("div", { class: "absolute bottom-0 left-0 bg-red-400 w-2 h-2 rounded-full" }, null, -1))
                            ], 64))
                            : _createCommentVNode("", true)
                    ]),
                    _createVNode(UiMenu, {
                        modelValue: showUserMenu.value,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event) => ((showUserMenu).value = $event)),
                        class: "absolute top-[60px] right-0 z-[10]"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(UiMenuItem, {
                                disabled: !!_unref(useAuthStore)().clientMode.tenant,
                                onClick: _cache[5] || (_cache[5] = () => refProfile.value?.open())
                            }, {
                                default: _withCtx(() => [
                                    (_unref(useAuthStore)().clientMode.tenant)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t('topbar.profile')), 1))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('topbar.profile')) + " ", 1),
                                            (userBirthday.value)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                                                    _createVNode(UiIcon, {
                                                        icon: "confetti",
                                                        size: 24
                                                    }),
                                                    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "animate-ping absolute top-0 right-0 bg-red-400 w-2 h-2 rounded-full" }, null, -1)),
                                                    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "absolute top-0 right-0 bg-red-400 w-2 h-2 rounded-full" }, null, -1))
                                                ]))
                                                : _createCommentVNode("", true)
                                        ]))
                                ]),
                                _: 1
                            }, 8, ["disabled"]),
                            _createVNode(UiSeparator),
                            _createVNode(UiMenuItem, {
                                "close-on-select": false,
                                onClick: _cache[6] || (_cache[6] = () => _unref(systemStore).toggleTheme())
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_16, [
                                        _createTextVNode(_toDisplayString(_ctx.$t('button.enable')) + " " + _toDisplayString(!_unref(systemStore).isDark ? _ctx.$t('button.lightMode') : _ctx.$t('button.darkMode')) + " ", 1),
                                        _createVNode(_Transition, {
                                            mode: "out-in",
                                            "enter-active-class": "transition ease-out duration-150 transform",
                                            "enter-from-class": "opacity-0 translate-y-4",
                                            "leave-active-class": "ease-in duration-200",
                                            "leave-to-class": "opacity-0 translate-y-4"
                                        }, {
                                            default: _withCtx(() => [
                                                (!_unref(systemStore).isDark)
                                                    ? (_openBlock(), _createBlock(UiIcon, {
                                                        key: 0,
                                                        size: 24,
                                                        icon: "sun-high"
                                                    }))
                                                    : (_openBlock(), _createBlock(UiIcon, {
                                                        key: 1,
                                                        size: 24,
                                                        icon: "moon"
                                                    }))
                                            ]),
                                            _: 1
                                        })
                                    ])
                                ]),
                                _: 1
                            }),
                            _createVNode(UiMenuItem, null, {
                                default: _withCtx(() => [
                                    _createVNode(UiLangSelector, {
                                        text: _ctx.$t('language.change')
                                    }, null, 8, ["text"])
                                ]),
                                _: 1
                            }),
                            _createVNode(UiMenuItem, {
                                onClick: _cache[7] || (_cache[7] = () => modalTermOfUse.value?.open()),
                                disabled: !_unref(authStore).userLogged.termoDeUsoId
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('topbar.termsAndConditions')), 1)
                                ]),
                                _: 1
                            }, 8, ["disabled"]),
                            _createVNode(UiMenuItem, {
                                onClick: _cache[8] || (_cache[8] = () => communicationsRef.value.open())
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('menu.communications')), 1)
                                ]),
                                _: 1
                            }),
                            (_unref(persistentInfo)?.multiplePartners)
                                ? (_openBlock(), _createBlock(UiMenuItem, {
                                    key: 0,
                                    to: "/partner",
                                    disabled: !!_unref(useAuthStore)().clientMode.tenant,
                                    onClick: _cache[9] || (_cache[9] = () => _unref(webStorage).removeItem('show-page-partner'))
                                }, {
                                    default: _withCtx(() => [
                                        (_unref(useAuthStore)().clientMode.tenant)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t('topbar.changeCompany')), 1))
                                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                _createTextVNode(_toDisplayString(_ctx.$t('topbar.changeCompany')), 1)
                                            ], 64))
                                    ]),
                                    _: 1
                                }, 8, ["disabled"]))
                                : _createCommentVNode("", true),
                            _createVNode(UiSeparator),
                            _createVNode(UiMenuItem, { to: "/logout" }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('topbar.logout')), 1)
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    }, 8, ["modelValue"])
                ], 512),
                (hasAppBtn.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createVNode(UiButton, {
                            type: "ghost",
                            class: "p-2",
                            onClick: _cache[11] || (_cache[11] = () => openSolutions())
                        }, {
                            default: _withCtx(() => [
                                _createVNode(UiIcon, {
                                    icon: "grid-dots",
                                    size: 24
                                })
                            ]),
                            _: 1
                        }),
                        (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
                            _cache[17] || (_cache[17] = _createElementVNode("div", { id: "app-toolbar" }, null, -1))
                        ]))
                    ]))
                    : _createCommentVNode("", true),
                _createVNode(NotificationsBar, {
                    ref_key: "notifyBar",
                    ref: notifyBar
                }, null, 512),
                _createVNode(ModalProfile, {
                    ref_key: "refProfile",
                    ref: refProfile
                }, null, 512),
                _createVNode(ModalAceiteTermoDeUso, {
                    preview: "",
                    ref_key: "modalTermOfUse",
                    ref: modalTermOfUse
                }, null, 512),
                _createVNode(ViewCommunication, {
                    showAllCommunication: "",
                    ref_key: "communicationsRef",
                    ref: communicationsRef
                }, null, 512)
            ]));
        };
    }
});
