import { useI18n } from 'vue-i18n';
import { clamp, toast } from '@/common/util';
import baseApi from './base.api';
import BaseService from './base.service';
export const mediaTypes = ['video', 'audio', 'image', 'scorm', 'document', 'link', 'pdf', 'course', 'trail'];
export const mediaType2Api = ['video', 'audio', 'imagem', 'scorm', 'documento', 'link', 'pdf', 'curso', 'formacao'];
export const allowedMedias = () => {
    return mediaTypes.map((type) => ({
        value: type,
        label: useI18n().t(`labels.${type}`)
    })).sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));
};
const normalizeType = (type, toApi) => {
    // From api to app
    if (!toApi)
        return mediaTypes[mediaType2Api.indexOf(type)] || 'other';
    return mediaType2Api[mediaTypes.indexOf(type)] || 'outros';
};
export const midiaService = {
    ...baseApi,
    resource: 'midia',
    search(term) {
        const normalize = (data) => ({
            id: data.id,
            title: data.titulo,
            description: data.descricao,
            synopsis: data.sinopse,
            thumbnail: data.urlDaImagem,
            type: normalizeType(data.tipo.toLowerCase())
        });
        return midiaService.get('quickSearch', { search: term })
            .then(({ success, data }) => ({
            success,
            data: {
                files: data.midias.map(normalize),
                courses: data.cursos.map(normalize),
                trails: data.formacoes.map(normalize)
            }
        }))
            .catch((error) => {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar buscar informações sobre o arquivo!' });
            return {
                success: false,
                data: {
                    files: [],
                    courses: [],
                    trails: []
                }
            };
        });
    },
    getFile(id, options) {
        return midiaService
            .get(id, options)
            .then(({ success, data }) => ({
            success,
            data: {
                id: data.id,
                name: data.nome,
                description: data.descricao,
                pullzone: data.pullzone,
                videoId: data.videoId,
                expirationDate: data.dataExpiracao,
                duration: data.duracao,
                fileType: normalizeType(data.tipoArquivo?.toLowerCase()),
                categoryId: data.categoriaId,
                categoryName: data.categoriaNome,
                competenciesIds: data.competenciasIds,
                sectionsIds: data.secoesIds,
                link: data.link,
                createdAt: data.createdAt,
                lastUpdatedAt: data.lastUpdatedAt,
                finished: data.finalizado,
                active: data.active,
                tenantId: data.tenantId,
                origin: data.origem,
                objectCode: data.codigoObjeto,
                cardImgUrl: data.linkDaImagemDeCapa,
                hlsPlaylistUrl: data.hlsPlaylistURL,
                scormScriptUrl: data.scormScriptLink,
                scormType: data.tipoScorm,
                downloadable: data.permitirDownload,
                disableFastForward: data.bloquearAvanco,
                subtitles: data.legendas?.map(sub => ({
                    id: sub.id,
                    name: sub.nomeLegenda,
                    srclang: sub.codigoLegenda,
                    url: sub.urlLegenda,
                    createdAt: sub?.createdAt || ''
                })),
                watermark: data.pdfMarcadAguaConfig?.includeWaterMarkInPDF,
                watermarkRules: {
                    username: data.pdfMarcadAguaConfig?.user.name,
                    email: data.pdfMarcadAguaConfig?.user.email,
                    company: data.pdfMarcadAguaConfig?.user.company,
                    unit: data.pdfMarcadAguaConfig?.user.businessUnit,
                    department: data.pdfMarcadAguaConfig?.user.department,
                    position: data.pdfMarcadAguaConfig?.user.role,
                    // dateTime: data.pdfMarcadAguaConfig?.user.dateTime,
                    ip: data.pdfMarcadAguaConfig?.user.ip
                }
            }
        }))
            .catch((error) => {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar buscar informações sobre o arquivo!' });
            return { success: false, data: null };
        });
    },
    async getFiles(opt, options) {
        opt = { ...opt };
        const paramsDictionary = {
            active: 'ativo',
            name: 'nomeCompleto',
            search: 'search',
            types: 'tiposArquivos',
            code: 'codigoObjeto',
            competencies: 'competenciasIds',
            categoryIds: 'categoriasIds',
            origin: 'origem',
            // page: 'page',
            // size: 'size'
        };
        const typeDictionary = {
            image: 'imagem',
            document: 'documento'
        };
        const orderDictionary = {
            name: 'Midia',
            categoryId: 'Categoria',
            active: 'Active',
            objectCode: 'CodigoObjeto',
            duration: 'Duracao',
            createdAt: 'createdAt'
        };
        const extraData = {};
        Object.keys(opt).forEach((key) => {
            if (typeof paramsDictionary[key] !== 'undefined' && typeof opt[key] !== 'undefined' && opt[key] !== null)
                extraData[paramsDictionary[key]] = opt[key];
            if (key === 'types' && typeof extraData[paramsDictionary[key]] === 'object') {
                extraData[paramsDictionary[key]] = Object.values(opt[key]).map((item) => typeDictionary[item] || item);
            }
        });
        // Ordenação
        if (opt.orderBy?.length) {
            extraData['Ordenacao'] = opt.orderBy.map((item) => `${orderDictionary[item.prop]}${item.order === 'DESC' ? 'Desc' : 'Asc'}`);
        }
        return midiaService
            .get('', extraData, options)
            .then((res) => ({
            status: res.success,
            page: res.pageNumber,
            size: res.pageSize,
            total: res.totalItems,
            pages: res.totalPages,
            data: res.data?.map((item) => ({
                id: item.id,
                name: item.nome,
                description: item.descricao,
                pullzone: item.pullzone,
                videoId: item.videoId,
                expirationDate: item.dataExpiracao,
                duration: item.duracao,
                fileType: normalizeType(item.tipoArquivo?.toLowerCase()),
                categoryId: item.categoriaId,
                categoryName: item.categoriaNome,
                competenciesIds: item.competenciasIds,
                sectionsIds: item.secoesIds,
                link: item.link,
                createdAt: item.createdAt,
                lastUpdatedAt: item.lastUpdatedAt,
                finished: item.finalizado,
                active: item.active,
                tenantId: item.tenantId,
                origin: item.origem,
                objectCode: item.codigoObjeto,
                coverImageUrl: item.linkDaImagemDeCapa,
                hlsPlaylistUrl: item.hlsPlaylistURL,
                scormScriptUrl: item.scormScriptLink,
                scormType: item.tipoScorm,
                allowDownload: item.permitirDownload,
                waterMarkRules: {
                    enabled: item?.pdfMarcadAguaConfig?.includeWaterMarkInPDF || false,
                    username: item?.pdfMarcadAguaConfig?.name || false,
                    email: item?.pdfMarcadAguaConfig?.email || false,
                    company: item?.pdfMarcadAguaConfig?.company || false,
                    unit: item?.pdfMarcadAguaConfig?.unit || item?.pdfMarcadAguaConfig?.businessUnit || false,
                    department: item?.pdfMarcadAguaConfig?.department || false,
                    position: item?.pdfMarcadAguaConfig?.position || item?.pdfMarcadAguaConfig?.role || false,
                    dateTime: item?.pdfMarcadAguaConfig?.dateTime || false,
                    ip: item?.pdfMarcadAguaConfig?.ip || false
                }
            }))
        }))
            .catch((error) => {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar buscar informações sobre os arquivos!' });
            return { status: false, data: [] };
        });
    },
    save(data) {
        const saveData = {
            nome: data.name,
            descricao: data.description,
            hlsPlaylistURL: data.hlsPlaylistUrl,
            pullzone: data.pullzone,
            videoId: data.videoId,
            dataExpiracao: data.expirationDate,
            duracao: data.duration,
            tipoArquivo: normalizeType(data.fileType, true),
            categoriaId: data.categoryId,
            competenciasIds: data.competenciesIds,
            secoesIds: data.sectionsIds,
            codigoObjeto: data.objectCode,
            link: data.link,
            finalizado: data.finished,
            active: data.active,
            linkDaImagemDeCapa: data.cardImgUrl,
            scormScriptLink: data.scormScriptUrl,
            tipoScorm: data.scormType,
            permitirDownload: data.downloadable,
            bloquearAvanco: data.disableFastForward,
            // Marca d'agua e download
            pdfMarcadAguaConfig: {
                includeWaterMarkInPDF: data.watermark,
                user: {
                    name: data.watermarkRules.username,
                    email: data.watermarkRules.email,
                    company: data.watermarkRules.company,
                    businessUnit: data.watermarkRules.unit,
                    department: data.watermarkRules.department,
                    role: data.watermarkRules.position,
                    waterMarkRegistrationDate: data.watermarkRules.dateTime,
                    ip: data.watermarkRules.ip
                }
            }
        };
        let req = null;
        if (data?.id) {
            req = midiaService.put(data.id, saveData);
        }
        else {
            req = midiaService.post('', saveData);
        }
        return req.catch((error) => {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar criar o arquivo!' });
            return { success: false, data: null };
        });
    },
    uploadSubtitle(data, progressCB) {
        const formData = {
            legenda: data.file,
            codigoLegenda: data.srclang,
            nomeLegenda: data.file.name
        };
        if (data.id) {
            formData['midiaId'] = data.sourceId;
        }
        const options = {
            onUploadProgress: (progressEvent) => {
                if (progressCB)
                    progressCB(progressEvent);
            }
        };
        let req = !data.id
            ? midiaService.post(`uploadLegenda/${data.sourceId}`, formData, options, true)
            : midiaService.put(`updateLegenda/${data.id}`, formData, options, true);
        return req
            .then((res) => res)
            .catch((error) => {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar enviar a legenda!' });
            return { success: false, data: null };
        });
    },
    removeSubtitle(id) {
        return midiaService.delete(`deleteLegenda/${id}`)
            .catch((error) => {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar remover a legenda!' });
            return { success: false, data: null };
        });
    },
    // File upload
    fileUpload(depotId, file, extraParams) {
        return midiaService.post(`big/${depotId}/fileUpload`, {
            arquivo: file,
            midiaId: depotId
        }, extraParams, true);
    },
    cardCoverUpload: (id, file, extraParams) => {
        return midiaService.post(`${id}/fileUpload`, {
            file,
            midiaId: id
        }, extraParams, true);
    },
    videoCoverUpload: (videoId, file, extraParams) => {
        return midiaService.post(`uploadThumbnail/${videoId}`, {
            file,
            name: file.name || null
        }, extraParams, true);
    },
    getFileInfo(id) {
        return midiaService
            .get(`getMidiaInfo/${id}`)
            .then(({ success, data: res }) => {
            let type = normalizeType(res?.tipoArquivo.toLowerCase());
            let source = res.link;
            if (type === 'video') {
                source = `//${res.pullzone}/${res.videoId}/playlist.m3u8`;
            }
            else if (type === 'scorm') {
                source = res.scormScriptLink;
            }
            return {
                success,
                data: {
                    id: res?.id,
                    name: res?.nome,
                    source,
                    videoId: res?.videoId,
                    pullzone: res?.pullzone,
                    type,
                    expiration: res?.dataExpiracao ? (new Date(res.dataExpiracao)) : null,
                    allowDownload: res.permitirDownload,
                    blockFastForward: res.bloquearAvanco,
                    watermark: res?.pdfMarcadAguaConfig?.includeWaterMarkInPDF,
                    watermarkRules: {
                        username: res?.pdfMarcadAguaConfig?.user.name,
                        email: res?.pdfMarcadAguaConfig?.user.email,
                        company: res?.pdfMarcadAguaConfig?.user.company,
                        unit: res?.pdfMarcadAguaConfig?.user.businessUnit,
                        department: res?.pdfMarcadAguaConfig?.user.department,
                        position: res?.pdfMarcadAguaConfig?.user.role,
                        // dateTime: data.pdfMarcadAguaConfig?.user.dateTime,
                        ip: res?.pdfMarcadAguaConfig?.user.ip
                    }
                }
            };
        });
    },
    getVideoInfo(videoId) {
        return midiaService
            .get(`GetVideoInfo/${videoId}`)
            .then(({ success, data }) => {
            return {
                success,
                data
            };
        });
    },
    setVideoInfo(midiaId, data) {
        return midiaService
            .post(`updateVideoInfo/${midiaId}`, data)
            .then(({ success, data }) => {
            return {
                success,
                data
            };
        });
    },
    async bookmark(midiaId) {
        return await midiaService.post([midiaId, 'bookmark'].join('/'));
    },
    async unbookmark(midiaId) {
        return await midiaService.post([midiaId, 'unbookmark'].join('/'));
    },
    async setReactionMedia(params) {
        return await midiaService.post(`SetReaction`, params);
    }
};
class MidiasService extends BaseService {
    constructor() {
        super({ resource: 'midia' });
    }
    async fileUploadCoverImage(id, file, params) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('midiaId', id);
        const { progressCB, controller } = params;
        const signal = controller ? { signal: controller.signal } : {};
        return await this.plantarApi.post(`${this.resource}/${id}/fileUpload`, formData, {
            ...signal,
            onUploadProgress: (progressEvent) => {
                if (progressCB)
                    progressCB(progressEvent);
            }
        });
    }
    fileUpload(id, file, params) {
        const formData = new FormData();
        formData.append('arquivo', file);
        formData.append('midiaId', id);
        const { progressCB, controller } = params;
        const signal = controller ? { signal: controller.signal } : {};
        return this.plantarApi.post(`${this.resource}/big/${id}/fileUpload`, formData, {
            ...signal,
            onUploadProgress: (progressEvent) => {
                if (progressCB)
                    progressCB(progressEvent);
            }
        });
    }
    async fileDownload(id, params) {
        const { progressCB, controller } = params;
        const signal = controller ? { signal: controller.signal } : {};
        return await this.plantarApi.get(`${this.resource}/${id}/downloadFile`, {
            responseType: 'blob',
            ...signal,
            timeout: 0,
            onDownloadProgress: (progressEvent) => {
                if (progressCB)
                    progressCB(progressEvent);
            }
        });
    }
    registerProgress(id, progress, { courseId, formationId, isClassroom }) {
        const postData = {
            midiaId: id,
            percentualProgresso: clamp(Number(progress), 0, 100),
            formacaoId: formationId,
            cursoId: !formationId ? courseId : null,
            isSalaDeAula: isClassroom ?? false
        };
        return this.plantarApi.post(`${this.resource}/progresso`, postData);
    }
    async getProgress(type, id) {
        return await this.plantarApi.get(`${this.resource}/progresso/${type}/${id}`);
    }
    async getBonds(midiaId) {
        return this.plantarApi.get(`${this.resource}/vinculos/${midiaId}`);
    }
    async getVideoInfo(videoId, params) {
        try {
            return await this.plantarApi.get(`${this.resource}/GetVideoInfo/${videoId}`, { ...params });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar buscar informações sobre o vídeo!' });
        }
    }
}
const midiasService = new MidiasService();
export default midiasService;
