import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = { class: "pe-card--body mx-4 lg:mx-6 flex-grow" };
const _hoisted_3 = {
    key: 0,
    class: "-mx-4 lg:-mx-6 border-0 border-t border-b-2 border-solid border-t-[#E1E2E4] dark:border-t-neutral-600 border-b-white dark:border-b-neutral-600 shadow-[0_0_2px_rgba(0,0,0,0.1)]"
};
const _hoisted_4 = {
    key: 1,
    class: "-mx-4 lg:-mx-6 border-0 border-t border-b-2 border-solid border-t-[#E1E2E4] dark:border-t-neutral-600 border-b-white dark:border-b-neutral-600 shadow-[0_0_2px_rgba(0,0,0,0.1)]"
};
import { computed, useSlots } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeCard',
    props: {
        header: {},
        footer: {},
        noPadding: { type: Boolean },
        noBackground: { type: Boolean }
    },
    setup(__props) {
        const props = __props;
        const slots = useSlots();
        const hasHeader = computed(() => !!slots.header || !!props.header);
        const hasFooter = computed(() => !!slots.footer || !!props.footer);
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["pe-card flex flex-col rounded-lg my-auto text-black dark:text-neutral-100", { 'bg-[#F5F6F8] dark:bg-neutral-800 shadow-md': !_ctx.noBackground }])
            }, [
                (hasHeader.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["pe-card--header", { 'px-6': !_ctx.noPadding }])
                    }, [
                        _renderSlot(_ctx.$slots, "header", {}, () => [
                            _createElementVNode("div", {
                                class: "my-0 py-4 text-xl",
                                innerHTML: _ctx.header
                            }, null, 8, _hoisted_1)
                        ])
                    ], 2))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_2, [
                    (hasHeader.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                        : _createCommentVNode("", true),
                    _renderSlot(_ctx.$slots, "default"),
                    (hasFooter.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                        : _createCommentVNode("", true)
                ]),
                (hasFooter.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(["pe-card--footer", { 'px-6': !_ctx.noPadding }])
                    }, [
                        _renderSlot(_ctx.$slots, "footer", {}, () => [
                            _createTextVNode(_toDisplayString(_ctx.footer), 1)
                        ])
                    ], 2))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
