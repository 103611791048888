import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, vShow as _vShow, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "absolute -left-20 -top-20" };
const _hoisted_2 = ["src"];
const _hoisted_3 = {
    controls: "",
    autoplay: ""
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
    key: 0,
    controls: "",
    autoplay: ""
};
const _hoisted_6 = ["src"];
import { ref, onMounted } from 'vue';
import { useReward } from 'vue-rewards';
import birthdayTheme from '@/assets/audio/happy-birthday-to-you-bossa-nova.mp3';
import blowerSfx from '@/assets/audio/blower.mp3';
import birthdayHat from '@/assets/birthdayhat.png';
// Confetti
export default /*@__PURE__*/ _defineComponent({
    __name: 'birthdayTheme',
    setup(__props) {
        const { reward: confettiReward } = useReward('avatar', 'confetti', {
            zIndex: 2000,
            lifetime: 600,
            elementCount: 100,
            startVelocity: 38,
            spread: 60,
            angle: 130,
            elementSize: 10
        });
        const blowWhistle = ref(false);
        onMounted(() => {
            setTimeout(() => {
                confettiReward();
                blowWhistle.value = true;
            }, 1000);
        });
        return (_ctx, _cache) => {
            const _directive_motion_roll_visible_top = _resolveDirective("motion-roll-visible-top");
            return (_openBlock(), _createElementBlock("div", null, [
                _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("img", {
                        ref: "hat",
                        src: _unref(birthdayHat),
                        alt: "",
                        class: "aspect-square w-36 -rotate-45"
                    }, null, 8, _hoisted_2)
                ])), [
                    [_directive_motion_roll_visible_top]
                ]),
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute bottom-0 right-0 w-full h-full flex items-center justify-center pointer-events-none" }, [
                    _createElementVNode("div", { id: "avatar" })
                ], -1)),
                _withDirectives(_createElementVNode("audio", _hoisted_3, [
                    _createElementVNode("source", {
                        src: _unref(birthdayTheme),
                        type: "audio/mpeg"
                    }, null, 8, _hoisted_4)
                ], 512), [
                    [_vShow, false]
                ]),
                (blowWhistle.value)
                    ? _withDirectives((_openBlock(), _createElementBlock("audio", _hoisted_5, [
                        _createElementVNode("source", {
                            src: _unref(blowerSfx),
                            type: "audio/mpeg"
                        }, null, 8, _hoisted_6)
                    ], 512)), [
                        [_vShow, false]
                    ])
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
