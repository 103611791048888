import { ref, computed } from 'vue';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import { notificationHubApi } from '@/config/appAPIs';
import { useAuthStore } from '@/stores';
const status = ref(0); // 0 = not started, 1 = loading, 2 = loaded, -1 = error, -2 = denied
const messages = ref([]);
const deviceToken = ref('');
// Remote device token (Server)
const remoteDT = ref();
// Aliare
const firebaseConfig = {
    apiKey: 'AIzaSyA7CpalKwxhfFSO6P3mXnhlZCHEYif0ILQ',
    authDomain: 'aliareservices-notificationhub.firebaseapp.com',
    projectId: 'aliareservices-notificationhub',
    storageBucket: 'aliareservices-notificationhub.appspot.com',
    messagingSenderId: '381812158938',
    appId: '1:381812158938:web:b78cf4823c24af3402d0e3'
};
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const messaging = getMessaging(firebaseApp);
onMessage(messaging, (payload) => {
    messages.value.push(payload);
});
const subscribed = computed(() => !!remoteDT.value?.id);
const subscribe = async (token) => {
    if (subscribed.value)
        return;
    const authStore = useAuthStore();
    try {
        const { data } = await notificationHubApi.post(`/DeviceToken`, {
            userId: authStore.userData.id,
            userName: authStore.userData.nomeCompleto,
            type: 'PUSH',
            device: 'Web Browser',
            product: 'plantar-educacao',
            token: token || deviceToken.value
        }).then(response => response.data);
        if (data?.id) {
            remoteDT.value = data;
        }
    }
    catch (error) {
        //
    }
};
const unsubscribe = async () => {
    if (!remoteDT.value?.id)
        return;
    try {
        await notificationHubApi.delete(`/DeviceToken/${remoteDT.value?.id}`);
        remoteDT.value = {
            id: '',
            userId: '',
            userName: '',
            product: '',
            device: '',
            token: ''
        };
    }
    catch (error) {
        // 
    }
};
const init = async () => {
    status.value = 1;
    // Invoca a api de notificaciones do navegador
    Notification.requestPermission()
        .then(permission => {
        if (permission === 'granted') {
            getToken(messaging, {
                vapidKey: 'BMQbypNB9WDSG9XzB28JpNhEjK2F4gscpc4GquVu1Rz-PuN5mit8l0uGOODERyCj-eCLOuIVNFDCe4lsA1QBLjI'
            }).then(async (token) => {
                deviceToken.value = token;
                // Verifica se o token já foi registrado
                const { data: dTdata } = await notificationHubApi.get(`/DeviceToken/`).then(response => response.data);
                status.value = 2;
                const remoteData = (dTdata || []).find(dt => dt.token === token) || {};
                if (remoteData?.id) {
                    remoteDT.value = remoteData;
                    return;
                }
                subscribe(token);
            }).catch(error => {
                status.value = -1;
                console.log('torken error', error.message);
            });
        }
    })
        .catch(error => {
        status.value = -2;
        console.log('permission error', error.message);
    });
};
export { subscribed, status, messages, deviceToken, messaging, analytics, init, subscribe, unsubscribe };
