import BaseService from './base.service';
import { toast } from '@/common/util';
import baseApi from './base.api';
export const formationService = {
    ...baseApi,
    resource: 'formacao',
    async bookmark(formationId) {
        return await formationService.post([formationId, 'bookmark'].join('/'));
    },
    async unbookmark(formationId) {
        return await formationService.post([formationId, 'unbookmark'].join('/'));
    },
    async setReactionFormation(params) {
        return await formationService.post(`SetReaction`, params);
    }
};
class FormacaoService extends BaseService {
    constructor() {
        super({ resource: 'formacao' });
    }
    /**
     * Inscreve o usuário em uma formação
     * @param formationId
     */
    subscribe(formationId) {
        return this.plantarApi.post([this.resource, formationId, 'Subscribe'].join('/'));
    }
    /**
     * Desinscreve o usuário em uma formação
     * @param formationId
     */
    unsubscribe(formationId) {
        return this.plantarApi.post([this.resource, formationId, 'UnSubscribe'].join('/'));
    }
    async fileUpload(id, file) {
        return await this.plantarApi.post(`${this.resource}/${id}/fileUpload`, {
            arquivo: file,
            formacaoId: id
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    async getBonds(formacaoId) {
        return this.plantarApi.get(`${this.resource}/vinculos/${formacaoId}`);
    }
    async getSubscriptions(formacaoId) {
        return this.plantarApi.get(`${this.resource}/inscricoes/${formacaoId}`);
    }
    async sendSubscriptions(data, inscrever) {
        return this.plantarApi.post(`${this.resource}/inscreverusuarios/${inscrever}`, data);
    }
    async getDetails(formationId) {
        try {
            return this.plantarApi.get(`${this.resource}/Detalhes/${formationId}`);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] });
        }
    }
}
const formacaoService = new FormacaoService();
export default formacaoService;
