import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue";
import { computed } from 'vue';
import { clamp } from '@/common/util';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeIcon',
    props: {
        type: {},
        fill: { type: Boolean },
        weight: {},
        grade: {},
        opticalSize: {},
        size: {},
        icon: {}
    },
    setup(__props) {
        _useCssVars(_ctx => ({
            "c34f68d8": (iconSize.value),
            "c35b3dd4": (iconFill.value),
            "7007154b": (iconWeight.value),
            "2c0bc6a4": (iconGrade.value),
            "c352d8ca": (iconOpsz.value)
        }));
        const props = __props;
        /**
         * Define o estilo do Material Symbol
         */
        const iconType = computed(() => ({
            outlined: 'material-symbols-outlined',
            rounded: 'material-symbols-rounded',
            sharp: 'material-symbols-sharp'
        }[props.type || 'outlined']));
        /**
         * Normaliza o "peso" (espessura do tracejado) do icone
         */
        const iconWeight = computed(() => ((parseInt(`${props.weight || 400}`) / 100) | 0) * 100);
        /**
         * Normaliza o preenchimento do icone
         */
        const iconFill = computed(() => (props.fill ? 1 : 0));
        /**
         * Normaliza o a ênfase do icone (valores permitidos: -25, 0 e 200, menos ênfase a mais ênfase)
         */
        const iconGrade = computed(() => ({ '-25': -25, '0': 0, '200': 200 }[`${props.grade}`] || 0));
        /**
         * Normaliza o a ênfase do icone (valores permitidos: 20 a 48 Dp)
         */
        const iconOpsz = computed(() => clamp(parseInt(`${props.opticalSize || 24}`), 20, 48));
        /**
         * Normaliza o tamanho da fonte (inherit caso omisso)
         */
        const iconSize = computed(() => {
            if (!props.size)
                return 'inherit';
            else
                return typeof props.size === 'number' ? `${props.size}px` : props.size;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("span", {
                class: _normalizeClass(["pe-icon", iconType.value])
            }, [
                (_ctx.icon)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.icon), 1)
                    ], 64))
                    : _renderSlot(_ctx.$slots, "default", { key: 1 })
            ], 2));
        };
    }
});
