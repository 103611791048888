import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { midiaService as depotService } from '@/services/midias.service';
export const useUploadStore = defineStore('upload', () => {
    const iteration = ref(0);
    const uploads = ref([]);
    const getUploads = computed(() => (relationId, type, hideComplete) => {
        return (uploads.value.filter((upload) => upload.relationId === relationId &&
            upload.type === type &&
            ((hideComplete && upload.status !== 'done') || !hideComplete)) || []);
    });
    /**
     * Adiciona um upload na lista
     * @param opt
     */
    const add = (opt) => {
        opt = {
            type: undefined,
            relationId: '',
            ...opt
        };
        if (!opt.type)
            throw new Error('Type is required!');
        if (!opt.file)
            throw new Error('File is required!');
        if (!opt.relationId)
            throw new Error('RelationId is required!');
        try {
            const controller = new AbortController();
            const currentIt = iteration.value++;
            const extraParams = {
                signal: controller.signal,
                onUploadProgress: (progressEvent) => {
                    // Update progress
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    const index = uploads.value.findIndex((upload) => upload.id === currentIt);
                    uploads.value[index].progress = progress;
                    uploads.value[index].status = 'uploading';
                    if (progress === 100) {
                        uploads.value[index].status = 'processing';
                    }
                }
            };
            let req = null;
            if (opt.type === 'depotFile')
                req = depotService.fileUpload(opt.relationId, opt.file, extraParams);
            if (opt.type === 'depotCardCover')
                req = depotService.cardCoverUpload(opt.relationId, opt.file, extraParams);
            if (opt.type === 'depotVideoCover')
                req = depotService.videoCoverUpload(opt.relationId, opt.file, extraParams);
            req.then((res) => {
                const index = uploads.value.findIndex((upload) => upload.id === currentIt);
                uploads.value[index].status = 'done';
                return res;
            });
            uploads.value.push({
                id: currentIt,
                type: opt.type,
                file: opt.file,
                relationId: opt.relationId,
                progress: 0,
                controller,
                status: 'pending'
            });
            return req;
        }
        catch (error) {
            // Erro
            console.log('Erro', error);
        }
    };
    const cancel = (id) => {
        const idx = uploads.value.findIndex((upload) => upload.id === id);
        if (idx >= 0 && uploads.value[idx].status === 'uploading') {
            uploads.value[idx].controller.abort();
            uploads.value[idx].status = 'canceled';
        }
        return idx;
    };
    /**
     * Remove um upload da lista
     * @param opt
     */
    const remove = (id) => {
        let idx = cancel(id); // Cancela se necessário
        if (idx === -1)
            idx = uploads.value.findIndex((upload) => upload.id === id);
        if (idx >= 0)
            uploads.value.splice(idx, 1);
        return idx;
    };
    /**
     * Remove todos os uploads com o status informado
     * @param status
     */
    const removeByStatus = (status) => {
        const uploadsToRemove = uploads.value.filter((upload) => upload.status === status);
        uploadsToRemove.forEach((upload) => remove(upload.id));
    };
    return {
        uploads,
        getUploads,
        add,
        cancel,
        remove,
        removeByStatus
    };
});
