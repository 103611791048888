import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, unref as _unref, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "n-item" };
const _hoisted_2 = { class: "grow" };
const _hoisted_3 = { class: "clamp-lines font-semibold mb-2" };
const _hoisted_4 = {
    key: 0,
    class: "w-full text-sm font-semibold text-red-500"
};
const _hoisted_5 = {
    key: 1,
    class: "w-full text-sm font-semibold text-neutral-600"
};
const _hoisted_6 = {
    key: 2,
    class: "w-full text-sm font-semibold text-neutral-600"
};
const _hoisted_7 = { class: "text-sm italic text-gray-500" };
const _hoisted_8 = {
    key: 3,
    class: "mt-1 flex items-center justify-start gap-2 text-blue-400"
};
const _hoisted_9 = {
    key: 4,
    class: "w-full text-sm font-semibold text-emerald-500"
};
const _hoisted_10 = ["title"];
import { useMidiaStore } from '@/stores';
import UiIcon from '@/components/ui/icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'uploadProgress',
    props: {
        item: {}
    },
    setup(__props) {
        const midiaStore = useMidiaStore();
        const getIconUpload = (type) => {
            if (type === 'application/pdf')
                return 'file-type-pdf';
            if (type.startsWith('image/'))
                return 'photo';
            if (type.startsWith('audio/'))
                return 'file-music';
            if (type.startsWith('video/'))
                return 'video';
            if (type.split('.')?.pop()?.toLowerCase() === 'zip')
                return 'file-type-zip';
            return 'cloud-upload';
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.item.status >= 0)
                    ? (_openBlock(), _createBlock(UiIcon, {
                        key: 0,
                        icon: getIconUpload(_ctx.item.fileType),
                        class: _normalizeClass(_ctx.item.status === 3 ? 'text-emerald-500' : 'text-neutral-800 dark:text-neutral-300'),
                        size: 30
                    }, null, 8, ["icon", "class"]))
                    : (_openBlock(), _createBlock(UiIcon, {
                        key: 1,
                        icon: "cloud-off",
                        size: 30,
                        class: "text-red-500"
                    })),
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.name), 1),
                    _createVNode(_Transition, {
                        mode: "out-in",
                        "enter-active-class": "transform transition-all duration-300",
                        "enter-from-class": "opacity-0 -translate-x-2",
                        "leave-active-class": "transform transition-all duration-300",
                        "leave-to-class": "opacity-0 translate-x-2"
                    }, {
                        default: _withCtx(() => [
                            (_ctx.item.status < 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.item.status === -2 ? 'Upload cancelado' : 'Upload interrompido'), 1))
                                : (_ctx.item.status === 0)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Iniciando... "))
                                    : (_ctx.item.status === 1)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                            _cache[1] || (_cache[1] = _createTextVNode(" Enviando... ")),
                                            _createElementVNode("div", _hoisted_7, [
                                                _createElementVNode("span", null, _toDisplayString(_ctx.item.loaded) + " - ", 1),
                                                _createTextVNode(" " + _toDisplayString(_ctx.item.total) + " ", 1),
                                                _createElementVNode("span", null, "(" + _toDisplayString(_ctx.item.progress) + "%)", 1)
                                            ])
                                        ]))
                                        : (_ctx.item.status === 2)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                                _createVNode(UiIcon, {
                                                    icon: "loader",
                                                    size: 14,
                                                    class: "animate-spin"
                                                }),
                                                _cache[2] || (_cache[2] = _createTextVNode(" Finalizando upload... "))
                                            ]))
                                            : (_ctx.item.status === 3)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Upload finalizado " + _toDisplayString(_ctx.item.total), 1))
                                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    })
                ]),
                _createElementVNode("div", {
                    class: "rounded-full w-6 h-6 flex items-center justify-center border border-solid border-neutral-300 text-neutral-400 transition-all hover:border-red-400 hover:text-red-400 cursor-pointer",
                    title: _ctx.item.status >= 0 && _ctx.item.status < 3 ? 'Cancelar Upload' : 'Limpar',
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.item.status >= 0 && _ctx.item.status < 3 ? _unref(midiaStore).cancelTransfer(_ctx.item.id) : _unref(midiaStore).removeTransfer(_ctx.item.id)))
                }, [
                    _createVNode(UiIcon, {
                        icon: "x",
                        weight: 200,
                        size: 16
                    })
                ], 8, _hoisted_10),
                _createElementVNode("div", {
                    class: _normalizeClass(["absolute bottom-0 left-0 h-0 w-full rounded-md bg-[#DDD] transition-all", { '!h-1': _ctx.item.status === 1 }])
                }, [
                    _createElementVNode("div", {
                        class: "text-sm h-full rounded-md bg-emerald-400 transition-all duration-1000",
                        style: _normalizeStyle({ width: `${_ctx.item.progress}%` })
                    }, null, 4)
                ], 2)
            ]));
        };
    }
});
