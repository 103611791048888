import Cookies from 'js-cookie';
export const cookies = {
    addCookie(key, value) {
        Cookies.set(key, value);
    },
    removeCookie(key) {
        Cookies.remove(key);
    },
    getCookie(key) {
        return Cookies.get(key);
    }
};
