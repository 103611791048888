import baseApi from './base.api';
export const vistraService = {
    ...baseApi,
    resource: 'PainelVistraBI',
    getBasicPanelAccess(asDashboard) {
        return baseApi
            .get([vistraService.resource, !asDashboard ? '' : 'Dashboard'].join('/'))
            .then(({ success, data }) => {
            return {
                success,
                data: (data?.map((item) => ({
                    uniqueId: item.id,
                    id: item.painelId,
                    name: item.painelNome,
                    public: item.publico,
                    contractsId: item.contratosIds,
                    params: item.parametros,
                    active: item.ativo
                })) || [])
            };
        })
            .catch((error) => {
            console.log('error', error);
            return {
                success: false,
                data: []
            };
        });
    },
    getBasicPanelUserAccess(userId) {
        return baseApi
            .get(`${this.resource}/getBasicPanelUserAccess`, { userId })
            .then(({ success, data }) => {
            return {
                success,
                data: (data?.map((item) => ({
                    uniqueId: item.id,
                    id: item.painelId,
                    name: item.painelNome,
                    public: item.publico,
                    contractsId: item.contratosIds,
                    active: item.ativo
                })) || [])
            };
        })
            .catch((error) => {
            console.log('error', error);
            return {
                success: false,
                data: []
            };
        });
    },
    getUsersFromPanelId(panelId) {
        return baseApi
            .get(`${this.resource}/getUsersFromPanelId`, { panelId })
            .then(({ success, data }) => {
            return {
                success,
                data: (data?.map(item => ({
                    id: item.id,
                    panelId: item.panelId,
                    userName: item.name,
                    email: item.email,
                    position: item.cargo,
                    unity: item.unidadeDeNegocio,
                    department: item.departamento,
                    hasAccess: item.hasAccess
                })) || [])
            };
        })
            .catch((error) => {
            console.log('error', error);
            return {
                success: false,
                data: []
            };
        });
    }
};
