import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createSlots as _createSlots, unref as _unref } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "box-border max-h-screen h-full flex items-center w-full overflow-auto py-10 px-4"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = { class: "py-4 text-right text-xl font-normal uppercase space-x-2" };
import { computed, ref, useSlots, watch, nextTick } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import { onKeyUp } from '@vueuse/core';
import UiButton from '@/components/ui/button.vue';
const __default__ = {
    name: 'PeModal',
    inheritAttrs: false
};
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        modelValue: {},
        header: {},
        footer: {},
        width: {},
        hideControls: { type: Boolean },
        disableConfirm: { type: Boolean },
        hideCancelButton: { type: Boolean },
        disableCancel: { type: Boolean },
        cancelButtonText: {},
        confirmButtonText: {},
        trapFocus: { type: Boolean },
        closeOnEsc: { type: Boolean },
        confirmOnEnter: { type: Boolean },
        noBackground: { type: Boolean },
        beforeClose: {},
        beforeComplete: {},
        loading: { type: Boolean },
        beforeOpen: { type: Function },
        beforeCancel: { type: Function },
        beforeConfirm: { type: Function }
    },
    emits: ['onConfirm', 'onCancel', 'update:modelValue'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const props = __props;
        const slots = useSlots();
        const emit = __emit;
        const modalWindow = ref();
        const isOpen = ref(false);
        const isLoading = computed(() => props.loading || false);
        const open = async () => {
            if (props.beforeOpen) {
                const result = await props.beforeOpen();
                if (result === false)
                    return;
            }
            isOpen.value = true;
            let ready = new Promise(resolve => {
                nextTick(() => {
                    resolve(true);
                });
            });
            return await ready;
        };
        const cancel = async () => {
            if (typeof props.beforeCancel !== 'undefined' && await props.beforeCancel() === false)
                return;
            isOpen.value = false;
            emit('update:modelValue', false);
        };
        // const confirm = async () => {
        //   if (typeof props.beforeConfirm !== 'undefined' && await props.beforeConfirm() === false) return
        //   isOpen.value = false
        // }
        watch(() => props.modelValue, (value) => {
            if (value)
                open();
            else
                cancel();
        });
        const hasHeader = computed(() => !!slots.header || !!props.header);
        const hasFooter = computed(() => {
            if (props.hideControls)
                return false;
            return true;
        });
        const onCancel = () => {
            if (typeof props.beforeClose !== 'undefined' && !props.beforeClose())
                return false;
            emit('onCancel');
            emit('update:modelValue', false);
        };
        const onConfirm = async () => {
            if (typeof props.beforeComplete !== 'undefined' && !(await props.beforeComplete()))
                return false;
            emit('onConfirm');
            emit('update:modelValue', false);
        };
        onKeyUp('Escape', () => {
            if (props.closeOnEsc)
                onCancel();
        });
        onKeyUp('Enter', () => props.confirmOnEnter && onConfirm());
        __expose({
            isLoading,
            isOpen,
            open,
            cancel,
            onCancel,
            onConfirm
        });
        return (_ctx, _cache) => {
            const _component_pe_card = _resolveComponent("pe-card");
            return (_openBlock(), _createBlock(_unref(VueFinalModal), {
                modelValue: isOpen.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((isOpen).value = $event)),
                "content-class": "flex justify-center items-center w-full h-full overflow-y-auto box-border bg-black/50",
                onClosed: _cache[4] || (_cache[4] = () => cancel())
            }, {
                default: _withCtx(() => [
                    (isOpen.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createVNode(_component_pe_card, {
                                ref_key: "modalWindow",
                                ref: modalWindow,
                                style: _normalizeStyle({ width: _ctx.width }),
                                class: "relative mx-auto max-w-full",
                                "no-background": _ctx.noBackground
                            }, _createSlots({
                                default: _withCtx(() => [
                                    _createElementVNode("button", {
                                        class: "absolute p-0 right-6 top-3 inline-flex h-8 w-8 cursor-pointer items-center justify-center border-0 bg-transparent text-neutral-400 transition-all hover:text-neutral-500",
                                        onClick: _cache[0] || (_cache[0] = () => cancel())
                                    }, _cache[5] || (_cache[5] = [
                                        _createElementVNode("svg", {
                                            class: "scale-150",
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "24",
                                            height: "24",
                                            viewBox: "0 0 24 24"
                                        }, [
                                            _createElementVNode("path", {
                                                fill: "currentColor",
                                                d: "m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z"
                                            })
                                        ], -1)
                                    ])),
                                    _renderSlot(_ctx.$slots, "default")
                                ]),
                                _: 2
                            }, [
                                (hasHeader.value)
                                    ? {
                                        name: "header",
                                        fn: _withCtx(() => [
                                            _renderSlot(_ctx.$slots, "header", {}, () => [
                                                _createElementVNode("div", {
                                                    class: "my-0 py-4 text-xl",
                                                    innerHTML: _ctx.header
                                                }, null, 8, _hoisted_2)
                                            ])
                                        ]),
                                        key: "0"
                                    }
                                    : undefined,
                                (hasFooter.value)
                                    ? {
                                        name: "footer",
                                        fn: _withCtx(() => [
                                            _renderSlot(_ctx.$slots, "footer", {
                                                cancel: cancel,
                                                confirm: onConfirm
                                            }, () => [
                                                _createElementVNode("div", _hoisted_3, [
                                                    (!_ctx.hideCancelButton)
                                                        ? (_openBlock(), _createBlock(UiButton, {
                                                            key: 0,
                                                            type: "danger",
                                                            outline: "",
                                                            class: "px-6 py-3 text-sm uppercase",
                                                            disabled: _ctx.disableCancel,
                                                            tabindex: "2",
                                                            onClick: _cache[1] || (_cache[1] = () => cancel())
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(_ctx.cancelButtonText || _ctx.$t('button.cancel')), 1)
                                                            ]),
                                                            _: 1
                                                        }, 8, ["disabled"]))
                                                        : _createCommentVNode("", true),
                                                    _createVNode(UiButton, {
                                                        id: "button-confirm",
                                                        class: "px-6 py-3 text-sm uppercase",
                                                        disabled: _ctx.disableConfirm,
                                                        onClick: _cache[2] || (_cache[2] = () => onConfirm()),
                                                        tabindex: "1",
                                                        type: "primary"
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.confirmButtonText || _ctx.$t('button.confirm')), 1)
                                                        ]),
                                                        _: 1
                                                    }, 8, ["disabled"])
                                                ])
                                            ])
                                        ]),
                                        key: "1"
                                    }
                                    : undefined
                            ]), 1032, ["style", "no-background"])
                        ]))
                        : _createCommentVNode("", true)
                ]),
                _: 3
            }, 8, ["modelValue"]));
        };
    }
});
