import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["disabled", "accept"];
const _hoisted_2 = { class: "flex h-full flex-col" };
const _hoisted_3 = { class: "flex flex-col items-center h-full justify-evenly text-neutral-500 dark:text-neutral-300 uppercase gap-4" };
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = { class: "text-center" };
const _hoisted_6 = {
    ref: "previewImg",
    class: "block h-40 w-full"
};
const _hoisted_7 = ["src", "alt"];
const _hoisted_8 = { class: "my-4 text-xl" };
import { nextTick, ref, watch } from 'vue';
import { useDropZone } from '@vueuse/core';
import { toast, validateFileSize } from '@/common/util';
import { useI18n } from 'vue-i18n';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeDragdrop',
    props: {
        disabled: { type: Boolean },
        allowMultiple: { type: Boolean },
        selectedFileLink: {},
        accept: {},
        validateType: {},
        maxFileSize: {},
        unitSize: {}
    },
    emits: ['onChange'],
    setup(__props, { emit: __emit }) {
        const { t } = useI18n();
        const props = __props;
        const emit = __emit;
        const fileinput = ref();
        const dopZone = ref();
        const imgData = ref('');
        const fileName = ref('');
        const hasFile = ref(false);
        watch(() => props.selectedFileLink, (link) => {
            if (link) {
                imgData.value = link;
                fileName.value = link.split('/').pop();
            }
            else {
                imgData.value = '';
                fileName.value = '';
            }
            nextTick(() => (hasFile.value = !!link));
        });
        const removeFile = () => {
            fileinput.value.value = null;
            onChange();
        };
        const changeFile = () => {
            fileinput.value.click();
        };
        const readFile = (file) => {
            if (file) {
                hasFile.value = true;
                fileName.value = file.name;
                if (file.type.startsWith('image/')) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        imgData.value = `${reader.result}`;
                    };
                }
                // @TODO: Da pra adicionar um else aqui pra colocar uma imagem de feedback caso seja: Documentos, arquivos (binarios), textos, musica, video, etc...
            }
            else {
                hasFile.value = false;
                fileName.value = '';
            }
        };
        const onChange = () => {
            const file = fileinput.value?.files[0] || null;
            if (file && props?.validateType &&
                !props.validateType.includes(file.type.split('/')[1]))
                return toast.error({ title: t('toasts.invalidFile'), message: t('toasts.acceptFiles', [props.validateType.join(', ')]) });
            if ((props.maxFileSize && props.unitSize) && file && !validateFileSize(file.size, props.maxFileSize, props.unitSize))
                return false;
            readFile(file);
            emit('onChange', fileinput.value?.files);
        };
        const onDrop = (files) => {
            if ((props.maxFileSize && props.unitSize) && files[0] && !validateFileSize(files[0].size, props.maxFileSize, props.unitSize))
                return false;
            readFile(files[0]);
            emit('onChange', files);
        };
        const { isOverDropZone } = useDropZone(dopZone, onDrop);
        return (_ctx, _cache) => {
            const _component_pe_button = _resolveComponent("pe-button");
            return (_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("input", {
                    ref_key: "fileinput",
                    ref: fileinput,
                    type: "file",
                    disabled: _ctx.disabled,
                    accept: _ctx.accept || '*.*',
                    class: "hidden",
                    onChange: _cache[0] || (_cache[0] = ($event) => (onChange()))
                }, null, 40, _hoisted_1),
                _withDirectives(_createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                        ref_key: "dopZone",
                        ref: dopZone,
                        class: _normalizeClass(["box-border h-full border-2 border-dashed border-neutral-400 py-4 px-8", {
                                'border-emerald-500': !_ctx.disabled && _unref(isOverDropZone),
                                'bg-emerald-100': !_ctx.disabled && _unref(isOverDropZone),
                                'bg-neutral-100': _ctx.disabled
                            }])
                    }, [
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("div", {
                                innerHTML: _ctx.$t('input.dragAndDrop')
                            }, null, 8, _hoisted_4),
                            _createElementVNode("div", null, [
                                _createVNode(_component_pe_button, {
                                    type: "primary",
                                    size: "large",
                                    disabled: _ctx.disabled,
                                    class: "uppercase",
                                    onClick: _cache[1] || (_cache[1] = ($event) => (fileinput.value.click()))
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(t)('labels.clickHere')), 1)
                                    ]),
                                    _: 1
                                }, 8, ["disabled"])
                            ])
                        ])
                    ], 2),
                    _renderSlot(_ctx.$slots, "extra")
                ], 512), [
                    [_vShow, !hasFile.value && !_ctx.selectedFileLink]
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_5, [
                    _renderSlot(_ctx.$slots, "hasFile", {
                        scope: { fileName: fileName.value, imgData: imgData.value, removeFile: removeFile, changeFile: changeFile }
                    }, () => [
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("img", {
                                src: imgData.value,
                                alt: fileName.value,
                                class: "h-full w-full object-contain"
                            }, null, 8, _hoisted_7)
                        ], 512),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(fileName.value), 1),
                        _createElementVNode("div", null, [
                            _createVNode(_component_pe_button, {
                                type: "danger",
                                size: "large",
                                onClick: _cache[2] || (_cache[2] = ($event) => (removeFile()))
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(t)('button.delete', { text: _unref(t)('labels.file').toLowerCase() })), 1)
                                ]),
                                _: 1
                            })
                        ])
                    ])
                ], 512), [
                    [_vShow, hasFile.value || !!_ctx.selectedFileLink]
                ])
            ]));
        };
    }
});
