import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = { class: "relative inline-block" };
import { ref, onMounted, computed } from 'vue';
import SparkleInstance from './spark.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    props: {
        color: {},
        path: {}
    },
    setup(__props) {
        const props = __props;
        let count = 0;
        const instances = ref([]);
        const customPath = computed(() => props.path != null);
        const multiplePaths = computed(() => Array.isArray(props.path));
        const customColor = computed(() => props.color != null);
        const multipleColors = computed(() => Array.isArray(props.color));
        const generateSparkle = () => {
            return {
                color: props.color,
                size: random(10, 30),
                style: {
                    top: random(0, 100) + '%',
                    left: random(0, 100) + '%',
                    zIndex: random(1, 3)
                }
            };
        };
        const random = (min, max) => {
            if (min === 0 && max === 1)
                return Math.round(Math.random());
            return Math.floor(Math.random() * (max - min)) + min;
        };
        const addSparkle = () => {
            let sparklePath = '';
            if (customPath.value && multiplePaths.value) {
                sparklePath = props.path[random(0, props.path.length - 1)];
            }
            else if (customPath.value) {
                sparklePath = props.path;
            }
            let sparkleColor = 'hsl(50deg, 100%, 50%)';
            if (customColor.value && multipleColors.value && Array.isArray(props.color)) {
                sparkleColor =
                    'hsl('
                        + random(parseInt(props.color[0].h), parseInt(props.color[1].h)) +
                        'deg, ' + random(parseInt(props.color[0].s), parseInt(props.color[1].s)) +
                        '%, ' + random(parseInt(props.color[0].l), parseInt(props.color[1].l))
                        + '%)';
            }
            else if (customColor.value) {
                sparkleColor = props.color;
            }
            const sparkle = generateSparkle();
            var instance = {
                id: count++,
                color: sparkleColor,
                size: sparkle.size,
                style: sparkle.style,
                path: sparklePath
            };
            instances.value.push(instance);
        };
        const tick = () => {
            addSparkle();
            window.setTimeout(tick, random(50, 200));
        };
        onMounted(() => {
            tick();
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(instances.value, (instance) => {
                    return (_openBlock(), _createBlock(SparkleInstance, {
                        key: instance.id,
                        color: instance.color,
                        size: instance.size,
                        appliedStyle: instance.style,
                        path: instance.path,
                        onRemove: () => instances.value.splice(instances.value.indexOf(instance), 1)
                    }, null, 8, ["color", "size", "appliedStyle", "path", "onRemove"]));
                }), 128)),
                _renderSlot(_ctx.$slots, "default")
            ]));
        };
    }
});
