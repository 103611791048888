import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "mt-2 text-red-600" };
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeErrorMsg',
    props: {
        message: {}
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.message), 1));
        };
    }
});
