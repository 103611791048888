import { defineStore } from 'pinia';
import { computed, ref, reactive, watch } from 'vue';
import { useAuthStore } from './authStore';
import desafiosService from '@/services/desafio.service';
import dsService from '@/services/desafios.service';
import { toast } from '@/common/util';
export const useGamificacaoStore = defineStore('gamificacao', () => {
    /**
    * States
    */
    const listRules = ref([]);
    const listChallenges = ref([]);
    const status = ref(0);
    const userStats = reactive({
        id: 1,
        points: 0,
        rank: 0,
        trophies: 0
    });
    const userRank = ref({
        status: 'initial',
        data: {
            score: 0,
            rank: 0,
            trophies: 0,
            img: ''
        }
    });
    //Utilizado com getMyRankV2
    const userRankV2 = ref({
        status: 'initial',
        data: {
            rankingGeral: {
                score: 0,
                rank: 0,
                trophies: 0,
                img: ''
            },
            rankingByUnidadeDeNegocio: {
                score: 0,
                rank: 0,
                trophies: 0,
                img: ''
            }
        }
    });
    const usersRanks = ref({
        status: 'initial',
        total: 0,
        page: {},
        pageStatus: {},
        lastUpdate: 0
    });
    //Utilizado com getRankV2
    const usersRanksV2 = ref({
        status: 'initial',
        total: 0,
        rankingGeralPage: {},
        rankingByUnidadeDeNegocioPage: {},
        rankingGeralPageStatus: {},
        rankingByUnidadeDeNegocioPageStatus: {},
        lastUpdate: 0
    });
    const rankList = computed(() => {
        return (page, size) => {
            // Não exibe nada enquanto estiver carregando
            if (usersRanks.value.pageStatus[page] === 'loading') {
                return {
                    items: [],
                    page: page || 0,
                    totalPages: Math.ceil(usersRanks.value.total / (size || 10)),
                    totalItems: usersRanks.value.total,
                    status: usersRanks.value.pageStatus[page] || 'initial'
                };
            }
            const list = usersRanks.value?.page[page || 0] || [];
            if (usersRanks.value.pageStatus[page] !== 'success' && !list.length) {
                // Carrega a página se necessário
                if (!usersRanks.value.pageStatus[page || 0] || usersRanks.value.pageStatus[page || 0] === 'error')
                    getRankV2({ page, size });
                return {
                    items: [],
                    page: page || 0,
                    totalPages: Math.ceil(usersRanks.value.total / (size || 10)),
                    totalItems: usersRanks.value.total,
                    status: usersRanks.value.pageStatus[page] || 'initial'
                };
            }
            return {
                items: list,
                page: page || 0,
                totalPages: Math.ceil(usersRanks.value.total / (size || 10)),
                totalItems: usersRanks.value.total,
                status: usersRanks.value.pageStatus[page] || 'initial'
            };
        };
    });
    //Utilizado com getRankV2 para listar o ranking geral e por unidade
    const rankListV2 = computed(() => {
        return (page, size) => {
            // Não exibe nada enquanto estiver carregando
            if (usersRanksV2.value.rankingGeralPageStatus[page] === 'loading' || usersRanksV2.value.rankingByUnidadeDeNegocioPageStatus[page] === 'loading') {
                return {
                    rankingGeral: {
                        items: [],
                        page: page || 0,
                        totalPages: Math.ceil(usersRanksV2.value.total / (size || 10)),
                        totalItems: usersRanksV2.value.total,
                        status: usersRanksV2.value.rankingGeralPageStatus[page] || 'initial'
                    },
                    rankingByUnidadeDeNegocio: {
                        items: [],
                        page: page || 0,
                        totalPages: Math.ceil(usersRanksV2.value.total / (size || 10)),
                        totalItems: usersRanksV2.value.total,
                        status: usersRanksV2.value.rankingByUnidadeDeNegocioPageStatus[page] || 'initial'
                    }
                };
            }
            const listGeral = usersRanksV2.value.rankingGeralPage[page || 0] || [];
            const listUnidadeDeNegocio = usersRanksV2.value.rankingByUnidadeDeNegocioPage[page || 0] || [];
            if ((usersRanksV2.value.rankingGeralPageStatus[page] !== 'success' ||
                usersRanksV2.value.rankingByUnidadeDeNegocioPageStatus[page] !==
                    'success') &&
                (!listGeral.length || !listUnidadeDeNegocio.length)) {
                // Carrega a página se necessário
                if (!usersRanksV2.value.rankingGeralPageStatus[page || 0] || usersRanksV2.value.rankingGeralPageStatus[page || 0] === 'error')
                    getRankV2({ page, size });
                return {
                    rankingGeral: {
                        items: [],
                        page: page || 0,
                        totalPages: Math.ceil(usersRanksV2.value.total / (size || 10)),
                        totalItems: usersRanksV2.value.total,
                        status: usersRanksV2.value.rankingGeralPageStatus[page] || 'initial'
                    },
                    rankingByUnidadeDeNegocio: {
                        items: [],
                        page: page || 0,
                        totalPages: Math.ceil(usersRanksV2.value.total / (size || 10)),
                        totalItems: usersRanksV2.value.total,
                        status: usersRanksV2.value.rankingByUnidadeDeNegocioPageStatus[page] || 'initial'
                    }
                };
            }
            return {
                rankingGeral: {
                    items: listGeral,
                    page: page || 0,
                    totalPages: Math.ceil(usersRanksV2.value.total / (size || 10)),
                    totalItems: usersRanksV2.value.total,
                    status: usersRanksV2.value.rankingGeralPageStatus[page] || 'initial'
                },
                rankingByUnidadeDeNegocio: {
                    items: listUnidadeDeNegocio,
                    page: page || 0,
                    totalPages: Math.ceil(usersRanksV2.value.total / (size || 10)),
                    totalItems: usersRanksV2.value.total,
                    status: usersRanksV2.value.rankingByUnidadeDeNegocioPageStatus[page] || 'initial'
                }
            };
        };
    });
    const trophies = ref({
        status: 'initial',
        total: 0,
        page: {},
        pageStatus: {},
        lastUpdate: 0
    });
    const trophiesList = computed(() => {
        return (page, size) => {
            // Não exibe nada enquanto estiver carregando
            if (trophies.value.pageStatus[page] === 'loading') {
                return {
                    items: [],
                    page: page || 0,
                    totalPages: Math.ceil(trophies.value.total / (size || 10)),
                    totalItems: trophies.value.total,
                    status: trophies.value.pageStatus[page] || 'initial'
                };
            }
            const list = trophies.value?.page[page || 0] || [];
            if (trophies.value.pageStatus[page] !== 'success' && !list.length) {
                // Carrega a pagina caso necessário
                if (!trophies.value.pageStatus[page || 0] || trophies.value.pageStatus[page || 0] === 'error')
                    getTrophies({ page, size });
                return {
                    items: [],
                    page: page || 0,
                    totalPages: Math.ceil(trophies.value.total / (size || 10)),
                    totalItems: trophies.value.total,
                    status: trophies.value.pageStatus[page] || 'initial'
                };
            }
            return {
                items: list,
                page: page || 0,
                totalPages: Math.ceil(trophies.value.total / (size || 10)),
                totalItems: trophies.value.total,
                status: trophies.value.pageStatus[page] || 'initial'
            };
        };
    });
    // Watches for rank changes
    watch(() => userStats.rank, (nRank, oRank) => {
        if (nRank < oRank)
            console.log('Rank up :D!');
        if (nRank > oRank)
            console.log('Rank down :(!');
    });
    /**
    * Getters
    */
    const loading = computed(() => status.value === 1);
    const getMyRankV2 = async (opt) => {
        userRankV2.value.status = 'loading';
        try {
            const { data } = await dsService.getMyRankV2(opt || null);
            userRankV2.value.data = data || null;
            userRankV2.value.status = 'success';
        }
        catch {
            userRankV2.value.status = 'error';
        }
    };
    const getRankV2 = async (opt) => {
        usersRanksV2.value.status = 'loading';
        usersRanksV2.value.rankingGeralPageStatus[opt.page] = 'loading';
        usersRanksV2.value.rankingByUnidadeDeNegocioPageStatus[opt.page] = 'loading';
        try {
            const { rankingGeral, rankingByUnidadeDeNegocio } = await dsService.getRankV2(opt || {});
            usersRanksV2.value.rankingGeralPage[opt.page] = rankingGeral.data;
            usersRanksV2.value.total = rankingGeral.total;
            usersRanksV2.value.rankingGeralPageStatus[opt.page] = 'success';
            usersRanksV2.value.rankingByUnidadeDeNegocioPage[opt.page] = rankingByUnidadeDeNegocio.data;
            usersRanksV2.value.total = rankingByUnidadeDeNegocio.total;
            usersRanksV2.value.rankingByUnidadeDeNegocioPageStatus[opt.page] = 'success';
            usersRanksV2.value.lastUpdate = performance.now();
            usersRanksV2.value.status = 'success';
        }
        catch {
            usersRanksV2.value.status = 'error';
            usersRanksV2.value.rankingGeralPageStatus[opt.page] = 'error';
            usersRanksV2.value.rankingByUnidadeDeNegocioPageStatus[opt.page] = 'error';
        }
    };
    const getMyRank = async () => {
        userRank.value.status = 'loading';
        try {
            const { data } = await dsService.getMyRank();
            userRank.value.data = data || null;
            userRank.value.status = 'success';
        }
        catch {
            userRank.value.status = 'error';
        }
    };
    const getRank = async (opt) => {
        usersRanks.value.status = 'loading';
        usersRanks.value.pageStatus[opt.page] = 'loading';
        try {
            const { data, total } = await dsService.getRank(opt || {});
            usersRanks.value.page[opt.page] = data;
            usersRanks.value.total = total;
            usersRanks.value.lastUpdate = performance.now();
            usersRanks.value.status = 'success';
            usersRanks.value.pageStatus[opt.page] = 'success';
        }
        catch {
            usersRanks.value.status = 'error';
            usersRanks.value.pageStatus[opt.page] = 'error';
        }
    };
    const getTrophies = async (opt) => {
        trophies.value.status = 'loading';
        trophies.value.pageStatus[opt.page] = 'loading';
        opt.userId = useAuthStore().payload.IdDoUsuario;
        try {
            const { data, total } = await dsService.getTrophies(opt || {});
            trophies.value.page[opt.page] = data;
            trophies.value.total = total;
            trophies.value.lastUpdate = performance.now();
            trophies.value.status = 'success';
            trophies.value.pageStatus[opt.page] = 'success';
        }
        catch {
            trophies.value.status = 'error';
            trophies.value.pageStatus[opt.page] = 'error';
        }
    };
    const getAll = async () => {
        status.value = 1;
        try {
            const { data } = await desafiosService.get();
            listChallenges.value = data.map(el => fmResponse(el));
            status.value = 2;
        }
        catch {
            status.value = -1;
        }
    };
    const create = async (dataObj) => {
        status.value = 1;
        try {
            const obj = fmSubmit(dataObj);
            const { success } = await desafiosService.post(null, obj);
            if (success) {
                toast.success({ message: 'Registro cadastrado com sucesso' });
                getAll();
                status.value = 2;
            }
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    const update = async (id, dataObj) => {
        status.value = 1;
        try {
            const obj = fmSubmit(dataObj);
            const { success } = await desafiosService.put(id, obj);
            if (success) {
                toast.success({ message: 'Registro atualizado com sucesso' });
                getAll();
                status.value = 2;
            }
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    const getById = async (id) => {
        status.value = 1;
        try {
            const { data } = await desafiosService.get(id);
            if (!listRules.value.length)
                await getRules();
            status.value = 2;
            return fmResponse(data);
        }
        catch {
            status.value = -1;
        }
    };
    const remove = async (id, motivo) => {
        try {
            status.value = 1;
            const { success } = await dsService.remove(id, motivo);
            if (success) {
                toast.success({ message: 'Registro removido com sucesso' });
                getAll();
            }
            status.value = 2;
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    const getRules = async () => {
        status.value = 1;
        try {
            const fmItem = (el, environment) => ({ rule: el.regra, description: el.descricao, points: 0, environment });
            const { data: contents } = await desafiosService.getRules({ environment: 'Conteudo' });
            const { data: environments } = await desafiosService.getRules({ environment: 'Plataforma' });
            const arrContent = contents.map(el => fmItem(el, 'Conteudo'));
            const arrEnvironments = environments.map(el => fmItem(el, 'Plataforma'));
            listRules.value = arrContent.concat(arrEnvironments);
            status.value = 2;
        }
        catch {
            status.value = -1;
        }
    };
    const fmSubmit = (dataSend) => {
        return {
            id: dataSend.id,
            nome: dataSend.name,
            observacao: dataSend.description,
            ambiente: dataSend.environment,
            tipoConteudo: dataSend.typeContent,
            cursos: dataSend.courseIds.map(el => ({ cursoId: el })),
            formacoes: dataSend.formationIds.map(el => ({ formacaoId: el })),
            segmentacoesIds: dataSend.segmentationsIds,
            regras: dataSend.rules.map(el => (el.challengeId ?? delete el.challengeId) && ({
                desafioId: dataSend.id,
                regra: el.rule,
                pontuacao: el.points
            })),
            dataInicio: dataSend.initDate,
            dataFim: dataSend.endDate,
            diasParaConcluir: dataSend.dateFinish,
            active: dataSend.active
        };
    };
    const fmResponse = (response) => {
        return {
            id: response.id,
            name: response.nome,
            description: response.observacao,
            environment: response.ambiente,
            typeContent: response.tipoConteudo,
            courseIds: response.cursos.map(el => el.cursoId),
            formationIds: response.formacoes.map(el => el.formacaoId),
            segmentationsIds: response.segmentacoesIds,
            rules: response.regras.map(el => ({
                rule: el.regra,
                points: el.pontuacao,
                challengeId: el.desafioId,
                description: listRules.value.find(rl => rl.rule === el.regra)?.description,
            })),
            initDate: response.dataInicio,
            endDate: response.dataFim,
            dateFinish: response.diasParaConcluir,
            active: response.active
        };
    };
    const resetRank = (timeMs) => {
        if (timeMs && performance.now() - usersRanks.value.lastUpdate < timeMs)
            return;
        usersRanks.value = {
            status: 'initial',
            total: 0,
            page: {},
            pageStatus: {},
            lastUpdate: 0
        };
        usersRanksV2.value = {
            status: 'initial',
            total: 0,
            rankingGeralPage: {},
            rankingByUnidadeDeNegocioPage: {},
            rankingGeralPageStatus: {},
            rankingByUnidadeDeNegocioPageStatus: {},
            lastUpdate: 0
        };
    };
    const resetTrohpies = (timeMs) => {
        if (timeMs && performance.now() - trophies.value.lastUpdate < timeMs)
            return;
        trophies.value = {
            status: 'initial',
            total: 0,
            page: {},
            pageStatus: {},
            lastUpdate: 0
        };
    };
    /**
     * Resetar lista de ranking e/ou troféus
     * @param opt 'ranking' | 'trophies' | 'all'
     * @param timeMs tempo minimo em milisegundos para resetar a lista, caso não seja informado, a lista será resetada sempre
     */
    const resetList = (opt, timeMs) => {
        if (!opt || ['all', 'ranking'].includes(opt) && usersRanks.value.status !== 'initial')
            resetRank(timeMs);
        if (!opt || ['all', 'trophies'].includes(opt) && trophies.value.status !== 'initial')
            resetTrohpies(timeMs);
    };
    const associationsContent = async ({ challengeId, type, contentIds }) => {
        status.value = 1;
        try {
            const { data, success } = await desafiosService.associationsContent({ challengeId, type, contentIds });
            if (success) {
                toast.success({ message: 'Registros associados com sucesso!' });
                const clg = listChallenges.value.find(el => el.id === challengeId);
                if (clg)
                    type?.toLowerCase() === 'curso' ? (clg.courseIds = contentIds) : (clg.formationIds = contentIds);
                status.value = 2;
            }
            return data;
        }
        catch {
            status.value = -1;
        }
    };
    return {
        userStats,
        userRank,
        userRankV2,
        usersRanks,
        usersRanksV2,
        trophies,
        listRules,
        listChallenges,
        // Getters
        loading,
        rankList,
        rankListV2,
        trophiesList,
        // Actions
        getRank,
        getRankV2,
        getMyRank,
        getMyRankV2,
        getTrophies,
        getAll,
        create,
        update,
        getById,
        remove,
        getRules,
        resetList,
        associationsContent
    };
});
export default useGamificacaoStore;
