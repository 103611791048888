import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, Transition as _Transition, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "grow truncate whitespace-nowrap" };
const _hoisted_2 = { class: "pe-select-caret ml-2" };
const _hoisted_3 = { class: "flex items-center border-0 border-b border-solid border-neutral-300" };
const _hoisted_4 = ["placeholder"];
const _hoisted_5 = ["onClick"];
const _hoisted_6 = { class: "option-text" };
import { computed, onMounted, onUnmounted, ref } from 'vue';
import UiIcon from '@/components/ui/icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeSelect',
    props: {
        modelValue: { type: [String, Number, Object, Boolean] },
        placeholder: {},
        data: {},
        height: {},
        filterable: { type: Boolean },
        disabled: { type: Boolean }
    },
    emits: ['onSelect', 'update:modelValue'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const selectComponent = ref(null); // armazena informações relacionadas a click exterior
        const showDropdown = ref(false);
        const quickSearch = ref('');
        const quickSearchInput = ref();
        const visibleItems = computed(() => quickSearch.value
            ? props.data.filter((item) => item.label.toLocaleLowerCase()?.includes(quickSearch.value.toLocaleLowerCase()))
            : props.data);
        const selected = computed(() => {
            let label = '';
            if ((props.modelValue || props.modelValue >= 0) && props.data)
                label = props.data.find((e) => e.value === props.modelValue)?.label;
            return label || props.placeholder || '';
        });
        const toggleDropdown = () => {
            showDropdown.value = !showDropdown.value;
            if (showDropdown.value) {
                setTimeout(() => {
                    quickSearchInput.value.focus();
                }, 200);
            }
        };
        const closeDropdown = () => {
            showDropdown.value = false;
        };
        const onSelect = (opt) => {
            emit('update:modelValue', opt.value);
            emit('onSelect', opt);
            closeDropdown();
        };
        /**
         * Auto-esconde o componente quando o usuário clica fora do elemento
         * @param event
         */
        const clickOutside = (e) => {
            if (showDropdown.value && !selectComponent.value.contains(e.target)) {
                closeDropdown();
            }
        };
        onMounted(() => {
            document.addEventListener('click', clickOutside, false);
        });
        onUnmounted(() => {
            document.removeEventListener('click', clickOutside, false);
        });
        return (_ctx, _cache) => {
            const _component_pe_icon = _resolveComponent("pe-icon");
            return (_openBlock(), _createElementBlock("div", {
                ref_key: "selectComponent",
                ref: selectComponent,
                class: _normalizeClass(["pe-select relative box-border rounded-md border border-solid text-sm", {
                        'pointer-events-none cursor-not-allowed': _ctx.disabled,
                        active: showDropdown.value && !_ctx.disabled
                    }])
            }, [
                _createElementVNode("div", {
                    class: "flex p-3",
                    onClick: toggleDropdown
                }, [
                    _createElementVNode("div", _hoisted_1, _toDisplayString(selected.value), 1),
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(UiIcon, { icon: "caret-down" })
                    ])
                ]),
                _createVNode(_Transition, {
                    "enter-active-class": "transition ease-out duration-300 transform",
                    "enter-from-class": "opacity-0 -translate-y-10 scale-y-50",
                    "enter-to-class": "opacity-100 translate-y-0 scale-100",
                    "leave-active-class": "ease-in duration-200",
                    "leave-from-class": "opacity-100 translate-y-0 scale-100",
                    "leave-to-class": "opacity-0 -translate-y-10 translate-y-0 scale-y-50"
                }, {
                    default: _withCtx(() => [
                        (showDropdown.value)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "absolute z-10 mt-2 w-full origin-top-right overflow-x-hidden overflow-y-auto whitespace-nowrap rounded-md bg-white shadow-md dark:bg-neutral-700",
                                style: _normalizeStyle({ 'max-height': `${_ctx.height || 240}px` })
                            }, [
                                _withDirectives(_createElementVNode("div", { class: "overflow-hidden text-ellipsis p-2" }, _toDisplayString(_ctx.$t('feedback.noFound')), 513), [
                                    [_vShow, visibleItems.value.length === 0]
                                ]),
                                _withDirectives(_createElementVNode("div", _hoisted_3, [
                                    _withDirectives(_createElementVNode("input", {
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((quickSearch).value = $event)),
                                        ref_key: "quickSearchInput",
                                        ref: quickSearchInput,
                                        type: "text",
                                        class: "mx-2 my-4 box-border grow rounded-md border border-neutral-900 px-2 py-1 text-base dark:bg-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-200",
                                        placeholder: _ctx.$t('input.search')
                                    }, null, 8, _hoisted_4), [
                                        [_vModelText, quickSearch.value]
                                    ]),
                                    _createElementVNode("div", {
                                        class: "mr-2 cursor-pointer py-4 px-2",
                                        onClick: _cache[1] || (_cache[1] = ($event) => (quickSearch.value = ''))
                                    }, [
                                        _createVNode(_component_pe_icon, null, {
                                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                                                _createTextVNode("close")
                                            ])),
                                            _: 1
                                        })
                                    ])
                                ], 512), [
                                    [_vShow, _ctx.filterable]
                                ]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleItems.value, (item, i) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: i,
                                        class: "overflow-hidden text-ellipsis p-2 hover:bg-neutral-300 dark:hover:bg-neutral-600 hover:cursor-pointer w-full",
                                        onClick: ($event) => (onSelect(item))
                                    }, [
                                        _createElementVNode("span", _hoisted_6, _toDisplayString(item.label), 1)
                                    ], 8, _hoisted_5));
                                }), 128))
                            ], 4))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                })
            ], 2));
        };
    }
});
