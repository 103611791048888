import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "text-center" };
const _hoisted_2 = ["src"];
const _hoisted_3 = { class: "whitespace-nowrap font-semibold" };
const _hoisted_4 = { class: "grow" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { class: "font-semibold" };
const _hoisted_7 = { class: "font-semibold" };
const _hoisted_8 = {
    key: 1,
    class: "font-semibold"
};
import { useI18n } from 'vue-i18n';
import { useShare } from '@vueuse/core';
import { formatter } from '@/common/util';
import UiIcon from '@/components/ui/icon.vue';
// import ImgTrophy from '@/assets/game/trophy.png'
import ImgGamePoints from '@/assets/game/icon-points.svg';
export default /*@__PURE__*/ _defineComponent({
    __name: 'achievement',
    props: {
        data: {},
        compact: { type: Boolean }
    },
    setup(__props) {
        const { share, isSupported } = useShare();
        const { t } = useI18n();
        const props = __props;
        function startShare() {
            share({
                title: props.data?.rule || t('labels.conquest'),
                text: t('gamification.achievementReceived'),
                url: location.href
            });
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["box-border flex items-center rounded-md border border-solid border-neutral-200 bg-neutral-50 dark:border-neutral-400 dark:bg-neutral-700 dark:text-neutral-300", _ctx.compact ? 'gap-4 p-2' : 'gap-6 px-6 py-2'])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("img", {
                        src: _unref(ImgGamePoints),
                        alt: "",
                        class: "h-8 w-8"
                    }, null, 8, _hoisted_2),
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.data?.score || '') + " pts", 1)
                ]),
                _createElementVNode("div", _hoisted_4, [
                    (_ctx.data?.content)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(t)('labels.content')) + ":", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.data?.content || ''), 1)
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", null, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(t)('labels.objective')) + ":", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.data?.rule || ''), 1)
                    ]),
                    (_ctx.data?.date)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(`${_unref(t)('gamification.conquered')} ${_unref(formatter).date(_ctx.data?.date)}`), 1))
                        : _createCommentVNode("", true)
                ]),
                (false && !_ctx.compact && _unref(isSupported))
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "cursor-pointer border-0 bg-transparent text-neutral-400 hover:text-neutral-600",
                        onClick: _cache[0] || (_cache[0] = ($event) => (startShare()))
                    }, [
                        _createVNode(UiIcon, {
                            icon: "share",
                            size: "24px"
                        })
                    ]))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
