import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "mx-20 flex flex-col items-center justify-center"
};
const _hoisted_2 = { class: "pe-progress my-10" };
const _hoisted_3 = ["onClick"];
import { ref, computed } from 'vue';
import { clamp } from '@/common/util';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeProgress',
    props: {
        modelValue: {},
        data: {},
        step: {},
        navigable: { type: Boolean }
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        _useCssVars(_ctx => ({
            "5f887928": (progressPercent.value)
        }));
        const props = __props;
        const emit = __emit;
        const progress = ref();
        const currentStep = computed(() => clamp(props.modelValue, 1, props.data.length));
        const progressPercent = computed(() => {
            if (props.data.length < 2)
                return '0%';
            return `${((currentStep.value - 1) * 100) / (props.data.length - 1)}%`;
        });
        return (_ctx, _cache) => {
            return (_ctx.data.length > 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", {
                            ref_key: "progress",
                            ref: progress,
                            class: "progress"
                        }, null, 512),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (step, key) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: key,
                                class: _normalizeClass(["circle", {
                                        'pe-progress--complete': currentStep.value > key + 1,
                                        'pe-progress--active': key + 1 === currentStep.value,
                                        'cursor-pointer': _ctx.navigable,
                                        'pointer-events-none': !_ctx.navigable
                                    }]),
                                onClick: ($event) => (emit('update:modelValue', key + 1))
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass(["absolute top-[-30px] transform whitespace-nowrap uppercase", {
                                            'font-bold text-black dark:text-neutral-200': key + 1 === currentStep.value,
                                            ' dark:text-neutral-500': key + 1 !== currentStep.value,
                                        }])
                                }, _toDisplayString(step.label || `Passo ${key + 1}`), 3)
                            ], 10, _hoisted_3));
                        }), 128))
                    ])
                ]))
                : _createCommentVNode("", true);
        };
    }
});
