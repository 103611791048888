import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeSkeleton',
    props: {
        width: {},
        height: {},
        bgColor: {},
        animated: { type: Boolean },
        borderRadius: {}
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["h-3 w-full bg-gray-300 dark:bg-neutral-600", { 'animate-pulse': !!_ctx.animated, 'rounded-full': !_ctx.borderRadius }]),
                style: _normalizeStyle({
                    backgroundColor: _ctx.bgColor,
                    width: _ctx.width,
                    height: _ctx.height,
                    borderRadius: _ctx.borderRadius
                })
            }, null, 6));
        };
    }
});
