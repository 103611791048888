import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { dialog } from '@/common/util';
import empresasService from '@/services/empresas.service';
export const useEmpresasStore = defineStore('empresas', () => {
    const { t } = useI18n();
    /**
     * States
     */
    const list = ref([]);
    const status = ref(0); // -1 erro de exceção; 0 - inicial; 1 - carregando; 2 - sucesso; 3 - erro não fatal
    /**
     * Getters
    */
    const loading = computed(() => status.value === 1);
    const companiesNoContract = computed(() => list.value.filter(el => !el.contratoId));
    /**
     * Actions
     */
    const create = async (objData) => {
        status.value = 1;
        try {
            const { data, success } = await empresasService.create(objData);
            if (success)
                getAll();
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const update = async (objData, id) => {
        status.value = 1;
        try {
            const { data, success } = await empresasService.update(id, objData);
            if (success)
                getAll();
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const getAll = async () => {
        status.value = 1;
        try {
            const result = await empresasService.get();
            list.value = result.data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const remove = async (empresa, force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.company').toLowerCase(), name: empresa.nome }),
                complete: () => remove(empresa, true)
            });
        try {
            status.value = 1;
            const { success } = await empresasService.remove(empresa.id);
            if (success)
                list.value.splice(list.value.findIndex(el => el.id === empresa.id), 1);
            status.value = 2;
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    // Busca dados de uma empresa com base no CpfCnpj
    const getDataWithIdentificationDocument = async (document) => {
        try {
            const { data } = await empresasService.getPeopleByIdentification(document);
            return data;
        }
        catch {
            //
        }
    };
    const getById = async (id) => {
        status.value = 1;
        try {
            const { data } = await empresasService.get(id);
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    return {
        list,
        companiesNoContract,
        //Getters
        loading,
        // Actions
        create,
        update,
        getAll,
        getById,
        remove,
        getDataWithIdentificationDocument
    };
});
export default useEmpresasStore;
