import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex justify-between w-full mt-6 mx-12" };
const _hoisted_2 = { class: "font-semibold text-xl" };
const _hoisted_3 = { class: "mx-8" };
const _hoisted_4 = { class: "my-4 flex flex-col gap-6" };
const _hoisted_5 = { class: "text-right text-xl font-normal uppercase mx-8 mb-6" };
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from '@/common/util';
import suggestionService from '@/services/contentSuggestion.service';
import UiButton from '@/components/ui/button.vue';
import UiInput from '@/components/ui/input.vue';
import UiModal from '@/components/ui/modal.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'modalSuggestion',
    props: {
        searchTerm: {}
    },
    setup(__props, { expose: __expose }) {
        const { t } = useI18n();
        const props = __props;
        const suggestion = ref('');
        const modal = ref();
        const busy = ref(false);
        const open = async () => await modal.value?.open();
        const submit = () => {
            if (!suggestion.value)
                return;
            busy.value = true;
            suggestionService
                .postSuggestion({
                suggestion: suggestion.value,
                searchTerm: props.searchTerm
            })
                .then(() => {
                modal.value?.confirm();
                suggestion.value = '';
                toast.success({
                    title: t('feedback.suggestionSent'),
                    message: t('feedback.suggestionSentMessage')
                });
            })
                .catch((err) => {
                console.log(err);
            })
                .finally(() => {
                busy.value = false;
            });
        };
        __expose({
            open,
            submit
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(UiModal, {
                ref_key: "modal",
                ref: modal,
                width: 960,
                hideClose: ""
            }, {
                title: _withCtx(({ cancel }) => [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('feedback.suggestionTitle')), 1),
                        _createVNode(UiButton, {
                            type: "ghost",
                            icon: "x",
                            class: "px-4 py-2 underline underline-offset-2",
                            onClick: () => cancel()
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('feedback.close')), 1)
                            ]),
                            _: 2
                        }, 1032, ["onClick"])
                    ])
                ]),
                footer: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                        _createVNode(UiButton, {
                            disabled: !suggestion.value,
                            loading: busy.value,
                            type: "primary",
                            class: "py-3 px-4",
                            icon: "send-2",
                            onClick: submit
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('button.sendSuggestion')), 1)
                            ]),
                            _: 1
                        }, 8, ["disabled", "loading"])
                    ])
                ]),
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _createVNode(UiInput, {
                                modelValue: suggestion.value,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((suggestion).value = $event)),
                                type: "textarea",
                                placeholder: _ctx.$t('placeholder.suggestions'),
                                rows: "6",
                                maxlength: "300",
                                class: "p-3 w-full"
                            }, null, 8, ["modelValue", "placeholder"])
                        ])
                    ])
                ]),
                _: 1
            }, 512));
        };
    }
});
