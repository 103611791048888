import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, renderSlot as _renderSlot, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "mb-1 font-medium flex gap-1 items-center"
};
const _hoisted_2 = {
    key: 0,
    class: "text-red-600"
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeInputControl',
    props: {
        label: {},
        required: { type: Boolean },
        tooltip: {},
        errorMsg: {}
    },
    setup(__props) {
        return (_ctx, _cache) => {
            const _component_pe_tooltip = _resolveComponent("pe-tooltip");
            const _component_pe_error_msg = _resolveComponent("pe-error-msg");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({ 'error': !!_ctx.errorMsg })
            }, [
                (_ctx.label)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
                        (_ctx.required)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
                            : _createCommentVNode("", true),
                        (_ctx.tooltip)
                            ? (_openBlock(), _createBlock(_component_pe_tooltip, {
                                key: 1,
                                text: _ctx.tooltip,
                                icon: ""
                            }, null, 8, ["text"]))
                            : _createCommentVNode("", true)
                    ]))
                    : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "default"),
                _createVNode(_component_pe_error_msg, { message: _ctx.errorMsg }, null, 8, ["message"])
            ], 2));
        };
    }
});
