export const config = {
    banners: {
        active: true,
        // showPlantar: true,
        items: [
        // {
        //   id: '1',
        //   url: 'https://plantareducacao.b-cdn.net/dev/fafca64f-a53b-478e-90b2-98f0e8e95cac/Banner%20Final%20V2.jpg',
        //   link: ''
        // },
        // {
        //   id: '2',
        //   url: 'https://plantareducacao.b-cdn.net/dev/fafca64f-a53b-478e-90b2-98f0e8e95cac/Banner%20Final%20V1.jpg',
        //   link: ''
        // },
        // {
        //   id: '3',
        //   url: 'https://plantareducacao.b-cdn.net/dev/fafca64f-a53b-478e-90b2-98f0e8e95cac/baner_aprendizado_2.png',
        //   link: ''
        // }
        ],
        // itemsPlantar: [] as bannerType[]
    },
    logos: {
        menu: '',
        loginLight: '',
        loginDark: '',
        icon: ''
    },
    colors: {
        topBar: {
            font: '#ffffff',
            bg: '#171717',
            icons: '#ffffff'
        },
        menu: {
            font: '#ffffff',
            bg: '#171717',
            icons: '#c191ff'
        },
        images: {
            primary: {
                light: '#c191ff',
                dark: '#c191ff',
                useDark: false
            },
            secondary: {
                useCustom: false,
                default: '#a3a3a3',
                light: '#3cdfad',
                dark: '#3cdfad',
                useDark: false
            }
        },
        interface: {
            primary: '#c191ff',
            textColor: '#ffffff',
            autoFontColor: true
        }
    },
    general: {
        certificate: {
            course: '',
            formation: ''
        },
        termOfUse: '',
        logoutUrl: '',
        privacyPolicyUrl: ''
    },
    login: {
        backgrounds: [],
        align: 'right',
        // bgMedia: [] as {
        //   active?: boolean
        //   type: 'image' | 'video'
        //   src: string
        //   opacity: number
        //   blur: number
        //   fit: 'fill' | 'contain' | 'cover' | 'scale-down' | 'none'
        //   from?: Date
        //   to?: Date
        // }[],
        carousels: []
    },
    page: {
        title: '',
        description: ''
    },
    hideSupport: false,
    defaultScripts: [
        {
            name: 'Identificador Movidesk',
            content: 'var mdChatClient="0C343DE179B44BF19A6DE0CA8B3DA75E";',
            isLink: false
        },
        {
            name: 'Script Movidesk',
            content: 'https://chat.movidesk.com/Scripts/chat-widget.min.js',
            isLink: true
        },
        // {
        //   name: 'Aliare-Suite',
        //   content: '/js/aliare-suite.js',
        //   isLink: true,
        //   condition: !['', '__Auth.SolutionsUrl__'].includes(((window as any)?.configuration?.aliare?.solutions_url || '').trim())
        // }
    ],
    scripts: [],
    waterMark: {
        user: {
            name: true,
            email: true,
            company: true,
            businessUnit: true,
            department: true,
            role: true,
            waterMarkRegistrtionDate: true,
            ip: true
        },
        allowPDFDownload: true,
        includeWaterMarkInPDF: false
    },
    tenantId: ''
};
