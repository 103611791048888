import { toast } from '@/common/util';
import baseApi from './base.api';
const api = {
    ...baseApi,
    resource: 'Comunicacao',
    async getUserCommunication(params) {
        try {
            return await api.get('getAllPorUsuarioAutenticado', { ...params });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] });
        }
    },
    async changeUserCommunicationsStatus(ids, showAgain) {
        try {
            return await api.post(`ChangeUserCommunicationStatus/${showAgain}`, ids);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] });
        }
    },
    async associationContracts(comunicacaoId, contratosComunicacoesIds) {
        try {
            return await api.put(`${comunicacaoId}/UpdateContratosComunicacoes`, contratosComunicacoesIds);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar vincular contratos a comunicação!' });
        }
    }
};
export default api;
