import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, unref as _unref } from "vue";
const _hoisted_1 = { class: "flex gap-4 justify-between" };
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = {
    key: 0,
    class: "py-6 text-center w-full"
};
const _hoisted_4 = { class: "flex flex-wrap justify-end gap-4" };
import { computed, ref } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import UiButton from '@/components/ui/button.vue';
import UiIcon from '@/components/ui/icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'modal',
    props: {
        disableEsc: { type: Boolean },
        hideClose: { type: Boolean },
        title: {},
        loading: { type: Boolean },
        width: {},
        confirmText: {},
        disabled: { type: Boolean },
        hideFooter: { type: Boolean },
        beforeOpen: { type: Function },
        beforeCancel: { type: Function },
        beforeConfirm: { type: Function }
    },
    setup(__props, { expose: __expose }) {
        const props = __props;
        const isOpen = ref(false);
        const isLoading = computed(() => props.loading || false);
        let localPromise = null;
        const open = async (isAsync) => {
            if (props.beforeOpen) {
                const result = await props.beforeOpen();
                if (result === false)
                    return;
            }
            isOpen.value = true;
            if (isAsync)
                return new Promise((resolve) => {
                    localPromise = resolve;
                });
        };
        const cancel = async () => {
            if (typeof props.beforeCancel !== 'undefined' && await props.beforeCancel() === false)
                return;
            isOpen.value = false;
            if (localPromise)
                localPromise('cancel');
        };
        const confirm = async (result) => {
            if (typeof props.beforeConfirm !== 'undefined' && await props.beforeConfirm() === false)
                return;
            isOpen.value = false;
            if (localPromise)
                localPromise(result);
        };
        __expose({
            isOpen,
            open,
            cancel,
            confirm
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(VueFinalModal), {
                modelValue: isOpen.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((isOpen).value = $event)),
                "content-class": "px-4 py-8 flex flex-wrap justify-center items-center w-full h-full overflow-y-auto box-border bg-black/50"
            }, {
                default: _withCtx(() => [
                    (isOpen.value)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            method: "dialog",
                            class: "w-full box-border flex flex-col gap-6 bg-neutral-50 dark:bg-neutral-700 text-neutral-800 dark:text-neutral-300 p-4 rounded-2xl shadow-md",
                            style: _normalizeStyle({ maxWidth: typeof _ctx.width === 'number' ? `${_ctx.width}px` : (_ctx.width || 'auto') })
                        }, [
                            _createElementVNode("div", _hoisted_1, [
                                _renderSlot(_ctx.$slots, "title", { cancel: cancel }, () => [
                                    _createElementVNode("h3", {
                                        class: "my-0 text-2xl font-bold",
                                        innerHTML: _ctx.title || ''
                                    }, null, 8, _hoisted_2)
                                ]),
                                (!_ctx.hideClose)
                                    ? (_openBlock(), _createBlock(UiButton, {
                                        key: 0,
                                        type: "ghost",
                                        onClick: _cache[0] || (_cache[0] = () => cancel())
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiIcon, { icon: "x" })
                                        ]),
                                        _: 1
                                    }))
                                    : _createCommentVNode("", true)
                            ]),
                            (isLoading.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                    _createVNode(UiIcon, {
                                        class: "animate-spin",
                                        icon: "loader"
                                    }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$t('feedback.loading')), 1)
                                ]))
                                : _renderSlot(_ctx.$slots, "default", { key: 1 }),
                            (!_ctx.hideFooter)
                                ? _renderSlot(_ctx.$slots, "footer", {
                                    key: 2,
                                    cancel: cancel,
                                    confirm: confirm
                                }, () => [
                                    _createElementVNode("div", _hoisted_4, [
                                        _createVNode(UiButton, {
                                            type: "primary",
                                            outline: "",
                                            disabled: isLoading.value,
                                            class: "p-4 uppercase",
                                            onClick: cancel
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t('button.cancel')), 1)
                                            ]),
                                            _: 1
                                        }, 8, ["disabled"]),
                                        _createVNode(UiButton, {
                                            type: "primary",
                                            disabled: isLoading.value || _ctx.disabled,
                                            loading: isLoading.value,
                                            class: "p-4 uppercase",
                                            onClick: confirm
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.confirmText || _ctx.$t('button.confirm')), 1)
                                            ]),
                                            _: 1
                                        }, 8, ["disabled", "loading"])
                                    ])
                                ])
                                : _createCommentVNode("", true)
                        ], 4))
                        : _createCommentVNode("", true)
                ]),
                _: 3
            }, 8, ["modelValue"]));
        };
    }
});
