import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = { key: 0 };
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import UiFloating from './util/floating.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'dropdown',
    props: {
        placement: { default: 'bottom-end' },
        disabled: { type: Boolean, default: false },
        closeOnClick: { type: Boolean }
    },
    setup(__props, { expose: __expose }) {
        const props = __props;
        const floating = ref();
        const el = ref();
        const show = ref(false);
        const open = () => {
            if (!show.value)
                show.value = true;
        };
        const close = () => {
            if (show.value && floating.value)
                floating.value.close();
        };
        const toggle = () => {
            if (props.disabled)
                return;
            if (show.value)
                close();
            else
                open();
        };
        onClickOutside(floating, (e) => {
            if (!show.value)
                return;
            else if (e.target?.closest('.ui-dp-element') !== el.value)
                close();
        });
        __expose({
            isOpen: computed(() => show.value),
            open,
            close,
            toggle
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                ref_key: "el",
                ref: el,
                onClick: _cache[2] || (_cache[2] = _withModifiers(() => toggle(), ["stop"]))
            }, [
                _renderSlot(_ctx.$slots, "default", { open: show.value }),
                (show.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(UiFloating, {
                            ref_key: "floating",
                            ref: floating,
                            show: show.value && !_ctx.disabled,
                            source: el.value,
                            placement: _ctx.placement,
                            class: "bg-neutral-50 border border-neutral-400/50 dark:bg-neutral-800 dark:border-neutral-600 rounded-lg shadow-lg overflow-hidden",
                            onClosed: _cache[0] || (_cache[0] = ($event) => (show.value = false)),
                            onClick: _cache[1] || (_cache[1] = () => _ctx.closeOnClick && close())
                        }, {
                            default: _withCtx(() => [
                                _renderSlot(_ctx.$slots, "content")
                            ]),
                            _: 3
                        }, 8, ["show", "source", "placement"])
                    ]))
                    : _createCommentVNode("", true)
            ], 512));
        };
    }
});
