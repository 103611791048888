import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex min-h-[220px] items-center" };
const _hoisted_2 = {
    key: 0,
    class: "text-6xl text-amber-500"
};
const _hoisted_3 = { class: "grow self-center px-6 overflow-hidden break-words dark:text-neutral-300 text-center" };
const _hoisted_4 = {
    key: 0,
    class: "text-3xl pt-4"
};
const _hoisted_5 = { class: "max-h-[500px] overflow-y-auto py-4" };
const _hoisted_6 = ["innerHTML"];
import { ref, computed, onMounted } from 'vue';
import PeModal from './PeModal.vue';
import UiIcon from './ui/icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeDialog',
    props: {
        modelValue: { type: Boolean },
        auto: { type: Boolean },
        title: {},
        message: {},
        show: { type: Boolean },
        hideImage: { type: Boolean },
        hideCancelButton: { type: Boolean },
        cancelButtonText: {},
        confirmButtonText: {},
        alt: { type: Boolean }
    },
    emits: ['onConfirm', 'onCancel', 'update:modelValue'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const internalValue = ref(false);
        const showDialog = computed({
            get: () => (props.auto && internalValue.value) || props.modelValue || false,
            set(newValue) {
                if (!props.auto)
                    emit('update:modelValue', newValue);
                else
                    internalValue.value = newValue;
            }
        });
        const onConfirm = () => {
            // if (typeof props.complete === 'function' && props.complete)
            //   props.complete()
            emit('onConfirm');
        };
        const onCancel = () => {
            emit('onCancel');
        };
        onMounted(() => {
            internalValue.value = props.auto || false;
        });
        __expose({
            message: props.message,
            onConfirm,
            close
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(PeModal, {
                modelValue: showDialog.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((showDialog).value = $event)),
                hideCancelButton: _ctx.hideCancelButton,
                cancelButtonText: _ctx.cancelButtonText,
                confirmButtonText: _ctx.confirmButtonText,
                width: "624px",
                "trap-focus": "",
                "close-on-esc": "",
                "confirm-on-enter": "",
                onOnConfirm: onConfirm,
                onOnCancel: onCancel
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        (!_ctx.hideImage)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                _createVNode(UiIcon, {
                                    icon: "alert-triangle",
                                    strokeWidth: "1.0"
                                })
                            ]))
                            : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_3, [
                            (_ctx.title)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.title), 1))
                                : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_5, [
                                (_ctx.message)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: "text-[15px] leading-[22px]",
                                        innerHTML: _ctx.message
                                    }, null, 8, _hoisted_6))
                                    : _createCommentVNode("", true),
                                _renderSlot(_ctx.$slots, "default")
                            ])
                        ])
                    ])
                ]),
                _: 3
            }, 8, ["modelValue", "hideCancelButton", "cancelButtonText", "confirmButtonText"]));
        };
    }
});
