import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref, watch, onMounted, onBeforeMount } from 'vue';
import { getLanguage } from '@/common/util';
export default /*@__PURE__*/ _defineComponent({
    __name: 'counter',
    props: {
        value: {},
        duration: { default: 600 },
        decimal: { default: 0 }
    },
    setup(__props) {
        let lang = '';
        const props = __props;
        const startVal = ref(0);
        const endVal = ref(0);
        const currentVal = ref(0);
        const result = ref('');
        let startTimestamp = null;
        function animateValue() {
            const step = (timestamp) => {
                if (!startTimestamp)
                    startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / props.duration, 1);
                currentVal.value = progress * (endVal.value - startVal.value) + startVal.value;
                result.value = currentVal.value.toLocaleString(lang, {
                    minimumFractionDigits: Number(props.decimal),
                    maximumFractionDigits: Number(props.decimal)
                });
                if (progress < 1)
                    window.requestAnimationFrame(step);
            };
            window.requestAnimationFrame(step);
        }
        watch(() => props.value, (val) => {
            startVal.value = currentVal.value;
            endVal.value = Number(val);
            startTimestamp = null;
            animateValue();
        });
        onBeforeMount(() => {
            lang = getLanguage();
        });
        onMounted(() => {
            endVal.value = Number(props.value);
            animateValue();
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("span", null, _toDisplayString(result.value), 1));
        };
    }
});
