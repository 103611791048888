import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "mt-8" };
const _hoisted_2 = { class: "flex flex-wrap gap-2 py-2 justify-end" };
import { ref, computed } from 'vue';
import UiButton from '@/components/ui/button.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeSelectModal',
    props: {
        title: {},
        width: {},
        multiple: { type: Boolean },
        columns: {},
        data: {},
        selected: {},
        returnData: { type: Boolean },
        loading: { type: Boolean },
        previewMode: { type: Boolean },
        onAfterOpen: { type: Function }
    },
    emits: ['onComplete'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const tableRef = ref();
        const searchTerm = ref('');
        const modal = ref();
        const rows = ref([]);
        const selectedIndex = ref();
        const isDisabled = computed(() => (props.multiple ? !selectedIndex.value?.length : !selectedIndex.value));
        const onSelectionChange = (value) => {
            selectedIndex.value = value;
        };
        const open = async (selected) => {
            await modal.value?.open();
            searchTerm.value = '';
            if (typeof props.onAfterOpen === 'function') {
                props.onAfterOpen()
                    .then(() => {
                    rows.value = props.data.filter(el => Array.isArray(selected) ? !selected.includes(el.id) : true);
                })
                    .catch(() => {
                });
            }
            else {
                rows.value = props.data.filter(el => Array.isArray(selected) ? !selected.includes(el.id) : true);
            }
            if (typeof selected !== 'undefined') {
                tableRef.value?.setSelection(selected);
                selectedIndex.value = selected;
            }
        };
        const cancel = () => {
            emit('onComplete', false);
            modal.value?.cancel();
        };
        const confirm = () => {
            if (props.returnData)
                emit('onComplete', props.multiple
                    ? props.data?.filter((el) => selectedIndex.value.includes(el.id))
                    : props.data?.find((el) => el.id === selectedIndex.value));
            else
                emit('onComplete', selectedIndex.value);
            modal.value?.cancel();
        };
        __expose({
            open,
            cancel,
            confirm
        });
        return (_ctx, _cache) => {
            const _component_pe_input_search = _resolveComponent("pe-input-search");
            const _component_pe_table = _resolveComponent("pe-table");
            const _component_pe_modal = _resolveComponent("pe-modal");
            return (_openBlock(), _createBlock(_component_pe_modal, {
                ref_key: "modal",
                ref: modal,
                header: _ctx.title,
                width: "1138px"
            }, {
                footer: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                        (_ctx.previewMode)
                            ? (_openBlock(), _createBlock(UiButton, {
                                key: 0,
                                type: "primary",
                                outline: "",
                                class: "p-3 uppercase",
                                onClick: _cache[1] || (_cache[1] = () => cancel())
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('button.back')), 1)
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true),
                        (!_ctx.previewMode)
                            ? (_openBlock(), _createBlock(UiButton, {
                                key: 1,
                                type: "primary",
                                outline: "",
                                class: "p-3 uppercase",
                                onClick: _cache[2] || (_cache[2] = () => cancel())
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('button.cancel')), 1)
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true),
                        (!_ctx.previewMode)
                            ? (_openBlock(), _createBlock(UiButton, {
                                key: 2,
                                type: "primary",
                                disabled: isDisabled.value,
                                class: "p-3 uppercase",
                                onClick: _cache[3] || (_cache[3] = () => confirm())
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('button.confirm')), 1)
                                ]),
                                _: 1
                            }, 8, ["disabled"]))
                            : _createCommentVNode("", true)
                    ])
                ]),
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_pe_input_search, {
                            modelValue: searchTerm.value,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((searchTerm).value = $event)),
                            placeholder: _ctx.$t('placeholder.search'),
                            class: "mx-4 mb-6"
                        }, null, 8, ["modelValue", "placeholder"]),
                        _createVNode(_component_pe_table, {
                            class: "mb-9",
                            ref_key: "tableRef",
                            ref: tableRef,
                            selectable: !_ctx.previewMode,
                            "selection-type": _ctx.multiple ? 'multiple' : 'single',
                            rowIndex: "id",
                            columns: _ctx.columns,
                            data: rows.value,
                            searchTerm: searchTerm.value,
                            "default-filters": { active: [true] },
                            loading: _ctx.loading,
                            onSelectionChange: onSelectionChange
                        }, null, 8, ["selectable", "selection-type", "columns", "data", "searchTerm", "loading"])
                    ])
                ]),
                _: 1
            }, 8, ["header"]));
        };
    }
});
