import { createI18n } from 'vue-i18n';
import { ptLocale } from '@/locales/pt';
import { enLocale } from '@/locales/en';
import { esLocale } from '@/locales/es';
const defaultI18nParams = {
    locale: 'pt',
    messages: {
        pt: ptLocale,
        en: enLocale,
        es: esLocale
    }
};
export const createI18nPlugin = ({ locale, messages } = defaultI18nParams) => {
    return createI18n({
        locale,
        messages,
        legacy: false,
        globalInjection: true,
        fallbackLocale: 'pt',
        fallbackWarn: false,
        missingWarn: false,
        warnHtmlMessage: false
    });
};
export default createI18nPlugin();
