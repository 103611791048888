import BaseService from './base.service';
import { toast } from '@/common/util';
import baseApi from './base.api';
export const departamentosServices = {
    ...baseApi,
    resource: 'departamento',
    async getListBasic(params) {
        try {
            return await departamentosServices.get(`Basic`, { ...params });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao buscar lista de unidades!' });
        }
    },
    async getAll(opt, options) {
        const paramsDictionary = {
            id: 'id',
            active: 'ativo',
            name: 'nome',
            tenantId: 'tenantId'
        };
        const paramsToSend = {};
        if (opt) {
            Object.keys(opt).forEach((key) => {
                if (typeof paramsDictionary[key] !== 'undefined' && typeof opt[key] !== 'undefined' && opt[key] !== null)
                    paramsToSend[paramsDictionary[key]] = opt[key];
            });
        }
        return departamentosServices.get('', paramsToSend, options)
            .then((res) => ({
            status: res.success,
            data: res.data?.map((item) => ({
                id: item.id || null,
                name: item.nome,
                active: item.active,
                observation: item.observacao,
                createdAt: item.createdAt,
                tenantId: item.tenantId,
            })),
        }));
    }
};
class DepartamentosService extends BaseService {
    constructor() {
        super({ resource: 'departamento' });
    }
}
const departamentosService = new DepartamentosService();
export default departamentosService;
