import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "custom-loader" };
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'loader',
    props: {
        size: {},
        color: {}
    },
    setup(__props) {
        _useCssVars(_ctx => ({
            "19736238": (size.value),
            "1419f40c": (color.value)
        }));
        const props = __props;
        const color = computed(() => props.color || 'var(--c-primary-600)');
        const size = computed(() => {
            if (!props.size)
                return '50px';
            const size = `${props.size}`.toLowerCase().replace(/\s*/, '').match(/(\d+)(px|em|rem|%)?/);
            return `${size[1] || 14}${size[2] || 'px'}`;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1));
        };
    }
});
