import { toast } from '@/common/util';
import baseApi from './base.api';
const api = {
    ...baseApi,
    resource: 'Desafio',
    async getRules(params) {
        try {
            return await api.get('Regra', { ambiente: params.environment });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar buscar regras!' });
        }
    },
    async associationsContent(params) {
        try {
            return await api.put(`${params.challengeId}/UpdateDesafioCursosFormacoes/${params.type}`, params.contentIds);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao associar conteúdos ao desafio!' });
        }
    }
};
export default api;
