import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["checked", "disabled"];
const _hoisted_2 = {
    key: 0,
    class: "ml-2"
};
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeSwitch',
    props: {
        size: {},
        modelValue: { type: [Boolean, Number] },
        disabled: { type: Boolean },
        showText: { type: Boolean },
        activeText: {},
        inactiveText: {},
        beforeChange: {},
        activeColor: {}
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        _useCssVars(_ctx => ({
            "b527a44a": (color.value)
        }));
        const props = __props;
        const emit = __emit;
        const color = computed(() => props.activeColor || '#3B82F6');
        const onChange = () => {
            if (typeof props.beforeChange === 'function') {
                Promise.resolve(props.beforeChange())
                    .then((result) => {
                    if (result)
                        emit('update:modelValue', !props.modelValue);
                })
                    .catch(() => { });
            }
            else {
                emit('update:modelValue', !props.modelValue);
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("label", {
                class: _normalizeClass([{ active: _ctx.modelValue, 'cursor-pointer': !_ctx.disabled, size: _ctx.size }, "inline-flex items-center align-middle text-inherit"])
            }, [
                _createElementVNode("input", {
                    type: "checkbox",
                    checked: !!_ctx.modelValue,
                    disabled: _ctx.disabled,
                    onChange: _cache[0] || (_cache[0] = ($event) => (onChange())),
                    class: "hidden"
                }, null, 40, _hoisted_1),
                _createElementVNode("span", {
                    class: _normalizeClass(["toggle__switch", { '--small': _ctx.size === 'small' }])
                }, null, 2),
                (_ctx.showText)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.modelValue ? _ctx.activeText || _ctx.$t('labels.active') : _ctx.inactiveText || _ctx.$t('labels.inactive')), 1))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
