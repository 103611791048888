import { toast } from '@/common/util';
import baseApi from './base.api';
const api = {
    ...baseApi,
    resource: 'CertificadoExterno',
    async sendReplyRequest(objData, approved) {
        try {
            return await api.put(`AprovarCertificadoExterno/${approved}`, {
                id: objData.id,
                tipoJustificativa: objData.justification,
                descricaoJustificativa: objData.reason
            });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar responder solicitação certicado externo!' });
        }
    },
    async getAdminList() {
        try {
            return await api.get('admin');
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar listar certificados externos !' });
        }
    },
    async getByUserList(userId) {
        try {
            return await api.get('', {
                usuarioId: userId
            });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar listar certificados externos!' });
        }
    }
};
export default api;
