import { toast } from '@/common/util';
import baseApi from './base.api';
export default {
    ...baseApi,
    resource: 'NotificacaoAutomatizada',
    async sendTestEmail(props) {
        try {
            return await this.post('SendTestEmail', {
                email: props.email,
                body: props.template,
                assunto: props.subject,
                evento: props.eventType
            });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar enviar um e-mail de teste' });
        }
    },
    async executePerTenant(notificationId) {
        try {
            return await this.post(`${notificationId}/ExecutePerTenant`);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar executar o fluxo de notificação' });
        }
    }
};
