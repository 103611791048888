import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { class: "flex-1 text-sm" };
const _hoisted_3 = {
    key: 0,
    class: "mb-2 font-bold"
};
const _hoisted_4 = { key: 0 };
import { computed, onMounted, ref } from 'vue';
import UiIcon from './icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'alert',
    props: {
        modelValue: {},
        type: { default: 'default' },
        icon: { type: [Boolean, String] },
        title: {},
        text: {},
        duration: {},
        closable: { type: Boolean }
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const isOpen = ref(true);
        const emit = __emit;
        const alertTypes = ['info', 'success', 'warning', 'danger'];
        const alertIcons = {
            info: 'info-circle',
            success: 'circle-check',
            warning: 'alert-triangle',
            danger: 'circle-x'
        };
        const currentType = computed(() => alertTypes.find((el) => el === props.type) || null);
        const alertIcon = computed(() => typeof props.icon === 'string' ? props.icon : (alertIcons[currentType.value] || null));
        const showAlert = computed(() => props.modelValue || (typeof props.modelValue === 'undefined' && isOpen.value));
        const hide = () => {
            if (typeof props.modelValue !== 'undefined')
                emit('update:modelValue', false);
            else
                isOpen.value = false;
        };
        onMounted(() => {
            if (props.duration)
                setTimeout(() => hide(), props.duration);
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_Transition, {
                mode: "out-in",
                "enter-active-class": "transition ease-out duration-150 transform",
                "enter-from-class": "opacity-0 -translate-y-4",
                "leave-active-class": "ease-in duration-200",
                "leave-to-class": "opacity-0 translate-y-4"
            }, {
                default: _withCtx(() => [
                    (showAlert.value)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["ui-alert", currentType.value ? `alert-${currentType.value}` : ''])
                        }, [
                            (props.closable)
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 0,
                                    class: "order-last self-start",
                                    onClick: hide
                                }, [
                                    _createVNode(UiIcon, { icon: "x" })
                                ]))
                                : _createCommentVNode("", true),
                            _renderSlot(_ctx.$slots, "icon", {}, () => [
                                (alertIcon.value)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                        _createVNode(UiIcon, {
                                            size: "30",
                                            "stroke-width": 1.8,
                                            icon: alertIcon.value
                                        }, null, 8, ["icon"])
                                    ]))
                                    : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_2, [
                                _renderSlot(_ctx.$slots, "title", {}, () => [
                                    (_ctx.title)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1))
                                        : _createCommentVNode("", true)
                                ]),
                                _renderSlot(_ctx.$slots, "default", {}, () => [
                                    (_ctx.text)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.text), 1))
                                        : _createCommentVNode("", true)
                                ])
                            ])
                        ], 2))
                        : _createCommentVNode("", true)
                ]),
                _: 3
            }));
        };
    }
});
