import { plantarApi } from '@/config/appAPIs';
const api = {
    resource: '',
    request: plantarApi,
    setResource(resource) {
        this.resource = resource;
        return this;
    },
    setApiVersion(version) {
        const { plantar } = window.configuration || { plantar: {} };
        this.request.defaults.baseURL = [plantar.host, `api/v${version || 1}`].join('/');
        return this;
    },
    getPath(resourceId) {
        return resourceId ? [this.resource, resourceId].filter(r => r).join('/') : this.resource;
    },
    async get(resourceId, params, options) {
        return await plantarApi.get(this.getPath(resourceId), {
            ...options,
            params,
            paramsSerializer: {
                indexes: null
            }
        });
    },
    async post(resourceId, data, options, isMultipart) {
        const headers = {};
        if (isMultipart)
            headers['Content-Type'] = 'multipart/form-data';
        return await plantarApi.post(this.getPath(resourceId), data, {
            headers,
            ...options
        });
    },
    async put(resourceId, data, options, isMultipart) {
        const headers = {};
        if (isMultipart)
            headers['Content-Type'] = 'multipart/form-data';
        return await plantarApi.put(this.getPath(resourceId), data, {
            headers,
            ...options
        });
    },
    async delete(resourceId, data) {
        return await plantarApi.delete(this.getPath(resourceId), { data });
    }
};
export default api;
