import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { dialog } from '@/common/util';
import termosDeUsoService from '@/services/termosDeUso.service';
import { termsService } from '@/services/termosDeUso.service';
export const useTermoDeUsoStore = defineStore('termosDeUso', () => {
    const { t } = useI18n();
    const status = ref(0); // -1 erro de exceção; 0 - inicial; 1 - carregando; 2 - sucesso; 3 - erro não fatal
    const loading = computed(() => status.value === 1);
    let listV2 = reactive({
        status: 'initial',
        time: 0,
        data: [],
        uc: 0 // update counter
    });
    /**
     * Adquire a lista de termos, atualizando novamente ao fim do tempo de vida
     * Suporte a filtros
     * @param opt Opções de filtro e tempo de vida
     * @returns A lista de termos de uso
     */
    const lazyList = computed(() => {
        return (opt) => {
            if (listV2.status === 'loading')
                return listV2.data;
            const ttl = opt?.ttl || (1000 * 60 * 5); //
            if (listV2.time === 0 || (new Date().getTime() - listV2.time) > ttl) {
                getAll();
                listV2.time = new Date().getTime();
            }
            if (!opt?.filter)
                return listV2.data;
            return listV2.data.filter(opt.filter);
        };
    });
    const create = async (dataObj) => {
        status.value = 1;
        try {
            const { data, success } = await termosDeUsoService.create(dataObj);
            if (success)
                getAll();
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const update = async (dataObj, id) => {
        status.value = 1;
        try {
            const { data, success } = await termosDeUsoService.update(id, dataObj);
            if (success) {
                const value = listV2.data.find(el => el.id === data.id);
                if (value)
                    Object.assign(value, data);
            }
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const getAll = () => {
        listV2.status = 'loading';
        return termsService.getAll()
            .then(({ success, data }) => {
            listV2.data = [...data];
            if (!success) {
                listV2.status = 'error';
                return;
            }
            listV2.status = 'success';
            return data;
        })
            .catch(() => {
            listV2.status = 'error';
        });
    };
    const remove = async (termoDeUso, force) => {
        if (!force) {
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.termOfUse').toLowerCase(), name: termoDeUso.nome }),
                complete: () => remove(termoDeUso, true)
            });
        }
        try {
            status.value = 1;
            const { success } = await termosDeUsoService.remove(termoDeUso.id);
            if (success)
                getAll();
            status.value = 2;
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    const getById = async (id) => {
        status.value = 1;
        try {
            const { data } = await termosDeUsoService.get(id);
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const exportTermosDeUso = async () => {
        status.value = 1;
        try {
            const csvFile = await termosDeUsoService.exportCsv();
            return csvFile;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    return {
        list: computed(() => listV2.data || []),
        status,
        loading,
        // getters
        lazyList,
        // actions
        create,
        update,
        getAll,
        remove,
        getById,
        exportTermosDeUso
    };
});
export default useTermoDeUsoStore;
