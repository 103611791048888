import { toast } from '@/common/util';
import baseApi from './base.api';
export const segmentacoesService = {
    ...baseApi,
    resource: 'Segmentacao',
    getSegmentationUsers(segmentationId) {
        try {
            return this.get(`${segmentationId}/Users`);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao buscar usuários da segmentação!' });
        }
    },
    async getAll(opt, options) {
        const paramsDictionary = {
            id: 'id',
            active: 'ativo',
            name: 'nome',
        };
        const paramsToSend = {};
        if (opt) {
            Object.keys(opt).forEach((key) => {
                if (typeof paramsDictionary[key] !== 'undefined' && typeof opt[key] !== 'undefined' && opt[key] !== null)
                    paramsToSend[paramsDictionary[key]] = opt[key];
            });
        }
        return segmentacoesService.get('', paramsToSend, options)
            .then((res) => ({
            status: res.success,
            data: res.data?.map((item) => ({
                id: item.id || null,
                name: item.nome,
                active: item.active,
                observation: item.observacao,
                jobSegmentations: item.segmentacoesCargos,
                departmentSegmentations: item.segmentacoesDepartamentos,
                businessUnitSegmentations: item.segmentacoesUnidadesDeNegocios,
                genderSegmentations: item.segmentacoesGeneros,
                segmentationSections: item.secoesSegmentacoes,
                automatedSegmentationNotifications: item.notificacoesAutomatizadaSegmentacoes,
                userSegmentations: item.segmentacoesUsuarios,
                operator: item.operador,
                segmentationChallenges: item.segmentacoesDesafios,
            })),
        }));
    }
};
export default segmentacoesService;
