import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "relative bg-neutral-50 dark:bg-neutral-800 w-full shadow-md" };
const _hoisted_2 = { class: "flex flex-wrap gap-2 items-center justify-center py-2 px-2 md:px-6" };
const _hoisted_3 = {
    key: 0,
    class: "flex items-center justify-center"
};
const _hoisted_4 = { class: "hidden md:inline-block" };
const _hoisted_5 = { class: "ml-1" };
const _hoisted_6 = { class: "ml-4 flex items-center gap-2" };
const _hoisted_7 = ["max"];
const _hoisted_8 = {
    key: 1,
    class: "flex gap-2"
};
const _hoisted_9 = ["data-tip"];
const _hoisted_10 = ["data-tip"];
const _hoisted_11 = ["data-tip"];
const _hoisted_12 = ["data-tip"];
import { computed } from 'vue';
import { clamp } from '@/common/util';
import UiButton from '@/components/ui/button.vue';
import UiIcon from '@/components/ui/icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'toolbar',
    props: {
        modelValue: {},
        pageCount: {}
    },
    emits: ['update:modelValue'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const fPage = computed({
            get: () => props.modelValue || 0,
            set: (v) => emit('update:modelValue', clamp(v, 1, props.pageCount))
        });
        const pageProgress = computed(() => {
            if (!props.pageCount)
                return 0;
            return ((props.modelValue - 1) * 100) / (props.pageCount - 1);
        });
        __expose({
            lastPage: () => fPage.value = props.pageCount,
            firstPage: () => fPage.value = 1,
            previousPage: () => fPage.value !== 1 && (fPage.value--),
            nextPage: () => fPage.value !== props.pageCount && (fPage.value++),
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    (_ctx.pageCount > 1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('labels.page', 2)), 1),
                            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "md:hidden" }, "Pág.", -1)),
                            _createElementVNode("span", _hoisted_5, _toDisplayString(fPage.value) + "/" + _toDisplayString(_ctx.pageCount || 0), 1),
                            _createElementVNode("div", _hoisted_6, [
                                _createTextVNode(_toDisplayString(_ctx.$t('labels.goTo')) + ": ", 1),
                                _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((fPage).value = $event)),
                                    type: "number",
                                    min: "1",
                                    max: _ctx.pageCount || 1,
                                    class: "w-16 p-2 rounded-lg border border-solid text-neutral-800 dark:text-neutral-200 bg-neutral-100 dark:bg-neutral-700 border-neutral-300 dark:border-neutral-600"
                                }, null, 8, _hoisted_7), [
                                    [_vModelText, fPage.value]
                                ])
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "hidden md:block grow" }, null, -1)),
                    _renderSlot(_ctx.$slots, "actions"),
                    (_ctx.pageCount > 1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("div", {
                                class: "tooltip tooltip-bottom",
                                "data-tip": _ctx.$t('button.firstPage', { text: '(Ctrl + ←)' })
                            }, [
                                _createVNode(UiButton, {
                                    type: "ghost",
                                    class: "p-2 disabled:!bg-transparent",
                                    disabled: fPage.value === 1,
                                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event) => (fPage.value = 1), ["stop", "prevent"]))
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(UiIcon, { icon: "arrow-bar-to-left" })
                                    ]),
                                    _: 1
                                }, 8, ["disabled"])
                            ], 8, _hoisted_9),
                            _createElementVNode("div", {
                                class: "tooltip tooltip-bottom",
                                "data-tip": _ctx.$t('labels.previousPage', { text: _ctx.$t('button.press', { text: '←' }) })
                            }, [
                                _createVNode(UiButton, {
                                    type: "ghost",
                                    class: "p-2 disabled:!bg-transparent",
                                    disabled: fPage.value === 1,
                                    onClick: _cache[2] || (_cache[2] = _withModifiers(($event) => (fPage.value--), ["stop", "prevent"]))
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(UiIcon, { icon: "arrow-left" })
                                    ]),
                                    _: 1
                                }, 8, ["disabled"])
                            ], 8, _hoisted_10),
                            _createElementVNode("div", {
                                class: "tooltip tooltip-bottom",
                                "data-tip": _ctx.$t('button.nextPage', { text: _ctx.$t('button.press', { text: '→' }) })
                            }, [
                                _createVNode(UiButton, {
                                    type: "ghost",
                                    class: "p-2 disabled:!bg-transparent",
                                    disabled: fPage.value === _ctx.pageCount,
                                    onClick: _cache[3] || (_cache[3] = _withModifiers(($event) => (fPage.value++), ["stop", "prevent"]))
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(UiIcon, { icon: "arrow-right" })
                                    ]),
                                    _: 1
                                }, 8, ["disabled"])
                            ], 8, _hoisted_11),
                            _createElementVNode("div", {
                                class: "tooltip tooltip-bottom",
                                "data-tip": _ctx.$t('button.lastPage', { text: '(Ctrl + →)' })
                            }, [
                                _createVNode(UiButton, {
                                    type: "ghost",
                                    class: "p-2 disabled:!bg-transparent",
                                    disabled: fPage.value === _ctx.pageCount,
                                    onClick: _cache[4] || (_cache[4] = _withModifiers(($event) => (fPage.value = _ctx.pageCount), ["stop", "prevent"]))
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(UiIcon, { icon: "arrow-bar-to-right" })
                                    ]),
                                    _: 1
                                }, 8, ["disabled"])
                            ], 8, _hoisted_12)
                        ]))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", null, [
                    _createElementVNode("div", {
                        class: "absolute bottom-0 left-0 h-0.5 block bg-[--c-primary-400] transition-all",
                        style: _normalizeStyle({ width: `${pageProgress.value}%` })
                    }, null, 4)
                ])
            ]));
        };
    }
});
