import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col h-full overflow-auto box-border text-neutral-900 dark:text-neutral-400 w-full" };
const _hoisted_2 = { class: "mb-8" };
const _hoisted_3 = { class: "mb-8 flex items-center gap-3" };
const _hoisted_4 = { class: "flex flex-row h-full gap-6 relative" };
const _hoisted_5 = { class: "w-1/2 h-full box-border" };
const _hoisted_6 = ["title"];
const _hoisted_7 = { class: "flex flex-col items-start box-border h-full rounded-xl border border-solid border-neutral-300 dark:border-neutral-500 bg-slate-200 dark:bg-neutral-800 dark:text-neutral-100" };
const _hoisted_8 = { class: "p-4 w-full box-border" };
const _hoisted_9 = { class: "mb-4" };
const _hoisted_10 = { class: "p-4 w-full h-full box-border flex flex-col gap-2 overflow-y-auto" };
const _hoisted_11 = { class: "p-2 grow" };
const _hoisted_12 = ["onClick"];
const _hoisted_13 = ["onMousedown"];
const _hoisted_14 = { class: "w-1/2 h-full box-border" };
const _hoisted_15 = ["title"];
const _hoisted_16 = { class: "flex flex-col items-start box-border h-full rounded-xl border border-solid border-neutral-200 bg-neutral-100 dark:bg-neutral-800 dark:border-neutral-500" };
const _hoisted_17 = { class: "p-4 w-full box-border" };
const _hoisted_18 = { class: "p-2" };
const _hoisted_19 = ["onClick"];
const _hoisted_20 = { class: "h-full max-h-full border-1 border-dashed border-slate-300 flex flex-col items-center justify-center" };
const _hoisted_21 = { class: "text-center text-slate-400 dark:" };
const _hoisted_22 = { class: "py-4 grow mt-6 flex justify-end items-end space-x-2" };
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { UseMousePressed, UseMouseInElement } from '@vueuse/components';
import { useEventListener } from '@vueuse/core';
import { utils, writeFileXLSX } from 'xlsx';
import UiButton from '@/components/ui/button.vue';
import UiSwitch from '@/components/ui/switch.vue';
import UiInput from '@/components/ui/input.vue';
import UiIcon from '@/components/ui/icon.vue';
import { formatter } from '@/common/util';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeDataExporter',
    props: {
        modelValue: { type: Boolean },
        cols: {},
        tableData: {},
        allData: {},
        defaultCols: {},
        filters: {}
    },
    emits: ['update:modelValue'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const props = __props;
        const { t } = useI18n();
        const route = useRoute();
        const lOpen = ref(false);
        const emit = __emit;
        const isOpen = computed({
            get: () => props.modelValue || lOpen.value,
            set: (val) => {
                emit('update:modelValue', val);
                lOpen.value = val;
            }
        });
        const filterSelected = computed(() => props.filters || {});
        watch(isOpen, (value) => value && setDefaultColumns());
        const searchTerm = ref('');
        const toExport = ref([]);
        const exportAll = ref(true);
        const leftCols = computed(() => props.cols.filter((c) => !toExport.value.map((exp) => exp.prop).includes(c.prop)).filter(c => c.name.toLocaleLowerCase().indexOf(searchTerm.value.toLocaleLowerCase()) > -1));
        const titlePag = computed(() => route.meta?.title);
        const hasFilter = computed(() => Object.values(filterSelected).some((el) => typeof el === 'boolean' ? true : el?.length));
        const dragColItem = ref({
            status: 0,
            item: {
                prop: '',
                name: ''
            }
        });
        const getFormatter = {
            'active-indicator': (value) => (value ? t('labels.active') : t('labels.inactive')),
            'type-people': formatter.typePeople,
            'format-cpfcnpj': formatter.cpfCnpj,
            'format-date': formatter.date,
            'format-status': (value) => (value ? t('labels.active') : t('labels.inactive')),
            'format-boolean': (value) => (value ? t('labels.yes') : t('labels.no')),
            'status-percentage': (value) => (value === 0 ? 'Inscrito' : `${value}% concluído`),
            'type-classificacao-cliente': formatter.typeClassificacaoCliente,
            'type-produto-aliare': formatter.typeProdutoAliare
        };
        const setDefaultColumns = () => {
            // Função que insere colunas já exibidas na tela para exportar
            // Como a coluna id não é exibida é necessário a concatenação
            const columnsData = props.defaultCols.map((el) => el.prop);
            toExport.value = [{ name: t('column.identifier'), prop: 'id' }].concat(props.cols.filter((el) => columnsData.includes(el.prop)));
        };
        const startDragging = (col) => {
            dragColItem.value.status = 1;
            dragColItem.value.item = col;
        };
        const onRelease = (isValid) => {
            if (dragColItem.value.item.prop && isValid)
                toExport.value.push(dragColItem.value.item);
            dragColItem.value.status = 0;
            dragColItem.value.item = {
                prop: '',
                name: ''
            };
        };
        const addAll = () => {
            toExport.value.splice(0, toExport.value.length);
            props.cols.forEach(col => {
                toExport.value.push(col);
            });
        };
        const exportXlsx = (data) => {
            const date = new Date().toLocaleDateString('pt-br').split('/').join('');
            const time = new Date().toLocaleTimeString('pt-br').split(':').join('');
            const fileName = `${t(titlePag.value)}_${date}_${time}.xlsx`;
            const wb = utils.book_new();
            utils.book_append_sheet(wb, utils.json_to_sheet(data), 'Dados Distribuidor');
            writeFileXLSX(wb, fileName);
        };
        const exportData = () => {
            const rows = exportAll.value ? props.allData : props.tableData;
            const exportkeys = toExport.value.map((el) => el.prop);
            const headers = {};
            const filters = [];
            toExport.value.forEach((el, i) => {
                headers[el.prop] = {
                    name: el.name,
                    order: i,
                    transform: getFormatter[el.transform] || null
                };
                if (hasFilter.value && filterSelected.value?.[el.prop])
                    filters.push(el.name);
            });
            const data = (rows || []).map((row) => {
                return Object.keys(row)
                    .filter((key) => exportkeys.includes(key))
                    .sort((a, b) => headers[a].order < headers[b].order ? -1 : 1)
                    .reduce((obj, key) => {
                    obj[`${headers[key].name}`] = headers[key].transform ? headers[key].transform(row[key]) : row[key];
                    return obj;
                }, {});
            });
            filters.map((el, i) => {
                data[i]
                    ? data[i][t('labels.filter', 2)] = el
                    : data.push({ [t('labels.filter', 2)]: el });
            });
            exportXlsx(data);
        };
        useEventListener('mouseup', () => {
            onRelease();
        });
        __expose({
            toggle: () => isOpen.value = !isOpen.value,
            open: () => isOpen.value = true,
            close: () => isOpen.value = false
        });
        return (_ctx, _cache) => {
            const _component_pe_tooltip = _resolveComponent("pe-tooltip");
            const _component_pe_icon = _resolveComponent("pe-icon");
            const _component_pe_drawer = _resolveComponent("pe-drawer");
            return (_openBlock(), _createBlock(_component_pe_drawer, {
                modelValue: isOpen.value,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => ((isOpen).value = $event)),
                title: `${_ctx.$t('button.export')} ${_unref(t)(`${titlePag.value}`) || _ctx.$t('labels.table')}`,
                width: 580
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "mt-2 mb-4 border-0 border-b border-solid border-b-neutral-300" }, null, -1)),
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('mainContent.export.help')), 1),
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("div", null, [
                                _createTextVNode(_toDisplayString(_ctx.$t('mainContent.export.exportAll')) + " ", 1),
                                _createVNode(_component_pe_tooltip, {
                                    text: _ctx.$t('tooltips.export'),
                                    icon: ""
                                }, null, 8, ["text"])
                            ]),
                            _createElementVNode("label", null, [
                                _createVNode(UiSwitch, {
                                    size: "small",
                                    modelValue: exportAll.value,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((exportAll).value = $event))
                                }, null, 8, ["modelValue"]),
                                _createTextVNode(" " + _toDisplayString(exportAll.value ? _unref(t)('labels.yes') : _unref(t)('labels.no')), 1)
                            ])
                        ]),
                        _createVNode(_unref(UseMouseInElement), { class: "h-full" }, {
                            default: _withCtx(({ elementX, elementY }) => [
                                _createElementVNode("div", _hoisted_4, [
                                    _createElementVNode("div", _hoisted_5, [
                                        _createElementVNode("div", {
                                            title: `${_unref(t)('mainContent.export.availableColumns')} ${leftCols.value.length}`,
                                            class: "mb-2 flex justify-between truncate"
                                        }, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('mainContent.export.availableColumns')) + " ", 1),
                                            _createElementVNode("span", null, _toDisplayString(leftCols.value.length), 1)
                                        ], 8, _hoisted_6),
                                        _createElementVNode("div", _hoisted_7, [
                                            _createElementVNode("div", _hoisted_8, [
                                                _createElementVNode("div", _hoisted_9, [
                                                    _createVNode(UiInput, {
                                                        modelValue: searchTerm.value,
                                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((searchTerm).value = $event)),
                                                        size: "medium",
                                                        placeholder: _ctx.$t('input.search'),
                                                        class: "w-full"
                                                    }, {
                                                        suffix: _withCtx(() => [
                                                            _createVNode(UiIcon, {
                                                                icon: "search",
                                                                class: "text-neutral-500",
                                                                size: "20"
                                                            })
                                                        ]),
                                                        _: 1
                                                    }, 8, ["modelValue", "placeholder"])
                                                ]),
                                                _createElementVNode("div", {
                                                    class: "font-medium text-left cursor-pointer text-slate-500 dark:text-slate-100",
                                                    onClick: _cache[2] || (_cache[2] = ($event) => (addAll()))
                                                }, [
                                                    _createVNode(_component_pe_icon, {
                                                        icon: "content_paste_go",
                                                        size: 24
                                                    }),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.$t('mainContent.export.moveColumns')), 1)
                                                ])
                                            ]),
                                            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "w-full border-0 border-b border-solid border-b-neutral-300 dark:border-b-neutral-500" }, null, -1)),
                                            _createElementVNode("div", _hoisted_10, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(leftCols.value, (col) => {
                                                    return (_openBlock(), _createElementBlock("div", {
                                                        key: col.prop
                                                    }, [
                                                        _createVNode(_unref(UseMousePressed), null, {
                                                            default: _withCtx(({ pressed }) => [
                                                                (_openBlock(), _createElementBlock("div", {
                                                                    key: col.prop,
                                                                    class: _normalizeClass(["w-full max-w-[220px] bg-white box-border flex justify-between items-center rounded dark:bg-neutral-600 dark:text-neutral-100", { 'absolute pointer-events-none opacity-80 -translate-x-[200px]': pressed && dragColItem.value.item.prop }]),
                                                                    style: _normalizeStyle(pressed && dragColItem.value.item.prop ? `left: ${elementX}px; top: ${elementY}px` : '')
                                                                }, [
                                                                    _createElementVNode("div", _hoisted_11, _toDisplayString(col.name), 1),
                                                                    _createElementVNode("div", {
                                                                        class: "h-full w-2/12 box-border grid place-items-center border-0 border-l border-solid border-l-slate-300 cursor-move",
                                                                        onClick: _withModifiers(($event) => (toExport.value.push(col)), ["stop"])
                                                                    }, [
                                                                        _createVNode(_component_pe_icon, {
                                                                            weight: 300,
                                                                            size: 20,
                                                                            icon: "arrow_right_alt",
                                                                            class: "text-slate-500 dark:text-neutral-100"
                                                                        })
                                                                    ], 8, _hoisted_12),
                                                                    _createElementVNode("div", {
                                                                        class: "h-full w-2/12 box-border grid place-items-center border-0 border-l border-solid border-l-slate-300 cursor-move",
                                                                        onMousedown: _withModifiers(($event) => (startDragging(col)), ["prevent"])
                                                                    }, [
                                                                        _createVNode(_component_pe_icon, {
                                                                            size: 24,
                                                                            icon: "drag_indicator",
                                                                            class: "text-slate-500 dark:text-neutral-100"
                                                                        })
                                                                    ], 40, _hoisted_13)
                                                                ], 6))
                                                            ]),
                                                            _: 2
                                                        }, 1024)
                                                    ]));
                                                }), 128))
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", _hoisted_14, [
                                        _createElementVNode("div", {
                                            title: `${_ctx.$t('mainContent.export.columnsExported')} ${toExport.value.length}`,
                                            class: "mb-2 flex justify-between truncate"
                                        }, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('mainContent.export.columnsExported')) + " ", 1),
                                            _createElementVNode("span", null, _toDisplayString(toExport.value.length), 1)
                                        ], 8, _hoisted_15),
                                        _createElementVNode("div", _hoisted_16, [
                                            _createElementVNode("div", _hoisted_17, [
                                                _createElementVNode("div", {
                                                    class: "font-medium text-center cursor-pointer text-slate-500 dark:text-slate-100",
                                                    onClick: _cache[3] || (_cache[3] = () => toExport.value.splice(0, toExport.value.length))
                                                }, [
                                                    _createVNode(_component_pe_icon, {
                                                        icon: "delete",
                                                        size: 24
                                                    }),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.$t('mainContent.export.removeColumns')), 1)
                                                ])
                                            ]),
                                            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "w-full border-0 border-b border-solid border-b-neutral-300 dark:border-b-neutral-500" }, null, -1)),
                                            _createElementVNode("div", {
                                                class: "p-4 w-full h-full box-border flex flex-col gap-2 overflow-y-auto",
                                                onMouseup: _cache[4] || (_cache[4] = ($event) => (onRelease(true)))
                                            }, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(toExport.value, (col, index) => {
                                                    return (_openBlock(), _createElementBlock("div", {
                                                        key: index,
                                                        class: "w-full bg-white box-border flex justify-between items-center rounded dark:bg-neutral-600 dark:text-neutral-100"
                                                    }, [
                                                        _createElementVNode("div", _hoisted_18, _toDisplayString(col.name), 1),
                                                        _createElementVNode("div", {
                                                            class: "h-full w-2/12 box-border grid place-items-center border-0 border-l border-solid border-l-slate-300 cursor-move",
                                                            onClick: ($event) => (toExport.value.splice(index, 1))
                                                        }, [
                                                            _createVNode(_component_pe_icon, {
                                                                weight: 300,
                                                                size: 20,
                                                                icon: "arrow_right_alt",
                                                                class: "text-slate-500 rotate-180 dark:text-neutral-100"
                                                            })
                                                        ], 8, _hoisted_19)
                                                    ]));
                                                }), 128)),
                                                _createElementVNode("div", _hoisted_20, [
                                                    _createElementVNode("div", _hoisted_21, [
                                                        _createVNode(_component_pe_icon, {
                                                            icon: "place_item",
                                                            weight: 200,
                                                            size: 36,
                                                            class: "block mb-2"
                                                        }),
                                                        _createElementVNode("div", null, _toDisplayString(_ctx.$t('mainContent.export.dragColumns')), 1)
                                                    ])
                                                ])
                                            ], 32)
                                        ])
                                    ])
                                ])
                            ]),
                            _: 1
                        }),
                        _createElementVNode("div", _hoisted_22, [
                            _createVNode(UiButton, {
                                type: "danger",
                                outline: "",
                                class: "px-6 py-3 text-sm uppercase",
                                onClick: _cache[5] || (_cache[5] = ($event) => (isOpen.value = false))
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('button.cancel')), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(UiButton, {
                                type: "primary",
                                class: "px-6 py-3 text-sm uppercase",
                                disabled: !toExport.value.length,
                                onClick: _cache[6] || (_cache[6] = ($event) => (exportData()))
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('button.export')), 1)
                                ]),
                                _: 1
                            }, 8, ["disabled"])
                        ])
                    ])
                ]),
                _: 1
            }, 8, ["modelValue", "title"]));
        };
    }
});
