import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition, createVNode as _createVNode, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "m-0 p-0"
};
import { computed, ref, nextTick, watchEffect } from 'vue';
import useValidate from 'vue-tiny-validate';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeForm',
    props: {
        formData: {},
        rules: {},
        autoValidate: { type: Boolean },
        showAlert: { type: Boolean },
        listErrors: { type: Boolean },
        noToast: { type: Boolean }
    },
    setup(__props, { expose: __expose }) {
        const props = __props;
        const options = ref({
            autoTest: false
        });
        watchEffect(() => {
            if (props.autoValidate)
                options.value.autoTest = props.autoValidate;
        });
        const { result } = useValidate(props.formData, props.rules, options);
        const silent = ref(false);
        const validate = () => {
            result.value.$test();
            return !result.value.$invalid;
        };
        __expose({
            validate,
            resetValidate: () => {
                silent.value = true;
                result.value.$reset();
                nextTick(() => silent.value = false);
            },
            invalid: computed(() => result.value.$invalid),
            getErrors: (prop) => (prop ? result.value[prop].$messages : result.value.$messages),
            getCurrentError: (prop) => (prop ? result.value[prop]?.$messages[0] : result.value?.$messages[0]) || '',
            hasError: (prop) => (prop ? result.value[prop].$invalid : result.value.$invalid)
        });
        return (_ctx, _cache) => {
            const _component_pe_alert = _resolveComponent("pe-alert");
            return (_openBlock(), _createElementBlock("div", null, [
                _createVNode(_Transition, {
                    "enter-active-class": "duration-300",
                    "enter-from-class": "opacity-0",
                    "enter-to-class": "opacity-100",
                    "leave-active-class": "duration-300",
                    "leave-from-class": "opacity-100",
                    "leave-to-class": "opacity-0"
                }, {
                    default: _withCtx(() => [
                        (_ctx.showAlert && _unref(result).$invalid)
                            ? (_openBlock(), _createBlock(_component_pe_alert, {
                                key: 0,
                                type: "danger",
                                unclosable: ""
                            }, {
                                default: _withCtx(() => [
                                    _cache[0] || (_cache[0] = _createTextVNode(" Confira o(s) erro(s) no formulário ")),
                                    (_ctx.listErrors && _unref(result).$messages.length > 0)
                                        ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(result).$messages, (msg, index) => {
                                                return (_openBlock(), _createElementBlock("li", {
                                                    key: index,
                                                    class: "ml-6 list-disc"
                                                }, _toDisplayString(msg), 1));
                                            }), 128))
                                        ]))
                                        : _createCommentVNode("", true)
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }),
                _renderSlot(_ctx.$slots, "default")
            ]));
        };
    }
});
