import inscricaoAutomatizadaService from '@/services/inscricaoAutomatizada.service';
import { defineStore } from 'pinia';
import { computed, reactive } from 'vue';
import useCargosStore from './cargosStore';
import useUnidadesDeNegocioStore from './unidadesDeNegocioStore';
export const useInscricaoAutomatizadaStore = defineStore('inscricaoAutomatizada', () => {
    const cargoStore = useCargosStore();
    const unidadesDeNegocioStore = useUnidadesDeNegocioStore();
    const status = reactive({
        create: 'initial',
        update: 'initial',
        get: 'initial',
    });
    const loading = computed(() => Object.values(status).some(el => el === 'loading'));
    const create = async (dataObj) => {
        status.create = 'loading';
        try {
            const { data, success } = await inscricaoAutomatizadaService.create({
                id: dataObj.id,
                cargoId: dataObj.cargoId,
                unidadeDeNegocioId: dataObj.unidadeDeNegocioId,
                tipo: dataObj.type?.toLowerCase() === 'cargo' ? 'Cargo' : 'Unidade',
                itens: dataObj.itens.map((el) => el.tipo?.toLowerCase() === 'curso' ? { cursoId: el.id } : { formacaoId: el.id }),
                dataInscricao: dataObj.initDate || null,
                notificarViaEmail: dataObj.notificarViaEmail
            });
            const cargo = cargoStore.list.find((el) => el.id === dataObj.cargoId);
            const unidadeDeNegocio = unidadesDeNegocioStore.linearList.find((el) => el.id === dataObj.unidadeDeNegocioId);
            cargo && Object.assign(cargo, { ...cargo, inscricaoAutomatizadaId: data ? data.id : null });
            unidadeDeNegocio && Object.assign(unidadeDeNegocio, { ...unidadeDeNegocio, inscricaoAutomatizadaId: data ? data.id : null });
            status.create = 'success';
            return success;
        }
        catch {
            status.create = 'error';
        }
    };
    const update = async (dataObj) => {
        status.update = 'loading';
        try {
            const { data, success } = await inscricaoAutomatizadaService.update(null, {
                id: dataObj.id,
                cargoId: dataObj.cargoId,
                unidadeDeNegocioId: dataObj.unidadeDeNegocioId,
                tipo: dataObj.type?.toLowerCase() === 'cargo' ? 'Cargo' : 'Unidade',
                itens: dataObj.itens.map((el) => el.tipo?.toLowerCase() === 'curso'
                    ? el.cursoId
                        ? { cursoId: el.cursoId, id: el.id }
                        : { cursoId: el.id }
                    : el.formacaoId
                        ? { formacaoId: el.formacaoId, id: el.id }
                        : { formacaoId: el.id }),
                dataInscricao: dataObj.initDate || null,
                notificarViaEmail: dataObj.notificarViaEmail
            });
            const cargo = cargoStore.list.find((el) => el.id === dataObj.cargoId);
            cargo && Object.assign(cargo, { ...cargo, inscricaoAutomatizadaId: data.itens.length ? data.id : null });
            const unidadesDeNegocio = unidadesDeNegocioStore.linearList.find((el) => el.id === dataObj.unidadeDeNegocioId);
            unidadesDeNegocio && Object.assign(unidadesDeNegocio, { ...unidadesDeNegocio, inscricaoAutomatizadaId: data.itens.length ? data.id : null });
            status.update = 'success';
            return success;
        }
        catch {
            status.update = 'error';
        }
    };
    const get = async (params) => {
        status.get = 'loading';
        try {
            const { data } = await inscricaoAutomatizadaService.get(null, params);
            status.get = 'success';
            return {
                id: data.id,
                initDate: data.dataInscricao,
                cargoId: data.cargoId,
                unidadeDeNegocioId: data.unidadeDeNegocioId,
                itens: data.itens.map((el) => ({
                    ...el,
                    active: el.ativo,
                    nome: el.titulo
                })),
                notificarViaEmail: data.notificarViaEmail
            };
        }
        catch {
            status.get = 'error';
        }
    };
    return {
        status,
        loading,
        // actions
        create,
        update,
        get
    };
});
export default useInscricaoAutomatizadaStore;
