import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "my-2 inline-flex gap-4" };
const _hoisted_2 = ["name", "value", "disabled"];
import { ref, onMounted, computed } from 'vue';
import { generateUUID } from '@/common/util';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeRadioGroup',
    props: {
        modelValue: {},
        options: {},
        beforeChange: {},
        disabled: { type: Boolean }
    },
    emits: ['update:modelValue'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const radioName = ref('');
        const selected = computed({
            get: () => props.modelValue,
            set: (value) => onChange(value)
        });
        const onChange = (to) => {
            if (typeof props.beforeChange === 'function') {
                Promise.resolve(props.beforeChange(to, selected.value))
                    .then((result) => result && emit('update:modelValue', to))
                    .catch(() => { });
            }
            else {
                emit('update:modelValue', to);
            }
        };
        onMounted(() => {
            radioName.value = `p-rg-${generateUUID()}`;
        });
        __expose({
            selected
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (opt) => {
                    return (_openBlock(), _createElementBlock("label", {
                        key: opt.value,
                        class: _normalizeClass(["pe-radio-button", {
                                '--radio-selected': selected.value === opt.value,
                                'cursor-not-allowed': _ctx.disabled
                            }]),
                        tabindex: "0"
                    }, [
                        _withDirectives(_createElementVNode("input", {
                            type: "radio",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((selected).value = $event)),
                            name: radioName.value,
                            value: opt.value,
                            disabled: _ctx.disabled,
                            class: "hidden"
                        }, null, 8, _hoisted_2), [
                            [_vModelRadio, selected.value]
                        ]),
                        _createElementVNode("span", null, _toDisplayString(opt.label), 1)
                    ], 2));
                }), 128))
            ]));
        };
    }
});
