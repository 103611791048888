import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeProps as _normalizeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue";
import { computed, ref } from 'vue';
import { useFloating, offset, flip, shift, autoUpdate } from '@floating-ui/vue';
export default /*@__PURE__*/ _defineComponent({
    ...{
        inheritAttrs: false
    },
    __name: 'floating',
    props: {
        show: { type: Boolean },
        placement: {},
        source: {},
        maxWidth: {}
    },
    emits: ['closed'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const props = __props;
        const el = ref();
        const emit = __emit;
        const shouldClose = ref(false);
        const visible = computed(() => {
            return props.show && !shouldClose.value;
        });
        let { floatingStyles } = useFloating(ref(props.source), el, {
            // transform: false,
            placement: props.placement || 'bottom-end',
            middleware: [offset(10), flip(), shift()],
            whileElementsMounted: autoUpdate,
            strategy: 'fixed'
        });
        const onDissapear = () => {
            shouldClose.value = false;
            emit('closed');
        };
        __expose({
            close: () => {
                shouldClose.value = true;
            }
        });
        return (_ctx, _cache) => {
            return (props.source)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    ref_key: "el",
                    ref: el,
                    class: "z-[1001]",
                    style: _normalizeStyle({ ..._unref(floatingStyles), maxWidth: _ctx.maxWidth })
                }, [
                    _createVNode(_Transition, {
                        appear: "",
                        "enter-active-class": "ease-out duration-150 transform trasition",
                        "enter-from-class": "opacity-0 transform -translate-y-8 scale-50",
                        "leave-active-class": "ease-in duration-150 transform trasition",
                        "leave-to-class": "opacity-0 transform -translate-y-8",
                        onAfterLeave: onDissapear
                    }, {
                        default: _withCtx(() => [
                            (visible.value)
                                ? (_openBlock(), _createElementBlock("div", _normalizeProps(_mergeProps({ key: 0 }, _ctx.$attrs)), [
                                    _renderSlot(_ctx.$slots, "default")
                                ], 16))
                                : _createCommentVNode("", true)
                        ]),
                        _: 3
                    })
                ], 4))
                : _createCommentVNode("", true);
        };
    }
});
