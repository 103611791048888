import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import CertificadosExternosService from '@/services/certificadosExternos.service';
import { convertTime, convertHHHmm, convertHMS, newDialog, toast } from '@/common/util';
const typeJustification = {
    IncompativelComFuncao: 1,
    CertificadoInvalido: 2,
    CertificadoIlegivel: 3,
    Outro: 4
};
export const useCertificadosExternos = defineStore('CertificadosExternos', () => {
    const { t } = useI18n();
    const list = ref([]);
    const adminList = ref([]);
    const status = reactive({
        getAll: 'initial',
        delete: 'initial',
        getById: 'initial',
        reply: 'initial',
        save: 'initial'
    });
    const loading = computed(() => Object.values(status).some((el) => el === 'loading'));
    const save = async (objData) => {
        status.save = 'loading';
        try {
            let result = null;
            if (!objData?.id)
                result = await CertificadosExternosService.post(null, getFormDataSubmit(objData), null, true);
            else
                result = await CertificadosExternosService.put(null, getFormDataSubmit(objData), null, true);
            if (result?.success && result?.data) {
                toast.success({ message: t(objData?.id ? 'toasts.createSuccess' : 'toasts.updateSuccess') });
                getAll(objData.userId);
                status.save = 'success';
                return true;
            }
        }
        catch (error) {
            //
        }
        status.save = 'error';
        return false;
    };
    const getAll = async (userId) => {
        if (!userId)
            return;
        status.getAll = 'loading';
        try {
            const { data } = await CertificadosExternosService.getByUserList(userId);
            list.value = data.map((el) => getFormatResponse(el));
            status.getAll = 'success';
        }
        catch {
            status.getAll = 'error';
        }
    };
    const getById = async (id) => {
        status.getById = 'loading';
        try {
            const { data } = await CertificadosExternosService.get(id);
            status.getById = 'success';
            return getFormatResponse(data);
        }
        catch {
            status.getById = 'error';
        }
    };
    const remove = async (data, force) => {
        if (!force)
            return newDialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.externalCertificate').toLowerCase(), name: data.name }),
                complete: () => remove(data, true)
            });
        try {
            status.delete = 'loading';
            const { success } = await CertificadosExternosService.delete(data.id);
            if (success) {
                toast.success({ message: t('toasts.removeSuccess') });
                getAll(data.userId);
            }
            status.delete = 'success';
            return success;
        }
        catch {
            status.delete = 'error';
        }
    };
    const sendReplyRequest = async (objData, approved) => {
        status.reply = 'loading';
        try {
            const { success } = await CertificadosExternosService.sendReplyRequest(objData, approved);
            if (success) {
                toast.success({ message: t('toasts.sentSuccess') });
                getAdminList();
            }
            status.reply = 'success';
            return success;
        }
        catch {
            status.reply = 'error';
        }
    };
    const getAdminList = async () => {
        try {
            status.getAll = 'loading';
            const { data, success } = await CertificadosExternosService.getAdminList();
            if (success) {
                adminList.value = data.map((el) => getFormatResponse(el));
                status.getAll = 'success';
            }
        }
        catch (error) {
            status.getAll = 'error';
        }
    };
    const getFormDataSubmit = (objData) => {
        const formData = new FormData();
        objData.id && formData.append('id', objData.id);
        formData.append('usuarioId', objData.userId);
        formData.append('descricao', objData.name);
        formData.append('tipo', objData.type);
        formData.append('outroTipo', objData.descriptionOtherType || '');
        formData.append('instituicao', objData.institution);
        formData.append('cargaHoraria', String(convertTime(`${objData.duration}:00`)));
        formData.append('dataInicio', new Date(objData.initDate).toISOString());
        formData.append('dataFim', new Date(objData.endDate).toISOString());
        formData.append('certificado', objData.certificate);
        formData.append('certificadoUrl', objData.certificateUrl || '');
        formData.append('linkCredencial', objData.credentialUrl || '');
        formData.append('codigoCredencial', objData.credentialCode || '');
        return formData;
    };
    const getFormatResponse = (objData) => {
        return {
            id: objData.id,
            userId: objData.usuarioId,
            name: objData.descricao,
            type: objData.tipo,
            descriptionOtherType: objData.outroTipo,
            institution: objData.instituicao,
            duration: convertHHHmm(convertHMS(objData.cargaHoraria)),
            initDate: objData.dataInicio,
            endDate: objData.dataFim,
            certificate: objData.certificado,
            certificateUrl: objData.certificadoUrl,
            status: objData.status,
            credentialCode: objData.codigoCredencial,
            credentialUrl: objData.linkCredencial,
            userName: objData?.usuario?.nomeCompleto || '',
            statusRequest: objData.statusAprovacao,
            createdAt: objData.createdAt,
            reason: objData.descricaoJustificativa,
            justification: typeJustification[objData.tipoJustificativa] || ''
        };
    };
    return {
        list,
        adminList,
        // Getters
        loading,
        // Actions
        save,
        getAll,
        remove,
        getById,
        sendReplyRequest,
        getAdminList
    };
});
export default useCertificadosExternos;
