import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["d", "fill"];
import { onMounted } from 'vue';
const defaultPath = "M80 0C80 0 84.2846 41.2925 101.496 58.504C118.707 75.7154 160 80 160 80C160 80 118.707 84.2846 101.496 101.496C84.2846 118.707 80 160 80 160C80 160 75.7154 118.707 58.504 101.496C41.2925 84.2846 0 80 0 80C0 80 41.2925 75.7154 58.504 58.504C75.7154 41.2925 80 0 80 0Z";
export default /*@__PURE__*/ _defineComponent({
    __name: 'spark',
    props: {
        size: {},
        appliedStyle: {},
        color: {},
        appliedKey: {},
        path: {}
    },
    emits: ['remove'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        onMounted(() => {
            setTimeout(() => {
                emit('remove');
            }, 600);
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: "sparkleWrapper",
                style: _normalizeStyle(_ctx.appliedStyle),
                key: _ctx.appliedKey
            }, [
                (_openBlock(), _createElementBlock("svg", {
                    width: _ctx.size,
                    height: _ctx.size,
                    viewBox: "0 0 160 160",
                    fill: "none",
                    class: "sparkle"
                }, [
                    _createElementVNode("path", {
                        d: _ctx.path || defaultPath,
                        fill: _ctx.color
                    }, null, 8, _hoisted_2)
                ], 8, _hoisted_1))
            ], 4));
        };
    }
});
