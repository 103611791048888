import { aliareApi } from '@/config/appAPIs';
class AliareService {
    tenantsPath = 'api/Tenant';
    peoplePath = 'api/People';
    constructor() { }
    async getTenants() {
        const response = await aliareApi.tenant.get(this.tenantsPath);
        return response;
    }
    async sendNewPassword(data) {
        return await aliareApi.users.post(`/aliare-auth/api/User/CreatePassword`, data);
    }
    async confirmEmail(data) {
        return await aliareApi.users.post(`/aliare-auth/api/User/ConfirmEmail`, data);
    }
    async getSegments() {
        const result = (await aliareApi.users.get(`aliare-backoffice/api/v1/Segment`));
        return (result || []);
    }
    async authenticatePortalAliare() {
        try {
            return await aliareApi.portal.post('/api/Login');
        }
        catch (error) {
            console.log({ error });
        }
    }
}
const aliareService = new AliareService();
export default aliareService;
