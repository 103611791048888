import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "space-y-2 px-4"
};
const _hoisted_2 = { class: "flex items-center gap-x-2 mb-2" };
const _hoisted_3 = { class: "font-semibold text-black dark:text-neutral-300" };
const _hoisted_4 = { class: "text-xs italic text-gray-500 dark:text-neutral-400" };
const _hoisted_5 = { class: "grid grid-cols-1 md:grid-cols-2 gap-4" };
const _hoisted_6 = ["onClick", "onKeyup"];
const _hoisted_7 = ["src"];
const _hoisted_8 = { class: "flex-grow" };
const _hoisted_9 = { class: "text-sm font-semibold text-black dark:text-neutral-300" };
const _hoisted_10 = { class: "text-xs text-gray-500 dark:text-neutral-400 line-clamp-4" };
const _hoisted_11 = { class: "shrink-0 text-neutral-600 group-hover:text-[--c-primary-500]" };
const _hoisted_12 = { key: 0 };
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { getMediaThumbnail, learnUrl } from '@/common/util';
import UiButton from '@/components/ui/button.vue';
import UiIcon from '@/components/ui/icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'topbarSearchItem',
    props: {
        itemList: {},
        title: {}
    },
    emits: ['close'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const router = useRouter();
        const emit = __emit;
        const showAll = ref(false);
        const visibleList = computed(() => (showAll.value ? props.itemList : props.itemList.slice(0, 4)));
        const onSelect = (item) => {
            if (!['course', 'trail'].includes(item.type)) {
                router.push({
                    name: 'main',
                    hash: `#${item.id}`
                });
            }
            else {
                router.push(`/about/${learnUrl.encode(item.type, item.id)}`);
            }
            emit('close');
        };
        return (_ctx, _cache) => {
            const _directive_auto_animate = _resolveDirective("auto-animate");
            return (_ctx.itemList)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.title)), 1),
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.itemList.length), 1)
                    ]),
                    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleList.value, (item) => {
                            return (_openBlock(), _createElementBlock("a", {
                                key: item.id,
                                tabindex: "0",
                                href: "#",
                                class: "flex items-center gap-x-2 p-2 rounded-md border bg-neutral-100 dark:bg-neutral-700 border-transparent hover:border-[--c-primary-500] focus:border-[--c-primary-500] cursor-pointer group",
                                onClick: _withModifiers(() => onSelect(item), ["prevent"]),
                                onKeyup: _withKeys(_withModifiers(() => onSelect(item), ["prevent"]), ["enter"])
                            }, [
                                _createElementVNode("img", {
                                    src: item.thumbnail || _unref(getMediaThumbnail)(item.type),
                                    class: "w-12 h-12 rounded-md"
                                }, null, 8, _hoisted_7),
                                _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("div", _hoisted_9, _toDisplayString(item.title), 1),
                                    _createElementVNode("div", _hoisted_10, _toDisplayString(item.description), 1)
                                ]),
                                _createElementVNode("div", _hoisted_11, [
                                    _createVNode(UiIcon, { icon: "arrow-right" })
                                ])
                            ], 40, _hoisted_6));
                        }), 128))
                    ])), [
                        [_directive_auto_animate]
                    ]),
                    (_ctx.itemList.length > 4)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createVNode(UiButton, {
                                type: "ghost",
                                class: "p-2",
                                tabindex: "0",
                                icon: "arrow-right",
                                onClick: _cache[0] || (_cache[0] = () => showAll.value = !showAll.value)
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('button.seeAll')), 1)
                                ]),
                                _: 1
                            })
                        ]))
                        : _createCommentVNode("", true)
                ]))
                : _createCommentVNode("", true);
        };
    }
});
