export default {
    autoplay: false,
    volume: 1,
    settings: ['captions', 'quality', 'speed', 'loop'],
    controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'captions',
        'settings',
        'pip',
        'airplay',
        'fullscreen',
        'capture'
    ],
    speed: { selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5, 2] },
    quality: {
        default: 720,
        options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240],
        forced: false,
        onChange: null
    },
    i18n: {
        restart: 'Reiniciar',
        rewind: 'Retroceder {seektime}s',
        play: 'Play',
        pause: 'Pause',
        fastForward: 'Avançar {seektime}s',
        seek: 'Procurar',
        seekLabel: '{currentTime} de {duration}',
        played: 'Reproduzido',
        buffered: 'Alocado',
        currentTime: 'Tempo atual',
        duration: 'Duração',
        volume: 'Volume',
        mute: 'Mudo',
        unmute: 'Desmutar',
        enableCaptions: 'Habiliar legendas',
        disableCaptions: 'Desabilitar legendas',
        download: 'Baixar',
        enterFullscreen: 'Modo tela cheia',
        exitFullscreen: 'Sair do modo tela cheia',
        frameTitle: 'Reprodutor de {title}',
        captions: 'Legendas',
        settings: 'Configurações',
        pip: 'Picture in Picture',
        menuBack: 'Voltar ao menu anterior',
        speed: 'Velocidade',
        normal: 'Normal',
        quality: 'Qualidade',
        loop: 'Loop',
        start: 'Inicio',
        end: 'Fim',
        all: 'Tudo',
        reset: 'Resetar',
        disabled: 'Desabilitar',
        enabled: 'Abilitar',
        advertisement: 'Ad',
        qualityBadge: {
            2160: '4K',
            1440: 'HD',
            1080: 'HD',
            720: 'HD',
            576: 'SD',
            480: 'SD'
        }
    },
    displayDuration: true,
    ratio: '16:9'
};
