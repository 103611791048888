import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "modal-wrapper" };
const _hoisted_2 = { class: "mx-4 my-4 flex items-center" };
const _hoisted_3 = { class: "text-neutral-800 dark:text-neutral-300" };
const _hoisted_4 = { class: "grow overflow-auto px-6" };
import { ref } from 'vue';
import { onKeyStroke, useFocusWithin } from '@vueuse/core';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeDrawer',
    props: {
        title: {},
        modelValue: { type: Boolean },
        direction: { default: 'rtl' },
        width: { default: '25%' },
        closeOnClickOut: { type: Boolean, default: true },
        closeOnEsc: { type: Boolean, default: true },
        beforeClose: { type: Function, default: () => true }
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const mask = ref();
        const container = ref();
        const { focused } = useFocusWithin(mask);
        const emit = __emit;
        const close = () => {
            if (typeof props.beforeClose === 'function' && props.beforeClose() === false)
                return;
            emit('update:modelValue', false);
        };
        onKeyStroke('Escape', () => focused.value && props.closeOnEsc && close());
        return (_ctx, _cache) => {
            const _component_pe_icon = _resolveComponent("pe-icon");
            return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
                _createVNode(_Transition, {
                    "enter-from-class": "scale-x-150 opacity-0",
                    "leave-to-class": "scale-x-150 opacity-0",
                    "enter-active-class": "transition duration-300",
                    "leave-active-class": "transition duration-300"
                }, {
                    default: _withCtx(() => [
                        (_ctx.modelValue)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                ref_key: "mask",
                                ref: mask,
                                class: "fixed top-0 left-0 z-50 h-full w-full bg-black/20 dark:bg-white/30 transition-all",
                                tabindex: "0"
                            }, [
                                _createElementVNode("div", _hoisted_1, [
                                    _createElementVNode("div", {
                                        ref_key: "container",
                                        ref: container,
                                        class: _normalizeClass(["fixed flex h-full flex-col bg-neutral-50 dark:bg-neutral-800 shadow-[0_6px_20px_rgba(0,_0,_0,_0.2)] max-w-full", { 'right-0': _ctx.direction === 'rtl', 'left-0': _ctx.direction === 'ltr' }]),
                                        style: _normalizeStyle({ width: typeof _ctx.width === 'string' ? _ctx.width : `${_ctx.width}px` })
                                    }, [
                                        _createElementVNode("div", _hoisted_2, [
                                            _renderSlot(_ctx.$slots, "title", {}, () => [
                                                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1)
                                            ]),
                                            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "grow" }, null, -1)),
                                            _createElementVNode("button", {
                                                class: "inline-flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-0 bg-transparent text-neutral-400 transition-all hover:text-neutral-500",
                                                onClick: _cache[0] || (_cache[0] = ($event) => (close()))
                                            }, [
                                                _createVNode(_component_pe_icon, {
                                                    size: 32,
                                                    weight: 300,
                                                    icon: "close"
                                                })
                                            ])
                                        ]),
                                        _createElementVNode("div", _hoisted_4, [
                                            _renderSlot(_ctx.$slots, "default")
                                        ])
                                    ], 6)
                                ])
                            ], 512))
                            : _createCommentVNode("", true)
                    ]),
                    _: 3
                })
            ]));
        };
    }
});
