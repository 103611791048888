// Componentes padrão da PLANTAR
import PeAlert from '../components/PeAlert.vue';
import PeButton from '../components/PeButton.vue';
import PeButtonFeedback from '../components/PeButtonFeedback.vue';
import PeButtonGroup from '../components/PeButtonGroup.vue';
import PeCard from '../components/PeCard.vue';
import PeCheckbox from '../components/PeCheckbox.vue';
import PeCollapse from '../components/PeCollapse.vue';
import PeDataExporter from '../components/PeDataExporter.vue';
import PeDateFilter from '../components/PeDateFilter.vue';
import PeDatepicker from '../components/PeDatepicker.vue';
import PeDialog from '../components/PeDialog.vue';
import PeDrawer from '../components/PeDrawer.vue';
import PeDropdown from '../components/PeDropDown.vue';
import PeErrorMsg from '../components/PeErrorMsg.vue';
import PeEyeDropper from '../components/PeEyeDropper.vue';
import PeForm from '../components/PeForm.vue';
import PeFormArea from '../components/PeFormArea.vue';
import PeIcon from '../components/PeIcon.vue';
import PeInput from '../components/PeInput.vue';
import PeInputControl from '../components/PeInputControl.vue';
import PeInputPhoneNumber from '@/components/PeInputPhoneNumber.vue';
import PeInputSearch from '../components/PeInputSearch.vue';
import PeModal from '../components/PeModal.vue';
import PePrevCertificate from '@/components/PePrevCertificate.vue';
import PeProgress from '../components/PeProgress.vue';
import PeRadioGroup from '../components/PeRadioGroup.vue';
import PeSelect from '../components/PeSelect.vue';
import PeSelectModal from '../components/PeSelectModal.vue';
import PeSkeleton from '../components/PeSkeleton.vue';
import PeSlider from '../components/PeSlider.vue';
import PeSwitch from '../components/PeSwitch.vue';
import PeTable from '../components/PeTable.vue';
import PeTabs from '../components/PeTabs.vue';
import PeTooltip from '../components/PeTooltip.vue';
import PeDragdrop from '../components/PeDragdrop.vue';
import { dialog, toast } from '../common/util';
export default {
    install: (app) => {
        app
            .component('pe-alert', PeAlert)
            .component('pe-button', PeButton)
            .component('pe-button-feedback', PeButtonFeedback)
            .component('pe-button-group', PeButtonGroup)
            .component('pe-card', PeCard)
            .component('pe-checkbox', PeCheckbox)
            .component('pe-collapse', PeCollapse)
            .component('pe-data-exporter', PeDataExporter)
            .component('pe-date-filter', PeDateFilter)
            .component('pe-datepicker', PeDatepicker)
            .component('pe-dialog', PeDialog)
            .component('pe-dragdrop', PeDragdrop)
            .component('pe-drawer', PeDrawer)
            .component('pe-dropdown', PeDropdown)
            .component('pe-eye-dropper', PeEyeDropper)
            .component('pe-error-msg', PeErrorMsg)
            .component('pe-form', PeForm)
            .component('pe-form-area', PeFormArea)
            .component('pe-icon', PeIcon)
            .component('pe-input', PeInput)
            .component('pe-input-control', PeInputControl)
            .component('pe-input-phone-number', PeInputPhoneNumber)
            .component('pe-input-search', PeInputSearch)
            .component('pe-modal', PeModal)
            .component('pe-prev-certificate', PePrevCertificate)
            .component('pe-progress', PeProgress)
            .component('pe-radio-group', PeRadioGroup)
            .component('pe-select', PeSelect)
            .component('pe-select-modal', PeSelectModal)
            .component('pe-skeleton', PeSkeleton)
            .component('pe-slider', PeSlider)
            .component('pe-switch', PeSwitch)
            .component('pe-table', PeTable)
            .component('pe-tabs', PeTabs)
            .component('pe-tooltip', PeTooltip);
        // Notificações estilo toast
        app.config.globalProperties.$toast = toast;
        // Janela de confirmação estilo diálogo
        app.config.globalProperties.$dialog = dialog;
    }
};
