import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import dashboardService from '@/services/dashboard.service';
export const useDashboardStore = defineStore('dashboard', () => {
    const { t } = useI18n();
    const status = reactive({
        manager: 'initial',
        user: 'initial',
    });
    const lastUpdated = {
        manager: null,
        user: null
    };
    const managerData = reactive({
        content: {
            filters: {},
            subscriptions: {
                total: 0,
                complete: 0,
                completePercent: 0,
                onGoing: 0,
                onGoingPercent: 0,
                notStarted: 0,
                notStartedPercent: 0
            },
            contentSubscriptions: {
                data: [],
                total: 0
            },
            hours: {
                total: 0,
                complete: 0,
                completePercent: 0,
                onGoing: 0,
                onGoingPercent: 0
            },
            users: {
                total: 0,
                active: 0,
                activePercent: 0,
                inactive: 0,
                inactivePercent: 0
            },
            consumedContent: {
                total: 0,
                complete: 0,
                completePercent: 0,
                onGoing: 0,
                onGoingPercent: 0
            },
            access: {
                accessPerYear: [],
                total: 0
            }
        },
        details: {
            subscriptions: [],
            hours: [],
            consumedContent: [],
            access: []
        }
    });
    const userData = reactive({
        contentSubscriptions: {
            total: 0,
            data: []
        },
        hours: {
            total: 0,
            course: 0,
            coursePercentage: 0,
            formation: 0,
            formationPercentage: 0
        },
        onGoing: [],
        details: {
            subscriptions: [],
            hours: [],
            consumedContent: [],
            access: []
        },
        consumedContent: {
            total: 0,
            complete: 0,
            completePercent: 0,
            onGoing: 0,
            onGoingPercent: 0
        },
        access: {
            accessPerYear: [],
            total: 0
        }
    });
    const months = computed(() => ({
        'janeiro': t('months.jan'),
        'fevereiro': t('months.feb'),
        'março': t('months.mar'),
        'abril': t('months.apr'),
        'maio': t('months.may'),
        'junho': t('months.jun'),
        'julho': t('months.jul'),
        'agosto': t('months.aug'),
        'setembro': t('months.sep'),
        'outubro': t('months.oct'),
        'novembro': t('months.nov'),
        'dezembro': t('months.dec')
    }));
    const loadManagerData = (filters, forceRefresh) => {
        if (!forceRefresh && status.manager === 'loading')
            return Promise.resolve();
        status.manager = 'loading';
        managerData.content.filters = filters;
        return dashboardService
            .getManagerData(filters)
            .then(({ success, data }) => {
            if (success) {
                managerData.content.subscriptions = data.subscriptions;
                managerData.content.contentSubscriptions = data.contentSubscriptions;
                managerData.content.hours = data.hours;
                managerData.content.users = data.users;
                managerData.content.access = data.access;
                managerData.content.consumedContent = data.consumedContent;
                managerData.details.subscriptions = data.details.subscriptions;
                managerData.details.hours = data.details.hours;
                managerData.details.consumedContent = data.details.consumedContent;
                managerData.details.access = data.details.access;
            }
            status.manager = success ? 'success' : 'error';
            return success;
        })
            .catch((e) => {
            status.manager = 'error';
            console.log('error', e);
        })
            .finally(() => {
            lastUpdated.manager = performance.now();
        });
    };
    const loadUserData = (filters, forceRefresh) => {
        if (!forceRefresh && status.manager === 'loading')
            return Promise.resolve();
        status.user = 'loading';
        return dashboardService
            .getUserData(filters)
            .then(({ success, data }) => {
            if (success) {
                Object.assign(userData, data);
            }
            status.user = success ? 'success' : 'error';
            return success;
        })
            .catch((e) => {
            status.user = 'error';
            console.log('error', e);
        })
            .finally(() => {
            lastUpdated.user = performance.now();
        });
    };
    return {
        status,
        // Getters
        getConsumedContent: computed(() => managerData.content.consumedContent),
        getSubscriptions: computed(() => managerData.content.subscriptions),
        getSubscriptionsByContent: computed(() => managerData.content.contentSubscriptions),
        getHours: computed(() => managerData.content.hours),
        getUsers: computed(() => managerData.content.users),
        getDetailedSubscriptions: computed(() => managerData.details.subscriptions),
        getDetailedHours: computed(() => managerData.details.hours),
        getDetailConsumedContent: computed(() => managerData.details.consumedContent),
        getUserData: computed(() => userData),
        getAccess: computed(() => ({
            accessPerYear: managerData.content.access.accessPerYear.map(el => ({
                accessPerMonth: el.accessPerMonth.map(el => ({ total: el.total, month: months.value[el.month.toLowerCase()] })),
                total: el.total,
                year: el.year
            })),
            total: managerData.content.access.total
        })),
        getDetailAccess: computed(() => managerData.details.access),
        userGetSubscriptions: computed(() => userData.contentSubscriptions),
        userGetDetailedSubscriptions: computed(() => userData.details.subscriptions),
        userGetOnGoing: computed(() => userData.onGoing),
        userGetHours: computed(() => userData.hours),
        userGetDetailedHours: computed(() => userData.details.hours),
        getUserConsumedContents: computed(() => userData.consumedContent),
        getUserDetailContesumedContents: computed(() => userData.details.consumedContent),
        getUserAccess: computed(() => ({
            accessPerYear: userData.access.accessPerYear.map(el => ({
                accessPerMonth: el.accessPerMonth.map(el => ({ total: el.total, month: months.value[el.month.toLowerCase()] })),
                total: el.total,
                year: el.year
            })),
            total: userData.access.total
        })),
        getUserDetailAccess: computed(() => userData.details.access),
        // Actions
        loadManagerData,
        loadUserData
    };
});
export default useDashboardStore;
