import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "box-border h-screen overflow-auto p-4 flex flex-wrap items-center justify-center text-center" };
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = { class: "p-4 -mb-4" };
import { computed, ref } from 'vue';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
export default /*@__PURE__*/ _defineComponent({
    __name: 'carousel',
    props: {
        carousels: {}
    },
    setup(__props) {
        const props = __props;
        const slides = computed(() => {
            let temp = [];
            props.carousels.forEach((c, cIdx) => {
                c.slides.forEach(s => temp.push({ ...s, cIdx }));
            });
            return temp;
        });
        const validSlides = computed(() => {
            return slides.value?.filter(i => i?.image || i?.content || i.video?.url || i?.video?.url || i?.video?.mp4 || i?.video?.webm || i?.video?.ogv || i?.video?.avi);
        });
        const carousel = computed(() => {
            return props.carousels.filter(c => c.active && c.slides.length > 0)[0];
            // return props.carousels.filter(c => c.active && c.slides.length > 0).map((c, cIdx) => {
            //   c.slides.forEach(s => slides.value.push({ ...s, cIdx }))
            //   return c
            // })[0]
        });
        const slideIdx = ref(0);
        const beforeSlide = (opt) => {
            const { nextSlide } = opt;
            slideIdx.value = nextSlide?.index || 0;
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", null, [
                (carousel.value)
                    ? (_openBlock(), _createBlock(_unref(VueperSlides), {
                        key: 0,
                        infinite: carousel.value?.infinite,
                        arrows: carousel.value?.arrows,
                        duration: carousel.value?.duration,
                        touchable: carousel.value?.touchable,
                        autoplay: carousel.value?.autoPlay && validSlides.value.length > 1,
                        "pause-on-hover": carousel.value?.pauseOnHover,
                        "pause-on-touch": carousel.value?.pauseOnTouch,
                        progress: carousel.value?.progress,
                        class: "h-screen no-shadow overflow-hidden transition-colors duration-1000",
                        style: _normalizeStyle({ background: slides.value?.[slideIdx.value]?.bgColor ?? carousel.value?.bgColor ?? 'transparent' }),
                        "fixed-height": "100vh",
                        onBeforeSlide: beforeSlide
                    }, {
                        bullet: _withCtx(({ active }) => [
                            _createElementVNode("span", _hoisted_3, [
                                _createElementVNode("span", {
                                    class: _normalizeClass(["inline-block h-3 rounded-full transition-all w-3", active ? '!w-6 bg-[--c-primary-600]' : ' bg-transparent border border-solid border-white'])
                                }, null, 2)
                            ])
                        ]),
                        default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(validSlides.value, (slide, index) => {
                                return (_openBlock(), _createBlock(_unref(VueperSlide), {
                                    key: index,
                                    image: slide?.image,
                                    link: slide?.link,
                                    openInNew: slide?.openInNew,
                                    content: slide?.content,
                                    video: (slide?.video?.url || slide?.video?.mp4 || slide?.video?.webm || slide?.video?.ogv || slide?.video?.avi) ? slide?.video : null,
                                    duration: slide?.duration,
                                    class: "transition"
                                }, _createSlots({ _: 2 }, [
                                    (!slide.video)
                                        ? {
                                            name: "content",
                                            fn: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_1, [
                                                    _createElementVNode("div", {
                                                        innerHTML: slide.content
                                                    }, null, 8, _hoisted_2)
                                                ])
                                            ]),
                                            key: "0"
                                        }
                                        : undefined
                                ]), 1032, ["image", "link", "openInNew", "content", "video", "duration"]));
                            }), 128))
                        ]),
                        _: 1
                    }, 8, ["infinite", "arrows", "duration", "touchable", "autoplay", "pause-on-hover", "pause-on-touch", "progress", "style"]))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
