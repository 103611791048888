import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { inject } from 'vue';
import { RouterLink } from 'vue-router';
export default /*@__PURE__*/ _defineComponent({
    __name: 'menuItem',
    props: {
        closeOnSelect: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        to: { default: undefined }
    },
    emits: ['action'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const parentMenu = inject('UiMenu', {
            closeOnSelect: true,
            close: () => { }
        });
        const action = (event) => {
            if (props.disabled)
                return event.stopImmediatePropagation();
            if (props.closeOnSelect && parentMenu.closeOnSelect)
                parentMenu.close();
            emit('action');
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.to && !_ctx.disabled ? _unref(RouterLink) : 'div'), {
                to: _ctx.to,
                class: _normalizeClass(["__menu-item", { disabled: _ctx.disabled }]),
                onClick: action
            }, {
                default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
            }, 8, ["to", "class"]));
        };
    }
});
