import { defineComponent as _defineComponent } from 'vue';
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
import { ref, onMounted, watch } from 'vue';
import { Canvas, StaticCanvas, IText } from 'fabric';
import { i18N } from '@/common/util';
// interface CourseItem {
//   name: string
//   duration: string
//   type: string
//   items?: CourseItem[]
// }
// interface TrailItem {
//   name: string
//   items: CourseItem[]
// }
export default /*@__PURE__*/ _defineComponent({
    __name: 'backPage',
    props: {
        width: { default: 640 },
        height: { default: 480 },
        courseItems: { default: () => ([]) },
        trailItems: { default: () => ([]) },
        editable: { type: Boolean, default: false }
    },
    setup(__props) {
        const canv = ref();
        let fabricCanvas = null;
        watch(() => [__props.width, __props.height, __props.courseItems, __props.trailItems], () => {
            if (fabricCanvas) {
                fabricCanvas.clear();
                redraw();
            }
        }, { deep: true });
        const redraw = () => {
            if (!fabricCanvas)
                return;
            const text = new IText(i18N('labels.syllabus'), {
                left: 32,
                top: 32,
                fontSize: 24,
                fontFamily: 'Arial',
                fontWeight: '700',
            });
            fabricCanvas.add(text);
            let top = 72;
            // Cursos
            if (__props.courseItems.length) {
                for (const item of __props.courseItems) {
                    const text = new IText('• ' + item?.name + (item?.duration ? ` - ${item.duration}` : ''), {
                        left: 32,
                        top,
                        fontSize: 18,
                        fontFamily: 'Arial',
                        fontWeight: '600',
                    });
                    fabricCanvas.add(text);
                    top += 24;
                }
            }
            // Trilhas
            if (__props.trailItems.length) {
                for (const item of __props.trailItems) {
                    const text = new IText('• ' + item?.name, {
                        left: 32,
                        top,
                        fontSize: 18,
                        fontFamily: 'Arial',
                        fontWeight: '600',
                    });
                    fabricCanvas.add(text);
                    top += 24;
                    for (const course of item.items) {
                        const text = new IText('  • ' + course?.name + (course?.duration ? ` - ${course.duration}` : ''), {
                            left: 48,
                            top,
                            fontSize: 16,
                            fontFamily: 'Arial',
                            fontWeight: '500',
                        });
                        fabricCanvas.add(text);
                        top += 20;
                        if (course.items) {
                            for (const subCourse of course.items) {
                                const text = new IText('    • ' + subCourse?.name + (subCourse?.duration ? ` - ${subCourse.duration}` : ''), {
                                    left: 64,
                                    top,
                                    fontSize: 14,
                                    fontFamily: 'Arial',
                                    fontWeight: '400',
                                });
                                fabricCanvas.add(text);
                                top += 20;
                            }
                        }
                    }
                    top += 8;
                }
            }
        };
        onMounted(() => {
            fabricCanvas = !__props.editable ? new StaticCanvas(canv.value) : new Canvas(canv.value);
            fabricCanvas.backgroundColor = '#fefefe';
            redraw();
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("canvas", {
                ref_key: "canv",
                ref: canv,
                width: _ctx.width,
                height: _ctx.height,
                class: "max-w-full !h-auto",
                style: _normalizeStyle({ aspectRatio: `${_ctx.width}/${_ctx.height}`, height: 'auto' })
            }, null, 12, _hoisted_1));
        };
    }
});
