import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { dialog, toast } from '@/common/util';
import cursosService from '@/services/cursos.service';
import { courseService } from '@/services/cursos.service';
const defaultStatus = {
    create: 'initial',
    update: 'initial',
    delete: 'initial',
    getById: 'initial',
    getAll: 'initial',
    associations: 'initial',
    subscriptions: 'initial',
    favorites: 'initial',
    reaction: 'initial'
};
export const useCursosStore = defineStore('cursos', () => {
    const { t } = useI18n();
    /**
     * States
     */
    const list = ref([]);
    const status = reactive({ ...defaultStatus });
    /**
     * Getters
     */
    const loading = computed(() => Object.values(status).some(el => el === 'loading'));
    /**
     * Actions
     */
    const create = async (objData, file) => {
        status.create = 'loading';
        try {
            const { data, success } = await cursosService.create(objData);
            const result = data;
            if (file)
                await cursosService.fileUpload(result.id, file);
            if (success) {
                status.create = 'success';
                getAll();
            }
            return data;
        }
        catch {
            status.create = 'error';
        }
    };
    const update = async (objData, id, file) => {
        status.update = 'loading';
        try {
            const { data, success } = await cursosService.update(id, objData);
            if (success && file)
                await cursosService.fileUpload(id, file);
            if (success) {
                status.update = 'success';
                const value = list.value.find(el => el.id === data.id);
                value && Object.assign(value, data);
            }
            return data;
        }
        catch {
            status.update = 'error';
        }
    };
    const clear = () => {
        list.value.splice(0, list.value.length);
        Object.assign(status, { ...defaultStatus });
    };
    const getAll = async () => {
        status.getAll = 'loading';
        try {
            const { data } = await cursosService.get();
            status.getAll = 'success';
            list.value = data;
        }
        catch {
            status.getAll = 'error';
        }
    };
    const remove = async (curso, force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.course').toLowerCase(), name: curso.titulo }),
                complete: () => remove(curso, true)
            });
        try {
            status.delete = 'loading';
            const { success } = await cursosService.remove(curso.id);
            if (success)
                list.value.splice(list.value.findIndex(el => el.id === curso.id), 1);
            status.delete = 'success';
            return success;
        }
        catch {
            status.delete = 'error';
        }
    };
    const getById = async (id) => {
        try {
            status.getById = 'loading';
            const { data } = await cursosService.get(id);
            status.getById = 'success';
            return data;
        }
        catch {
            status.getById = 'error';
        }
    };
    const exportCursos = async () => {
        try {
            return await cursosService.exportCsv();
        }
        catch {
            //
        }
    };
    const getAssociations = async (cursoId) => {
        status.associations = 'loading';
        try {
            const { data } = await cursosService.getBonds(cursoId);
            status.associations = 'success';
            return data;
        }
        catch {
            status.associations = 'error';
        }
    };
    const sendSubscriptions = async (data, inscrever) => {
        status.subscriptions = 'loading';
        try {
            const { success } = await cursosService.sendSubscriptions(data, inscrever);
            if (success) {
                toast.success({
                    message: inscrever
                        ? t('toasts.inscriptions.subscribe', [data.usuariosIds.length])
                        : t('toasts.inscriptions.unsubscribe', [data.usuariosIds.length])
                });
                status.subscriptions = 'success';
            }
        }
        catch {
            status.subscriptions = 'error';
            throw new Error('Erro ao inscrever alunos!');
        }
    };
    const setSubscription = async (id, unsubscribe) => {
        status.subscriptions = 'loading';
        try {
            const { success } = (!unsubscribe ? await courseService.subscribe(id) : await courseService.unsubscribe(id));
            status.subscriptions = 'success';
            return success;
        }
        catch {
            status.subscriptions = 'error';
        }
    };
    const setFavorite = async (id, favorite) => {
        status.favorites = 'loading';
        try {
            const { success } = (favorite ? await courseService.bookmark(id) : await courseService.unbookmark(id));
            status.favorites = 'success';
            return success;
        }
        catch {
            status.favorites = 'error';
        }
    };
    const setReactionCourse = async (objData) => {
        status.reaction = 'loading';
        try {
            const data = await courseService.setReactionCourse(objData);
            status.reaction = 'success';
            return data;
        }
        catch {
            status.reaction = 'error';
        }
    };
    return {
        list,
        status,
        // Getters
        loading,
        // Actions
        create,
        update,
        clear,
        getAll,
        getById,
        remove,
        exportCursos,
        getAssociations,
        sendSubscriptions,
        setSubscription,
        setFavorite,
        setReactionCourse
    };
});
export default useCursosStore;
