import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { dialog } from '@/common/util';
import segmentacoesService from '@/services/segmentacoes.service';
export const useSegmentacoesStore = defineStore('segmentacoes', () => {
    const { t } = useI18n();
    /**
     * States
     */
    const list = ref([]);
    const associatedUsers = ref([]);
    const status = ref(0); // -1 erro de exceção; 0 - inicial; 1 - carregando; 2 - sucesso; 3 - erro não fatal
    const loading = computed(() => status.value === 1);
    /**
     * Actions
     */
    const create = async (dataObj) => {
        status.value = 1;
        try {
            const { success } = await segmentacoesService.post(null, getFormSubmit(dataObj));
            if (success)
                getAll();
            status.value = 2;
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    const update = async (dataObj) => {
        status.value = 1;
        try {
            const { data, success } = await segmentacoesService.put(null, getFormSubmit(dataObj));
            if (success) {
                const value = list.value.find(el => el.id === data.id);
                value && Object.assign(value, data);
            }
            status.value = 2;
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    const getAll = async () => {
        status.value = 1;
        try {
            const { data } = await segmentacoesService.get();
            list.value = data;
            status.value = 2;
        }
        catch {
            status.value = -1;
        }
    };
    const remove = async (segmentacao, force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.segmentation').toLowerCase(), name: segmentacao.nome }),
                complete: () => remove(segmentacao, true)
            });
        try {
            status.value = 1;
            const { success } = await segmentacoesService.delete(segmentacao.id);
            if (success)
                list.value.splice(list.value.findIndex(el => el.id === segmentacao.id), 1);
            status.value = 2;
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    const getById = async (id) => {
        status.value = 1;
        try {
            const { data } = await segmentacoesService.get(id);
            status.value = 2;
            return {
                ...data,
                segmentacoesUnidadesDeNegocios: data.segmentacoesUnidadesDeNegocios.map(el => el.unidadeDeNegocioId),
                segmentacoesCargos: data.segmentacoesCargos.map(el => el.cargoId),
                segmentacoesDepartamentos: data.segmentacoesDepartamentos.map(el => el.departamentoId),
                segmentacoesGeneros: data.segmentacoesGeneros.map(el => el.genero),
                segmentacoesUsuarios: data.segmentacoesUsuarios.map(el => el.usuarioId)
            };
        }
        catch {
            status.value = -1;
        }
    };
    const getFormSubmit = (data) => {
        return {
            ...data,
            segmentacoesUnidadesDeNegocios: data.segmentacoesUnidadesDeNegocios.map(el => ({ unidadeDeNegocioId: el })),
            segmentacoesCargos: data.segmentacoesCargos.map(el => ({ cargoId: el })),
            segmentacoesDepartamentos: data.segmentacoesDepartamentos.map(el => ({ departamentoId: el })),
            segmentacoesGeneros: data.segmentacoesGeneros.map(el => ({ genero: el })),
            segmentacoesUsuarios: data.segmentacoesUsuarios.map(el => ({ usuarioId: el })),
        };
    };
    const getSegmentationUsers = async (segmentationId) => {
        status.value = 1;
        try {
            const { data } = await segmentacoesService.getSegmentationUsers(segmentationId);
            status.value = 2;
            associatedUsers.value = data;
        }
        catch {
            status.value = -1;
        }
    };
    return {
        list,
        associatedUsers,
        status,
        loading,
        // Actions
        create,
        update,
        getAll,
        getById,
        remove,
        getSegmentationUsers
    };
});
export default useSegmentacoesStore;
