import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "mr-2 inline h-5 w-5 flex-shrink-0",
    fill: "currentColor",
    viewBox: "0 0 20 20",
    xmlns: "http://www.w3.org/2000/svg"
};
const _hoisted_2 = { class: "grow" };
import { computed, onMounted } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeAlert',
    props: {
        modelValue: {},
        type: {},
        icon: { type: Boolean },
        title: {},
        text: {},
        duration: {},
        unclosable: { type: Boolean }
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        const AlertTypes = ['primary', 'secondary', 'info', 'success', 'warning', 'danger'];
        const props = __props;
        const emit = __emit;
        const currentType = computed(() => {
            const type = props.type || 'primary';
            return AlertTypes.find((el) => el === type);
        });
        const showAlert = computed(() => props.modelValue || typeof props.modelValue === 'undefined');
        const hide = () => {
            emit('update:modelValue', false);
        };
        onMounted(() => {
            if (props.duration)
                setTimeout(() => hide(), props.duration);
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_Transition, null, {
                default: _withCtx(() => [
                    (showAlert.value)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["pe-alert flex", currentType.value])
                        }, [
                            (_ctx.icon)
                                ? (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
                                    _createElementVNode("path", {
                                        "fill-rule": "evenodd",
                                        d: "M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z",
                                        "clip-rule": "evenodd"
                                    }, null, -1)
                                ])))
                                : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_2, [
                                _renderSlot(_ctx.$slots, "default")
                            ]),
                            (!_ctx.unclosable)
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 1,
                                    type: "button",
                                    class: "pe-alert--close -mx-1.5 -my-1.5 inline-flex h-8 w-8 cursor-pointer border-0 bg-transparent p-1.5",
                                    "data-dismiss-target": "#alert-1",
                                    "aria-label": "Close",
                                    onClick: hide
                                }, _cache[1] || (_cache[1] = [
                                    _createElementVNode("span", { class: "sr-only" }, "Close", -1),
                                    _createElementVNode("svg", {
                                        class: "w-5 h-5",
                                        fill: "currentColor",
                                        viewBox: "0 0 20 20",
                                        xmlns: "http://www.w3.org/2000/svg"
                                    }, [
                                        _createElementVNode("path", {
                                            "fill-rule": "evenodd",
                                            d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                                            "clip-rule": "evenodd"
                                        })
                                    ], -1)
                                ])))
                                : _createCommentVNode("", true)
                        ], 2))
                        : _createCommentVNode("", true)
                ]),
                _: 3
            }));
        };
    }
});
