import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["viewBox", "width", "height"];
const _hoisted_2 = { id: "mask01" };
const _hoisted_3 = ["r", "cx", "cy", "stroke-width", "stroke-dasharray"];
const _hoisted_4 = ["transform"];
const _hoisted_5 = ["cx", "cy", "r", "stroke-width"];
const _hoisted_6 = ["cx", "cy", "r", "stroke-dasharray", "stroke-width"];
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'circleProgress',
    props: {
        radius: {},
        progress: {},
        stroke: {},
        segments: {}
    },
    setup(__props) {
        const props = __props;
        const normalizedSegments = computed(() => (props.segments || 10));
        const segmentsMask = computed(() => `${(1000 - normalizedSegments.value * 5) / normalizedSegments.value} 5`);
        const segmentedProgress = computed(() => `${(((props.progress || 0) / normalizedSegments.value) | 0) * normalizedSegments.value * (normalizedSegments.value)} 1000`);
        const normalizedRadius = computed(() => props.radius - props.stroke * 2);
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("svg", {
                class: "",
                viewBox: `0 0 ${_ctx.radius * 2} ${_ctx.radius * 2}`,
                width: _ctx.radius * 2,
                height: _ctx.radius * 2,
                xmlns: "http://www.w3.org/2000/svg"
            }, [
                _createElementVNode("defs", null, [
                    _createElementVNode("mask", _hoisted_2, [
                        _createElementVNode("circle", {
                            id: "c1",
                            r: normalizedRadius.value,
                            cx: _ctx.radius,
                            cy: _ctx.radius,
                            fill: "none",
                            stroke: "white",
                            "stroke-width": _ctx.stroke,
                            "stroke-dasharray": segmentsMask.value,
                            pathLength: "1000"
                        }, null, 8, _hoisted_3)
                    ])
                ]),
                _createElementVNode("g", {
                    transform: `rotate(-90 ${_ctx.radius} ${_ctx.radius})`
                }, [
                    _createElementVNode("circle", {
                        class: "stroke-white",
                        fill: "transparent",
                        cx: _ctx.radius,
                        cy: _ctx.radius,
                        r: normalizedRadius.value,
                        "stroke-width": _ctx.stroke,
                        pathLength: "1000",
                        mask: "url(#mask01)"
                    }, null, 8, _hoisted_5),
                    _createElementVNode("circle", {
                        class: "stroke-emerald-600",
                        fill: "transparent",
                        cx: _ctx.radius,
                        cy: _ctx.radius,
                        r: normalizedRadius.value,
                        "stroke-dasharray": segmentedProgress.value,
                        "stroke-width": _ctx.stroke,
                        pathLength: "1000",
                        mask: "url(#mask01)"
                    }, null, 8, _hoisted_6)
                ], 8, _hoisted_4)
            ], 8, _hoisted_1));
        };
    }
});
