import { useLocalStorage } from '@vueuse/core';
import CryptoJs from 'crypto-js';
import { toast } from '@/common/util';
import baseApi from './base.api';
import BaseService from './base.service';
import { useAuthStore } from '@/stores';
export const usersService = {
    ...baseApi,
    resource: 'usuario',
    // @TODO: O Endpoint foi adicionado na service mas como os filtros não estão funcionando não sera usado no momento, 
    //mas ja fica implementado para uso futuro
    async getAll(opt, options) {
        const paramsDictionary = {
            tenantId: 'tenantId',
            active: 'ativo',
            name: 'nomeCompleto',
            search: 'search',
            usersIds: 'usuarioIds',
            businessUnitIds: 'unidadeDeNegocioIds',
            departmentIds: 'departamentoIds',
            positionIds: 'CargoIds',
            positionId: 'cargoId',
            partnerId: 'partnerId',
            gender: 'genero',
            genres: 'generos',
            page: 'page',
            size: 'size',
        };
        const paramsToSend = {};
        if (opt) {
            Object.keys(opt).forEach((key) => {
                if (typeof paramsDictionary[key] !== 'undefined' && typeof opt[key] !== 'undefined' && opt[key] !== null)
                    paramsToSend[paramsDictionary[key]] = opt[key];
            });
        }
        return usersService.get('', paramsToSend, options)
            .then((res) => ({
            status: res.success,
            page: res.pageNumber,
            size: res.pageSize,
            total: res.totalItems,
            pages: res.totalPages,
            data: res.data?.map((item) => ({
                id: item.id || null,
                name: item.nomeCompleto,
                active: item.active,
                email: item.email,
                cpf: item.cpf,
                phone: item.telefone,
                personType: item.tipoPessoa,
                hireDate: item.dataDeAdmissao,
                directSupervisor: item.superiorDireto,
                directSupervisorName: item.superiorDiretoNome,
                positionId: item.cargoId,
                positionName: item.cargoNome,
                departmentId: item.departamentoId,
                departmentName: item.departamentoNome,
                businessUnitId: item.unidadeDeNegocioId,
                businessUnitName: item.unidadeDeNegocioNome,
                permissionId: item.permissaoId,
                gender: item.genero,
                nickname: item.apelido,
                preferredLanguage: item.idiomaPreferido,
                birthDate: item.dataDeNascimento,
                notifyViaPush: item.notificarViaPush,
                notifyViaSMS: item.notificarViaSMS,
                ativo: item.ativo,
                peopleId: item.peopleId,
                photo: item.foto,
                createdAt: item.createdAt,
                tenantId: item.tenantId,
                educationLevel: item.escolaridade,
                skillsId: item.competenciasId,
                termsOfUseAcceptedDate: item.dataTermoDeUsoAceito,
                termsOfUseId: item.termoDeUsoId,
                isForeign: item.estrangeira,
                achievements: item.conquistas,
            }))
        }));
    }
};
class UsuariosService extends BaseService {
    constructor() {
        super({ resource: 'usuario' });
    }
    async recoverPassword(email) {
        return await this.plantarApi.get(`${this.resource}/forgotpassword/${email}`);
    }
    async getProgressUser() {
        return await this.plantarApi.get(`${this.resource}/progresso`);
    }
    async getUsersByTenant(tenantId, partnerId, pagination) {
        try {
            return await this.plantarApi.get(`${this.resource}`, {
                params: {
                    tenantId,
                    partnerId: partnerId,
                    page: pagination?.page || 1
                    // size: pagination?.pageSize || 10
                }
            });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] });
        }
    }
    async getSubscriptionsUser(userId) {
        return await this.plantarApi.get(`${this.resource}/inscricao/${userId}`);
    }
    async acceptTermOfUse(userId) {
        try {
            return await this.plantarApi.put(`${this.resource}/termoDeUso/${userId}`);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar aceitar o termo de uso' });
        }
    }
    async validateFileImport(file) {
        const formData = new FormData();
        formData.append('usersBatch', file);
        return await this.plantarApi.post(`${this.resource}/validate/batch`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    async importUsers(fileId) {
        try {
            return await this.plantarApi.post(`${this.resource}/import/batch/${fileId}`);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar importar dados!' });
        }
    }
    async getUserInfoNPS() {
        try {
            return await this.plantarApi.get(`${this.resource}/nps`);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] });
        }
    }
    async updateUserEmail(id, email) {
        try {
            return await this.plantarApi.put(`${this.resource}/updateEmail`, { id, newEmail: email, resetPassword: true });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar atualizar e-mail do usuário!' });
        }
    }
    async getUserAccounts() {
        try {
            const { success, data } = await this.plantarApi.get(`${this.resource}/useraccount`);
            const persistentInfo = useLocalStorage('userInfo', { multiplePartners: false }, { mergeDefaults: true });
            persistentInfo.value.multiplePartners = success && data.length > 1;
            return { data };
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao recuperar dados de contas do usuário!' });
        }
    }
    async setDefaultPartner(tenantId) {
        try {
            return await this.plantarApi.post(`${this.resource}/useraccount/tenant/${tenantId}`);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao tentar definir conta como padrão!' });
        }
    }
    async getPasswordCode(userId) {
        const hash = CryptoJs.SHA256(`${userId}_plataforma-aliare_secret`)
            .toString(CryptoJs.enc.Hex)
            .replace('-', '')
            .toLowerCase();
        try {
            const { data, success } = await this.plantarApi.get(`${this.resource}/${userId}/LinkResetPassword?hash=${hash}`);
            return {
                token: ((new URLSearchParams(data?.link || '')).get('token') || '').split(' ').join('+'),
                success
            };
        }
        catch (error) {
            return { success: false, token: '' };
        }
    }
    async changePassword(opts) {
        const { token, success: hasToken } = await this.getPasswordCode(opts.userId);
        if (!hasToken) {
            toast.error({ message: 'Erro ao tentar recuperar token de alteração de senha!' });
        }
        try {
            const { data, success } = await this.plantarApi.post(`${this.resource}/CreatePassword`, {
                userId: opts.userId,
                email: opts.email,
                password: opts.password,
                token,
                clientId: opts?.clientId || window?.configuration?.aliare?.credentials?.client_id || '',
                tenantId: opts?.tenantId || useAuthStore().getActiveTentant
            });
            return {
                success,
                data
            };
        }
        catch (error) {
            return {
                success: false,
                data: null
            };
        }
    }
}
const usuariosService = new UsuariosService();
export default usuariosService;
