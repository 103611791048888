import { defineStore, getActivePinia } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import router from '@/router';
import { useUsuariosStore, useGrupoDePermissoes, useContratosStore } from '@/stores';
import { getInitials } from '@/common/util';
import webStorage from '@/common/webStorage';
import authService from '@/services/aliare/auth.service';
import oidcService from '@/services/aliare/oidc.service';
const initialState = {
    access_token: '',
    expires_in: 0,
    refresh_token: '',
    scope: '',
    token_type: '',
    method: undefined
};
const initialStateClientMode = {
    tenant: '',
    contract: ''
};
const initialStatePerson = {
    nomeCompleto: '',
    email: '',
    cpf: '',
    telefone: '',
    dataDeAdmissao: '',
    superiorDireto: '',
    cargoId: '',
    departamentoId: '',
    unidadeDeNegocioId: '',
    ativo: false,
    tipoPessoa: 'Fisica',
    dataTermoDeUsoAceito: null,
    termoDeUsoId: null
};
export const useAuthStore = defineStore('auth', () => {
    const usuarioStore = useUsuariosStore();
    const grupoDePermissoes = useGrupoDePermissoes();
    const nStatus = reactive({
        authenticate: 'initial',
        getUserInfo: 'initial',
        getUserAccounts: 'initial'
    });
    /**
     * @EXPERIMENTAL: Persiste dados simples do usuário na useLocalStorage
     * Se o ID não esta preenchido significa que as informações do usuário não foram carregadas
     * useLocalStorage é reativo e compartilhado entre as abas do navegador
     */
    const PIInitialValue = {
        id: '',
        name: '',
        role: '',
        avatar: '',
        birthdate: null,
        lastUpdated: null,
        supervisor: {
            id: '',
            name: ''
        }
    };
    const pInfo = useLocalStorage('userInfo', PIInitialValue, { mergeDefaults: true });
    const resetPI = () => Object.assign(pInfo.value, PIInitialValue);
    const isDistribuidor = useLocalStorage('isDistribuidor', false);
    /**
     * States
     */
    const token = reactive({
        ...initialState,
        ...webStorage.getItem('token')
    });
    /**
     * @description: Atualiza o token no state e no localStorage
     * @param data tokenType | null
     * @param local boolean
     */
    const setToken = (data, local) => {
        Object.assign(token, data === null ? initialState : data);
        webStorage.setItem('token', data === null ? initialState : data, local ?? true);
    };
    const partnerIdPlantar = ref(window.configuration.plantar.partnerId);
    const tenantCurrentPartner = ref(webStorage.getItem('tenant-partner'));
    const clientMode = reactive({
        ...initialStateClientMode,
        ...webStorage.getItem('client-mode')
    });
    const userData = reactive(initialStatePerson);
    /**
     * Getters
     */
    const canAcceptTermOfUse = computed(() => (!userData.dataTermoDeUsoAceito && !!userData.termoDeUsoId));
    const userName = computed(() => userData.apelido || userData.nomeCompleto);
    const userInitials = computed(() => getInitials(userData.nomeCompleto) || '');
    const payload = computed(() => (token.access_token ? JSON.parse(window.atob(token.access_token.split('.')[1])) : {}));
    const loggedIn = computed(() => !!token.access_token);
    const accessToken = computed(() => token.access_token);
    const hasElevatedPrivileges = computed(() => (['N0', 'N1'].includes(payload.value?.Level) && !clientMode.tenant));
    // Modo exclusivo para usuário plantar que não esteja se passando por admin de um cliente
    const inAdminMode = computed(() => hasElevatedPrivileges.value || useContratosStore().isDistribuidor);
    const getTenantCurrentPartner = computed(() => tenantCurrentPartner.value);
    const getActiveTentant = computed(() => clientMode.tenant || tenantCurrentPartner.value || payload.value?.Tenant || '');
    const getUserAccounts = () => {
        nStatus.getUserAccounts = 'loading';
        const pInfo = useLocalStorage('userInfo', { multiplePartners: false }, { mergeDefaults: true });
        pInfo.value.multiplePartners = false;
        return usuarioStore
            .getUserAccounts()
            .then(accounts => {
            nStatus.getUserAccounts = 'success';
            if (accounts?.length && accounts.length === 1)
                setTenantIdPartner(accounts[0].tenantId);
        })
            .catch(() => {
            nStatus.getUserAccounts = 'error';
        });
    };
    /**
     * Actions
     */
    const authenticate = async (email, password, persist) => {
        nStatus.authenticate = 'loading';
        try {
            const result = (await authService.login({ username: email, password }));
            if (result?.access_token) {
                if (persist)
                    localStorage.setItem('remember', String(persist));
                setToken({ ...result, method: 'local' }, persist);
            }
            nStatus.authenticate = 'success';
            getUserAccounts();
            return result;
        }
        catch (e) {
            let err = e.response?.data?.error_description || 'Ocorreu um erro inesperado, tente novamente em alguns instantes.';
            if (err === 'invalid user')
                err = 'Usuário e/ou senha inválido(s)';
            nStatus.authenticate = 'error';
            throw err;
        }
    };
    const ssoAuthenticate = async (data) => {
        nStatus.authenticate = 'loading';
        const { access_token, expires_at: expires_in, token_type, refresh_token, scope } = data;
        setToken({
            access_token,
            expires_in,
            token_type,
            refresh_token,
            scope,
            method: 'sso'
        });
        nStatus.authenticate = 'success';
        await getUserAccounts();
        return true;
    };
    const refresh = async () => {
        // @TODO: Acredito que não seja necessário, mas verificar se o refresh token é do SSO ou local
        // pra saber se precisa chamar o silentRefresh do oidcService ou do authService
        try {
            const result = (await authService.silentRefresh(token.refresh_token));
            if (result?.access_token) {
                const isLocal = Boolean(localStorage.getItem('remember'));
                setToken({ ...result, method: 'local' }, isLocal);
                return true;
            }
            // await grupoDePermissoes.getPermissionUser(payload.value.sub)
        }
        catch {
            // Caso o refresh token tenha expirado ou ocorrido alguma falha, desloga o usuário
            router.push('/logout');
        }
        return false;
    };
    const getUserInfo = async (id) => {
        nStatus.getUserInfo = 'loading';
        const userId = payload.value?.IdDoUsuario !== id && !!payload.value?.IdDoUsuario ? payload.value?.IdDoUsuario : id;
        try {
            const data = await usuarioStore.getById(userId);
            Object.assign(userData, data);
            if (data.id) {
                resetPI();
                pInfo.value.id = data.id;
                pInfo.value.name = data.apelido || data.nomeCompleto;
                pInfo.value.role = data.cargoNome || '';
                pInfo.value.avatar = data.foto || '';
                pInfo.value.supervisor = {
                    id: data.superiorDireto || '',
                    name: data.superiorDiretoNome || ''
                };
                if (data?.dataDeNascimento)
                    pInfo.value.birthdate = new Date(data.dataDeNascimento);
                pInfo.value.lastUpdated = new Date();
                nStatus.getUserInfo = 'success';
            }
            else {
                nStatus.getUserInfo = 'error';
            }
            return data;
        }
        catch {
            nStatus.getUserInfo = 'error';
        }
    };
    const logout = async () => {
        resetPI();
        const isSSO = token.method === 'sso';
        usuarioStore.clearUserInfoNps();
        resetAllStates();
        setToken(null);
        exitClientMode();
        webStorage.removeItem('token');
        webStorage.removeItem('permissions');
        webStorage.removeItem('remember-me');
        webStorage.removeItem('showCommunication');
        isDistribuidor.value = false;
        window.sessionStorage.removeItem('filtersLearning');
        window.sessionStorage.removeItem('filtersSessLearning');
        removeTenantPartner();
        try {
            const ssoMode = (window?.configuration?.oidc?.sso_mode || '').trim();
            if (!['', '__Oidc.SsoMode__'].includes(ssoMode) && isSSO)
                await oidcService.signoutRedirect();
        }
        catch (error) {
            //
            console.log('erro signout SSO', error);
        }
    };
    const setClientMode = ({ contract, tenant }) => {
        resetAllStates();
        Object.assign(clientMode, { contract, tenant });
        webStorage.setItem('client-mode', { contract, tenant }, true);
    };
    const exitClientMode = () => {
        resetAllStates();
        setClientMode({ contract: '', tenant: '' });
        webStorage.removeItem('client-mode');
    };
    // Reseta todos os states do pinia (Ignorando AUTH)
    const resetAllStates = () => {
        const { _s: allStores } = getActivePinia();
        allStores?.forEach((store) => store.$id !== 'auth' && store.$id !== 'system' && store.$reset());
        grupoDePermissoes.setPermissions();
    };
    const setTenantIdPartner = (tenantId) => {
        webStorage.setItem('tenant-partner', tenantId, true);
        tenantCurrentPartner.value = tenantId;
    };
    const removeTenantPartner = () => {
        webStorage.removeItem('tenant-partner');
        tenantCurrentPartner.value = null;
    };
    return {
        nStatus,
        persistentInfo: pInfo,
        token,
        partnerIdPlantar,
        userLogged: userData,
        userData,
        hasElevatedPrivileges,
        // plantarMode,
        clientMode,
        // Getters
        payload,
        loggedIn,
        inAdminMode,
        getActiveTentant,
        accessToken,
        userName,
        userInitials,
        canAcceptTermOfUse,
        getTenantCurrentPartner,
        // Actions
        authenticate,
        ssoAuthenticate,
        refresh,
        logout,
        setClientMode,
        exitClientMode,
        getUserInfo,
        getUserAccounts,
        setTenantIdPartner
    };
});
export default useAuthStore;
