import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "pe-input" };
const _hoisted_2 = ["value", "placeholder", "disabled"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeInputSearch',
    props: {
        modelValue: {},
        icon: {},
        iconSize: {},
        iconWeight: {},
        disabled: { type: Boolean },
        placeholder: {}
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const updateInput = (event) => {
            emit('update:modelValue', event.target.value);
        };
        return (_ctx, _cache) => {
            const _component_pe_icon = _resolveComponent("pe-icon");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_pe_icon, {
                    class: "pe-input-icon",
                    size: _ctx.iconSize || 22,
                    weight: _ctx.iconWeight || 600
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.icon || 'search'), 1)
                    ]),
                    _: 1
                }, 8, ["size", "weight"]),
                _createElementVNode("input", {
                    type: "text",
                    value: _ctx.modelValue,
                    placeholder: _ctx.placeholder,
                    disabled: _ctx.disabled,
                    onInput: updateInput,
                    class: "placeholder-current placeholder:opacity-60"
                }, null, 40, _hoisted_2)
            ]));
        };
    }
});
