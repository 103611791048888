import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import pessoasService from '@/services/pessoas.service';
import { dialog } from '@/common/util';
export const usePessoasStore = defineStore('pessoas', () => {
    const { t } = useI18n();
    /**
     * States
     */
    const list = ref([]);
    const status = ref(0); // -1 erro de exceção; 0 - inicial; 1 - carregando; 2 - sucesso; 3 - erro não fatal
    /**
     * Actions
     */
    const create = async (dataObj) => {
        status.value = 1;
        try {
            const { data, success } = await pessoasService.create(dataObj);
            if (success)
                getAll();
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const update = async (dataObj, id) => {
        status.value = 1;
        try {
            const { data, success } = await pessoasService.update(id, dataObj);
            if (success) {
                const value = list.value.find(el => el.id === data.id);
                value && Object.assign(value, data);
            }
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const getAll = async () => {
        status.value = 1;
        try {
            const { data } = await pessoasService.get();
            list.value = data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const remove = async (pessoa, force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.person').toLowerCase(), name: pessoa.legalName }),
                complete: () => remove(pessoa, true)
            });
        try {
            status.value = 1;
            const { success } = await pessoasService.remove(pessoa.id);
            if (success)
                list.value.splice(list.value.findIndex(el => el.id === pessoa.id), 1);
            status.value = 2;
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    const getById = async (id) => {
        status.value = 1;
        try {
            const { data } = await pessoasService.get(id);
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const exportPessoas = async () => {
        status.value = 1;
        try {
            const csvFile = await pessoasService.exportCsv();
            return csvFile;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    return {
        list,
        status,
        // Actions
        create,
        update,
        getAll,
        getById,
        remove,
        exportPessoas
    };
});
export default usePessoasStore;
