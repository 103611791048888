import { toast } from '@/common/util';
import baseApi from './base.api';
const api = {
    ...baseApi,
    resource: 'scormacompanhamento',
    async getProgressScorm(midiaId, query) {
        try {
            return api.get(`/midia/${midiaId}`, { params: query });
        }
        catch (error) {
            toast.error({ message: 'Erro ao tentar buscar dados sobre o scorm!' });
            throw error;
        }
    }
};
export default api;
