import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "min-w-40 mt-1 rounded border border-solid border-neutral-100 bg-white text-black shadow-lg dark:border-neutral-600 dark:bg-neutral-800 dark:text-neutral-200" };
import { ref, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { computePosition, autoUpdate } from '@floating-ui/vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeDropDown',
    props: {
        position: { default: 'bottom' },
        disabled: { type: Boolean }
    },
    setup(__props) {
        const props = __props;
        const dropdownComponent = ref();
        const show = ref(false);
        const referenceEl = ref();
        const floatingEl = ref();
        const cleanup = ref();
        const updatePosition = () => {
            computePosition(referenceEl.value, floatingEl.value, {
                placement: props.position
            }).then(({ x, y }) => {
                Object.assign(floatingEl.value.style, {
                    left: `${x}px`,
                    top: `${y}px`,
                });
            });
        };
        watch(() => floatingEl.value, (nVal) => {
            if (nVal) {
                cleanup.value = autoUpdate(referenceEl.value, floatingEl.value, updatePosition, { elementResize: false });
            }
        });
        const changeShow = () => {
            cleanup.value?.();
            show.value = !show.value;
        };
        onClickOutside(dropdownComponent, () => {
            cleanup.value?.();
            show.value = false;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                ref_key: "dropdownComponent",
                ref: dropdownComponent,
                class: _normalizeClass(["inline-block", { 'pointer-events-none cursor-not-allowed': _ctx.disabled }])
            }, [
                _createElementVNode("span", {
                    ref_key: "referenceEl",
                    ref: referenceEl,
                    onClick: changeShow
                }, [
                    _renderSlot(_ctx.$slots, "default", { active: show.value })
                ], 512),
                _createVNode(_Transition, {
                    "enter-active-class": "transition ease-out duration-300 transform",
                    "enter-from-class": "opacity-0 -translate-y-4",
                    "enter-to-class": "opacity-100 translate-y-0",
                    "leave-active-class": "ease-in duration-200",
                    "leave-from-class": "opacity-100 translate-y-0",
                    "leave-to-class": "opacity-0 -translate-y-4"
                }, {
                    default: _withCtx(() => [
                        (show.value)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                ref_key: "floatingEl",
                                ref: floatingEl,
                                class: "absolute z-10 max-w-max"
                            }, [
                                _createElementVNode("div", _hoisted_1, [
                                    _renderSlot(_ctx.$slots, "content")
                                ])
                            ], 512))
                            : _createCommentVNode("", true)
                    ]),
                    _: 3
                })
            ], 2));
        };
    }
});
