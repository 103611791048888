import CryptoJs from 'crypto-js';
export function encodeToBase64(str) {
    return CryptoJs.enc.Base64.stringify(CryptoJs.enc.Utf8.parse(str));
}
export function decodeFromBase64(str) {
    return CryptoJs.enc.Utf8.stringify(CryptoJs.enc.Base64.parse(str));
}
export class PKCE {
    base64URLEncode(str) {
        return str.toString().replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }
    sha256(buffer) {
        return CryptoJs.SHA256(CryptoJs.enc.Utf8.parse(buffer));
    }
    generateAuthorizationKeys() {
        const code_verifier = this.base64URLEncode(CryptoJs.lib.WordArray.random(32));
        const code_challenge = this.base64URLEncode(this.sha256(code_verifier));
        return { code_challenge, code_verifier };
    }
}
export default new PKCE();
