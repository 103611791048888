import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, isRef as _isRef } from "vue";
const _hoisted_1 = { class: "text-neutral-900 dark:text-neutral-200 flex flex-col gap-8" };
const _hoisted_2 = { class: "flex w-full p-2 gap-4" };
const _hoisted_3 = { class: "stats" };
const _hoisted_4 = ["src"];
const _hoisted_5 = { class: "text-sm font-semibold" };
const _hoisted_6 = { class: "text-xs text-neutral-600" };
const _hoisted_7 = { class: "stats" };
const _hoisted_8 = ["src"];
const _hoisted_9 = { class: "text-sm font-semibold" };
const _hoisted_10 = { class: "text-xs text-neutral-600" };
const _hoisted_11 = { class: "stats" };
const _hoisted_12 = ["src"];
const _hoisted_13 = { class: "text-sm font-semibold" };
const _hoisted_14 = { class: "text-xs text-neutral-600" };
const _hoisted_15 = { class: "flex items-center justify-between mb-4" };
const _hoisted_16 = { class: "font-medium text-base" };
const _hoisted_17 = { key: 0 };
const _hoisted_18 = {
    key: 2,
    class: "flex flex-col gap-4"
};
const _hoisted_19 = { class: "flex gap-8" };
const _hoisted_20 = { class: "w-full" };
const _hoisted_21 = { class: "font-medium text-base mb-4" };
const _hoisted_22 = { key: 0 };
const _hoisted_23 = {
    key: 3,
    class: "mb-8 flex flex-col gap-4"
};
const _hoisted_24 = {
    key: 0,
    class: "w-full"
};
const _hoisted_25 = { class: "font-medium text-base mb-4" };
const _hoisted_26 = { class: "mb-8 flex flex-col gap-4" };
const _hoisted_27 = ["src"];
const _hoisted_28 = { class: "font-semibold mb-1" };
const _hoisted_29 = { key: 0 };
const _hoisted_30 = { class: "font-medium text-base-m-4" };
import { onMounted, watch } from 'vue';
import { useSessionStorage } from '@vueuse/core';
import { useGamificacaoStore, storeToRefs } from '@/stores';
import UiGameRank from '@/components/ui/gamification/rank.vue';
import UiGameAchievement from '@/components/ui/gamification/achievement.vue';
import UiAlert from '@/components/ui/alert.vue';
import ImgGamePoints from '@/assets/game/icon-points.svg';
import ImgGameRank from '@/assets/game/icon-rank.svg';
import ImgGameTrophy from '@/assets/game/icon-trophy.svg';
import ImgGameBg from '@/assets/game/game-bg.jpg';
import { useI18n } from 'vue-i18n';
export default /*@__PURE__*/ _defineComponent({
    __name: 'sidebar',
    setup(__props) {
        const gameBar = useSessionStorage('game-bar', false);
        const gamificationStore = useGamificacaoStore();
        const { userRank, usersRanks, trophies } = storeToRefs(gamificationStore);
        const { t } = useI18n();
        const refresh = () => {
            if (gamificationStore.userRank.status === 'initial')
                gamificationStore.getMyRank();
        };
        watch(() => gameBar.value, (val) => {
            if (val)
                refresh();
        });
        // Mock ongoing courses
        const onGoing = Array.from({ length: 0 }, () => ({
            name: ['Exemplo', 'Exemplo 2', 'Exemplo 3', 'Exemplo 4'][Math.floor(Math.random() * 4)],
            type: ['Cruso', 'Formacao'][Math.floor(Math.random() * 2)],
            img: 'https://images.unsplash.com/photo-1598112154392-8125edb6e71d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
        }));
        // Mock earn more points
        const earnMorePoints = Array.from({ length: 0 }, () => ({
            name: ['Exemplo', 'Exemplo 2', 'Exemplo 3', 'Exemplo 4'][Math.floor(Math.random() * 2)],
            type: ['Cruso', 'Formacao'][Math.floor(Math.random() * 4)],
            img: ['https://s3-alpha-sig.figma.com/img/dced/19bc/a3f212db452d0dcaccc4311208f9e40a?Expires=1684713600&Signature=HcJEdAvbvS4bztJCgvcy6VEoPHoCZepTJaEDQKDg0mctT59ffXsF2uy9Mqdx2j~16I-CIEIxijtGzRbeqr9KcrewUeTM1W5TqMUMco4KAAJGv76LAwlg5SNCeX5upYzaX3t3-f7rUXrUNXiKS9wfIjnlx34rUpNZQsIbFsJ6JywFax~y3M2f6-V4RNcvNbWLK5bMVKzE7~S9UnzC6RjgQInRoaj6heNXe5NlBoDteWtTtm8Cv0oRjVCxEfiQWT18SPGC9zg4mvSblKltm10jWruUv4TqNKeC7vfoUQs3zrmQhwjdv5oEQgXcO5eYn595ZlLBNqqqZpcpySM1hgR1Qg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4', 'https://s3-alpha-sig.figma.com/img/b144/f3b6/8f1f87ece59001f281129534163d311e?Expires=1684713600&Signature=oTRRC39jaH6TSYEDyKrC4l~DhQ4L1AB8dD2kXnJhR6lcMTQjLMMMsnhjbVFeVYQD6oLUwIiVhgYZbDpZbiv0yF5rxGEjTsmUuXPs8LTcRDTnB1cuOHyg5jFCqM6AZ9ZJoNHlJP3kp6Uro3JAH04K4wfU6GwDk2FRPEpgGahIXupny16tmqbQK6RCmgHrTEJrtiWNvQFptg7IhR1Vim6xDFQ2zGK44ulfPsJWriyM~SHTCAJsV8uY7Dyie8F5VJkRAo-zMo1d7Bn6CW13zpbvZs2ysxRimCFyLKjhQd07lB0mxVfV~D4i8cd1WBgwueUuh1NXNgCh7iifP47nrkNsoA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4'][Math.floor(Math.random() * 2)],
        }));
        onMounted(() => {
            refresh();
        });
        return (_ctx, _cache) => {
            const _component_RouterLink = _resolveComponent("RouterLink");
            const _component_pe_drawer = _resolveComponent("pe-drawer");
            return (_openBlock(), _createBlock(_component_pe_drawer, {
                modelValue: _unref(gameBar),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => (_isRef(gameBar) ? (gameBar).value = $event : null)),
                title: _ctx.$t('gamification.title'),
                width: 600
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", {
                            class: "flex h-[200px] items-center bg-cover",
                            style: _normalizeStyle({ backgroundImage: `url(${_unref(ImgGameBg)})` })
                        }, [
                            _createElementVNode("div", _hoisted_2, [
                                _createElementVNode("div", _hoisted_3, [
                                    _createElementVNode("img", {
                                        src: _unref(ImgGamePoints),
                                        alt: "points",
                                        width: "24",
                                        height: "24"
                                    }, null, 8, _hoisted_4),
                                    _createElementVNode("div", null, [
                                        _createElementVNode("div", _hoisted_5, _toDisplayString(Number(_unref(userRank).data?.score || 0).toLocaleString('pt-BR')), 1),
                                        _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)('gamification.points')), 1)
                                    ])
                                ]),
                                _createElementVNode("div", _hoisted_7, [
                                    _createElementVNode("img", {
                                        src: _unref(ImgGameRank),
                                        alt: "game rank"
                                    }, null, 8, _hoisted_8),
                                    _createElementVNode("div", null, [
                                        _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(userRank).data?.rank ? `${_unref(userRank).data?.rank} Lugar` : 'Indefinido'), 1),
                                        _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(t)('gamification.ranking')), 1)
                                    ])
                                ]),
                                _createElementVNode("div", _hoisted_11, [
                                    _createElementVNode("img", {
                                        src: _unref(ImgGameTrophy),
                                        alt: "game trophy"
                                    }, null, 8, _hoisted_12),
                                    _createElementVNode("div", null, [
                                        _createElementVNode("div", _hoisted_13, _toDisplayString(Number(_unref(userRank).data?.trophies || 0).toLocaleString('pt-BR')) + " conquistas", 1),
                                        _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(t)('labels.total')), 1)
                                    ])
                                ])
                            ])
                        ], 4),
                        _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_15, [
                                _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(t)('gamification.ranking')), 1),
                                _createVNode(_component_RouterLink, {
                                    to: "/gamificacao/jornada",
                                    class: "text-xs font-medium",
                                    onClick: _cache[0] || (_cache[0] = ($event) => (gameBar.value = false))
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(t)('gamification.fullJourney')), 1)
                                    ]),
                                    _: 1
                                })
                            ]),
                            (_unref(usersRanks).pageStatus[1] === 'loading')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_unref(t)('feedback.loadingRanking')), 1))
                                : (_unref(usersRanks).pageStatus[1] === 'success' && !_unref(usersRanks).page[1].length)
                                    ? (_openBlock(), _createBlock(UiAlert, {
                                        key: 1,
                                        type: "info"
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_unref(t)('gamification.status.emptyData')), 1)
                                        ]),
                                        _: 1
                                    }))
                                    : (_unref(usersRanks).pageStatus[1] === 'success' && _unref(usersRanks).page[1].length)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(usersRanks).page[1].slice(0, 3), (user) => {
                                                return (_openBlock(), _createBlock(UiGameRank, {
                                                    key: user.score,
                                                    rank: user.rank,
                                                    name: user.name,
                                                    role: user.role,
                                                    score: user.score,
                                                    img: user.img,
                                                    compact: ""
                                                }, null, 8, ["rank", "name", "role", "score", "img"]));
                                            }), 128))
                                        ]))
                                        : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("div", _hoisted_20, [
                                _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(t)('gamification.latestAchievements')), 1),
                                (_unref(trophies).pageStatus[1] === 'loading')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_unref(t)('feedback.loadingAchievements')), 1))
                                    : _createCommentVNode("", true),
                                (_unref(trophies).pageStatus[1] === 'error')
                                    ? (_openBlock(), _createBlock(UiAlert, {
                                        key: 1,
                                        type: "danger"
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_unref(t)('gamification.status.error')), 1)
                                        ]),
                                        _: 1
                                    }))
                                    : (_unref(trophies).pageStatus[1] === 'success' && !_unref(trophies).page[1].length)
                                        ? (_openBlock(), _createBlock(UiAlert, {
                                            key: 2,
                                            type: "info"
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_unref(t)('gamification.status.emptyData')), 1)
                                            ]),
                                            _: 1
                                        }))
                                        : (_unref(trophies).pageStatus[1] === 'success' && _unref(trophies).page[1].length)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(trophies).page[1].slice(0, 4), (achv, index) => {
                                                    return (_openBlock(), _createBlock(UiGameAchievement, {
                                                        key: index,
                                                        data: achv,
                                                        compact: "",
                                                        class: "text-xs"
                                                    }, null, 8, ["data"]));
                                                }), 128))
                                            ]))
                                            : _createCommentVNode("", true)
                            ]),
                            false
                                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                    _createElementVNode("div", _hoisted_25, _toDisplayString(_unref(t)('labels.onGoing')), 1),
                                    _createElementVNode("div", _hoisted_26, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(onGoing), (item, index) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                                key: index,
                                                class: "box-border h-14 px-2 flex items-center gap-4 rounded-md border border-solid border-neutral-200 bg-neutral-50 dark:border-neutral-400 dark:bg-neutral-700 dark:text-neutral-300"
                                            }, [
                                                _createElementVNode("img", {
                                                    src: item.img,
                                                    alt: "",
                                                    class: "h-10 w-10 object-cover rounded-full"
                                                }, null, 8, _hoisted_27),
                                                _createElementVNode("div", null, [
                                                    _createElementVNode("div", _hoisted_28, _toDisplayString(item.type), 1),
                                                    _createElementVNode("div", null, _toDisplayString(item.name), 1)
                                                ])
                                            ]));
                                        }), 128))
                                    ])
                                ]))
                                : _createCommentVNode("", true)
                        ]),
                        false
                            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                _createElementVNode("div", _hoisted_30, _toDisplayString(_unref(t)('gamification.earnMorePoints')), 1),
                                _createElementVNode("div", null, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(earnMorePoints), (items, index) => {
                                        return (_openBlock(), _createElementBlock("div", { key: index }, _cache[2] || (_cache[2] = [
                                            _createElementVNode("div", { class: "flex items-center gap-4 mb-4" }, [
                                                _createElementVNode("div", { class: "w-12 h-12 bg-neutral-200 rounded-full" }),
                                                _createElementVNode("div", { class: "flex flex-col gap-1" }, [
                                                    _createElementVNode("div", { class: "font-semibold" }, "Exemplo de atividade"),
                                                    _createElementVNode("div", { class: "text-xs text-neutral-600" }, "Exemplo de atividade")
                                                ])
                                            ], -1)
                                        ])));
                                    }), 128))
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ])
                ]),
                _: 1
            }, 8, ["modelValue", "title"]));
        };
    }
});
