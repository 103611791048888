import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { dialog, toast } from '@/common/util';
import secaoService from '@/services/secao.service';
import { useSecoesStore } from './index';
export const useSecoesAdminStore = defineStore('secoesAdmin', () => {
    const { t } = useI18n();
    const secoesStore = useSecoesStore();
    /**
     * States
     */
    const list = ref([]);
    const status = ref(0);
    const loading = computed(() => status.value === 1);
    const getAll = async () => {
        status.value = 1;
        try {
            const { data } = await secaoService.get();
            list.value = data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const create = async (dataObj, file) => {
        status.value = 1;
        try {
            const { success, data } = await secaoService.post(null, dataObj);
            const result = data;
            if (file)
                await secaoService.fileUpload(result.id, file);
            if (success) {
                toast.success({ message: t('toasts.createSuccess') });
                secoesStore?.emptySectionsPool();
                getAll();
            }
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const remove = async (secao, force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.section').toLowerCase(), name: secao.nome }),
                complete: () => remove(secao, true)
            });
        try {
            status.value = 1;
            const { success } = await secaoService.delete(secao.id);
            if (success) {
                toast.open({ type: 'success', message: t('toasts.removeSuccess') });
                list.value.splice(list.value.findIndex(el => el.id === secao.id), 1);
                secoesStore?.emptySectionsPool();
            }
            status.value = 2;
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    const update = async (dataObj, id, file) => {
        status.value = 1;
        try {
            const { data, success } = await secaoService.put(id, dataObj);
            if (success && file)
                await secaoService.fileUpload(id, file);
            if (success) {
                const value = list.value.find(el => el.id === data.id);
                value && Object.assign(value, data);
                toast.success({ message: t('toasts.updateSuccess') });
                secoesStore?.emptySectionsPool();
            }
            status.value = 2;
            return data;
        }
        catch {
            status.value = -1;
        }
    };
    const associateSections = async (idsSecoes) => {
        status.value = 1;
        try {
            //@TODO: Implementar lógica quando endpoint for construido
            return idsSecoes;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const getById = async (id) => {
        status.value = 1;
        try {
            const { data } = await secaoService.get(id);
            status.value = 2;
            return data;
        }
        catch {
            status.value = -1;
        }
    };
    const saveOrderSections = async (dataObj) => {
        status.value = 1;
        try {
            const { success } = await secaoService.sendOrderSections(dataObj);
            if (success) {
                toast.open({ type: 'success', message: t('toasts.sections.sortingSaved') });
                secoesStore?.emptySectionsPool();
                getAll();
            }
            status.value = 2;
        }
        catch {
            status.value = -1;
        }
    };
    const associationContracts = async (sectionId, contracstIds) => {
        status.value = 1;
        try {
            const { success } = await secaoService.associationContracts(sectionId, contracstIds);
            if (success)
                toast.open({ type: 'success', message: t('toasts.sections.contractLinked') });
            status.value = 2;
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    return {
        // State
        list,
        // Getters
        loading,
        // Actions
        saveOrderSections,
        getAll,
        create,
        update,
        remove,
        associateSections,
        getById,
        associationContracts,
    };
});
export default useSecoesAdminStore;
