import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { setupEndPoints } from '@/config/axiosConfig';
import router from '@/router';
// Plugins
import i18n from '@/plugins/i18n';
import plantarEducacao from '@/plugins/peComponents';
import { vMaska } from 'maska/vue';
import { createVfm } from 'vue-final-modal';
import datePicker from '@vuepic/vue-datepicker';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import { MotionPlugin } from '@vueuse/motion';
import mavonEditor from 'mavon-editor';
import VueEasyLightbox from 'vue-easy-lightbox';
import 'vue-final-modal/style.css';
import 'mavon-editor/dist/css/index.css';
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css';
import '@vuepic/vue-datepicker/dist/main.css';
// Simplebar
import Simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';
import App from './App.vue';
// Folha de estilos padrão
import '@/styles/app.scss';
// Icones (Material Symbols)
import 'material-symbols';
import storeReset from './stores/plugin/resetStore';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, BarElement } from 'chart.js';
// Custom Directives
import uiTooltip from '@/directives/tooltip';
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, BarElement);
const app = createApp(App);
const pinia = createPinia();
pinia.use(storeReset);
const vfm = createVfm();
app
    .use(i18n)
    .use(router)
    .use(pinia)
    .use(plantarEducacao)
    .use(mavonEditor)
    .use(vfm)
    .use(autoAnimatePlugin)
    .use(MotionPlugin)
    .use(VueEasyLightbox)
    .component('DatePicker', datePicker)
    .component('Simplebar', Simplebar)
    .directive('maska', vMaska)
    .directive('tooltip', uiTooltip);
// Isso aqui deve vir após a definição do pinia, e antes do app.mount pois alguns componentes dependem do de comunicação via axios
setupEndPoints();
app.mount('#app');
// current version
const cv = localStorage.getItem('v');
import { version } from './version';
if (!version || !cv || version !== cv) {
    console.log('Atualizando versão do app');
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('v', version);
    caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
    window.location.reload();
}
