import baseApi from './base.api';
const api = {
    ...baseApi,
    resource: 'SugestaoPesquisa',
    async postSuggestion(params) {
        return api.post('', {
            descricaoOpcional: params.suggestion,
            termoPesquisado: params.searchTerm,
            tipo: params.tipo || 'Sugestao'
        });
    }
};
export default api;
