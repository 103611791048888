import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue";
import { inject } from 'vue';
import { useSystemStore } from '@/stores/systemStore';
import pt from '@/components/ui/icons/flagBrazil.vue';
import en from '@/components/ui/icons/flagUnitedKingdom.vue';
import es from '@/components/ui/icons/flagSpain.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'langSelector',
    props: {
        text: {},
        asModal: { type: Boolean },
        size: {}
    },
    setup(__props) {
        const openLangSelector = inject('app.openLangSelector');
        const getFlag = (lang) => {
            switch (lang) {
                case 'pt':
                    return pt;
                case 'en':
                    return en;
                case 'es':
                    return es;
                default:
                    return pt;
            }
        };
        const systemStore = useSystemStore();
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                onClick: _cache[0] || (_cache[0] = ($event) => (_unref(openLangSelector)())),
                class: "flex justify-between items-center"
            }, [
                _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
                (_openBlock(), _createBlock(_resolveDynamicComponent(getFlag(_unref(systemStore).clientSettings.lang)), {
                    class: _normalizeClass({
                        'w-8 h-8': _ctx.size === 'lg',
                        'w-6 h-6': !_ctx.size || _ctx.size === 'md',
                        'w-4 h-4': _ctx.size === 'sm'
                    })
                }, null, 8, ["class"]))
            ]));
        };
    }
});
