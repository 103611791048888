import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { newDialog, toast } from "@/common/util";
import comunicacaoService from '@/services/comunicacao.service';
export const useComunicacoesStore = defineStore('comunicacoes', () => {
    const { t } = useI18n();
    const list = ref([]);
    const userCommunications = ref([]);
    const showCommunications = ref(false);
    const status = reactive({
        getAll: 'initial',
        create: 'initial',
        update: 'initial',
        delete: 'initial',
        getById: 'initial',
    });
    const loading = computed(() => Object.values(status).some(el => el === 'loading'));
    const create = async (communication) => {
        status.create = 'loading';
        try {
            const { data, success } = await comunicacaoService.post(null, getFormDataSubmit(communication));
            if (success) {
                toast.success({ message: t('toasts.createSuccess') });
                getAll();
            }
            status.create = 'success';
            return data;
        }
        catch {
            status.create = 'error';
        }
    };
    const update = async (communication, id) => {
        status.update = 'loading';
        try {
            const { data, success } = await comunicacaoService.put(id, getFormDataSubmit(communication));
            if (success) {
                toast.success({ message: t('toasts.updateSuccess') });
                const value = list.value.find(el => el.id === data.id);
                value && Object.assign(value, getFormatResponse(data));
            }
            status.update = 'success';
            return data;
        }
        catch {
            status.update = 'error';
        }
    };
    const getAll = async () => {
        status.getAll = 'loading';
        try {
            const { data } = await comunicacaoService.get();
            list.value = data.map(el => getFormatResponse(el));
            status.getAll = 'success';
        }
        catch {
            status.getAll = 'error';
        }
    };
    const remove = async (data, force) => {
        if (!force)
            return newDialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.communication').toLowerCase(), name: data.name }),
                complete: () => remove(data, true)
            });
        try {
            status.delete = 'loading';
            const { success } = await comunicacaoService.delete(data.id);
            if (success) {
                getAll();
                toast.success({ message: t('toasts.removeSuccess') });
            }
            status.delete = 'success';
            return true;
        }
        catch {
            status.delete = 'success';
        }
    };
    const getById = async (id) => {
        status.getById = 'loading';
        try {
            const { data } = await comunicacaoService.get(id);
            status.getById = 'success';
            return getFormatResponse(data);
        }
        catch {
            status.getById = 'error';
        }
    };
    const getFormDataSubmit = (communication) => {
        return {
            id: communication.id,
            titulo: communication.name,
            inicio: communication.initDate,
            termino: communication.endDate,
            exibirSempre: communication.forceDisplay,
            postagens: communication.posts.map((el, i) => (el.id ?? delete el.id) && ({
                id: el.id,
                titulo: el.title,
                html: el.content,
                imagem: {
                    data: el?.file?.split(',').at(-1),
                    mime: el?.fileType
                },
                linkImagem: el.linkImage,
                ordem: i,
            })),
            cargos: communication.jobTitles.map((el) => ({ cargoId: el })),
            departamentos: communication.departments.map((el) => ({ departamentoId: el })),
            unidadeDeNegocio: communication.units.map((el) => ({ unidadeDeNegocioId: el })),
            active: communication.active,
            publico: communication.publico,
            contratosComunicacoes: communication.contratosComunicacoes
        };
    };
    const getFormatResponse = (response) => {
        return {
            id: response.id,
            name: response.titulo,
            initDate: response.inicio?.split('+')[0],
            endDate: response.termino?.split('+')[0],
            posts: response.postagens.map((el) => ({
                id: el.id,
                title: el.titulo,
                content: el.html,
                linkImage: el.linkImagem,
                order: el.ordem,
                file: el.imagem?.data,
                fileType: el.imagem?.mime
            })),
            active: response.active,
            publico: response.publico,
            origem: response.origem,
            jobTitles: response.cargos.map((el) => el.cargoId),
            departments: response.departamentos.map((el) => el.departamentoId),
            units: response.unidadeDeNegocio.map((el) => el.unidadeDeNegocioId),
            forceDisplay: response.exibirSempre,
            dontDisplay: response.usuariosComunicacoes?.length > 0,
            contratosComunicacoes: response.contratosComunicacoes,
        };
    };
    const getUserCommunication = async (showAllCommunication = false) => {
        status.getAll = 'loading';
        try {
            const { data } = await comunicacaoService.getUserCommunication({
                exibirComunicacoesDesabilitadas: showAllCommunication
            });
            status.getAll = 'success';
            if (!showAllCommunication)
                showCommunications.value = data.length > 0;
            userCommunications.value = data.map((el) => getFormatResponse(el));
            return userCommunications.value;
        }
        catch (error) {
            status.getAll = 'error';
        }
    };
    const toggledontDisplay = async (communications) => {
        if (!communications.length)
            return;
        let displayList = [];
        let dontDiplayList = [];
        communications.forEach((el) => {
            const uc = userCommunications.value.find((uc) => uc.id === el.id);
            // Ignora se o valor atual for igual ao valor registrado anteriormente
            if (uc?.dontDisplay === el.dontDisplay)
                return;
            if (el.dontDisplay)
                dontDiplayList.push(el.id);
            else
                displayList.push(el.id);
        });
        if (displayList.length)
            await changeUserCommunicationsStatus(displayList, true);
        if (dontDiplayList.length)
            await changeUserCommunicationsStatus(dontDiplayList, false);
        showCommunications.value = false;
    };
    const changeUserCommunicationsStatus = async (ids, display) => {
        try {
            status.update = 'loading';
            const { success } = await comunicacaoService.changeUserCommunicationsStatus(ids, display);
            status.update = 'success';
            return success;
        }
        catch (error) {
            status.update = 'error';
        }
    };
    const associationContracts = async (comunicacaoId, contratosComunicacoesIds) => {
        status.update = 'loading';
        try {
            const { success } = await comunicacaoService.associationContracts(comunicacaoId, contratosComunicacoesIds);
            if (success)
                toast.open({ type: 'success', message: t('toasts.sections.contractLinked') });
            status.update = 'success';
            return success;
        }
        catch {
            status.update = 'error';
        }
    };
    return {
        list,
        status,
        userCommunications,
        showCommunications,
        loading,
        create,
        update,
        getAll,
        remove,
        getById,
        getUserCommunication,
        toggledontDisplay,
        associationContracts
    };
});
export default useComunicacoesStore;
