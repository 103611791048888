import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { dialog } from '@/common/util';
import cargosService from '@/services/cargos.service';
import { cargosServices } from '@/services/cargos.service';
export const useCargosStore = defineStore('cargos', () => {
    const { t } = useI18n();
    /**
     * States
     */
    const list = ref([]);
    const basicList = ref([]);
    const status = ref(0); // -1 erro de exceção; 0 - inicial; 1 - carregando; 2 - sucesso; 3 - erro não fatal
    const loading = computed(() => status.value === 1);
    /**
     * Getters
     */
    const lazyBasicList = computed(() => {
        if (status.value === 0) {
            getBasicList();
            return [{ value: '', label: 'Aguarde...' }];
        }
        return basicList.value;
    });
    /**
     * Actions
     */
    const create = async (dataObj) => {
        status.value = 1;
        try {
            const { data, success } = await cargosService.create(dataObj);
            if (success)
                getAll();
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const update = async (dataObj, id) => {
        status.value = 1;
        try {
            const { data, success } = await cargosService.update(id, dataObj);
            if (success) {
                const value = list.value.find(el => el.id === data.id);
                value && Object.assign(value, data);
            }
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const getAll = async () => {
        status.value = 1;
        try {
            const { data } = await cargosService.get();
            list.value = data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const remove = async (cargo, force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.jobTitle').toLowerCase(), name: cargo.nome }),
                complete: () => remove(cargo, true)
            });
        try {
            status.value = 1;
            const { success } = await cargosService.remove(cargo.id);
            if (success) {
                list.value.splice(list.value.findIndex(el => el.id === cargo.id), 1);
            }
            status.value = 2;
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    const getById = async (id) => {
        status.value = 1;
        try {
            const { data } = await cargosService.get(id);
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const exportCargos = async () => {
        status.value = 1;
        try {
            const csvFile = await cargosService.exportCsv();
            return csvFile;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const getBasicList = (params) => {
        status.value = 1;
        return cargosServices.getListBasic({ ...params })
            .then(({ data }) => {
            basicList.value = (data || []).map(c => ({ value: c.id, label: c.nome }));
            status.value = 2;
            return basicList.value;
        })
            .catch(() => { status.value = 3; });
    };
    return {
        list,
        status,
        loading,
        basicList,
        //Getters
        lazyBasicList,
        // Actions
        create,
        update,
        getAll,
        getById,
        remove,
        getBasicList,
        exportCargos
    };
});
export default useCargosStore;
