import certificadosUsuariosService from '@/services/certificadosUsuarios.service';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
export const useCertificadosUsuariosStore = defineStore('certificadosUsuarios', () => {
    const status = ref(0); // -1 erro de exceção; 0 - inicial; 1 - carregando; 2 - sucesso; 3 - erro não fatal
    const loading = computed(() => status.value === 1);
    const create = async (idCertificado, idUsuario, tipoCertificado, idCursoFormacao, certificate) => {
        status.value = 1;
        try {
            const { data, success } = await certificadosUsuariosService.createCertificateUser(idCertificado, idUsuario, tipoCertificado, idCursoFormacao, certificate);
            if (success) {
                status.value = 2;
                return data;
            }
        }
        catch (error) {
            status.value = -1;
        }
        return false;
    };
    return {
        loading,
        //
        create
    };
});
