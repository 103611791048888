import { aliareApi } from '@/config/appAPIs';
import tokenService from './token.service';
class AuthService {
    formData;
    signInUrl;
    constructor() {
        const { client_id, client_secret, grant_type, scope } = window.configuration?.aliare?.credentials || {};
        this.formData = {
            client_id,
            client_secret,
            grant_type,
            scope
        };
        this.signInUrl = 'connect/token';
    }
    getSignInUrl() {
        return this.signInUrl;
    }
    async login({ username, password }) {
        const payload = new URLSearchParams({
            ...this.formData,
            username: username.replace(/\s/g, ''),
            password: password
        });
        try {
            const { data } = await aliareApi.auth.post(this.signInUrl, payload);
            return data;
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async silentRefresh(refreshToken) {
        const payload = new URLSearchParams({
            ...this.formData,
            grant_type: 'refresh_token',
            refresh_token: refreshToken
        });
        const { data } = await aliareApi.auth.post('connect/token', payload);
        return data;
    }
    async logout() {
        tokenService.removeToken();
    }
}
const authService = new AuthService();
export default authService;
