import baseApi from './base.api';
import BaseService from './base.service';
class TermosDeUsoService extends BaseService {
    constructor() {
        super({ resource: 'termoDeUso' });
    }
}
const termosDeUsoService = new TermosDeUsoService();
export default termosDeUsoService;
export const termsService = {
    ...baseApi,
    resource: 'termoDeUso',
    getAll: () => termsService.get(''),
    /**
     * Aceita ou recusa os termos de uso.
     * @param id O ID do termo de uso.
     * @param inscriptionId O ID da inscrição (opcional, usado na sala de aula).
     * @param accept Flag indicando se os termos devem ser aceitos ou recusados. (default: true)
     * @returns Uma Promise que resolve para a resposta da chamada da API.
     */
    accept(id, inscriptionId, accept) {
        const action = (accept || typeof accept === 'undefined') ? 'accept' : 'decline';
        const url = [termsService.resource, action, id];
        if (inscriptionId)
            url.push(inscriptionId);
        return baseApi.put(url.join('/'));
    }
};
