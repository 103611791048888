import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, vModelText as _vModelText, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = ["type", "placeholder", "disabled", "data-maska"];
const _hoisted_2 = {
    key: 1,
    class: "pr-[14px] italic text-xs text-gray-500 dark:text-neutral-200 flex"
};
const _hoisted_3 = { key: 0 };
const _hoisted_4 = ["placeholder", "disabled", "rows"];
const _hoisted_5 = {
    key: 0,
    class: "text-length"
};
const _hoisted_6 = { key: 0 };
import { computed, useSlots } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PeInput',
    props: {
        modelValue: {},
        placeholder: {},
        prefix: {},
        suffix: {},
        type: {},
        textTransform: {},
        disabled: { type: Boolean },
        maxlength: {},
        simple: { type: Boolean },
        rows: {},
        inputClass: {},
        error: {},
        mask: {},
        countCharacters: { type: Boolean, default: true }
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const slots = useSlots();
        const emit = __emit;
        const currentLength = computed(() => {
            if (props.modelValue != null && props.modelValue != undefined) {
                return `${String(props.modelValue).length}`;
            }
            return 0;
        });
        const value = computed({
            get: () => props.modelValue,
            set: (val) => emit('update:modelValue', val)
        });
        const hasPrefix = computed(() => !!slots.prefix || !!props.prefix || false);
        const hasSuffix = computed(() => !!slots.suffix || !!props.suffix || false);
        const hasMaxLength = computed(() => Number(props.maxlength) >= 0);
        const cMask = computed(() => (Array.isArray(props.mask) ? JSON.stringify(props.mask) : props.mask));
        return (_ctx, _cache) => {
            const _directive_maska = _resolveDirective("maska");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["inline-flex items-center relative", {
                        'pe-input': !_ctx.simple,
                        'pe-input-simple': _ctx.simple,
                        error: !!_ctx.error,
                        disabled: _ctx.disabled
                    }])
            }, [
                (hasPrefix.value)
                    ? _renderSlot(_ctx.$slots, "prefix", {
                        key: 0,
                        class: "pl-3"
                    })
                    : _createCommentVNode("", true),
                (_ctx.type !== 'textarea')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (_ctx.type !== 'textarea')
                            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                key: 0,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((value).value = $event)),
                                type: _ctx.type,
                                placeholder: _ctx.placeholder,
                                disabled: _ctx.disabled,
                                "data-maska": cMask.value,
                                class: _normalizeClass(["pe-input-content w-full grow text-inherit", _ctx.inputClass])
                            }, null, 10, _hoisted_1)), [
                                [_vModelDynamic, value.value],
                                [_directive_maska]
                            ])
                            : _createCommentVNode("", true),
                        (_ctx.type !== 'number' && !_ctx.disabled && _ctx.countCharacters)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                                _createTextVNode(_toDisplayString(currentLength.value) + " ", 1),
                                (hasMaxLength.value)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, "/" + _toDisplayString(_ctx.maxlength), 1))
                                    : _createCommentVNode("", true)
                            ]))
                            : _createCommentVNode("", true)
                    ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _withDirectives(_createElementVNode("textarea", {
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((value).value = $event)),
                            placeholder: _ctx.placeholder,
                            disabled: _ctx.disabled,
                            rows: _ctx.rows,
                            class: _normalizeClass(["pe-input-content h-full grow resize-none", _ctx.inputClass])
                        }, null, 10, _hoisted_4), [
                            [_vModelText, value.value]
                        ]),
                        (!_ctx.disabled && _ctx.countCharacters)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                                _createTextVNode(_toDisplayString(currentLength.value), 1),
                                (hasMaxLength.value)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, "/" + _toDisplayString(_ctx.maxlength), 1))
                                    : _createCommentVNode("", true)
                            ]))
                            : _createCommentVNode("", true)
                    ], 64)),
                (hasSuffix.value)
                    ? _renderSlot(_ctx.$slots, "suffix", {
                        key: 3,
                        class: "pr-4"
                    })
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
