import BaseService from './base.service';
import { toast } from '@/common/util';
import baseApi from './base.api';
export const unidadesDeNegocioServices = {
    ...baseApi,
    resource: 'unidadeDeNegocio',
    async getListBasic(params) {
        try {
            return await unidadesDeNegocioServices.get(`Basic`, { ...params });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao buscar lista de unidades!' });
        }
    }
};
class UnidadesDeNegocioService extends BaseService {
    constructor() {
        super({ resource: 'unidadeDeNegocio' });
    }
}
const unidadesDeNegocioService = new UnidadesDeNegocioService();
export default unidadesDeNegocioService;
