import inscricoesService from '@/services/inscricoes.service';
import { defineStore } from 'pinia';
import { ref } from 'vue';
export const useInscricoesStore = defineStore('inscricoes', () => {
    /**
     * States
     */
    const status = ref(0); // -1 erro de exceção; 0 - inicial; 1 - carregando; 2 - sucesso; 3 - erro não fatal
    const validateFile = async (file) => {
        status.value = 1;
        try {
            const { data } = await inscricoesService.validateFile(file);
            status.value = 2;
            return data;
        }
        catch (error) {
            status.value = -1;
        }
    };
    const importSubscriptions = async (fileId) => {
        status.value = 1;
        try {
            const { data } = await inscricoesService.importSubscriptions(fileId);
            status.value = 2;
            return data;
        }
        catch {
            status.value = -1;
        }
    };
    return {
        status,
        // Actions
        validateFile,
        importSubscriptions
    };
});
